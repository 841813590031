import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import scss from './StackLineMemoryInfo.scss';
import IconMemory from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_memory_icon.svg';
import { Button, Col, Input, message, Modal, Row, Tooltip } from 'antd';
import AiIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_ai_icon.svg';
import StackAi from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_stack_ai_icon.svg';
import { ze } from 'utils/zhEn';
import { CS_STYLES } from 'utils/constants/style-constants';
import { sleep } from 'utils/sleep';
import { connect } from 'react-redux';
import { isNotNullish } from 'utils/nullish';

const StackLineMemoryInfo = ({
  reduxState,
  appId,
  crashId,
  rva,
  detailStackMemoryInfo,
  isMinidump,
}) => {
  const isSuper = !!reduxState.user.get('current').get('isSuper');

  const {
    lineNumber,
    lineStack,
    stackMemoryInfo,
    asmCodeInfo,
    stackVariable: stackVariableJsonStr,
    originAddress, // 来自移动端非minidump的memoryData
    originMemoryData, // 来自移动端非minidump的memoryData
  } = detailStackMemoryInfo || {};

  const [asmCodeExplainModalVisible, setAsmCodeExplainModalVisible] = useState(false);
  const [asmCodeExplainText, setAsmCodeExplainText] = useState('');
  const [asmCodeExplainLoading, setAsmCodeExplainLoading] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const rvaHex = useMemo(() => {
    const matchRes = (rva || '').match('0x[0-9A-Fa-f]+');
    return matchRes?.[0] || '';
  }, [rva]);

  const stackVariableList = useMemo(() => {
    if (!stackVariableJsonStr) {
      return [];
    }
    try {
      return JSON.parse(stackVariableJsonStr);
    } catch (e) {
      console.error('parse stackVariable failed. ', e);
      return [];
    }
  }, [stackVariableJsonStr]);

  const asmCodeLineDoms = useMemo(() => {
    const asmCodeLines = (asmCodeInfo || '').split('\n').map(x => x.trimEnd());
    return asmCodeLines.map((x, i) => {
      const isRvaHexMatched = rvaHex && x.startsWith(rvaHex);
      return <div
        key={i}
        style={isRvaHexMatched ? { color: CS_STYLES.PRIMARY_COLOR_ORANGE } : undefined }
      >{ x }</div>
    });
  }, [asmCodeInfo, rvaHex]);

  const hasStackVariable = !!stackVariableList && stackVariableList.length > 0;

  const hasAsmCode = !!asmCodeInfo;

  function onClickExplainAsmCodeByAi({ clearCache } = {}) {
    if (isNotNullish(abortController)) {
      abortController.abort();
      setAbortController(null);
    }
    setAsmCodeExplainModalVisible(true);
    setAsmCodeExplainText('');
    setAsmCodeExplainLoading(true);
    const url = '/api/aiPilot/explainAssemblyCode';
    const params = {
      appId,
      crashId,
      code: asmCodeInfo,
      clearCache,
    };
    const ac = new AbortController();
    setAbortController(ac);
    RestHelper.postEventStream(url, params, {
      signal: ac.signal,
      statusCodeToCallback: {
        500() {
          message.error(ze('后台正忙，请稍后重试','The server is busy, please try again later'));
        },
      },
      onmessage(event) {
        const rawData = event.data;
        if (!rawData) {
          return;
        }
        let data = rawData;
        try {
          data = JSON.parse(rawData);
        } catch (e) {
          console.error(`explainAsmCodeByAi error when parsing: `, rawData);
        }

        if (data) {
          setAsmCodeExplainLoading(false);
          setAsmCodeExplainText(oldValue => {
            if (!!data.trim() || !!oldValue) {
              return oldValue + data;
            } else {
              return oldValue;
            }
          });
        }
      },
    });
  }

  if (!detailStackMemoryInfo) {
    return null;
  }

  const asmCodeExplainModal = <Modal
    title={ze('AI解析反汇编', 'Explain assembly code with AI')}
    visible={asmCodeExplainModalVisible}
    width='1000px'
    footer={null}
    maskClosable={false}
    onCancel={() => {
      if (isNotNullish(abortController)) {
        abortController.abort();
        setAbortController(null);
      }
      setAsmCodeExplainModalVisible(false);
    }}
  >
    { asmCodeExplainLoading && <div>{ ze('正在生成结果...', 'Generating...') }</div> }
    { !asmCodeExplainLoading && <div>
      <Input.TextArea
        style={{
          maxHeight: '70vh',
          fontFamily: 'Consolas, Menlo, Monaco, “DejaVu Sans Mono”, “Courier New”, monospace',
      }}
        value={asmCodeExplainText}
        readOnly={true}
        autoSize={{
          minRows: 8,
        }}
      />
    </div> }
    { isSuper && <Button
      onClick={() => onClickExplainAsmCodeByAi({ clearCache: true })}
    >（超管）清除结果缓存</Button>}
  </Modal>;

  return <div className={scss.stackLineMemoryInfo}>
    <Row gutter={16}>
      { isMinidump && <Col span={16}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <IconMemory />
          <div>Memory</div>
        </div>
        <pre style={{ marginTop: '12px' }}>{ stackMemoryInfo }</pre>
      </Col> }
      <Col span={8}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div>Assembly Code</div>
          { hasAsmCode && <Tooltip
            title={ze('用AI解析反汇编', 'Explain assembly code with AI')}
          >
            <Button
              style={{
                width:'32px',
                height:'32px',
                paddingTop:'6px',
              }}
              icon={<StackAi />}
              onClick={() => onClickExplainAsmCodeByAi({ clearCache: false })}
            />
          </Tooltip> }
        </div>
        { hasAsmCode && <pre style={{ width: '100%', padding: '8px 20px' }}>{ asmCodeLineDoms }</pre> }
        { !hasAsmCode && <pre style={{ width: '100%', padding: '8px 20px' }}>(Not Collected)</pre> }
        { hasStackVariable && <div style={{ marginTop: '12px' }}>Stack Variables</div> }
        { hasStackVariable && <pre style={{ width: '100%', padding: '8px 20px' }}>{
          stackVariableList.map(({ variableName, variableValue }, i) => <div key={i}>
            <span style={{ marginRight: '1em' }}>{ variableName }:</span>
            <span>{ variableValue }</span>
          </div>)
        }</pre> }
      </Col>
    </Row>
    { asmCodeExplainModal }
  </div>;
};

StackLineMemoryInfo.propTypes = {
  /**
   * @type { { lineNumber: number, lineStack: string, stackMemoryInfo: string } }
   * */
  detailStackMemoryInfo: PropTypes.object,
};

const mapStateToProps = (state, router) => ({
  reduxState: state,
});

export default connect(mapStateToProps)(StackLineMemoryInfo);
