import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { renderBarChart, renderFakeLabel } from 'utils/Bar';
import EmptyData from 'components/commons/EmptyData';
import { formatNum } from 'utils/helper';
import { PLATFORM_ID } from 'utils/constants';
import { DownloadOutlined, DownOutlined, UpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, notification, Modal, Select, Row, Col, Space } from 'antd';
import XLSX from 'xlsx';
import { ze } from 'utils/zhEn';
import axios from "axios";
import cookie from "../../../../utils/cookie";
import { withTranslation } from 'react-i18next';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import moment from 'moment';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import reportEvent, { EVENT_ACTIONS, ReportEventUtil } from 'utils/reportEvent';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import SingleOrRangeDatePicker from 'components/exception/ProductSummaryPage/SingleOrRangeDatePicker';
import CountrySelect from 'components/exception/ProductSummaryPage/CountrySelect';
import { CsvUtil } from 'utils/csv-util';
import { VersionUtil } from 'utils/version-util';
import { CountryUtil } from 'utils/country';
import { orange } from '@ant-design/colors';
import { Tab } from 'components/exception/issue';
import RankIcon from 'svg/v2/newcs_dashboard_overview_crashrankinglist_icon.svg';
import { UpdateTimeTag } from '../UpdateTimeTag';
import CardHead from '../../../commons/CardHead';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import isEqual from 'lodash/lang/isEqual';
import CsTabs from 'components/antd-extension/CsTabs';
import { CsNewTabs } from 'components/antd-extension/CsNewTabs';
import { VmTypeUtil } from 'utils/constants/vm-type';
import { PlatformUtil } from 'utils/platform';
import VmTypeSelect from 'components/exception/ProductSummaryPage/VmTypeSelect';
import { isNotNullish } from 'utils/nullish';


const ALL_VERSION = '-1';

function getToken(tokenType) {
  // 根据tokenType切换返回的token信息。
  if (tokenType === 'node') return cookie.get('csrfToken');
  // php的token处理。
  const skey = cookie.get('token-skey');
  let hash = 5381;
  if (skey) {
    for (let i = 0, len = skey.length; i < len; ++i) {
      hash += (hash << 5 & 0x7fffffff) + skey.charAt(i).charCodeAt();
    }
    return hash & 0x7fffffff;
  }
}

function createExcel(data,title){
  const { utils } = XLSX;
  var ws_name = "sheet1";
  const wb= utils.book_new();
  /* make worksheet */
  var ws = utils.aoa_to_sheet(data);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, title);
}

@withTranslation()
export default class RankingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topDownloadSelection: false, //选择下载条数的弹框是否打开
      downloadModel: null,
      downloadName: null,
      topLimit: 50,
      currentTab: 'RANKINGLIST.userNumAffect',
      isContentVisible: false,
      downloadList: [],
    }
    this.pageTypeStr = {
      "崩溃": "EXCP_OVERVIEW.userCrashRate",
      "ANR": "EXCP_OVERVIEW.userANRRate",
      "错误": "EXCP_OVERVIEW.userErrorRate",
      "卡顿": "EXCP_OVERVIEW.userANRRate",
      "JANK": "EXCP_OVERVIEW.userJankRate",
    };
    this.key2Color = {
      app: '#5E67FF',
      system: '#4B81FE',
      model: '#5FB4FA',
      gpu: '#5FB4FA',
      cpu: '#5FD9FC'
    };
    this.allDownloads = [
      {
        value: 50,
        label: 50,
      },{
        value: 100,
        label: 100,
      },{
        value: 300,
        label: 300,
      },{
        value: 500,
        label: 500,
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    let isPropsChanged = false;
    let isNotEmpty = false;
    // 是否全部rank数组都为0
    const {appTag, systemTag, modelTag, gpuTag, cpuTag, pid } = nextProps;
    const dataKeys = parseInt(pid) === PLATFORM_ID.PC ? [appTag, systemTag, gpuTag, cpuTag] : [appTag, systemTag, modelTag];

    for (const key of dataKeys) {
      if (!isEqual(nextProps[`${key}Data`], this.props[`${key}Data`])) {
        isPropsChanged = true;
      }
      if (!!nextProps[`${key}Data`]?.length) {
        isNotEmpty = true;
      }
    }
    console.log('componentWillReceiveProps>>>>', nextProps, this.props, isPropsChanged, isNotEmpty);

    if (isPropsChanged && isNotEmpty && !this.state.isContentVisible) {
      this.setState({
        isContentVisible: true,
      });
    }
  }

  getPercent(crashUser, accessUser) { // (100 * o.crashUser / o.accessUser).toFixed(2)
    let result = 0;
    if (accessUser > 0 && crashUser >= 0) {
      let temp = (100 * crashUser / accessUser);
      result = temp.toFixed(2);
    }
    return result;
  }

  formatter(data) {
    const { pageType } = this.props;
    return (params) => {
      const index = params[0].dataIndex;
      const i = 4 - index;
      // console.log('formatter data and index', data, i, index);
      // data.rate存在 且有一个或以上内容的时候 才会进来这个函数了，所以这里只需要判断内容是否为空
      if (data.rate[index] < 0) {
        return `-<br>${this.props.t(this.pageTypeStr[pageType])} ： -<br>${this.props.t("EXCP_OVERVIEW.userNumAffect")} ： -<br>${this.props.t("EXCP_OVERVIEW.userNumConnect")} ： -`;
      }
      if (data.labels[i]){
        return `${data.labels[i]
        }<br>`
          + `${this.props.t(this.pageTypeStr[pageType])} ： ${data.rate[index]}%`
          + '<br>'
          + `${this.props.t("EXCP_OVERVIEW.userNumAffect")} ： ${formatNum(data.crash[i])}<br>`
          + `${this.props.t("EXCP_OVERVIEW.userNumConnect")} ： ${formatNum(data.access[i])}`;
      } else {
        return ''
      }
    };
  }

  parseData(data, key) {
    let origin = [];
    const crash = [];
    const access = [];
    const rate = [];
    const labels = [];

    data && (origin = data.filter((d) => (d.accessUser !== 0) || (d.crashUser !== 0)));
    if (origin.length > 0) {
      for (let i = 0; i < origin.length + 4; i++) {
        const o = origin[i];
        if (o) {
          crash.push(o.crashUser);
          access.push(o.accessUser);
          rate.push(this.getPercent(o.crashUser, o.accessUser));
          labels.push(o[key]);
        } else {
          crash.push('');
          access.push('');
          rate.push('');
          labels.push('');
        }
      }
      crash.length = 5;
      access.length = 5;
      rate.length = 5;
      labels.length = 5;
    }
    return {
      crash, access, rate, labels,
    };
  }

  renderBar(data, isRate, style, sortOption, key, key2Color, onClickBar) {
    if (data && data.length) {
      return (
        <>
          {renderBarChart(data, isRate, this.formatter(data, data.labels), sortOption, key, key2Color, onClickBar)}
        </>
      );
    } else {
      return (<EmptyData emptyImgVisiable={false} extraStyle={{paddingTop: 30}} text={ this.props.t("RANKINGLIST.noData") } />);
    }
  }
  /*******
   * 数据下载重载数据
   * *******/
  async handleDownloadData(){
    if (!this.state.downloadList.length) {
      message.error('No field data selected');
      return;
    }
    this.handleDownloadAllData();
  }

  //点击下载弹出选择下载条数
  downloadData(model,name) {
    this.setState({
      topDownloadSelection: true,
      downloadModel: model,
      downloadName: name,
    })
  }


  //点击折叠框全局下载弹出选择下载条数
  downloadAllData(list) {
    this.setState({
      topDownloadSelection: true,
      downloadList: list,
    })
  }

  async handleDownloadAllData() {
    this.topHandleCancel();
    const { actions, t, pageType, pid } = this.props;
    const { topLimit, downloadList} = this.state;

    const isJankCategory = ExceptionCategoryUtil.isJank(pageType);

    const promiseList = [];
    downloadList.forEach(item => {
      const s = item.tag.includes('Rate')?'rate':'access';
      const params = sessionStorage.getItem(`${item.key}-${s}`);
      const paramsAll = JSON.parse(params);
      promiseList.push(RestHelper.mustPost('/redir/api/statResource/fetchFieldTopStats', {
        ...paramsAll,
        limit: topLimit,
      }))
    })

    actions.showLoading();

    const categorySpecific = {
      system: {
        title: `${t('RANKINGLIST.异常排行系统版本TOP')}${topLimit}`,
        firstColumnLabel: t('RANKINGLIST.系统版本'),
        firstColumnProp: 'systemVersion',
      },
      model: {
        title: `${t('RANKINGLIST.异常排行设备TOP')}${topLimit}`,
        firstColumnLabel: t('RANKINGLIST.设备'),
        firstColumnProp: 'model',
      },
      app: {
        title: `${t('RANKINGLIST.异常排行应用版本TOP')}${topLimit}`,
        firstColumnLabel: t('RANKINGLIST.应用版本'),
        firstColumnProp: 'version',
      },
      gpu: {
        title: `${t('RANKINGLIST.异常排行gpuTop')}${topLimit}`,
        firstColumnLabel: 'GPU',
        firstColumnProp: 'displayCard',
      },
      cpu: {
        title: `${t('RANKINGLIST.异常排行cpuTop')}${topLimit}`,
        firstColumnLabel: 'CPU',
        firstColumnProp: 'cpu',
      },
    };
    try {
      let retList = await Promise.all(promiseList);
      const { utils } = XLSX;
      const workBook = utils.book_new();
      const excelTitle = `${t('RANKINGLISTOPTION.rankWithType', { type: ExceptionCategoryUtil.toI18n(pageType, pid) })} TOP${topLimit}.xlsx`;
      // const excelTitle = ze(`崩溃排行 TOP${topLimit}.xlsx`, `Crash Rank TOP${topLimit}.xlsx`)
      downloadList.forEach((item, index) => {
        const options =  categorySpecific[item.key]
        const { title, firstColumnLabel } = options;
        const { data } = retList[index].json;
        const header = [
          firstColumnLabel,
          !isJankCategory && t('RANKINGLIST.设备崩溃率'),
          isJankCategory && t('EXCP_OVERVIEW.occurNumJankRate'),
          !isJankCategory && t('RANKINGLIST.userNumAffect'),
          isJankCategory && t('EXCP_OVERVIEW.occurNum'),
          t('RANKINGLIST.userNumConnect'),
        ].filter(x => x);

        const dataRows = data.map((item) => {
          const rate = this.getPercent(item.exceptionDevices, item.accessDevices);
          const jankNumAverage = this.getPercent(item.exceptionNum, item.accessNum * 100);
          return [
            item.fieldValue || '',
            !isJankCategory ? `${rate}%` : null,
            isJankCategory ? (jankNumAverage || 0) : null,
            !isJankCategory ? (item.exceptionDevices || 0) : null,
            isJankCategory ? (item.exceptionNum || 0) : null,
            item.accessDevices || 0,
          ].filter(x => isNotNullish(x));
        });

        /* make worksheet */
        var workSheet = utils.aoa_to_sheet([header, ...dataRows]);
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, workSheet, title);

      })
      XLSX.writeFile(workBook, excelTitle);

      // 排行榜下载上报
      reportEvent({
        action: EVENT_ACTIONS.CLICK,
        tp1: '崩溃排行榜多Sheet下载',
        tp6: categorySpecific.firstColumnLabel,
      });
    } catch (error) {
      console.log('批量下载失败', error);
    }
    actions.hideLoading();
  }

  //点击弹框取消
  topHandleCancel() {
    this.setState({
      topDownloadSelection: false,
      downloadList: [], // 默认还原成空数组
    })
  }

  /**
   * 点击柱状图，跳转到对应的高级搜索页面
   * @param {string} key 字段key
   * @param {string} fieldOriginalValue 字段的原始值
   */
  onClickBar(key, fieldOriginalValue) {
    const { appId, pid, searchParams, selectOptions } = this.props;

    const keyToCondition = {
      app: { field: FieldName.version, queryType: QueryType.TERMS, terms: [fieldOriginalValue] },
      system: { field: FieldName.osVersion, queryType: QueryType.TERM, term: fieldOriginalValue },
      model: { field: FieldName.model, queryType: QueryType.TERM, term: fieldOriginalValue },
      cpu: { field: FieldName.cpu, queryType: QueryType.TERM, term: fieldOriginalValue },
      gpu: { field: FieldName.gpu, queryType: QueryType.TERM, term: fieldOriginalValue },
    };

    const condition = keyToCondition[key];
    if (!condition || !fieldOriginalValue) {
      console.error(`RankingList onClickBar, invalid key = ${key} or fieldOriginalValue = ${fieldOriginalValue}`);
      return;
    }

    const { pageType } = selectOptions.toJS();
    const exceptionCategory = ExceptionCategoryUtil.unify(pageType);

    const startDate3 = searchParams.get('startDate3');
    const rankMaxDate = searchParams.get('rankMaxDate');
    const minMoment = startDate3 ? moment(startDate3) : moment();
    const maxMoment = rankMaxDate ? moment(rankMaxDate) : minMoment;
    const uploadTimeGte = minMoment.valueOf();
    const uploadTimeLte = maxMoment.add(1, 'days').subtract(1, 'seconds').valueOf();

    const searchConditionGroup = {
      conditions: [
        { field: FieldName.crashUploadTime, queryType: QueryType.RANGE, gte: uploadTimeGte, lte: uploadTimeLte },
        { field: FieldName.exceptionCategory, queryType: QueryType.TERMS, terms: [exceptionCategory] },
        condition,
      ],
    };

    const query = new URLSearchParams({
      pid,
      searchConditionGroup: JSON.stringify(searchConditionGroup),
    }).toString();
    const url = `/crash-reporting/advanced-search/${appId}?${query}`;
    window.open(url, '_blank');
  }

  render() {
    const {
      appId, pid, style, onClickRankTag, appTag, t,
      tagTag, tagRateData, tagAccessData,
      systemTag, modelTag, gpuTag, cpuTag, appRateData, appAccessData, systemRateData, systemAccessData, modelRateData, modelAccessData, rankVersion,
      gpuRateData, gpuAccessData, cpuRateData, cpuAccessData, selectOptions, searchParams, reduxState, isRegionalDashboard, handleChangeRankSearchParams,
      pageType, changeTag, setSortOption, bigDataResultDailyUpdateMillis,
    } = this.props;

    const { rankVersion: rankVersionTree } = selectOptions.toJS();
    const versionTree = rankVersionTree.versionTree;
    const versionListValue = rankVersion !== ALL_VERSION
      ? CsvUtil.singleLineParse(rankVersion)
      : [];
    const isMultipleVersions = VersionUtil.isMultipleVersions(versionListValue);

    const vmTypeListValue = VmTypeUtil.vmSelectValueToVmTypeList(searchParams.get('rankVmSelectValue'));

    const teamType = reduxState.app.get('current').get('teamType');
    const [datePickerMinAvailableMoment, datePickerMaxAvailableMoment] = (teamType === 'COMMERCIAL')
      ? [moment().subtract(90, 'days').startOf('day'), moment().endOf('day')]
      : [moment().subtract(3, 'years').startOf('day'), moment().add(1, 'years').endOf('day')];
    const startDate3 = searchParams.get('startDate3');
    const rankMaxDate = searchParams.get('rankMaxDate');
    const minMoment = startDate3 ? moment(startDate3) : moment();
    const maxMoment = rankMaxDate ? moment(rankMaxDate) : minMoment;
    const isMultipleDates = !minMoment.isSame(maxMoment);

    const selectedCountryList = CountryUtil.queryStringToList(searchParams.get('rankCountry'));

    const subModuleId = searchParams.get('subModuleId') || '-1';

    const isJankCategory = ExceptionCategoryUtil.isJank(pageType);

    const pageTypeStr = {
      "崩溃": "EXCP_OVERVIEW.userCrashRate",
      "ANR": "EXCP_OVERVIEW.userANRRate",
      "错误": "EXCP_OVERVIEW.userErrorRate",
      "卡顿": "EXCP_OVERVIEW.userANRRate",
      "JANK": "EXCP_OVERVIEW.userJankRate",
    };

    let data = [
      {
        title: this.props.t("RANKINGLIST.appVersionTop5"),
        key: 'app',
        tag: appTag,
        appRateData,
        appAccessData,
        labelKey: 'version',
      },
      {
        title: this.props.t("RANKINGLIST.osVersionTop5"),
        key: 'system',
        tag: systemTag,
        systemRateData,
        systemAccessData,
        labelKey: 'osVersion',
      },
      {
        title: this.props.t("RANKINGLIST.hardwareTop5"),
        key: 'model',
        tag: modelTag,
        modelRateData,
        modelAccessData,
        labelKey: 'model',
      },
      /* {
        title: this.props.t("RANKINGLIST.tagTop5"),
        key: 'tag',
        tag: tagTag,
        tagRateData,
        tagAccessData,
        appAccessData,
        labelKey: 'tag',
      }, */
    ];

    if (parseInt(pid) === PLATFORM_ID.PC) {
      data = [
        {
          title: this.props.t("RANKINGLIST.appVersionTop5"),
          key: 'app',
          tag: appTag,
          appRateData,
          appAccessData,
          labelKey: 'version',
        },
        {
          title: this.props.t("RANKINGLIST.osVersionTop5"),
          key: 'system',
          tag: systemTag,
          systemRateData,
          systemAccessData,
          labelKey: 'osVersion',
        },
        {
          title: this.props.t("RANKINGLIST.gpuTop5"),
          key: 'gpu',
          tag: gpuTag,
          gpuRateData,
          gpuAccessData,
          labelKey: 'gpu',
        },
        {
          title: this.props.t("RANKINGLIST.cpuTop5"),
          key: 'cpu',
          tag: cpuTag,
          cpuRateData,
          cpuAccessData,
          labelKey: 'cpu',
        },
      ]
    }

    const tabOptions = [
      'RANKINGLIST.userNumAffect',
      pageTypeStr[pageType],
      'RANKINGLIST.userNumConnect',
    ]
      .map(value => {
        let label = t(value);
        if (isJankCategory && value === 'RANKINGLIST.userNumAffect') {
          label = t('EXCP_OVERVIEW.occurNum');
        } else if (isJankCategory && value === 'EXCP_OVERVIEW.userJankRate') {
          label = t('EXCP_OVERVIEW.occurNumJankRate');
        }
        return {
          value,
          label,
        };
      });


    return (
      <>
        {
          subModuleId !== '-1' ?
          <CardHead
            title={t('RANKINGLISTOPTION.rankWithType', { type: ExceptionCategoryUtil.toI18n(pageType, pid) })}
            hideDivider={!this.state.isContentVisible}
          >
            <div>{ ze('暂不支持子场景下的查询', 'Currently not available when sub module ID is set.') }</div>
          </CardHead> :
          <CardHead
              title={t('RANKINGLISTOPTION.rankWithType', { type: ExceptionCategoryUtil.toI18n(pageType, pid) })}
              hideDivider={!this.state.isContentVisible}
              svgIcon={<RankIcon />}
              iconExtraStyle={{height: '16px'}}
              onClick={() => {
                const newIsExpand = !this.state.isContentVisible;
                this.setState({ isContentVisible: newIsExpand });
                ReportEventUtil.reportCardHeadClickEvent('崩溃排行榜', false, newIsExpand);
              }}
            >
            <div style={{display: 'flex', flexGrow: '1', alignItems: 'center', justifyContent: 'end'}}>
              <UpdateTimeTag updateTime={bigDataResultDailyUpdateMillis} type="hour" />
              <CsDownloadButton onClick={(e) => {
                e.stopPropagation();
                this.downloadAllData(data);
              }} style={{ margin: '0px 24px'}} />
              {/* {
                this.state.isContentVisible ? <UpOutlined onClick={() => this.setState({ isContentVisible: false })} />
                  : <DownOutlined onClick={() => this.setState({ isContentVisible: true })} />
              } */}
              <CsCollapseButton
                extraStyle={{ width: '14px', height: '14px' }}
                isCollapsed={!this.state.isContentVisible}
                enableHover={true}
                onClick={(e) => {
                  e.stopPropagation();
                  const newIsExpand = !this.state.isContentVisible;
                  this.setState({ isContentVisible: newIsExpand });
                  ReportEventUtil.reportCardHeadClickEvent('崩溃排行榜', true, newIsExpand);
                }}
               />
            </div>
          </CardHead>
        }
        {
          this.state.isContentVisible &&
          <>
             <Row gutter={10} align='middle' style={{margin: '20px -5px'}}>
               {
                 PlatformUtil.isVmTypeSupported(pid) && <Col>
                   <VmTypeSelect
                     value={vmTypeListValue}
                     onChange={(v) => handleChangeRankSearchParams({ rankVmSelectValue: VmTypeUtil.vmTypeListToVmSelectValue(v) })}
                   />
                 </Col>
               }
              <Col style={{width: isRegionalDashboard ? 280 : 320}}>
                <VersionHybridSelect
                  style={{ width: '100%' }}
                  value={versionListValue}
                  treeData={versionTree}
                  onChange={(v) => handleChangeRankSearchParams({ rankVersion: CsvUtil.singleLineStringify(v) || ALL_VERSION })}
                />
              </Col>
              <Col style={{width: 240}}>
                <SingleOrRangeDatePicker
                  style={{ width: '100%' }}
                  allowClear={false}
                  disabledDate={moment => moment && (moment.isBefore(datePickerMinAvailableMoment) || moment.isAfter(datePickerMaxAvailableMoment))}
                  value={[minMoment, maxMoment]}
                  onChange={([minDate, maxDate]) => handleChangeRankSearchParams({
                    startDate3: minDate ? minDate.format('YYYYMMDD') : null,
                    rankMaxDate: (maxDate && !maxDate.isSame(minDate)) ? maxDate.format('YYYYMMDD') : null,
                  })}
                />
              </Col>
             {
              isRegionalDashboard && <Col style={{width: 260}}>
                <CountrySelect
                  style={{ width: '100%' }}
                  value={selectedCountryList}
                  isMultiple={true}
                  onChangeWhenDropdownClosed={(v) => handleChangeRankSearchParams({ rankCountry: CountryUtil.listToQueryString(v) })}
                />
              </Col>
             }
              <Col flex="auto">
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {/* <CsTabs
                    bigSize={true}
                    activeKey={this.state.currentTab}
                    onChange={(tag) => {
                      this.setState({
                        currentTab: tag,
                      });
                      ['system', 'model', 'app'].forEach(key => {
                        changeTag(`${key}${tag.includes('Rate') ? 'Rate' : 'Access'}`);
                      });
                      setSortOption(tag);
                    }}
                    items={tabOptions}
                  /> */}
                  <CsNewTabs options={tabOptions} value={this.state.currentTab} onChange={(value) => {
                    this.setState({
                      currentTab: value,
                    });
                    ['system', 'model', 'app'].forEach(key => {
                      changeTag(`${key}${value !== 'RANKINGLIST.userNumConnect' ? 'Rate' : 'Access'}`);
                    });
                    setSortOption(value);
                  }} />
                </div>
                {/* <Tab
                  tabs={['RANKINGLIST.userNumAffect', pageTypeStr[pageType], 'RANKINGLIST.userNumConnect']}
                  currentTab={this.state.currentTab}
                  tabType='trend'
                  style={style}
                  changeTab={(tag) => {
                    this.setState({
                      currentTab: tag,
                    });
                    ['system', 'model', 'app'].forEach(key => {
                      changeTag(`${key}${tag.includes('Rate') ? 'Rate' : 'Access'}`);
                    });
                    setSortOption(tag);
                  }}
                /> */}
              </Col>
            </Row>
            {
              (isMultipleVersions || isMultipleDates) && <div className='clearfix' style={{marginBottom: '20px'}}>
                  <div style={{
                  color: orange.primary, fontSize: '12px', float: 'left', display: 'inline-block', height: '24px', lineHeight: '24px' }}>
                  <Space>
                    <ExclamationCircleOutlined/>
                    <div>{this.props.t('EXCP_OVERVIEW.multipleVersionsDatesWarning')}</div>
                  </Space>
                </div>
              </div>
            }
            <ul className={style.bangdan}>
              {data.map((item) => {
                // 如果rankVersion 不是所有版本（即-1），不渲染top5 应用
                if (rankVersion !== '-1' && item.key === 'app') return null;
                const targetData = item[`${item.tag}Data`] || [];
                const isRate = this.state.currentTab.includes('Rate') && !isJankCategory; // 王者卡顿，卡顿是次数率，次数率显示为启动平均次数
                const parsedData = this.parseData(targetData, item.labelKey);
                return (
                  <li className={style.bangdan_item} key={item.key} style={{ height: targetData.length ? 280 : 168 }}>
                    {/* <div> */}
                      <div className={style.bangdan_title}>{item.title}</div>
                      {/* {parsedData.crash.length>0 &&
                      <Button onClick={()=>this.downloadData(item.key,item.tag)} style={{'marginLeft':'10px','marginBottom':'7px'}} icon={<DownloadOutlined style={{backgroundColor: '#fff'}} />}  size="small" />} */}
                    {/* </div> */}
                    <div className={style.bar_container}>
                      {this.renderBar(targetData, isRate, style, this.props.sortOption, item.key, this.key2Color, this.onClickBar.bind(this))}
                    </div>
                  </li>
                );
              })}
              <Modal
                title={ze('下载','download')}
                width={400}
                open={this.state.topDownloadSelection}
                onOk={() => this.handleDownloadData()}
                onCancel={() => this.topHandleCancel()}>
                <div style={{width:'100%',height:'50px',display:'flex',alignItems:'center'}}>
                  <div>{ze('数据下载条数:','Number of downloads')}</div>
                  <Select
                    defaultValue={this.state.topLimit}
                    style={{
                      flex:'1',
                      paddingLeft:'10px'
                    }}
                    onChange={(value) => this.setState({topLimit: value})}
                    options={this.allDownloads}
                  />
                </div>
              </Modal>
            </ul>
          </>
        }
      </>
    );
  }
}

RankingList.propTypes = {
  appId: PropTypes.string.isRequired,
  pid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  onClickRankTag: PropTypes.func.isRequired,
  appRateData: PropTypes.array,
  appAccessData: PropTypes.object,
  systemRateData: PropTypes.array,
  systemAccessData: PropTypes.object,
  modelRateData: PropTypes.array,
  modelAccessData: PropTypes.object,
  tagRateData: PropTypes.array,
  tagAccessData: PropTypes.object,
  gpuRateData: PropTypes.array,
  gpuAccessData: PropTypes.object,
  cpuRateData: PropTypes.array,
  cpuAccessData: PropTypes.object,
  rankVersion: PropTypes.string.isRequired,
  appTag: PropTypes.string,
  systemTag: PropTypes.string,
  modelTag: PropTypes.string,
  tagTag: PropTypes.string,
  gpuTag: PropTypes.string,
  cpuTag: PropTypes.string,
  pageType: PropTypes.string,
  actions: PropTypes.object,
  sortOption: PropTypes.string,
  searchParams: PropTypes.object,
  selectOptions: PropTypes.object,
  handleChangeRankSearchParams: PropTypes.func,
};
