import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';
let levelOfOriginalJson = 0
const JankFlameGraph = ({ data }) => {
  const chartRef = useRef(null);
  const colorList = ['#dacd57', '#e0b251', '#e7974b', '#eb8547', '#f45c3d'];
  // const colorList = ['#f45c3d', '#eb8547', '#e7974b', '#e0b251', '#dacd57'];

  useEffect(() => {
    if (!data || !colorList || !data.value) {
      return;
    }

    const filterJson = (json, pathList = []) => {
      if (pathList.length <= 1) {
        return json;
      }
      // 先处理原始数据，添加pathList
      const pathString = pathList.join();
      const pathDataTree = structuredClone(json);
      const handleRecur = (item, parent = null) => {
        item.pathList = parent ? parent?.pathList?.concat(item.name) :  [item.name]; // 当前路径-用于火焰图点击区分相同name
        for (const child of item.children || []) {
          handleRecur(child,  item);
        }
      };
      handleRecur(pathDataTree);

      // 针对pathList找到对应的位置
      const recur = (item, pathString) => {
        if (item.pathList.join() === pathString) {
          return item;
        }
        for (const child of item.children || []) {
          const temp = recur(child, pathString);
          if (temp) {
            item.children = [temp];
            item.value = temp.value; // change the parents' values
            return item;
          }
        }
      };
      return recur(pathDataTree, pathString);
    };

    const recursionJson = (jsonObj, pathList) => {
      const flameData = [];
      const filteredJson = filterJson(structuredClone(jsonObj), pathList);
    //   if (!filteredJson || !filteredJson.value) {
    //     console.error('Filtered JSON is invalid or missing value:', filteredJson);
    //     return data;
    //   }
      levelOfOriginalJson = heightOfJson(filteredJson);
      const rootVal = jsonObj.value;
      const recur = (item, start = 0, level = 0, parent) => {
        const { value, name, avg, cnt, avgPF } = item;
        const colorIndex = Math.floor((value / rootVal) / 0.2);
        const color = colorList[colorIndex] || '#dacd57'; // 使用红色填充如果没有找到颜色
        const temp = {
          name: name,
          // [level, start_val, end_val, name, percentage]
          value: [
            level,
            start,
            start + value,
            item.name,
            (value / rootVal) * 100,
            avg,
            cnt,
            avgPF,
          ],
          pathList: level === 0 ?  [name] : parent?.pathList?.concat(item.name), // 当前路径-用于火焰图点击区分相同name
          itemStyle: {
            color: level === 0 ? '#e3a34e' : color,
          },
        };
        flameData.push(temp);
        let prevStart = start;
        for (const child of item.children || []) {
          recur(child, prevStart, level + 1, temp);
          prevStart = prevStart + child.value;
        }
      };
      recur(filteredJson);
      return flameData;
    };

    const heightOfJson = (json) => {
      const recur = (item, level = 0) => {
        if ((item.children || []).length === 0) {
          return level;
        }
        let maxLevel = level;
        for (const child of item.children) {
          const tempLevel = recur(child, level + 1);
          maxLevel = Math.max(maxLevel, tempLevel);
        }
        return maxLevel;
      };
      return recur(json);
    };

    const renderItem = (params, api) => {
      const level = api.value(0);
      const start = api.coord([api.value(1), level]);
      const end = api.coord([api.value(2), level]);
      const height = ((api.size && api.size([0, 1])) || [0, 20])[1];
      const width = end[0] - start[0];

      return {
        type: 'rect',
        transition: ['shape'],
        shape: {
          x: start[0],
          y: start[1] - height / 2,
          width,
          height: height - 2 /* itemGap */,
          r: 2,
        },
        style: {
          fill: api.visual('color'),
        },
        emphasis: {
          style: {
            stroke: '#000',
          },
        },
        textConfig: {
          position: 'insideLeft',
        },
        textContent: {
          style: {
            text: api.value(3),
            fontFamily: 'Verdana',
            fill: '#000',
            width: width - 4,
            overflow: 'truncate',
            ellipsis: '..',
            truncateMinChar: 1,
          },
          emphasis: {
            style: {
              stroke: '#000',
              lineWidth: 0.5,
            },
          },
        },
      };
    };

    levelOfOriginalJson = heightOfJson(data);

    const option = {
      tooltip: {
        formatter: (params) => {
          const samples = params.value[2] - params.value[1];
          return `${params.marker} ${
            params.value[3]
          }: (avgPF=${(params.value[7] || 0).toFixed(2)}ms)`;
        // }: (${echarts.format.addCommas(samples)} samples, ${+params.value[4].toFixed(2)}%)`;
        },
      },
      grid: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      // toolbox: {
      //   feature: {
      //     restore: {},
      //   },
      //   right: 20,
      //   top: 10,
      // },
      xAxis: {
        show: false,
        max: data.value
      },
      yAxis: {
        show: false,
        max: levelOfOriginalJson,
        min: 0,
        inverse: false, // 从下往上渲染
      },
      series: [
        {
          type: 'custom',
          renderItem,
          encode: {
            x: [1, 2],
            y: 0,
          },
          data: recursionJson(data),
          animation: false, // 禁用动画
          animationDuration: 0, // 设置动画持续时间为0
        },
      ],
    };

    const myChart = echarts.init(chartRef.current);
    myChart.setOption(option);
    myChart.on('click', (params) => {
    //   const name = params.data.value[3];
      const rootData = recursionJson(data, params.data.pathList);
      const rootValue = rootData[0].value[2];
      console.log(params, rootData,'params')
        myChart.setOption({
          ...option,
          xAxis: { max: rootValue, show: false, },
          yAxis: {
            show: false,
            max: levelOfOriginalJson,
            min: 0,
            inverse: false, // 从下往上渲染
          },
        series: {
          type: 'custom',
          renderItem,
          encode: {
            x: [1, 2],
            y: 0,
          },
          data: rootData
        },
        });
    });

    return () => {
      // 组件卸载时销毁 ECharts 实例
      myChart.dispose();
    };
  }, [data, colorList]);

  return (
    <div ref={chartRef} style={{ width:'100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
  );
};

export default JankFlameGraph;
