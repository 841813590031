import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Modal,
  Spin,
  Divider,
  Table, Button, message, notification,
} from 'antd';
import { connect, useDispatch } from 'react-redux';
import { isNotNullish, isNullish } from 'utils/nullish';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';
import { ze } from 'utils/zhEn';
import moment from 'moment';
import { upsertWebAppSettings } from 'reducers/app/appActions';
import { isAndroid, isMobile, isPc, PlatformUtil } from 'utils/platform';
import { WebAppSettings } from 'utils/web-app-settings';
import { getVersionRangeString } from 'utils/helper';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination';

export const TapdTitleTemplateUtil = (() => {
  /** 这个后端也有一份，修改的时候要联动后端一起修改 */
  const templateVarList = Object.freeze([{
    key: 'platform',
    desc: ze('平台', 'Platform'),
    issueInfoValue: enrichedIssueInfo => PlatformUtil.toName(enrichedIssueInfo.platformId),
  }, {
    key: 'exceptionName',
    desc: i18n.t('REPORTDETAIL.expName'),
  }, {
    key: 'exceptionMessage',
    desc: i18n.t('REPORTDETAIL.expMessage'),
  }, {
    key: 'keyStack',
    desc: ze('首次上报关键堆栈', 'Key Stack'),
  }, {
    key: 'firstUploadTime',
    desc: ze('首次上报时间', 'First Reported Time'),
    issueInfoValue: enrichedIssueInfo => moment(enrichedIssueInfo.firstUploadTime, 'YYYY-MM-DD HH:mm:ss SSS').format('YYYY-MM-DD HH:mm:ss'),
  }, {
    key: 'latestUploadTime',
    desc: ze('最近上报时间', 'Last Reported Time'),
    issueInfoValue: enrichedIssueInfo => moment(enrichedIssueInfo.lastestUploadTime, 'YYYY-MM-DD HH:mm:ss SSS').format('YYYY-MM-DD HH:mm:ss'),
  }, {
    key: 'count',
    desc: i18n.t('EXCP_OVERVIEW.occurNum'),
  }, {
    key: 'imeiCount',
    desc: i18n.t('EXCP_OVERVIEW.userNum'),
  }, {
    key: 'versionRange',
    desc: ze('版本号范围', 'Version Range'),
    issueInfoValue: enrichedIssueInfo => getVersionRangeString((enrichedIssueInfo.issueVersions || []).map(x => x.version)),
  }, {
    key: 'lastReport.cpu',
    desc: i18n.t('REPORTDETAIL.cpu'),
    crashMapValue: crashMap => crashMap.cpuType,
  }, {
    key: 'lastReport.gpu',
    desc: i18n.t('REPORTDETAIL.gpu'),
    availableByPid: pid => isAndroid(pid) || isPc(pid, undefined),
    crashMapValue: crashMap => crashMap.gpu,
  }, {
    key: 'lastReport.model',
    desc: i18n.t('REPORTDETAIL.hardware'),
    crashMapValue: crashMap => crashMap.model,
  }, {
    onlyForFeatureConfig: true,
    key: 'reportCustomKv.__custom_key__',
    desc: ze('最近一条上报的自定义关键字。用需要指定的关键字key替换模板里面的__custom_key__。仅支持K#（字符串）和I#（数值）类型的关键字。', 'Custom Keyword of last matched report. Replace __custom_key__ with the specified keyword key. Only K# (string) and I# (number) type keywords are supported.'),
  }]);

  const templateVarToInfo = Object.assign({}, ...templateVarList.map(x => ({ [x.key]: x })));

  return Object.freeze({
    getSettingKey() {
      return WebAppSettings.keys.tapdBugTitleTemplate;
    },

    makeDefaultValue(pid) {
      if (isMobile(pid)) {
        return `${ze('【CrashSight一键提单】', '[CrashSight Issue] ')}{{exceptionName}} - {{keyStack}} - {{latestUploadTime}}`;
      }
      return `${ze('【CrashSight一键提单】', '[CrashSight Issue] ')}{{exceptionMessage}} - {{keyStack}} - {{latestUploadTime}}`;
    },

    getTemplateVarList(fromFeatureAnalysis, platformId) {
      return templateVarList.filter(x => {
        if (!fromFeatureAnalysis && x.onlyForFeatureConfig) {
          return false;
        }
        if (x.availableByPid && !x.availableByPid(platformId)) {
          return false;
        }
        return true;
      });
    },

    makeTitleFromTemplate(template, enrichedIssueInfo, lastReportCrashMap) {
      return (template || '').replace(/\{\{([^\s{}]+)\}\}/g, (match, p1) => {
        const varInfo = templateVarToInfo[p1];
        if (!varInfo) {
          return match;
        }
        const { key, issueInfoValue, crashMapValue } = varInfo;
        if (typeof issueInfoValue === 'function') {
          return issueInfoValue(enrichedIssueInfo || {}) || '';
        } else if (typeof crashMapValue === 'function') {
          return crashMapValue(lastReportCrashMap || {}) || '';
        } else {
          return enrichedIssueInfo[key] || match;
        }
      });
    },
  });
})();

const TapdTitleTemplateEditor = ({
  appId,
  platformId,
  fromFeatureAnalysis,
  value,
  onChange,
  enrichedIssueInfo,
  lastReportCrashMap,
}) => {
  const templateVarInfoTableColumns = useMemo(() => [{
    title: ze('占位符（点击可复制）', 'Variable (Click to copy to clipboard)'),
    dataIndex: 'key',
    render: (text, record, index) => {
      const varWithMustache = `{{${text}}}`;
      return <Button
        type='text'
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(varWithMustache);
            message.info(ze(`已复制 ${varWithMustache} 到剪贴板`, `Copied to clipboard.`));
          } catch (e) {
            message.error( 'Failed to copy text to clipboard.');
          }
        }}
      >{ varWithMustache }</Button>;
    },
    width: '50%'
  }, {
    title: ze('描述', 'Description'),
    dataIndex: 'desc',
    width: '50%'
  }], []);

  const pageSize = 5;
  const totalList = TapdTitleTemplateUtil.getTemplateVarList(fromFeatureAnalysis, platformId);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentList, setCurrentList] = useState(totalList.slice((currentPage - 1) * pageSize, currentPage * pageSize));

  const paginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setCurrentList(totalList.slice((page - 1) * pageSize, page * pageSize));
  }

  return <div>
    <div style={{ marginBottom: '8px' }}>{ ze('标题模板', 'Template') }</div>
    <div style={{marginBottom: '20px'}}>
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
    <div style={{ marginBottom: '8px' }}>{ ze('支持的占位符', 'Supported Template Variables') }</div>
    <div>
      <Table
        pagination={false}
        size='small'
        dataSource={currentList}
        columns={templateVarInfoTableColumns}
      />
      <div style={{ marginTop: '12px', display:'flex', justifyContent:'flex-end' }}>
        <NoJumpNextPagination
          size='small'
          defaultPageSize={pageSize}
          current={currentPage}
          total={totalList.length}
          onChange={(page, pageSize) => paginationChange(page, pageSize)}
          ></NoJumpNextPagination>
      </div>
    </div>
    { enrichedIssueInfo && <React.Fragment>
    <div style={{marginTop: '4px'}}>{ ze('标题预览', 'Preview') }</div>
    <div>{ TapdTitleTemplateUtil.makeTitleFromTemplate(value, enrichedIssueInfo, lastReportCrashMap) }</div></React.Fragment> }
  </div>;
};

TapdTitleTemplateEditor.propTypes = {
};


export default TapdTitleTemplateEditor;
