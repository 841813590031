import i18n from 'i18next';
import { isAndroidOrHarmony, isMobile, PlatformUtil } from 'utils/platform';
import { isNullish } from 'utils/nullish';
import { ExceptionTypeInt } from 'utils/constants/exception-type-int';

/** 异常上报的大类（联网、崩溃、卡顿、错误、OOM等） */
export const ExceptionCategory = Object.freeze({
  /** 联网 */
  ACCESS: 'ACCESS',
  /** 崩溃 */
  CRASH: 'CRASH',
  /** ANR */
  ANR: 'ANR',
  /** 错误 */
  ERROR: 'ERROR',
  /** OOM */
  OOM: 'OOM',
  /** 王者需求，新增的卡顿大类 */
  JANK: 'JANK',
  /** 未知 */
  UNKNOWN: 'UNKNOWN',
});

export const ExceptionCategoryUtil = (function() {
  /** 将各种混乱的异常大类文本转换成统一的ExceptionCategory */
  function unify(chaosCategory) {
    if (!chaosCategory) {
      return ExceptionCategory.UNKNOWN;
    }
    if (ExceptionCategory[chaosCategory]) {
      return ExceptionCategory[chaosCategory];
    }
    switch (String(chaosCategory).toLowerCase()) {
      case 'access':
      case '联网': {
        return ExceptionCategory.ACCESS;
      }
      case 'crash':
      case 'crashes':
      case '崩溃': {
        return ExceptionCategory.CRASH;
      }
      case 'anr':
      case 'block':
      case 'blocks':
      case '卡顿': {
        return ExceptionCategory.ANR;
      }
      case 'error':
      case 'errors':
      case '错误': {
        return ExceptionCategory.ERROR;
      }
      case 'oom': {
        return ExceptionCategory.OOM;
      }
      case 'jank':
      case 'janks': {
        return ExceptionCategory.JANK;
      }
      default: {
        return ExceptionCategory.UNKNOWN;
      }
    }
  }

  /**
   * 将各种混乱的异常大类文本转换成统一的i18n文本
   * @param chaosCategory {string}
   * @param platformId {number}
   * @return {string}
   */
  function toI18n(chaosCategory, platformId) {
    if (!platformId) {
      console.error('toI18n, no platformId provided.');
    }
    const category = unify(chaosCategory);
    switch (category) {
      case ExceptionCategory.ACCESS: {
        return i18n.t('common.access');
      }
      case ExceptionCategory.CRASH: {
        return i18n.t('common.crash');
      }
      case ExceptionCategory.ANR: {
        return i18n.t('common.anr');
      }
      case ExceptionCategory.ERROR: {
        return i18n.t('common.error');
      }
      case ExceptionCategory.OOM: {
        return i18n.t('common.oom');
      }
      case ExceptionCategory.JANK: {
        return i18n.t('common.jank');
      }
      default: {
        return 'Unknown';
      }
    }
  }

  function fromExceptionType(exceptionType) {
    if (!exceptionType) {
      return ExceptionCategory.UNKNOWN;
    }
    if (exceptionType === 'Crash' || exceptionType === 'Native' || exceptionType === 'ExtensionCrash') {
      return ExceptionCategory.CRASH;
    } else if (exceptionType === 'ANR') {
      return ExceptionCategory.ANR;
    } else if (exceptionType === 'JankRaw' || exceptionType === 'JankParsed') {
      return ExceptionCategory.JANK;
    } else {
      return ExceptionCategory.ERROR;
    }
  }

  function fromExceptionTypeInt(exceptionTypeInt) {
    if (isNullish(exceptionTypeInt)) {
      return ExceptionCategory.UNKNOWN;
    }
    exceptionTypeInt = Number(exceptionTypeInt);
    switch (exceptionTypeInt) {
      case ExceptionTypeInt.Crash:
      case ExceptionTypeInt.Native:
      case ExceptionTypeInt.ExtensionCrash: {
        return ExceptionCategory.CRASH;
      }
      case ExceptionTypeInt.ANR: {
        return ExceptionCategory.ANR;
      }
      case ExceptionTypeInt.JankRaw:
      case ExceptionTypeInt.JankParsed: {
        return ExceptionCategory.JANK;
      }
      default: {
        return ExceptionCategory.ERROR;
      }
    }
  }

  function isAccess(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.ACCESS;
  }

  function isCrash(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.CRASH;
  }

  function isAnr(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.ANR;
  }

  function isError(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.ERROR;
  }

  function isOom(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.OOM;
  }

  function isJank(chaosCategory) {
    return unify(chaosCategory) === ExceptionCategory.JANK;
  }

  function getAvailableOptions(pid, enableJank) {
    const categories = [
      PlatformUtil.isCrashAvailable(pid) && ExceptionCategory.CRASH,
      PlatformUtil.isAnrAvailable(pid) && ExceptionCategory.ANR,
      ExceptionCategory.ERROR,
      enableJank && ExceptionCategory.JANK,
    ].filter(x => x);
    return categories.map(x => ({ value: x, label: toI18n(x, pid) }));
  }

  return Object.freeze({
    unify,
    toI18n,
    fromExceptionType,
    fromExceptionTypeInt,
    isAccess,
    isCrash,
    isAnr,
    isError,
    isOom,
    isJank,
    getAvailableOptions,
  });
})();
