export const ExceptionTypeInt = Object.freeze({
  Crash: 0,
  Native: 1,
  AllCatched: 2,
  AllCrash: 2,
  ANR: 3,
  Unity3D: 4,
  ExtensionCrash: 5,
  Lua: 6,
  JS: 7,
  OOM: 9,
  JankRaw: 40,
  JankParsed: 41,
});

export const ExceptionTypeName = Object.freeze({
  Crash: 'Crash',
  Native: 'Native',
  AllCatched: 'AllCatched',
  AllCrash: 'AllCrash',
  ANR: 'ANR',
  Unity3D: 'Unity3D',
  ExtensionCrash: 'ExtensionCrash',
  Lua: 'Lua',
  JS: 'JS',
  OOM: 'OOM',
  JankRaw: 'JankRaw',
  JankParsed: 'JankParsed',
});

export const EXCEPTION_TYPE_INT_ANDROID_JAVA = ExceptionTypeInt.Crash;
export const EXCEPTION_TYPE_INT_ANDROID_NATIVE = ExceptionTypeInt.Native;

/**
 * 将issueExceptionType / crashExceptionType 转为异常类型字串
 * @param exceptionTypeInt
 * @returns {'crashes' | 'blocks' | 'errors'}
 */
export function getReportCategoryByExceptionTypeInt(exceptionTypeInt) {
  exceptionTypeInt = Number(exceptionTypeInt);
  if ([0, 1, 5].includes(exceptionTypeInt)) {
    return 'crashes';
  }
  if (exceptionTypeInt === 3) {
    return 'blocks';
  }
  if (exceptionTypeInt === ExceptionTypeInt.JankRaw || exceptionTypeInt === ExceptionTypeInt.JankParsed) {
    return 'janks';
  }
  return 'errors';
}
