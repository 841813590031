import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import SeniorSearchRest from 'utils/SeniorSearchRest';
import i18n from 'i18n.js';
import {
  SEARCH_EXCEPTION_TYPE_TO_OPTIONS,
  DATE_OPTIONS,
} from 'utils/constants';
import IssueRest from 'utils/IssueRest';
import {
  LOCATION_CHANGE,
  SEARCH_LIST_SUCC,
  CHECK_ISSUE_ITEM,
  SET_SEARCH_LIST,
  SEARCH_OPTIONS_SUCC,
  INIT_SEARCH_PARAMS,
  INIT_ADVANCED_SEARCH_RESULT,
} from 'store/actions';

import { compareBundleId, getNickName, getUserId, parseSearch } from 'utils/helper';
import { AdvancedSearchUtil } from 'components/exception/SeniorSearchPage/util';
import { notification } from 'antd';
import IssueCrashSearchUtil from 'utils/IssueCrashSearchUtil';
import { isNullish } from 'utils/nullish';
import {
  FieldCategory, FieldName,
  FieldNameToFieldInfo,
  IssueCrashFilterExUtil, QueryType
} from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import RestHelper from 'utils/RestHelper';
import {
  selectFeatureTagOptionsInfo,
  selectIssueCrashFilterCustomKeyOptionsInfo,
  selectIssueCrashFilterModelOptionsInfo, selectIssueCrashFilterOsVersionOptionsInfo
} from 'utils/selectors/selectors';
import { ReduxActionType } from 'reducers/redux-action-type';
import { isMobile, isPcOrLinux } from 'utils/platform';
import { makeCrashListSearchParamsFromSearchParams } from 'utils/api/crash';
import CrashRest from 'utils/CrashRest';
import uniqBy from 'lodash.uniqby';
import uniq from 'lodash/array/uniq';
import { CustomKvUtil } from 'utils/custom-kv';
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import moment from 'moment';
import { IssueAggregateType } from 'utils/constants/issue-aggregate-type';

const TAG = '[reducer/seniorSearch]-';

// 下拉框的参数
export const initialSelectOptions = fromJS({
  version: {// 所有版本
    options: [
      { label: i18n.t('versionOptions.全版本'), value: 'all' },
    ],
  },
  date: DATE_OPTIONS,
  exceptionTypeList: { options: [] },
  status: {// 所有状态
    options: [
      { label: i18n.t('statusOptions.所有状态'), value: 'all' },
      { label: i18n.t('statusOptions.未处理'), value: '0' },
      { label: i18n.t('statusOptions.已处理'), value: '1' },
      { label: i18n.t('statusOptions.处理中'), value: '2' },
    ],
  },
  processor: {// 所有处理人
    options: [],
  },
  tagList: {// 所有标签
    options: [],
  },
  bundleId: {// 所有BundleId
    options: [],
  },
  channelId: {// 所有渠道
    options: [],
  },
  ftNames: {// OA上支持ft
    options: [],
  },
  featureTag: {
    isFetching: false,
    hasFetched: false,
    options: [],
  },
  customKey: {
    isFetching: false,
    hasFetched: false,
    options: [],
  },
  model: {
    isFetching: false,
    hasFetched: false,
    options: [],
  },
  osVersion: {
    isFetching: false,
    hasFetched: false,
    options: [],
  },
});

export const DEFAULT_ADVANCED_SEARCH_SORT_FIELD = 'matchCount';

// 搜索的参数
const initialSearchParams = fromJS({
  start: 0,
  sortField: null,
  desc: true,
  oomStart: 0,
  oomRows: 10,
  oomSortField: 'uploadTimestamp',
  oomSortOrder: '',
});

// 初始化搜索的state
const initialSearchState = fromJS({
  searchParams: initialSearchParams,
  lastSearchParams: initialSearchParams, // 最后一次请求搜索时使用的searchParams
  selectOptions: initialSelectOptions,
  searchResultData: {},
  numFound: 0,
  totalPages: 0,
  searchList: [],
  oomSearched: false,
  oomSearchSucceeded: false,
  totalOomMatchCount: 0,
  oomReportList: [],
});


/**
 * 高级搜索的接口
 */
export function getSearchList() {
  return async (dispatch, getState) => {
    const {seniorSearch, app} = getState();
    const {appId, pid} = app.get('current').toJS();
    const {
      oomSortOrder,
      queryUngroupedCrashList,
      searchConditionGroup,
    } = seniorSearch.get('searchParams').toJS();

    const fields = IssueCrashFilterExUtil.getAllFieldsFromSearchConditionGroup(searchConditionGroup);
    const nonOomSearchFields = fields.filter(field => {
      const fieldInfo = FieldNameToFieldInfo[field];
      return fieldInfo && !fieldInfo.fieldCategoryList.includes(FieldCategory.OOM);
    });

    let paramsAll = seniorSearch.get('searchParams').merge({
      appId,
      platformId: pid,
      enableSearchOomInAdvancedSearch: isMobile(pid),
      oomDesc: !oomSortOrder || oomSortOrder === 'descend',
      rows: getState().global.get('rows'),
    }).toJS();

    // TODO: 特殊处理，不传nullish和空字符串的参数
    for (const k in paramsAll) {
      if (isNullish(paramsAll[k]) || paramsAll[k] === '') {
        delete paramsAll[k];
      }
    }

    // 填充默认排序方式
    if (!paramsAll.sortField) {
      paramsAll.sortField = queryUngroupedCrashList ? 'uploadTime' : DEFAULT_ADVANCED_SEARCH_SORT_FIELD;
    }

    sessionStorage.setItem('seniorParams', JSON.stringify(paramsAll))

    if (!queryUngroupedCrashList) {
      const rsp = await RestHelper.mustPost('/api/advancedSearch', paramsAll);
      await dispatch({
        type: ReduxActionType.setAdvancedSearchState,
        response: rsp.json.data,
        params: paramsAll,
      });
      // 王者卡顿定制需求，额外拉取问题的自定义kv：TopForm分布情况
      const jankIssueIdList = (rsp.json.data?.issueList || [])
        .filter(x => ExceptionCategoryUtil.fromExceptionTypeInt(x.issueExceptionType) === ExceptionCategory.JANK)
        .map(x => x.issueId);
      if (jankIssueIdList.length > 0) {
        const rsp = await RestHelper.mustPost('/api/issue/queryIssueAggregate', {
          appId,
          searchConditionGroup: {
            conditions: [
              ...(searchConditionGroup?.conditions || []),
              {
                field: FieldName.issueId,
                queryType: QueryType.TERMS,
                terms: jankIssueIdList,
              },
            ],
          },
          customFields: [{
            aggregateType: IssueAggregateType.MULTI_TERMS,
            name: 'crashIssueId',
            subTerm: 'hashKeyValues.dgfGbgRrbDOPvvCPX00J2A==.originValue', // C03_K#TopForm
            termsSizeLimit: 100,
          }],
        });
        dispatch({ type: ReduxActionType.parseAggResultAndSetIssueIdToWangZheTopFormList, payload: rsp });
      }
    } else {
      await dispatch({
        rest: CrashRest.crashes.list,
        data: paramsAll,
      });
    }

    return dispatch({
      type: 'setLastSearchParams',
      payload: fromJS(paramsAll),
    });
  };
}

// 获取初始化页面高级搜索的参数:版本,标签,处理人,渠道,BundleId
export function getSearchInitOptions() {
  return (dispatch, getState) => {
    const { app } = getState();
    const { appId, pid } = app.get('current').toJS();

    const bundleIdOptionsAvailable = IssueCrashFilterExUtil.getIsBundleIdOptionsAvailable(appId);

    return dispatch({
      rest: SeniorSearchRest.search.options,
      data: {
        appId,
        pid,
        types: bundleIdOptionsAvailable
          ? ['version', 'member', 'bundle', 'tag', 'channel'].join(',')
          : ['version', 'member', 'tag', 'channel'].join(','),
      },
    });
  };
}

export function actionFetchFeatureTagOptions() {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId } = app.get('current').toJS();
    const { isFetching } = selectFeatureTagOptionsInfo(getState());
    if (isFetching) { // 防止多个FeatureTagSelect实例同时请求多次接口
      return;
    }

    dispatch({
      type: 'patchSelectOptions',
      payload: {
        featureTag: {
          isFetching: true,
        },
      },
    });

    let options = [];
    try {
      const rsp = await RestHelper.mustPost('/api/featureAnalysis/listFeatureConfigs', {appId});
      options = rsp.json.data
        .filter(x => x.realtimeTag)
        .map(x => ({ label: x.name, value: x.key }));
    } finally {
      dispatch({
        type: 'patchSelectOptions',
        payload: {
          featureTag: {
            isFetching: false,
            hasFetched: true,
            options,
          },
        },
      });
    }
  }
}

export function actionFetchCustomKeyOptions() {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId, platformId } = app.get('current').toJS();
    const { isFetching } = selectIssueCrashFilterCustomKeyOptionsInfo(getState());
    if (isFetching) { // 防止多个CustomKvSearchKey实例同时请求多次接口
      return;
    }

    dispatch({
      type: 'patchSelectOptions',
      payload: {
        customKey: {
          isFetching: true,
        },
      },
    });

    let options = [];
    try {
      const validKeyTypes = ['I', 'K'];
      const rsp = await RestHelper.post('/redir/api/appConfig/queryCustomKeys', { appId });
      options = rsp.json.data.items
        .map(x => {
          const { key, uploadMillis } = x;
          return CustomKvUtil.splitCompositeKv(key, '')
            .map(({ keyWithType }) => ({ key: keyWithType, uploadMillis }));
        })
        .flat()
        .map(x => {
          const { key, uploadMillis } = x;
          const keyRaw = !isPcOrLinux(platformId) && !(key || '').startsWith('C03_')
            ? `C03_${key}`
            : key;
          const { keyWithoutPrefix, keyType, keyTypeLabel } = CustomKvUtil.getKeyInfoFromPrefixedCustomKey(key);
          return {
            label: keyWithoutPrefix,
            value: key,
            keyRaw, // 带C03前缀的key
            keyType,
            keyTypeLabel,
            uploadMillis,
          }
        })
        .filter(x => validKeyTypes.includes(x.keyType)); // 只支持数值型I和字符串型K搜索
      options = uniqBy(options, x => x.value);
    } finally {
      dispatch({
        type: 'patchSelectOptions',
        payload: {
          customKey: {
            isFetching: false,
            hasFetched: true,
            options,
          },
        },
      });
    }
  }
}

export function actionFetchModelOptions() {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId, platformId } = app.get('current').toJS();
    const { isFetching } = selectIssueCrashFilterModelOptionsInfo(getState());
    if (isFetching) { // 防止多个CustomKvSearchKey实例同时请求多次接口
      return;
    }

    dispatch({
      type: 'patchSelectOptions',
      payload: {
        model: {
          isFetching: true,
        },
      },
    });

    let options = [];
    try {
      const rsp = await RestHelper.post('/redir/api/queryModel/listModelOptions', { appId });
      options = rsp.json.data.options
        .map(x => {
          const { model, deviceName } = x;
          return {
            label: `${deviceName} (${model})`,
            value: model,
          }
        })
    } finally {
      dispatch({
        type: 'patchSelectOptions',
        payload: {
          model: {
            isFetching: false,
            hasFetched: true,
            options,
          },
        },
      });
    }
  }
}

export function actionFetchOsVersionOptions() {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId, platformId } = app.get('current').toJS();
    const { isFetching } = selectIssueCrashFilterOsVersionOptionsInfo(getState());
    if (isFetching) {
      return;
    }

    dispatch({
      type: 'patchSelectOptions',
      payload: {
        osVersion: {
          isFetching: true,
        },
      },
    });

    let options = [];
    try {
      const rsp = await RestHelper.mustPost('/redir/api/fieldOptions/listOsVersions', { appId });
      const osList = uniq(rsp.json.data);
      options = osList
        .map(x => {
          const levelNumberStr = x.match(/level\s*(\d+)/i)?.[1];
          let levelNumber = levelNumberStr ? Number(levelNumberStr) : -1;
          if (!Number.isFinite(levelNumber)) {
            levelNumber = -1;
          }
          return {
            label: x,
            value: x,
            levelNumber,
          };
        });
      options.sort((a, b) => b.levelNumber - a.levelNumber);
    } finally {
      dispatch({
        type: 'patchSelectOptions',
        payload: {
          osVersion: {
            isFetching: false,
            hasFetched: true,
            options,
          },
        },
      });
    }
  }
}

/**
 * 选中/取消列表项
 * @param issue
 * @param checked
 * @param multi
 * @param checkAll
 */
export const checkIssueItem = createAction(CHECK_ISSUE_ITEM);

export const setSearchList = createAction(SET_SEARCH_LIST);

/**
 * 初始化搜索选项
 */
export const initSearchParams = createAction(INIT_SEARCH_PARAMS);

export const initAdvancedSearchResult = createAction(INIT_ADVANCED_SEARCH_RESULT);

/**
 *改变异常类型搜索下拉选项, 同时赋予exceptionTypeList默认值
 */
export const changeSearchExceptionTypeOptions = createAction('CHANGE_SEARCH_EXCEPTION_TYPE_OPTION');

export function addTagItem(issueId, tagName, path) {
  return (dispatch, getState) => {
    const current = getState().app.get('current');
    return dispatch({
      rest: IssueRest.tag.add,
      data: {
        appId: current.get('appId'),
        platformId: current.get('pid'),
        issueId,
        tagName,
        path,
      },
    });
  };
}

export function delTagItem(issueId, tagId, path) {
  return (dispatch, getState) => {
    const current = getState().app.get('current');
    return dispatch({
      rest: IssueRest.tag.del,
      data: {
        appId: current.get('appId'),
        pid: current.get('pid'),
        issueId,
        tagId,
        path,
      },
    });
  };
}

/**
 * 处理添加或删除标签成功后的action
 */
function handleAddOrDelTagSucc(state, action) {
  if (action.params.path !== 'advanced-search' && action.params.path !== 'advancedSearch') {
    return state;
  }

  // 在列表页，需把结果存在issueList，在详情页（URL中又issue字段），需要结果存在current
  console.log(`${action.type} state.router`, state);
  const { tagInfoList } = action.response;
  // 是否是详情页的请求
  return state.update('searchList', (searchList) => searchList.update(
    searchList.findIndex(
      (issue) => issue.get('issueId') === action.params.issueId
    ),
    (issue) => issue.set('tagInfoList', fromJS(tagInfoList))
  ));
}

/**
 * reducers
 */
export default handleActions({

  /**
   *  执行高级搜索成功
   */
  [ReduxActionType.setAdvancedSearchState]: (state, action) => {
    let {
      numFound = 0, issueList, anrNums = 0, crashNums = 0, errorNums = 0, totalCrashMatchCount = 0,
      jankNums = 0,
      totalOomMatchCount,
      oomReportList,
      oomSearched,
      oomSearchSkippedReason,
      oomSearchUnsupportedFieldList,
      oomSearchSucceeded,
    } = action.response;
    const pid = action.params.platformId;
    // 这里和FeatureConfigPage.jsx里有重复逻辑
    if (issueList) {
      issueList = issueList.map(x => ({
        enrichedIssueEsMap: AdvancedSearchUtil.enrichIssueEsMap(pid, x.esMap),
        ...x,
      }));
    }
    return state.update('searchList', (searchList) => searchList.clear().mergeDeep(issueList))
      .set('numFound', numFound > 0 ? numFound : 0)
      .set('anrNums', anrNums)
      .set('crashNums', crashNums)
      .set('errorNums', errorNums)
      .set('jankNums', jankNums)
      .set('totalCrashMatchCount', totalCrashMatchCount)
      .set('oomSearched', oomSearched)
      .set('oomSearchSucceeded', oomSearchSucceeded)
      .set('oomSearchSkippedReason', oomSearchSkippedReason)
      .set('oomSearchUnsupportedFieldList', oomSearchUnsupportedFieldList || [])
      .set('totalOomMatchCount', totalOomMatchCount)
      .set('oomReportList', oomReportList);
  },

  /**
   * 选中项
   */
  [CHECK_ISSUE_ITEM]: (state, action) => {
    const {
      issue, checkAll, checked, multi,
    } = action.payload;
    return state.update('searchList', (searchList) => searchList.map((oneIssue) => {
      if (checkAll) {
        return oneIssue.set('checked', checked);
      } else {
        const finalOneIssue = multi ? oneIssue : oneIssue.set('checked', false);
        return finalOneIssue.get('issueId') === issue.get('issueId') ? finalOneIssue.set('checked', checked) : finalOneIssue;
      }
    }));
  },

  /**
   * 解绑Tapd缺陷单
   */
  [SET_SEARCH_LIST]: (state, action) => {
    return state.update('searchList', (searchList) => searchList.clear().mergeDeep(action.payload));
  },

  patchSelectOptions: (state, action) => state.update('selectOptions', x => x.merge(action.payload)),

  setLastSearchParams: (state, action) => state.set('lastSearchParams', action.payload),

  /**
   * 加载高级搜索下拉选项成功
   */
  [SEARCH_OPTIONS_SUCC]: (state, action) => {
    const { data = {} } = action.response;
    const {
      bundleIdList = [], channelList = [], processorList = [], tagList = [], versionList = [], ftNames = [],
    } = data;
    const pid = action.params.platformId;
    return state.update('selectOptions', (options) => options.merge({
      version: {
        options: (() => {
          const results = [];

          versionList.forEach((item) => {
            results.push({
              label: parseInt(item.enable) === 0 ? (`${item.productVersion}(已关闭)`) : item.productVersion,
              value: item.productVersion,
            });
          });
          results.unshift({
            label: i18n.t('versionOptions.全版本'),
            value: 'all',
          });
          return results;
        })(),
      },
      processor: {
        options: [{
          label: i18n.t('processorOptions.所有处理人'),
          value: 'all',
        }].concat(uniqBy(processorList, x => getUserId(x)).map((item) => ({
          label: getNickName(item),
          value: encodeURIComponent(getUserId(item)),
        }))),
      },
      tagList: {
        options: [{
          label: i18n.t('tagOptions.所有标签'),
          value: 'all',
        }].concat(tagList.map((item) => ({ label: item.tagName, value: `${item.tagId}` }))),
      },
      bundleId: {
        options: [{
          label: `${pid}` === '1' ? i18n.t('bundleOptions.所有应用包名') : i18n.t('bundleOptions.所有BundleId'),
          value: 'all',
        }].concat(bundleIdList.map((item) => ({ label: item, value: encodeURIComponent(item) })))
          .sort((a, b) => compareBundleId(a.label, b.label)),
      },
      channelId: {
        options: [{
          label: i18n.t('channelOptions.全渠道'),
          value: 'all',
        }].concat(channelList.map((item) => ({ label: item, value: encodeURIComponent(item) }))),
      },
    }));
  },

  [ReduxActionType.resetAppRelatedStateWhenAppChanged]: (state, action) => {
    return state.set('selectOptions', initialSelectOptions);
  },

  /**
   * 路由改变, 当用户修改搜索条件时触发
   */
  [LOCATION_CHANGE]: (state, action) => {
    const { location } = action.payload;
    const { pathname } = location;
    const query = IssueCrashSearchUtil.urlQueryObjToSearchParamsObj(parseSearch(location));
    if (pathname.match(/crash-reporting\/advanced-search/i)) {
      return state.update('searchParams', (searchParams) => {
        return Object.keys(query).length ? initialSearchParams.merge(query) : initialSearchParams;
      });
    }
    return state;
  },

  TAG_ADD_SUCC: handleAddOrDelTagSucc,
  TAG_DEL_SUCC: handleAddOrDelTagSucc,

  /**
     * 这里改变搜索类型
     */
  CHANGE_SEARCH_EXCEPTION_TYPE_OPTION: (state, action) => {
    const pid = action.payload;
    const options = SEARCH_EXCEPTION_TYPE_TO_OPTIONS[pid] || SEARCH_EXCEPTION_TYPE_TO_OPTIONS.OTHER_PLATFORM;
    return state.updateIn(['selectOptions', 'exceptionTypeList'], () => fromJS({ options })).updateIn(['searchParams', 'exceptionTypeList'], (exceptionTypeList) => exceptionTypeList || options[0].value);
  },
  CHANGE_STATE_SUCC: (state, action) => {
    if (action.params.page === 'advancedSearch') {
      const issueIdArr = !!action.params.issueIds && action.params.issueIds.toString().split(';');
      return state.update('searchList', (searchList) => {
        return searchList.map((issue) => {
          if (issueIdArr && issueIdArr.indexOf(issue.get('issueId').toString()) !== -1) {
            issue = issue.set('processor', action.params.processors)
              .set('assigneeList', action.params.assigneeWithoutLocalUserIdList)
              .set('status', action.params.status);
            return issue;
          } else {
            return issue;
          }
        });
      });
    } else {
      return state;
    }
  },
  [INIT_SEARCH_PARAMS]: (state) => state.set('searchParams', initialSearchParams),

  [INIT_ADVANCED_SEARCH_RESULT]: (state) => state.update('searchList', (searchList) => searchList.clear())
    .set('numFound', 0)
    .set('anrNums', 0)
    .set('crashNums', 0)
    .set('errorNums', 0)
    .set('totalCrashMatchCount', 0)
    .set('oomSearched', false)
    .set('oomSearchSucceeded', false)
    .set('totalOomMatchCount', 0)
    .set('oomReportList', []),

}, initialSearchState);
