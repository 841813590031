import React, { useMemo } from 'react';
import { ze } from 'utils/zhEn';
import { isNotNullish } from 'utils/nullish';
import dataDetailStyle from 'components/exception/issue/DataDetail/DataDetail.scss';
import { Table } from 'antd';

export const JankLinesTableUtil = Object.freeze({
  parseJankMetricLineList(reservedMapStr) {
    if (!reservedMapStr) {
      return [];
    }
    try {
      const reservedMap = JSON.parse(reservedMapStr);
      return !!reservedMap?.metric
        ? JSON.parse(reservedMap.metric)
        : [];
    } catch (e) {
      console.error('jankMetricList parse error', e);
      return [];
    }
  },
});

const JankLinesTable = ({
  crashEsMap,
}) => {
  const reservedMapStr = crashEsMap?.reservedMapStr;

  const jankMetricLineList = useMemo(() => {
    return JankLinesTableUtil.parseJankMetricLineList(reservedMapStr);
  }, [reservedMapStr]);

  let jankMetricInfo = [];
  let columns = [
    {
      title: '#',
      dataIndex: 'lineNumber',
      key: 'lineNumber',
      width: '40px',
    }, {
      title: ze('函数','Function'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return <div style={{
          whiteSpace: 'pre',
        }}>{ text }</div>;
      },
    },
  ];

  let data = [];

  for (let index = 0; index < jankMetricLineList.length; index ++){
    // 把表格翻转过来展示
    let jankMetric = jankMetricLineList[jankMetricLineList.length - index - 1] || {};
    jankMetricInfo = Object.entries(jankMetric)
      .filter(([key, value]) => isNotNullish(value) && key !== 'index')
      .reduce((acc, [key, value]) => {
        if (key === 'min' || key === 'max' || key === 'avg' || key === 'avgPF') {
          acc[key + '(ms)'] = (value || 0).toFixed(2);
        } else if (key === 'percentage') {
          acc[key] = (value || 0).toFixed(2);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
    const newObj = {
      key:index,
      lineNumber: index + 1,
      ...jankMetricInfo,
    };
    data.push(newObj);
  }
  const newColumnsObj = [
    'percentage',
    'avgPF(ms)',
    'min(ms)',
    'max(ms)',
    'avg(ms)',
    'level',
    'cnt',
  ].map(item => {
    return {
      title: item,
      dataIndex: item,
      key: item,
      align: 'right',
      width: 130,
    }
  })
  columns.push(...newColumnsObj);
  return <div className={dataDetailStyle.stackLineContainer}>
    <Table columns={columns} dataSource={data} size="small" pagination={false}/>
  </div>;
};

export default JankLinesTable;
