import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import {
  Pager, CopyRight, Loading, PureRender,
} from 'components/commons';
import isArray from 'lodash/lang/isArray';
import { REQUEST_PARAMS_ERROR, REQUEST_PARAMS_ERROR_TIPS } from 'utils/constants';
import {
  routeReclick, getStatus, generateSearch, parseSearch,
} from 'utils/helper';
import { show } from 'utils/EventEmitterHelper';
import RestHelper from 'utils/RestHelper.js';
import PageHeader from './PageHeader';
import { Radio, Divider, Button, Modal, message } from 'antd';
import pickBy from 'lodash.pickby';
import ListHeader from './ListHeader';
import IssueList from './IssueList';
import IssueItem from './IssueItem/IssueItem';
import style from './style.scss';
import { withTranslation } from 'react-i18next'
import { TapdModal } from 'components/exception/issue';
import { isEqual } from 'lodash';
import CardHead from 'components/commons/CardHead';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination.jsx';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import mapValues from 'lodash/object/mapValues';
import reportEvent, { EVENT_ACTIONS, getPageTitle } from 'utils/reportEvent';
import { DownloadOutlined } from '@ant-design/icons';
import IssueListIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuelist_icon.svg';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import CsTabs from 'components/antd-extension/CsTabs';
import ButtonGotoTop from 'components/commons/ButtonGotoTop/ButtonGotoTop';
import { CS_STYLES } from 'utils/constants/style-constants';
import MergeIssuesModal from '../issue/MergeIssuesModal';
import { ze } from 'utils/zhEn';
import { ApiErrorCode } from 'utils/api-error-code';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';

const TAG = 'BaseProblemPage: ';
// 对比查询最大查询条数。这个值api-server那边会校验，并且限制最大值
const MAX_COMPARE_FETCH_COUNT = 100;

const TOP_HEADER_HEIGHT = parseInt(CS_STYLES.MAIN_HEADER_HEIGHT.split('px')[0]);
const GLOBAL_NOTIFICATION = parseInt(CS_STYLES.GLOBAL_NOTIFICATION.split('px')[0]);

/**
 * 问题列表父视图
 */
@PureRender
@autobind
@withTranslation()
class BaseProblemPage extends Component {
  static contextTypes = {
    actions: PropTypes.object,
  }

  static propTypes = {
    searchParams: PropTypes.object.isRequired,
    searchOptions: PropTypes.object.isRequired,
    currentApp: PropTypes.object.isRequired,
    issueList: PropTypes.object.isRequired,
    numFound: PropTypes.number,
    exceptionType: PropTypes.any,
    currentAppId: PropTypes.any,
    pid: PropTypes.any,
    location: PropTypes.any,
    dispatch: PropTypes.any,
    path: PropTypes.any,
    isDemoApp: PropTypes.any,
    rows: PropTypes.number,
    loading: PropTypes.any,
    issueListStatusCode: PropTypes.any,
  }

  loadInitialState(props) {
    const { searchParams } = props;
    return {
      tapdModalVisible: false,
      tapdModalIssueInfoList: [],
      tapdBugIdToStatus: {}, // tapd bug id -> bug状态 的字典

      isCompareSearch: !!(searchParams.get('isCmp') | 0),
      searchBarHeight: 0,
      // 下面的都是对比搜索用到的state，非对比搜索的状态在redux里
      cmpRadioValue: 'issueListUniqueA',
      issueListUniqueA: [],
      issueListUniqueB: [],
      issueListIntersection: [],
      currentTabIssueList: [],
      pageSize: 10,
      paginationType: null,//判断是否点击更改每页条数
      // 合并问题弹窗的属性
      mergeIssuesModalVisible: false,
      checkedIssueInfoList: [],
      mergeIssuesModalLoading: false,
      downloadValue:'all',
    };
  }

  constructor(props) {
    super(props);
    this.state = this.loadInitialState(this.props);
  }

  componentDidMount() {
    this.loadIssueData(this.props.currentApp);
    this.onDidMountOrDidUpdate(undefined);
  }

  componentWillReceiveProps(nextProps) {
    const { currentApp } = nextProps;
    // console.log(`${TAG} componentWillReceiveProps: currentAppId=${this.props.currentApp.get('appId')}, nextAppId=${nextProps.currentApp.get('appId')}`);
    if (currentApp.get('appId') && currentApp !== this.props.currentApp) {
      this.loadIssueData(currentApp);
    } else if (routeReclick(nextProps.location, this.props.location)) {
      this.setState(this.loadInitialState(nextProps));
      this.loadIssueData(currentApp);
    }
  }

  componentDidUpdate(prevProps) {
    this.onDidMountOrDidUpdate(prevProps);
  }

  onDidMountOrDidUpdate(prevProps) {
    const {
      issueList: oldIssueList,
      reduxState: oldReduxState,
    } = prevProps || {};
    const {
      issueList,
      reduxState,
    } = this.props;

    let issueTrendFetched = false;

    if (!issueList.equals(oldIssueList)) {
      const issueIdSortedList = issueList.toJS().map(x => x.issueId).sort();
      const oldIssueIdSortedList = oldIssueList?.toJS().map(x => x.issueId).sort();
      if (!isEqual(issueIdSortedList, oldIssueIdSortedList)) {
        this.fetchTapdBugIdToStatus();
        this.fetchMultipleIssueTrend();
        issueTrendFetched = true;
      }
    }

    if (!issueTrendFetched
      && reduxState.global.get('localStorage').get('issueListTrendType') !== oldReduxState?.global.get('localStorage').get('issueListTrendType')) {
      this.fetchMultipleIssueTrend();
    }
  }

  // 拉取当前页的issuelist的bug状态
  async fetchTapdBugIdToStatus() {
    const { currentApp, issueList } = this.props;
    const appId = currentApp.get('appId');
    const platformId = currentApp.get('pid');
    const bugInfos = issueList.toJS().filter(x => x.bugs && x.bugs.length > 0)
      .map(x => {
        return {
          // 老数据（TAPD）在获取issueList时没有bugPlatform字段
          bugPlatform: x.bugs[0].bugPlatform || "TAPD",
          id: x.bugs[0].id,
        };
      });
    if (bugInfos.length === 0) {
      return;
    }

    let tapdBugIdToStatus = bugInfos.map(item => item.id).reduce((acc, x) => {
      acc[x] = {
        isLoading: true,
        isFetched: false,
        status: null,
      }
      return acc;
    }, {});
    this.setState({ tapdBugIdToStatus });
    const rsp = await RestHelper.post('/redir/query/data/queryBugs', {
      appId,
      platformId,
      bugInfos,
    });
    tapdBugIdToStatus = bugInfos.reduce((acc, x) => {
      const bug = (isArray(rsp.json.data) ? rsp.json.data : []).find(y => y.id === x.id);
      if (bug) {
        acc[x.id] = {
          isLoading: false,
          isFetched: true,
          status: bug.status,
        }
      } else {
        acc[x.id] = {
          isLoading: false,
          isFetched: false,
          status: null,
        }
      }
      return acc;
    }, {});
    this.setState({ tapdBugIdToStatus });
  }

  // 拉取当前页的issueList的趋势图
  async fetchMultipleIssueTrend() {
    const { actions } = this.context;
    const { issueList } = this.props;
    const issueIds = issueList.toJS().map(x => x.issueId);
    if (issueIds.length > 0) {
      actions.fetchMultipleIssueTrend(issueIds);
    }
  }

  getMemberList() {
    const { searchOptions } = this.props;
    const members = searchOptions.get('processor');
    let arr = [];
    if (members) {
      arr = (members.get && members.get('options')) || members.options;
    }
    const memberList = arr.map((member) => ({
      label: (member.get && member.get('label')) || member.label,
      value: (member.get && member.get('value')) || member.value,
    }));// 这里传进去成员列表
    return memberList;
  }

  getInitCheckedStatus(issueList, checkedIssues) {
    const obj = {
      processor: '',
      status: '',
    };
    if (checkedIssues && issueList) {
      if (checkedIssues && isArray(checkedIssues) && checkedIssues.length === 1) {
        const checkIssue = issueList.filter((issue) => issue.get('issueId') === checkedIssues[0]);
        const { processor = '', status } = checkIssue && checkIssue.size === 1 && checkIssue.get(0).toJS();
        obj.processor = processor;
        obj.status = status;
      }
    }
    return obj;
  }

  // 提供一个方法给item来修改状态
  handleChangeIssueStatus(issue, assigneeList, state, members) {
    const { actions } = this.context;
    const { issueId } = issue && issue.toJS && issue.toJS() || {};
    actions.activePopAlert({
      issueId,
      assigneeList,
      members,
      status: getStatus(state),
      page: 'issueList',
    });
  }


  async handleChangeSearchParamWithoutSendSearchRequest(name, value) {
    const { searchParams, location } = this.props;
    const { actions } = this.context;
    const relatedToIsCmp = name === 'isCmp' || (typeof name === 'object' && 'isCmp' in name);
    this.setState({
      isCompareSearch: relatedToIsCmp
        ? (name === 'isCmp' ? !!(value | 0) : !!name.isCmp)
        : !!(searchParams.get('isCmp') | 0),
    });
    if (relatedToIsCmp) {
      this.setState({
        issueListUniqueA: [],
        issueListUniqueB: [],
        issueListIntersection: [],
      });
      actions.setIssueList([]);
    }

    let query = null;
    if (name === 'start' && value === searchParams.get('start')) {
      return false;
    }

    if (typeof name === 'object') {
      query = name;
    } else if (name === 'date') { // 更新时间插件
      const { date, startDate, endDate } = value;
      query = Object.assign({
        date,
        startDateStr: startDate,
        endDateStr: endDate,
      }, query);
    } else if (name === 'cmpDate') { // 更新时间插件
      const { date, startDate, endDate } = value;
      query = Object.assign({
        cmpDate: date,
        cmpStartDateStr: startDate,
        cmpEndDateStr: endDate,
      }, query);
    } else {
      query = {
        [name]: value,
      };
    }

    await actions.updateReduxIssueSearchParams(query);
    /* actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, Object.assign(query, { start: query.start || 0 })),
    });*/
  }

  /**
   * 处理搜索条件改变action
   * @param name {string|object}
   * @param value
   */
  async handleChangeSearchParam(name, value) {
    if (name) {
      await this.handleChangeSearchParamWithoutSendSearchRequest(name, value);
    }
    const { actions } = this.context;

    // 设置query参数到URL上
    const { searchParams, location } = this.props;
    const query = mapValues(searchParams.toJS(), v => !!v && v !== 'all' ? v : null); // 把空值、all值填为null，用于清除原有query里面的旧值
    actions.pushState({
      pathname: location.pathname,
      search: generateSearch(location.search, Object.assign(query, { start: query.start || 0 }), true),
    });

    // 输入框输入不马上进行搜索，需要按enter进行搜索
    if (name !== 'search') {
      actions.showLoading();
      // 用settimeout解决setState不及时更新问题
      setTimeout(() => {
        this.fetchIssueList()
          .then(actions.hideLoading)
          .catch((err) => {
            console.log(err);
            actions.hideLoading();
          });
      }, 0);
    }

    return true;
  }

  fetchIssueList () {
    if (!this.state.isCompareSearch) {
      const { actions } = this.context;
      return actions.getIssueList();
    } else {
      return (async () => {
        // FIXME: redux-router的push看起来是异步的，要sleep一下
        await new Promise(x => setTimeout(x, 10));

        const { currentApp, searchParams } = this.props;
        const appId = currentApp.get('appId');
        const platformId = currentApp.get('pid');
        const params = searchParams.filter((value) => !!value && value !== 'all').toJS();
        if (params.version) {
          params.version = decodeURIComponent(params.version);
        }
        if (params.processor) {
          params.processor = decodeURIComponent(params.processor);
        }
        if (params.searchType && params.search) {
          params[params.searchType] = params.search;
          delete params.searchType;
          delete params.search;
        }

        const queryUniqueA = pickBy(Object.assign({}, params, {
          appId,
          platformId,
          start: 0,
          skipQueryHbase: true,
          rows: MAX_COMPARE_FETCH_COUNT,
          sortField: 'uploadTime',
          sortOrder: 'desc', // 排序现在是前台做，所以往后台传固定的排序
          hasExtraMustNot: true,
          extraVersion: params.cmpVersion,
          extraStartDateStr: params.cmpStartDateStr,
          extraEndDateStr: params.cmpEndDateStr,
          extraDate: params.cmpDate,
        }));
        const queryUniqueB = pickBy(Object.assign({}, params, {
          appId,
          platformId,
          start: 0,
          skipQueryHbase: true,
          rows: MAX_COMPARE_FETCH_COUNT,
          sortField: 'uploadTime',
          sortOrder: 'desc', // 排序现在是前台做，所以往后台传固定的排序
          version: params.cmpVersion,
          date: params.cmpDate,
          startDateStr: params.cmpStartDateStr,
          endDateStr: params.cmpEndDateStr,
          hasExtraMustNot: true,
          extraVersion: params.version,
          extraStartDateStr: params.startDateStr,
          extraEndDateStr: params.endDateStr,
          extraDate: params.date,
        }));
        const queryIntersection = pickBy(Object.assign({}, params, {
          appId,
          platformId,
          start: 0,
          skipQueryHbase: true,
          rows: MAX_COMPARE_FETCH_COUNT,
          sortField: 'uploadTime',
          sortOrder: 'desc', // 排序现在是前台做，所以往后台传固定的排序
          hasExtraMust: true,
          extraVersion: params.cmpVersion,
          extraStartDateStr: params.cmpStartDateStr,
          extraEndDateStr: params.cmpEndDateStr,
          extraDate: params.cmpDate,
        }));
        let [issueListUniqueA, issueListUniqueB, issueListIntersection] = [[], [], []];

        await Promise.all([(async () => {
          const rsp = await RestHelper.post('/api/issue/queryIssueList', queryUniqueA);
          issueListUniqueA = rsp.json.ret.issueList;
        })(), (async () => {
          const rsp = await RestHelper.post('/api/issue/queryIssueList', queryUniqueB);
          issueListUniqueB = rsp.json.ret.issueList;
        })(), (async () => {
          const rsp = await RestHelper.post('/api/issue/queryIssueList', queryIntersection);
          issueListIntersection = rsp.json.ret.issueList;
        })()]);

        this.setState({
          issueListUniqueA,
          issueListUniqueB,
          issueListIntersection,
        });

        for (let x of ['issueListIntersection', 'issueListUniqueA', 'issueListUniqueB']) {
          if (this.state[x].length > 0) {
            this.setState({ cmpRadioValue: x });
            break;
          }
        }
        this.onChangeCmpRadio(this.state.cmpRadioValue);
      })()
    }
  }

  onClickSort (params) {
    if (!this.state.isCompareSearch) {
      this.handleChangeSearchParam(params);
    } else {
      this.handleChangeSearchParamWithoutSendSearchRequest(params);
      const { sortField, sortOrder } = params;
      const sortResultMultiplier = sortOrder === 'asc' ? 1 : -1;
      const { searchParams } = this.props;
      const { actions } = this.context;
      const sortRoutine = (x) => {
        switch (sortField) {
          case 'uploadTime': {
            return [...x].sort((a, b) => {
              if (a.lastestUploadTime > b.lastestUploadTime) {
                return sortResultMultiplier;
              } else if (a.lastestUploadTime < b.lastestUploadTime) {
                return -1 * sortResultMultiplier;
              } else {
                return 0;
              }
            });
          }
          case 'crashCount': {
            return [...x].sort((a, b) => (a.crashNum - b.crashNum) * sortResultMultiplier );
          }
          case 'crashCountConditionA': {
            return [...x].sort((a, b) => (a.crashNumA - b.crashNumB) * sortResultMultiplier );
          }
          case 'crashCountConditionB': {
            return [...x].sort((a, b) => (a.crashNumB - b.crashNumB) * sortResultMultiplier );
          }
          default: {
            return x;
          }
        }
      }

      const newState = {
        issueListUniqueA: sortRoutine(this.state.issueListUniqueA),
        issueListUniqueB: sortRoutine(this.state.issueListUniqueB),
        issueListIntersection: sortRoutine(this.state.issueListIntersection),
      }
      const currentTabIssueList = newState[this.state.cmpRadioValue];
      const begin = searchParams.get('start') | 0;
      const end = begin + this.state.pageSize;
      this.setState({
        ...newState,
        currentTabIssueList,
      })
      actions.setIssueList(currentTabIssueList.slice(begin, end));
    }
  }

  async handlePageClick(data) {
    if (!this.state.isCompareSearch) {
      this.handleChangeSearchParam('start', parseInt(data.selected) * this.props.rows);
    } else {
      this.handleChangeSearchParamWithoutSendSearchRequest('start', parseInt(data.selected) * this.state.pageSize);
      // 这里有个坑，redux-router的push看起来是异步的，要sleep一下
      await new Promise(x => setTimeout(x, 10));
      this.onChangeCmpRadio(this.state.cmpRadioValue);
    }
  }

  // 判断分页是切换页数或者条数
  async paginationChange(page,pageSize){
    await new Promise(x => setTimeout(x, 3));
    if(!this.state.paginationType){
      this.handlePageClick({selected:page - 1});
    }
    this.setState({paginationType: null });
  }

  loadIssueData(currentApp) {
    const { exceptionType, location, t } = this.props;
    const exceptionTypeToText = {
      crash: t('BASEPROBLEM.崩溃列表'),
      anr: t('BASEPROBLEM.ANR列表'),
      error: t('BASEPROBLEM.错误列表'),
      jank: t('BASEPROBLEM.Jank列表'),
    };
    const titleTextExcTypePart = exceptionTypeToText[this.props.exceptionType] || this.props.exceptionType;
    this.props.exceptionType && currentApp.get('appName') && (document.title = `${titleTextExcTypePart}-${currentApp.get('appName')}`);
    const { actions } = this.context;

    if (currentApp.size && currentApp.get('appId')) {
      actions.showLoading();
      actions.changeExceptionTypeOptions({
        pid: currentApp.get('pid'),
        exceptionType,
        query: location.query,
      });
      this.fetchIssueList().then(() => {
        actions.hideLoading();
        return actions.getOptions('version,member,tag,channel');
      }).catch((err) => { // 异常的时候,统一返回-1
        if (err.status === REQUEST_PARAMS_ERROR) {
          show(REQUEST_PARAMS_ERROR_TIPS, 'error');
        }
        actions.hideLoading();
      });
    }
  }

  // 拉取数据异常的时候
  refreshFunc() {
    const { location, currentApp, exceptionType } = this.props;
    const { actions } = this.context;

    if (currentApp.size && currentApp.get('appId')) {
      actions.showLoading();
      actions.changeExceptionTypeOptions({ pid: currentApp.get('pid'), exceptionType, query: location.query });
      actions.getIssueList().then(() => {
        actions.hideLoading();
        return actions.getIssueSearchOptions();
      }).catch(() => { // 加载失败了,把标志位change过来
        actions.hideLoading();
      });
    }
  }

  /** 下载按钮 */
  renderDownlaodIcon() {
    const { actions } = this.context;
    return <CsDownloadButton
      onClick={async () => {
        this.setState({ isDownLoading: true })
      }}
    />
  }

  downloadRadioType(e) {
    this.setState({ downloadValue: e.target.value })
  }

  onChangeCmpRadio(v) {
    const { searchParams } = this.props;
    const { actions } = this.context;

    const begin = searchParams.get('start') | 0;
    const end = begin + this.state.pageSize;
    this.setState({
      cmpRadioValue: v,
      currentTabIssueList: this.state[v],
    })
    actions.setIssueList(this.state[v].slice(begin, end));
  }

  onClickUpsertTapdBugByIssueIdList(issueIdList) {
    const {
      issueList,
    } = this.props;

    this.setState({
      tapdModalIssueInfoList: issueList.toJS().filter(x => issueIdList.includes(x.issueId)),
      tapdModalVisible: true,
    });
  }

  onBugUnbind(issueIdList) {
    const { actions } = this.context;
    const { issueList } = this.props;
    const newIssueListJs = issueList.toJS().map(x => {
      if (!issueIdList.includes(x.issueId)) {
        return x;
      }
      return {
        ...x,
        bugs: [],
      };
    });
    actions.setIssueList(newIssueListJs);
  }

  getIsMultiVersionSearch() {
    const { searchParams } = this.props;
    const versionList = (searchParams.get('version') || '').split(';');
    return versionList.length > 1 || versionList[0].endsWith('*');
  }

  getIsCompareSearch() {
    return this.state.isCompareSearch;
  }

  getSearchBarHeight() {
    const { reduxState } = this.props;
    const isAnnouncementShown = reduxState.global.get('isAnnouncementShown');
    const showTop = isAnnouncementShown ? GLOBAL_NOTIFICATION : 0;
    return this.state.searchBarHeight + showTop;
  }

  getStickyTop() {
    return this.getSearchBarHeight() + TOP_HEADER_HEIGHT;
  }

  renderIssue(issue) {
    const {
      currentApp, dispatch, exceptionType, searchParams,
    } = this.props;
    const { tapdBugIdToStatus } = this.state;
    const { actions } = this.context;
    let { path } = this.props;
    path = path.split('/')[0];
    const memberList = this.getMemberList();
    const versionList = (searchParams.get('version') || '').split(';');

    return (
      <IssueItem
        demoApp={currentApp.get('demoApp')}
        issue={issue}
        isShowUserNum={!this.getIsMultiVersionSearch() && !this.getIsCompareSearch()}
        key={issue.get('issueId')}
        handleCheck={actions.checkIssue}
        dispatch={dispatch}
        appId={currentApp.get('appId')}
        pid={currentApp.get('pid')}
        exceptionType={exceptionType}
        path={path}
        toggleCrashDetail={actions.toggleCrashDetail}
        addTag={actions.addTag}
        delTag={actions.delTag}
        memberList={memberList}
        tapdBugIdToStatus={tapdBugIdToStatus}
        handleChangeIssueStatus={(...params) => this.handleChangeIssueStatus(...params)}
        onClickUpsertTapdBugByIssueIdList={(issueIdList) => this.onClickUpsertTapdBugByIssueIdList(issueIdList)}
        onBugUnbind={(issueId) => this.onBugUnbind([issueId])}
        style={style} />
    );
  }

  render() {
    const {
      issueList,
      numFound,
      queryInaccurateReason,
      rows,
      searchParams,
      searchOptions,
      currentApp,
      exceptionType,
      loading,
      isDemoApp,
      issueListStatusCode,
      t,
      reduxState
    } = this.props;
    const state = this.state;
    const { actions } = this.context;
    const { checkIssue, activePopAlert } = actions;
    const { appId, demoApp, platformId } = currentApp.toJS();

    // 获取所有checked的issue
    const checkedIssues = issueList.filter((issue) => issue.get('checked')).map((issue) => issue.get('issueId'));
    const memberList = this.getMemberList();
    const checkStatus = this.getInitCheckedStatus(issueList, checkedIssues.toJS());
    const pageSize = state.isCompareSearch ? state.pageSize : rows;
    const pageNum = state.isCompareSearch ? Math.ceil(state.currentTabIssueList.length / pageSize) : Math.ceil(numFound / pageSize);
    const checkedNum = issueList.count((issue) => !!issue.get('checked'));

    const modalProps = {
      visible: state.tapdModalVisible,
      onCancel: () => this.setState({ tapdModalVisible: false }),
      onOk: () => {
        this.setState({ tapdModalVisible: false });

        actions.showLoading();
        this.fetchIssueList()
          .then(() => {
            actions.hideLoading();
            this.fetchTapdBugIdToStatus();
          })
          .catch((err) => {
            console.log(err);
            actions.hideLoading();
          });
      },
    };

    const mergeIssuesModalProps = {
      visible: state.mergeIssuesModalVisible,
      loading: state.mergeIssuesModalLoading,
      onCancel: () => this.setState({ mergeIssuesModalVisible: false }),
      onOk: async (needToBeMergedIssueList, mergeIssueId) => {
        this.setState({ mergeIssuesModalLoading: true })
        try {
          const rsp = await RestHelper.post('/redir/query/merge/mergeIssue', {
            appId,
            platformId,
            issueIdList: needToBeMergedIssueList.map((info) => info.issueId),
            ...(mergeIssueId ? { mergeIssueId } : {}),
          }, { silentErrorCodes: [ApiErrorCode.IllegalArgument] });
          if (rsp?.json?.ret === 200) {
            message.success(ze('合并成功', 'successful'));
            this.handleChangeSearchParam();
          }
        } catch (e) {
          message.error(e.message);
          this.handleChangeSearchParam();
        }
        this.setState({
          mergeIssuesModalVisible: false,
          mergeIssuesModalLoading: false
        });
      },
      showModal: (issueIdList) => {
        this.setState({
          mergeIssuesModalVisible: true,
          checkedIssueInfoList: issueList.toJS().filter(x => issueIdList.includes(x.issueId))
        });
      }
    }

    return (
      <div className={style.product_problem} style={{ padding: '24px 24px 0px' }}>
        <div className={style.product_problem_list}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CardHead title={t('BASEPROBLEM.问题列表')} hideDivider={true} svgIcon={ <IssueListIcon/> } />
            {this.renderDownlaodIcon()}
          </div>
          <Divider style={{ marginTop: '0px', marginBottom: '0px' }}/>
          <PageHeader
            {...{
              searchParams, searchOptions, currentApp, exceptionType,
            }}
            reduxState={reduxState}
            handleChange={async (...params) => {
              await this.handleChangeSearchParamWithoutSendSearchRequest('start', 0);
              this.handleChangeSearchParam(...params);
            }}
            handleChangeSearchBarHeight={(height) => { this.setState({ searchBarHeight: height }) }}
            handleChangeWithoutSendSearchRequest={(...params) => this.handleChangeSearchParamWithoutSendSearchRequest(...params)}
            handleSearch={() => this.handleChangeSearchParam()} />

          {
            state.isCompareSearch && <CsTabs
              style={{ flexGrow: 1, margin: "20px 0 10px 0"}}
              bigSize={true}
              activeKey={state.cmpRadioValue}
              onChange={key => this.onChangeCmpRadio(key)}
              items={[
                { label: t("BASEPROBLEM.条件A独有问题"), key: 'issueListUniqueA' },
                { label: t("BASEPROBLEM.条件B独有问题"), key: 'issueListUniqueB' },
                { label: t("BASEPROBLEM.公共问题"), key: 'issueListIntersection' },
              ]}
            />
          }

          <ListHeader
            {...{
              checkIssue,
              style,
              checkedIssues,
              demoApp,
              currentApp,
              actions,
              exceptionType,
            }}
            isMultiVersionSearch={this.getIsMultiVersionSearch()}
            isCompareSearch={this.getIsCompareSearch()}
            fixedHeight={this.getStickyTop()}
            totalRows={issueList.size ? (state.isCompareSearch ? state.currentTabIssueList.length : numFound) : 0}
            queryInaccurateReason={queryInaccurateReason}
            initialSortData={{ sortField: searchParams.get('sortField'), sortOrder: searchParams.get('sortOrder') }}
            checkedNum={checkedNum}
            hasCheckAll={!!issueList.size && issueList.every((issue) => !!issue.get('checked'))}
            onChangeSearchParam={(...params) => this.onClickSort(...params)}
            onClickMergeIssues={(issueIdList) => { mergeIssuesModalProps.showModal(issueIdList) }}
            changeState={() => activePopAlert({
              issueId: checkedIssues.size === 1 ? checkedIssues.toJS()[0] : checkedIssues.join(';'), processor: checkStatus.processor, members: memberList, status: getStatus(checkStatus.status), page: 'issueList',
            })}
            onClickUpsertTapdBugByIssueIdList={(issueIdList) => this.onClickUpsertTapdBugByIssueIdList(issueIdList)}
          />

          <IssueList
            issueList={issueList}
            issueListStatusCode={issueListStatusCode}
            actions={actions}
            refreshFunc={(...params) => this.refreshFunc(...params)}
            renderIssue={(...params) => this.renderIssue(...params)}
            style={style}
            exceptionType={exceptionType}
            searchOptions={searchOptions}
            pid={this.props.currentApp.get('pid')}
          />
          {
            issueListStatusCode === 0 ? (
            <div style={{ marginTop: '20px', display:'flex', justifyContent:'flex-end' }}>
              <NoJumpNextPagination
                showSizeChanger
                current={((searchParams.get('start') / pageSize) ?? 0) + 1}
                defaultPageSize={pageSize}
                total={issueList.size ? (state.isCompareSearch ? state.currentTabIssueList.length : numFound) : 0}
                onChange={(page,pageSize)=>this.paginationChange(page,pageSize)}
                onShowSizeChange={(current, size) => {
                  this.setState({paginationType: '1' });
                  if (state.isCompareSearch) {
                    this.setState({pageSize: size | 0, start: 1});
                    this.handleChangeSearchParam('rows', size);
                  } else {
                    this.handleChangeSearchParam('start', 0);
                    this.setState({ start:1 });
                    this.handleChangeSearchParam('rows', size);
                  }
                }}
              />
              </div>
            ) : ''
          }

          { loading && <Loading />}
        </div>
        <TapdModal
          issueInfoList={state.tapdModalIssueInfoList}
          modalProps={modalProps}
        ></TapdModal>
        <ButtonGotoTop></ButtonGotoTop>
        <MergeIssuesModal
          issueInfoList={state.checkedIssueInfoList}
          modalProps={mergeIssuesModalProps}
        ></MergeIssuesModal>
         <Modal
          title={ze('下载问题数据', 'Download Issues')}
          visible={this.state.isDownLoading}
          onOk={async()=> {
            const result = await actions.getIssueList({ isDownload: true, numDownloads: this.state.downloadValue === 'all' ? 1000 : checkedNum })
            result && this.setState({ isDownLoading: false })

            reportEvent({
              action: EVENT_ACTIONS.CLICK,
              tp1: `${getPageTitle()}-数据下载`,
            })
          }}
          onCancel={()=>this.setState({ isDownLoading: false })}
        >
          <Radio.Group onChange={(e) => this.downloadRadioType(e)} value={this.state.downloadValue}>
            <Radio
              value="portion"
              disabled = {checkedNum < 1}
            >{ ze(`下载已选择的${checkedNum}条数据`, `Download Selected ${checkedNum} Issues` ) }</Radio>
            <Radio value="all">{ ze(`下载全部数据（最大${'1000'}条）`, `Download All Issues (Max ${'1000'} Issues)`) }</Radio>
          </Radio.Group>
        </Modal>
      </div>
    );
  }
}

function createPage(exceptionType) {
  return connect((state, router) => {
    const query = parseSearch(router.location);
    return {
      reduxState: state,
      issueListStatusCode: state.issue.get('issueListStatusCode'),
      issueList: state.issue.get('issueList'),
      numFound: state.issue.get('numFound'),
      queryInaccurateReason: state.issue.get('queryInaccurateReason'),
      rows: parseInt(state.global.get('rows')),
      searchParams: state.issue.get('searchParams'),
      searchOptions: state.issue.get('selectOptions'),
      loading: state.global.get('loading'),
      isDemoApp: state.global.get('isDemoApp'),
      currentApp: state.app.get('current'),
      appList: state.app.get('appList'),
      path: router.location.pathname,
      location: router.location,
      currentAppId: router.match.params.appId,
      pid: query && query.pid,
      exceptionType,
    };
  })(BaseProblemPage);
}

export default {
  CrashProblemPage: createPage('crash'),
  ANRProblemPage: createPage('anr'),
  ErrorProblemPage: createPage('error'),
  JankProblemPage: createPage('jank'),
};
