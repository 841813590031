import React, { Suspense } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {
  App,
  UserPage,
  MailPage,
  ExceptionPage,
  ProductPage,
  WorkBench,
  CrosIframe,
} from 'containers';
import Loading from 'components/commons/Loading';

const BillingMainPage = React.lazy(() => import(/* webpackChunkName: "billing-main-page" */ 'containers/billing/BillingMainPage'));
const ReportShareMainPage = React.lazy(() => import(/* webpackChunkName: "report-share-main-page" */ 'containers/ReportShareMainPage'));
const PersonalAccountMainPage = React.lazy(() => import(/* webpackChunkName: "personal-account-main-page" */ 'containers/PersonalAccount/PersonalAccountMainPage'));
const InviteConfirmPage = React.lazy(() => import(/* webpackChunkName: "invite-confirm-page" */ 'containers/InviteConfirmPage/InviteConfirmPage'));
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ 'containers/welcomeLogin/login'));
const Reset = React.lazy(() => import(/* webpackChunkName: "reset" */ 'containers/welcomeLogin/Reset'));
const CreateAccount = React.lazy(() => import(/* webpackChunkName: "create-account" */ 'containers/welcomeLogin/createAccount'));
const Welcome = React.lazy(() => import(/* webpackChunkName: "welcome" */ 'containers/Welcome/Welcome'));

const WrapApp = withRouter(App);

export const LoadingPage = () => (
  <div style={{height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <Loading />
  </div>
);


export default (history) => (
  <ConnectedRouter history={history}>
    <Suspense fallback={<LoadingPage/>}>
      <WrapApp>
        <Suspense fallback={<LoadingPage/>}>
          <Switch>
            <Route path="/invite-to-app/:invitationCode" component={InviteConfirmPage} />
            <Route path="/invite-to-organization/:invitationCode" component={InviteConfirmPage} />
            <Route path="/crash-reporting" component={ExceptionPage} />
            <Route path='/shared-report/:shareId' component={ReportShareMainPage} />
            { /** @deprecated */ <Route path="/account/profile/:userId" component={UserPage} /> }
            <Route path="/account/profile" component={UserPage} />
            <Route path="/user/:userId" component={UserPage} />
            <Route path="/mail/:userId" component={MailPage} />
            <Route path="/product" component={ProductPage} />
            <Route path='/billing' component={BillingMainPage} />
            <Route path='/personal-account' component={PersonalAccountMainPage} />
            <Route path="/welcome/register" component={CreateAccount} />
            <Route path="/workbench" component={WorkBench} />
            <Route path="/welcome/login" component={Login} />
            <Route path="/welcome/reset" component={Reset} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/cros-iframe" component={CrosIframe} />
            <Route path="/" component={ExceptionPage} />
          </Switch>
        </Suspense>
      </WrapApp>
    </Suspense>
  </ConnectedRouter >
);
