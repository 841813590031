import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Modal, Radio, Row, Space, Spin, Switch, Table } from 'antd';
import { isZh, ze } from 'utils/zhEn';
import { CustomKvUtil } from 'utils/custom-kv';
import { isNullish } from 'utils/nullish';
import _style from 'components/exception/issue/Behavior/style.scss';
import { useImmer } from 'use-immer';
import { ServerAppSettings } from 'utils/server-app-settings';
import { selectIssueCrashFilterCustomKeyOptionsInfo } from 'utils/selectors/selectors';
import { actionFetchCustomKeyOptions } from 'reducers/seniorSearch';
import { CsSelect } from 'components/antd-extension/CsSelect';
import RestHelper from 'utils/RestHelper';
import { fetchCustomKvConfigForCurrentApp } from 'reducers/app/appActions';
import CustomKvValueAliasConfigModal
  from 'components/exception/issue/CustomKvConfigModal/CustomKvValueAliasConfigModal';

const CustomKvConfigModal = ({
  reduxState,
  visible,
  onCancel = () => {},
  onSave = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [selectedTableDataKeys, setSelectedTableDataKeys] = useState([]);

  const [state, updateState] = useImmer({
    keysTableDataInEdit: [],
  });

  const filteredTableData = useMemo(() => {
    const trimmedLowerFilterValue = (filterValue || '').trim().toLowerCase();
    return state.keysTableDataInEdit.filter((x) => {
      return (x.keyWithoutPrefix || '').toLowerCase().includes(trimmedLowerFilterValue)
        || (x.alias || '').toLowerCase().includes(trimmedLowerFilterValue)
    });
  }, [filterValue, state.keysTableDataInEdit]);

  const appId = reduxState.app.get('current').get('appId');
  const serverAppSettings = useMemo(() => reduxState.app.get('appIdToServerAppSettings')?.[appId] || {},
    [reduxState.app, appId]);
  const ignoreCustomKeyType = !!serverAppSettings[ServerAppSettings.keys.ignoreCustomKeyType];
  const configList = useMemo(() => reduxState.app.get('appIdToCustomKvConfig')?.[appId] || [],
    [reduxState.app, appId]);

  const formsRef = useRef({});
  const [formRefreshTrigger, setFormRefreshTrigger] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  //触发分页事件
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };
  //当每页条数发生变化的时候更改页数
  useEffect(() => {setPagination({current: 1, pageSize: pagination.pageSize})},[pagination.pageSize]);

  const [addNewKeysModalVisible, setAddNewKeysModalVisible] = useState(false);
  const [addNewKeysValue, setAddNewKeysValue] = useState([]);

  const [keyForValueAliasConfig, setKeyForValueAliasConfig] = useState(null);
  const [valueAliasConfigModalVisible, setValueAliasConfigModalVisible] = useState(false);

  const { hasFetched: addNewKeysHasFetched, options: customKeyOptions } = useSelector(selectIssueCrashFilterCustomKeyOptionsInfo);

  const addNewKeysOptions = (customKeyOptions || []).map(x => ({ label: x.label, value: x.keyRaw }));

  useEffect(() => {
    setSelectedTableDataKeys([]);
    setFormRefreshTrigger(formRefreshTrigger + 1);
    setPagination({ current: 1, pageSize: 10 });
    if (visible) {
      initTableData();
    }
  }, [visible]);

  // 校验所有步长是否被设置
  const handleValidateAll = async () => {
    let allValid = true;
    for (const key in formsRef.current) {
      try {
        await formsRef.current[key].validateFields(['interval']);
      } catch (error) {
        allValid = false;
      }
    }
    return allValid;
  };
  function isAllDigits(s) {
    return /^\d+$/.test(s);
  }

  const tableColumns = [{
    dataIndex: 'keyWithoutPrefix',
    title: t('behavior.键名'),
  }, {
    dataIndex: 'keyTypeLabel',
    title: t('behavior.键类型'),
    width: '80px',
  }, {
    dataIndex: 'alias',
    title: t('behavior.别名'),
    // eslint-disable-next-line react/display-name
    render: (value, row) => <Input
      style={{ minWidth: '160px' }}
      value={value}
      onChange={(e) => {
        const newVal = e.target.value;
        updateState((draft) => {
          const i = draft.keysTableDataInEdit.findIndex(x => x.key === row.key);
          if (i >= 0) {
            draft.keysTableDataInEdit[i].alias = newVal;
          }
        });

      }}
    />,
  }, {
    dataIndex: 'hasValueAliasConfig',
    title: ze('值别名', 'Value Alias'),
    // eslint-disable-next-line react/display-name
    render: (value, row) => <div
      style={{ display: 'flex', alignItems: 'center', gap: '8px', minWidth: '80px' }}
    >
      <Switch
        size='small'
        checked={row.hasValueAliasConfig}
        onChange={(checked) => {
          updateState((draft)=>{
            const i = draft.keysTableDataInEdit.findIndex(x => x.key === row.key);
            if (i >= 0) {
              draft.keysTableDataInEdit[i].hasValueAliasConfig = checked;
            }
          });
        }}
      />
      { row.hasValueAliasConfig && <Button
        size='small'
        type='link'
        style={{ padding: '0' }}
        onClick={() => {
          setKeyForValueAliasConfig(row.key);
          setValueAliasConfigModalVisible(true);
        }}
      >{ ze('编辑', 'Edit') }</Button> }
    </div>,
  }, {
    dataIndex: 'otherConfig',
    title: t('behavior.可视化展示'),
    width: '400px',
    render: (value, row, idx) => {
      const isUseHistogram = value?.chartType === 'HISTOGRAM';
      const isShowHistogramSetting = ignoreCustomKeyType || row.keyType === 'I';
      return <div className={_style.otherConfig}>
        <Space align="center" style={{width: '100%'}}>
          <Switch size='small' checked={row.showAsChart} onChange={(checked)=>{
            updateState((draft)=>{
              const i = draft.keysTableDataInEdit.findIndex(x => x.key === row.key);
              if (i >= 0) {
                draft.keysTableDataInEdit[i].showAsChart = checked;
                if(!draft.keysTableDataInEdit[i].otherConfig){
                  draft.keysTableDataInEdit[i].otherConfig = {"chartType": "PIE", "interval": null};
                }
              }
            })
          }} />
          {row.showAsChart && <Radio.Group style={{minWidth:isZh()?'168px':'188px'}} onChange={(e)=>{
            const chartType = e.target.value;
            updateState((draft)=>{
              const i = draft.keysTableDataInEdit.findIndex(x => x.key === row.key);
              if (i >= 0) {
                draft.keysTableDataInEdit[i].showAsChart = true;
                draft.keysTableDataInEdit[i].otherConfig = {...value,"chartType": chartType};
              }
            })
          }} value={value?.chartType}>
            <Radio value={'PIE'}>{ze('饼状图','PIE')}</Radio>
            {isShowHistogramSetting && <Radio value={'HISTOGRAM'}>{ze('直方图','HISTOGRAM')}</Radio>}
          </Radio.Group>
          }
          {row.showAsChart && isShowHistogramSetting && isUseHistogram && <Form ref={(formRef) => {
            if (formRef) {
              formsRef.current[row.key] = formRef;
            }
          }}
          key={`intervalForm_${row.key}_${formRefreshTrigger}`}
          layout="vertical"
          onValuesChange={(changedValues, allValues)=>{
            updateState((draft) => {
              const i = draft.keysTableDataInEdit.findIndex(x => x.key === row.key);
              if (i >= 0) {
                draft.keysTableDataInEdit[i].otherConfig = {"chartType": value?.chartType, "interval": changedValues.interval};
              }
            })
          }}>
            <Form.Item
              required
              name={'interval'}
              initialValue={value?.interval}
              rules={[
                { required: true, message: ze('请输入步长','Please input interval') },
                { type: 'number',min: 0, message: ze('数字必须大于0','Number must be over 0'), transform: (value) => Number(value)},
                {
                  validator: (_, value) => {
                    if (!isAllDigits(value)) {
                      return Promise.reject(ze('必须为整数','Must be an integer'));
                    }
                    const numberValue = Number(value);
                    if (numberValue <= 0) {
                      return Promise.reject(ze('数字必须大于0','Number must be over 0'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input style={{width:'100px'}} autoComplete="off" placeholder={ze('请输入步长','interval')}/>
            </Form.Item>
          </Form>}
        </Space>
      </div>
    }
  }];

  const rowSelection = {
    selectedRowKeys: selectedTableDataKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableDataKeys(selectedRowKeys);
    },
  };

  function initTableData() {
    const keysTableDataInEdit = configList.map((x) => {
      const keyInfo = CustomKvUtil.getKeyInfoFromPrefixedCustomKey(x.key);
      if(x.showAsChart && isNullish(x?.otherConfig?.chartType)){
        return {
          ...x,
          otherConfig: {chartType: "PIE"},
          keyWithoutPrefix: keyInfo.keyWithoutPrefix,
          keyType: keyInfo.keyType,
          keyTypeLabel: keyInfo.keyTypeLabel,
        };
      }
      return {
        ...x,
        keyWithoutPrefix: keyInfo.keyWithoutPrefix,
        keyType: keyInfo.keyType,
        keyTypeLabel: keyInfo.keyTypeLabel,
      };
    });
    updateState((draft) => {
      draft.keysTableDataInEdit = keysTableDataInEdit;
    });
  }

  function onClickDeleteKeys() {
    const newKeysTableDataInEdit = state.keysTableDataInEdit.filter(x => !selectedTableDataKeys.includes(x.key));
    setSelectedTableDataKeys([]);
    updateState((draft) => {
      draft.keysTableDataInEdit = newKeysTableDataInEdit;
    });
  }

  async function onConfirmSave() {
    const validated = await handleValidateAll();
    if (!validated) {
      message.error('Invalid configuration.');
      return;
    }
    setLoading(true);
    const config = state.keysTableDataInEdit.map(({
      appId,
      valueAliasList,
      ...keepProps
    }) => keepProps);
    await RestHelper.mustPost('/redir/api/appConfig/setCustomReportKeyConfig', {
      appId,
      config,
    });
    await dispatch(fetchCustomKvConfigForCurrentApp({ skipIfFetched: false }));
    setLoading(false);
    onSave();
  }

  function onConfirmAddNewKeys() {
    const existedKeySet = new Set(state.keysTableDataInEdit.map(x => x.key));
    const addKeyList = addNewKeysValue.filter(x => !existedKeySet.has(x));
    setAddNewKeysValue([]);
    if (addKeyList.length > 0) {
      setPagination({ current: 1, pageSize: pagination.pageSize });
      setFilterValue('');
      const newTableData = [
        ...addKeyList.map(key => {
          const keyInfo = CustomKvUtil.getKeyInfoFromPrefixedCustomKey(key);
          return {
            ...keyInfo,
            key,
            alias: '',
            showAsChart: false,
          };
        }),
        ...state.keysTableDataInEdit,
      ];
      updateState(draft => {
        draft.keysTableDataInEdit = newTableData;
      });
    }
  }

  const addNewKeysModal = <Modal
    title={ ze('添加新的关键字', 'Add New Keys')}
    visible={addNewKeysModalVisible}
    width='640px'
    maskClosable={false}
    onCancel={() => {
      setAddNewKeysValue([]);
      setAddNewKeysModalVisible(false);
    }}
    onOk={() => {
      setAddNewKeysModalVisible(false);
      onConfirmAddNewKeys();
    }}
  >
    <div>{ ze('从最近15日上报的关键字中选择需要添加配置的关键字：', 'Choose the keywords reported in last 15 days:') }</div>
    <div>
      <CsSelect
        mode='multiple'
        allowClear
        showSearch={true}
        optionFilterProp='label'
        style={{ width: '100%' }}
        options={addNewKeysOptions}
        value={addNewKeysValue}
        onChange={v => setAddNewKeysValue(v)}
      />
    </div>
  </Modal>;

  const keyConfigForValueAliasConfig = state.keysTableDataInEdit.find(x => x.key === keyForValueAliasConfig);

  const valueAliasConfigModal = <CustomKvValueAliasConfigModal
    visible={valueAliasConfigModalVisible}
    appId={appId}
    keyConfig={keyConfigForValueAliasConfig}
    onCancel={() => {
      setKeyForValueAliasConfig(null);
      setValueAliasConfigModalVisible(false);
    }}
    onSave={(key, valueAliasList) => {
      setKeyForValueAliasConfig(null);
      setValueAliasConfigModalVisible(false);
      updateState((draft) => {
        const i = draft.keysTableDataInEdit.findIndex(x => x.key === key);
        if (i >= 0) {
          draft.keysTableDataInEdit[i].hasValueAliasConfig = true;
          draft.keysTableDataInEdit[i].valueAliasList = valueAliasList;
        }
      });
    }}
  />;

  const modal = <Modal
    title={ t('behavior.管理自定义关键字') }
    visible={visible}
    width='1000px'
    maskClosable={false}
    onCancel={() => onCancel()}
    footer={<div style={{ display: 'flex', gap: '20px' }}>
      <Button onClick={() => onCancel()}>{ ze('取消', 'Cancel') }</Button>
      <Button
        disabled={loading}
        type='primary'
        onClick={() => onConfirmSave()}
      >{ ze('保存', 'Save') }</Button>
    </div>}
  >
    <Spin spinning={loading}>
      <div>{ ze('可配置需要设置显示别名，或需要支持可视化聚合统计的关键字。', 'You can configure the keywords that need to be set with an alias or support visualization aggregation statistics.') }</div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '8px' }}>
        <div style={{ flexShrink: 0 }}>{ ze('关键字名称过滤', 'Filter By Key / Alias') }</div>
        <Input
          value={filterValue}
          onChange={(e) => {
            setFilterValue(e.target.value.trim());
            setPagination({ current: 1, pageSize: pagination.pageSize });
          }}
        />
        <Button
          onClick={() => {
            setAddNewKeysModalVisible(true);
            if (!addNewKeysHasFetched) {
              dispatch(actionFetchCustomKeyOptions());
            }
          }}
        >{ ze('添加新的关键字', 'Add New Key') }</Button>
        <Button
          danger
          disabled={selectedTableDataKeys.length === 0}
          onClick={() => onClickDeleteKeys()}
        >{ ze('删除选中关键字', 'Delete Selected Keys') }</Button>
      </div>
      <div style={{ marginTop: '8px' }}>
        <Table
          size='small'
          dataSource={filteredTableData}
          rowSelection={rowSelection}
          columns={tableColumns}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
      { addNewKeysModal }
      { valueAliasConfigModal }
    </Spin>
  </Modal>;

  return modal;
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(CustomKvConfigModal);
