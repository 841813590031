import React, { useMemo } from 'react';
import { Button, DatePicker, Input, InputNumber, Select, Space } from 'antd';
import { getTreeSelectVersionOptions } from 'utils/getTreeSelectVersionOptions';
import VersionHybridSelect from 'components/commons/VersionHybridSelect';
import {
  ERROR_EXCEPTION_TYPE_LIST_CUSTOM_ERROR_OPTIONS,
  JANK_EXCEPTION_TYPE_LIST_OPTIONS,
  TapdBugStatusOptions
} from 'utils/constants';
import DurationRangeInput from 'components/commons/IssueCrashFilter/DurationRangeInput';
import RamSelect from 'components/commons/IssueCrashFilter/RamSelect';
import CustomKvSearchKey from 'components/commons/IssueCrashFilter/CustomKvSearchKey';
import { ze } from 'utils/zhEn';
import IosDeviceSelect from 'components/commons/IssueCrashFilter/IosDeviceSelect';
import {
  AllFilterFields, CaseSensitivity,
  FieldName,
  IssueCrashFilterExUtil,
  QueryType,
} from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { useTranslation } from 'react-i18next';
import RamGibSelect from 'components/commons/IssueCrashFilter/RamGibSelect';
import AbsoluteAndRelativeDateRange from 'components/commons/IssueCrashFilter/AbsoluteAndRelativeDateRange';
import VersatileKeywordSearch from 'components/commons/IssueCrashFilter/VersatileKeywordSearch';
import VersatileTextSearch from 'components/commons/IssueCrashFilter/VersatileTextSearch';
import EnlargeableInputTerms from 'components/commons/IssueCrashFilter/EnlargeableInputTerms';
import { COUNTRY_LIST_OPTIONS } from 'components/exception/ProductSummaryPage/CountrySelect';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import FeatureTagSelect from 'components/commons/IssueCrashFilter/FeatureTagSelect';
import AndroidDeviceSelect from 'components/commons/IssueCrashFilter/AndroidDeviceSelect';
import { useSelector } from 'react-redux';
import { selectNullableServerAppSettings } from 'utils/selectors/selectors';
import { ServerAppSettings } from 'utils/server-app-settings';
import VersionWithRangeFilter from 'components/commons/IssueCrashFilter/VersionWithRangeFilter';
import { getExceptionIconByType } from 'utils/exception-icon';
import { CsSelect } from 'components/antd-extension/CsSelect';
import ProcessorSearch from 'components/commons/IssueCrashFilter/ProcessorSearch';
import IssueStatusSelect from 'components/commons/IssueCrashFilter/IssueStatusSelect';
import { isAndroid, isPc, PlatformUtil } from 'utils/platform';
import MacDeviceSelect from 'components/commons/IssueCrashFilter/MacDeviceSelect';
import OsVersionSearch from 'components/commons/IssueCrashFilter/OsVersionSearch';
import { VmTypeUtil } from 'utils/constants/vm-type';
import YesNoAllSelect from 'components/commons/IssueCrashFilter/YesNoAllSelect';
import PcDeviceSelect from 'components/commons/IssueCrashFilter/PcDeviceSelect';
import NumberRangeFilter from 'components/commons/IssueCrashFilter/NumberRangeFilter';

const FieldValuePart = ({
  name,
  value,
  appId,
  platformId,
  filterOptions,
  isReadOnly,
  isFeatureConfigRealtimeTag,
  updateValue = () => {},
}) => {
  const { t } = useTranslation();

  const nullableServerAppSettings = useSelector(reduxState => selectNullableServerAppSettings(reduxState, appId));
  const enableJank = !!(nullableServerAppSettings || {})[ServerAppSettings.keys.enableJank];
  const enableCustomErrorTypes = !!(nullableServerAppSettings || {})[ServerAppSettings.keys.enableCustomErrorTypes];

  const {
    subField,
    not,
    queryType,
    term,
    terms,
    gt,
    gte,
    lt,
    lte,
    legacyValue,
  } = value || {};

  const enrichedFilterOptions = useMemo(() => {
    if (!filterOptions || !filterOptions.exceptionTypeList) {
      return filterOptions;
    }
    return {
      ...filterOptions,
      exceptionTypeList: [
        ...filterOptions.exceptionTypeList,
        ...(enableCustomErrorTypes ? ERROR_EXCEPTION_TYPE_LIST_CUSTOM_ERROR_OPTIONS : []),
        ...(enableJank ? JANK_EXCEPTION_TYPE_LIST_OPTIONS : []),
      ],
    }
  }, [filterOptions]);

  const fieldInfo = AllFilterFields.find(x => x.name === name) || {};
  const {
    filterOptionProperty,
    isMultipleSelect,
    isVersatileKeywordSearch,
    searchMethodWhitelist,
    defaultQueryType,
    isVersatileTextSearch,
    hasIsEmptyQueryType,
  } = fieldInfo;

  if (!name) {
    return <Input
      disabled={true}
      style={{ width: '100%' }}
    />;
  }

  if (FieldName.version === name || FieldName.issueFirstCrashVersion === name) {
    const versionTree = getTreeSelectVersionOptions(filterOptions.version || []);
    return <VersionWithRangeFilter
      isReadOnly={isReadOnly}
      style={{ width: '100%' }}
      treeData={versionTree}
      value={value}
      onChange={(v) => {
        updateValue(v);
      }}
    />;
  }

  if (FieldName.exceptionType === name) {
    return <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      showSearch={false}
      options={enrichedFilterOptions[filterOptionProperty || name]}
      placeholder={fieldInfo.placeholder}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />;
  }

  if (FieldName.issueStatus === name) {
    return <IssueStatusSelect
      style={{ width: '100%', fontSize: '14px' }}
      isMultiple={true}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />;
  }

  if (FieldName.hasHackedModule === name) {
    return <Select
      style={{ width: '100%', fontSize: '14px' }}
      allowClear
      options={[
        { label: ze('不限', 'All'), value: null },
        { label: ze('存在', 'Exists'), value: 1 },
        { label: ze('不存在', 'Not Exists'), value: 0 },
      ]}
      placeholder={ze('不限', 'All')}
      value={ term }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERM, term: v });
      }}
    />;
  }

  if (FieldName.exceptionCategory === name) {
    const options = ExceptionCategoryUtil.getAvailableOptions(platformId, enableJank);
    return <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      showSearch={false}
      placeholder={fieldInfo.placeholder}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    >
      {
        options.map((option) => {
          const icon = getExceptionIconByType({exceptionType: option.value, size: 14});
          return <Option key={option.value} value={option.value}>
            <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
              {icon}
              {option.label}
            </div>
          </Option>;
        })
      }
    </CsSelect>;
  }

  if (name === FieldName.issueTag) {
    return <div style={{ display: 'flex' }}>
      <Select
        style={{ width: '120px' }}
        options={[
          { label: t('issueCrashFilter.包含'), value: 0 },
          { label: t('issueCrashFilter.不包含'), value: 1 },
        ]}
        value={ Number(!!not) }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue({
            ...value,
            not: !!v,
          });
        }}
      />
      <CsSelect
        style={{ flexGrow: 1, fontSize: '14px' }}
        mode='multiple'
        allowClear
        showSearch={true}
        optionFilterProp='label'
        options={filterOptions[filterOptionProperty || name]}
        placeholder={fieldInfo.placeholder}
        value={ terms }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue({ queryType: QueryType.TERMS, terms: v, not });
        }}
      />
    </div>
  }

  if (FieldName.featureTag === name) {
    return <div style={{ display: 'flex' }}>
      <Select
        style={{ width: '120px', flexShrink: 0 }}
        options={[
          { label: t('issueCrashFilter.包含'), value: 0 },
          { label: t('issueCrashFilter.不包含'), value: 1 },
        ]}
        value={ Number(!!not) }
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue({
            ...value,
            not: !!v,
          });
        }}
      />
      <FeatureTagSelect
        appId={appId}
        isReadOnly={isReadOnly}
        value={ terms }
        onChange={(v) => {
          updateValue({ queryType: QueryType.TERMS, terms: v });
        }}
      />
    </div>;
  }

  if (FieldName.processor === name) {
    return <ProcessorSearch
      options={filterOptions[filterOptionProperty || name]}
      value={value}
      onChange={v => updateValue(v)}
      isReadOnly={isReadOnly}
    />
  }

  if (FieldName.issueReportCount === name || FieldName.issueDeviceCount === name) {
    return <NumberRangeFilter
      isReadOnly={isReadOnly}
      fieldValue={value}
      onChangeFieldValue={v => updateValue(v)}
    />
  }

  if ([
    ...(IssueCrashFilterExUtil.getIsBundleIdOptionsAvailable(appId) ? [FieldName.bundleId] : []),
    FieldName.channelId,
  ].includes(name)) {
    return <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      showSearch={true}
      optionFilterProp='label'
      options={filterOptions[filterOptionProperty || name]}
      placeholder={fieldInfo.placeholder}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />
  }

  if (FieldName.vmStatus === name) {
    return <Select
      style={{ width: '100%', fontSize: '14px' }}
      allowClear
      options={[
        { label: t('issueCrashFilter.anyPlaceholder'), value: 'ALL' },
        { label: t('EXCP_OVERVIEW.仅真机'), value: 'ONLY_NOT_VM' },
        { label: t('EXCP_OVERVIEW.仅模拟器'), value: 'ONLY_VM' },
      ]}
      placeholder={t('issueCrashFilter.anyPlaceholder')}
      value={ term }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERM, term: v });
      }}
    />
  }

  if (FieldName.vmType === name) {
    return <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      options={VmTypeUtil.getAndroidOptions()}
      placeholder={t('issueCrashFilter.anyPlaceholder')}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />
  }

  if (FieldName.appInBack === name) {
    return <YesNoAllSelect
      isReadOnly={isReadOnly}
      fieldValue={value}
      onChangeFieldValue={v => updateValue(v)}
      yesLabel={t('REPORTDETAIL.background')}
      noLabel={t('REPORTDETAIL.foreground')}
    />;
  }

  if (FieldName.isSecondReport === name) {
    return <YesNoAllSelect
      isReadOnly={isReadOnly}
      fieldValue={value}
      onChangeFieldValue={v => updateValue(v)}
      yesLabel={t('REPORTDETAIL.secondReportTrue')}
      noLabel={t('REPORTDETAIL.secondReportFalse')}
    />;
  }

  if (FieldName.appBit === name) {
    return <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      options={[
        { label: ze('32位', '32 bit'), value: 32 },
        { label: ze('64位', '64 bit'), value: 64 },
        { label: ze('未识别', 'Unknown'), value: 0 },
      ]}
      placeholder={t('issueCrashFilter.anyPlaceholder')}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />
  }

  if (FieldName.systemExitStatus === name) {
    return <Select
      style={{ width: '100%', fontSize: '14px' }}
      allowClear
      options={[
        { label: t('issueCrashFilter.anyPlaceholder'), value: 'ALL' },
        { label: t('common.yes'), value: 'ONLY_SYSTEM_EXIT' },
        { label: t('common.no'), value: 'ONLY_NOT_SYSTEM_EXIT' },
      ]}
      placeholder={fieldInfo.placeholder}
      value={ term }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERM, term: v });
      }}
    />
  }

  if (FieldName.tapdBugStatus === name || FieldName.jiraBugStatus === name) {
    return <Select
      style={{ width: '100%', fontSize: '14px' }}
      options={TapdBugStatusOptions}
      placeholder={fieldInfo.placeholder}
      value={ term }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERM, term: v });
      }}
    />
  }

  if (FieldName.oomStatus === name) {
    return <Select
      style={{ width: '100%', fontSize: '14px' }}
      allowClear
      options={[
        { label: t('issueCrashFilter.anyPlaceholder'), value: 'ALL' },
        { label: t('issueCrashFilter.仅OOM崩溃'), value: 'ONLY_IS_OOM' },
        { label: t('issueCrashFilter.仅非OOM崩溃'), value: 'ONLY_NOT_IS_OOM' },
      ]}
      placeholder={fieldInfo.placeholder}
      value={ term }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERM, term: v });
      }}
    />
  }

  if (FieldName.ramGib === name) {
    return <RamGibSelect
    platformId={platformId}
    value={ terms }
    onChange={(v) => {
      if (isReadOnly) {
        return;
      }
      updateValue({ queryType: QueryType.TERMS, terms: v });
    }}
  />
  }

  if ([FieldName.crashUploadTime, FieldName.crashTime, FieldName.issueFirstUploadTime, FieldName.issueUploadTime].includes(name)) {
    return <AbsoluteAndRelativeDateRange
      value={value}
      onChange={(v) => updateValue(v)}
      isReadOnly={isReadOnly}
    />;
  }

  if (PlatformUtil.isMac(platformId) && FieldName.model === name) {
    return <MacDeviceSelect
      appId={appId}
      isReadOnly={isReadOnly}
      value={terms}
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />;
  }

  if (FieldName.androidModel === name) {
    return <AndroidDeviceSelect
      appId={appId}
      value={terms}
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />;
  }

  if (FieldName.iosModel === name) {
    return <IosDeviceSelect
      value={terms}
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />;
  }

  if (FieldName.model === name && isPc(platformId, undefined)) {
    return <PcDeviceSelect
      isReadOnly={isReadOnly}
      fieldValue={value}
      onChangeFieldValue={v => updateValue(v)}
    />;
  }

  if (isAndroid(platformId) && FieldName.osVersion === name) {
    return <OsVersionSearch
      appId={appId}
      isReadOnly={isReadOnly}
      value={value}
      onChange={v => updateValue(v)}
    />;
  }

  if (isVersatileKeywordSearch
    || (FieldName.bundleId === name && !IssueCrashFilterExUtil.getIsBundleIdOptionsAvailable(appId))
    || (isVersatileTextSearch && isFeatureConfigRealtimeTag)
  ) {
    return <VersatileKeywordSearch
      defaultQueryType={defaultQueryType}
      searchMethodWhitelist={searchMethodWhitelist}
      hasIsEmptyQueryType={hasIsEmptyQueryType}
      value={value}
      onChange={v => updateValue(v)}
      isReadOnly={isReadOnly}
    />
  }

  if (isVersatileTextSearch && !isFeatureConfigRealtimeTag) {
    return <VersatileTextSearch
      value={value}
      onChange={v => updateValue(v)}
      isReadOnly={isReadOnly}
    />
  }

  if (FieldName.elapsedTime === name) {
    return <DurationRangeInput
      value={ [gte, lte] }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.RANGE, gte: v[0], lte: v[1] });
      }}
    />;
  }

  if (FieldName.ram === name) {
    return <RamSelect
      value={ { gte, lte } }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.RANGE, gte: v.gte, lte: v.lte });
      }}
    />;
  }

  if (FieldName.countryOrRegion === name) {
    return <CsSelect
      style={{ width: '100%', fontSize: '14px' }}
      mode='multiple'
      allowClear
      showSearch={true}
      optionFilterProp='label'
      options={COUNTRY_LIST_OPTIONS}
      value={ terms }
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        updateValue({ queryType: QueryType.TERMS, terms: v });
      }}
    />
  }

  if (FieldName.customKey === name) {
    const isRangeQueryTypeAvailable = /^(?:C03_)?I#.+$/.test(subField || '');
    return <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <CustomKvSearchKey
        appId={appId}
        isReadOnly={isReadOnly}
        value={subField}
        onChange={v => updateValue({ subField: v, QueryType: QueryType.TERMS })}
      />
      { subField && <Select
        style={{ width: '120px' }}
        options={[
          { value: 0, label: ze('满足', 'Is') },
          { value: 1, label: ze('不满足', 'Is Not') },
        ]}
        value={Number(!!not)}
        onChange={v => {
          if (isReadOnly) {
            return;
          }
          updateValue({ ...value, not: !!v });
        }}
      /> }
      { subField && <Select
        style={{ width: '120px' }}
        options={[
          { value: QueryType.TERMS, label: t('issueCrashFilter.精确匹配') },
          ...(!isRangeQueryTypeAvailable ? [{ value: QueryType.TERM_WILDCARD, label: t('issueCrashFilter.通配符匹配') }] : []),
          ...(isRangeQueryTypeAvailable ? [{ value: QueryType.RANGE, label: t('issueCrashFilter.数值范围') }] : []),
        ]}
        value={queryType}
        onChange={v => {
          if (isReadOnly) {
            return;
          }
          updateValue({ subField, queryType: v });
        }}
      /> }
      { subField && queryType === QueryType.TERMS && <Select
        mode='tags'
        placeholder={ze('输入一个或多个值。单击弹出的选项，或按回车键确认输入的值', 'Enter one or more values. Click the pop-up option, or press Enter to confirm the entered values.')}
        style={{ flexGrow: 1 }}
        value={terms}
        allowClear={true}
        notFoundContent={null}
        onChange={v => {
          if (isReadOnly) {
            return;
          }
          updateValue({
            ...value,
            terms: v,
            // 设置为大小写不敏感的方式匹配，但是因为es的数值型字段不允许设置为case insensitive，所以数值型搜索不设置
            ...(isRangeQueryTypeAvailable ? {} : { caseSensitivity: CaseSensitivity.INSENSITIVE }),
          });
        }}
      /> }
      { subField && queryType === QueryType.TERM_WILDCARD && <div style={{ flexGrow: '1' }}>
        <Input
          placeholder={ze('支持通配符 * 和 ?', 'Supports two wildcard operators: * and ?')}
          value={term}
          onChange={e => {
            if (isReadOnly) {
              return;
            }
            updateValue({ ...value, term: e.target.value })
          }}
        />
      </div> }
      { subField && queryType === QueryType.RANGE && <div style={{ flexGrow: '1' }}>
        <Input.Group compact>
          <InputNumber
            value={gte}
            readOnly={isReadOnly}
            style={{ width: 'calc(50% - 20px)', textAlign: 'center' }}
            placeholder="下限（包含）"
            onChange={v => updateValue({ ...value, gte: v })}
          />
          <Input style={{ width: '40px', backgroundColor: 'rgba(0,0,0,0)', textAlign: 'center', pointerEvents: 'none' }} placeholder="~" disabled />
          <InputNumber
            value={lte}
            readOnly={isReadOnly}
            style={{ width: 'calc(50% - 20px)', textAlign: 'center' }}
            placeholder="上限（包含）"
            onChange={v => updateValue({ ...value, lte: v })}
          />
        </Input.Group>
      </div> }
    </div>;
  }

  if (FieldName.appSoNameWhitelist === name) { // iGame定制需求
    return <div style={{ display: 'flex' }}>
      <Select
        style={{ width: '120px' }}
        options={[
          { label: '全命中', value: 0 },
          { label: '非全命中', value: 1 },
        ]}
        value={Number(not)}
        onChange={(v) => {
          if (isReadOnly) {
            return;
          }
          updateValue({ queryType: QueryType.CUSTOM, terms, not: !!v });
        }}
      />
      <EnlargeableInputTerms
        style={{ flexGrow: 1, fontSize: '14px' }}
        value={terms}
        placeholder='大小写敏感，用逗号分隔多个so'
        description='大小写敏感，用逗号分隔多个so。'
        onChange={v => {
          if (isReadOnly) {
            return;
          }
          updateValue({ queryType: QueryType.CUSTOM, terms: v, not });
        }}
      />
    </div>
  }

  // fallback
  return <Input
    disabled={true}
    style={{ width: '100%' }}
    value={JSON.stringify(value)}
  />;
};

export default FieldValuePart;
