import React, { useState, useEffect, useRef } from 'react';
import { CountryZhToEnDict, CountryEnToZhDict } from 'utils/country';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import cloneDeep from 'lodash/lang/cloneDeep';
import { formatEnNumber } from 'utils/format/format-number';
import PropTypes from 'prop-types';
import 'echarts/extension/bmap/bmap';
import { ze } from 'utils/zhEn';
import { EnvUtil } from 'utils/env-util';

export const WorldMapChart = (props) => {
  const { countryStatList, currentTab, tabsMap } = props;
  const tabRef = useRef(currentTab);
  const [initDone, setInitDone] = useState(false);
  const chinaAreas = ['中国大陆', '中国香港', '中国澳门', '中国台湾'];

  const tooltipFormatter = (value) => {
    let formattedValue = '';
    switch (tabRef.current) {
      case 'EXCP_OVERVIEW.userCrashRate':
      case 'EXCP_OVERVIEW.occurNumCrashRate':
      case 'EXCP_OVERVIEW.userANRRate':
      case 'EXCP_OVERVIEW.userANRRate':
      case 'EXCP_OVERVIEW.userErrorRate':
      case 'EXCP_OVERVIEW.occurNumErrorRate':
      case 'EXCP_OVERVIEW.sessionCrashRate':
        formattedValue = `${value.toFixed(2)}%`;
        break;
      case 'EXCP_OVERVIEW.occurNumber':
      case 'EXCP_OVERVIEW.userNumAffect':
      case 'EXCP_OVERVIEW.sessionNumAffect':
      case 'EXCP_OVERVIEW.sessionCount':
      case 'EXCP_OVERVIEW.userNumConnect':
        formattedValue = `${formatEnNumber(value)}`;
        break;
      default:
        formattedValue = '';
    }
    return formattedValue;
  };
  const options = {
    geo: {
      map: 'World',
      zoom: 1,
      show: true,
      roam: true,
      scaleLimit: {
        min: 1,
        max: 5,
      },
      label: {
        normal: {
          show: false,
        },
        emphasis: {
          show: false,
        },
      },
    },
    visualMap: {
      type: 'continuous',
      orient: 'horizontal',
      right: 100,
      bottom: 10,
      min: 0,
      max: 0,
      seriesIndex: '0',
      precision: 0,
      range: [0, 0],
      calculable: true,
      inRange: {
        color: ['#FFFFFF', '#104BFF'],
      },
    },
    series: [
      {
        id: 'value',
        type: 'map',
        geoIndex: 0, // 防止出现seris-map和geo同时使用导致缩放时图层重叠问题
        map: 'World',
        zoom: 1,
        show: true,
        roam: true,
        label: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
          },
        },
        scaleLimit: {
          min: 1,
          max: 5,
        },
        data: [],
      },
    ],
  };
  const [mapOption, setMapOption] = useState(options);

  async function getGeoJson() {
    console.log('__DEBUG__', __DEBUG__);
    const basePath = __DEBUG__ ? 'crashsight-localhost.woa.com:3333' : EnvUtil.getCdnHost();
    const url = `https://${basePath}/static/geojson.json`;
    let data = {};
    try {
      const response = await fetch(url);
      data = await response.json();
    } catch(error) {
      console.error('geoJson load error', error)
    }
    echarts.registerMap('World', data);
    setInitDone(true);
  }

  // 地图组件初始化
  useEffect(() => {
    getGeoJson();
  }, []);
  // 地图数据及统计指标变化时重新渲染
  useEffect(() => {
    tabRef.current = currentTab;
    const options = cloneDeep(mapOption);
    if (countryStatList.length < 1) {
      options.visualMap.inRange.color = ['#FFFFFF'];
      options.series[0].data = [];
      options.visualMap.min = 0;
      options.visualMap.max = 0;
      setMapOption(options);
      return;
    }
    const chinaArr = [];
    // 需要转化成英文地名匹配地图轮廓数据
    const data = countryStatList.filter(item => !item.country.includes('Unknown') && !item.country.includes('-1')).map((item) => {
      // 合并展示中国大陆、港澳台地区数据
      if (chinaAreas.includes(item.country)) {
        chinaArr.push({
          name: CountryZhToEnDict[item.country],
          value: item[tabsMap[currentTab]],
        });
      }
      return {
        name: CountryZhToEnDict[item.country],
        value: item[tabsMap[currentTab]],
      };
    });
    // 大陆港澳台降序展示
    const chinaStr = chinaArr.sort((a, b) => b.value - a.value).reduce((acc, cur) => {
      acc += `${ze(CountryEnToZhDict[cur.name], cur.name)} : ${tooltipFormatter(cur.value)}<br/>`;
      return acc;
    }, `${ze(CountryEnToZhDict.China, 'China')}<br/>`);
    // China对应的数值，若为数量，取地区之和；若为率，取最大值
    let chinaValue = 0;
    if (chinaArr.length > 0) {
      switch (tabRef.current) {
        case 'EXCP_OVERVIEW.userCrashRate':
        case 'EXCP_OVERVIEW.occurNumCrashRate':
        case 'EXCP_OVERVIEW.userANRRate':
        case 'EXCP_OVERVIEW.userANRRate':
        case 'EXCP_OVERVIEW.userErrorRate':
        case 'EXCP_OVERVIEW.occurNumErrorRate':
        case 'EXCP_OVERVIEW.sessionCrashRate':
          chinaValue = chinaArr[0].value;
          break;
        case 'EXCP_OVERVIEW.occurNumber':
        case 'EXCP_OVERVIEW.userNumAffect':
        case 'EXCP_OVERVIEW.sessionNumAffect':
        case 'EXCP_OVERVIEW.sessionCount':
        case 'EXCP_OVERVIEW.userNumConnect':
          chinaValue = chinaArr.map(item => item.value).reduce((acc, cur) => acc + cur);
          break;
        default:
          chinaValue = 0;
      }
    }
    const filteredData = data.filter(item => item.name !== 'China');
    filteredData.push({
      name: 'China',
      value: chinaValue,
    });
    const maxVal = Math.max(...filteredData.map(item => item.value));
    options.series[0].data = filteredData;
    options.visualMap.inRange.color = ['#FFFFFF', '#104BFF'];
    options.visualMap.min = 0;
    options.visualMap.max = maxVal;
    options.visualMap.range = [0, maxVal];
    options.visualMap.precision = [
      'EXCP_OVERVIEW.userCrashRate',
      'EXCP_OVERVIEW.occurNumCrashRate',
      'EXCP_OVERVIEW.userANRRate',
      'EXCP_OVERVIEW.occurNumANRRate',
      'EXCP_OVERVIEW.userErrorRate',
      'EXCP_OVERVIEW.occurNumErrorRate',
      'EXCP_OVERVIEW.sessionCrashRate',
    ].includes(currentTab) ? 2 : 0;
    options.tooltip = {
      trigger: 'item',
      formatter(params) {
        switch (params.componentSubType) {
          case 'map':
            // 目前有主权争议地区不做tooltip提示
            if (params.name === 'Disputed Area') return;
            // 合并展示中国大陆、港澳台地区数据
            if (params.name === 'China') {
              return chinaStr;
            } if (Number.isNaN(params.value)) {
              return `${ze(CountryEnToZhDict[params.name], params.name)} : 0`;
            }
            return `${ze(CountryEnToZhDict[params.name], params.name)} : ${tooltipFormatter(params.value)}`;
        }
      },
    };
    switch (currentTab) {
      case 'EXCP_OVERVIEW.userCrashRate':
      case 'EXCP_OVERVIEW.occurNumCrashRate':
      case 'EXCP_OVERVIEW.userANRRate':
      case 'EXCP_OVERVIEW.occurNumANRRate':
      case 'EXCP_OVERVIEW.userErrorRate':
      case 'EXCP_OVERVIEW.occurNumErrorRate':
      case 'EXCP_OVERVIEW.userJankRate':
      case 'EXCP_OVERVIEW.occurNumJankRate':
      case 'EXCP_OVERVIEW.sessionCrashRate':
        options.visualMap.max = Math.min(Math.max(3, maxVal), 10);
        break;
      case 'EXCP_OVERVIEW.occurNumber':
      case 'EXCP_OVERVIEW.userNumAffect':
      case 'EXCP_OVERVIEW.sessionNumAffect':
        options.visualMap.max = Math.max(100, maxVal);
        break;
      case 'EXCP_OVERVIEW.sessionCount':
      case 'EXCP_OVERVIEW.userNumConnect':
        options.visualMap.max = Math.max(1000, maxVal);
        break;
      default:
        break;
    }
    options.visualMap.range = [options.visualMap.min, options.visualMap.max];
    setMapOption(options);
  }, [currentTab, countryStatList]);
  return initDone ? <ReactEcharts notMerge={true} option={mapOption} style={{ width: '100%', height: '50vh' }} /> : <div></div>;
};

WorldMapChart.propTypes = {
  countryStatList: PropTypes.array,
  currentTab: PropTypes.string,
  tabsMap: PropTypes.object,
};
