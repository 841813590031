import React from 'react';
import { isZh, ze } from 'utils/zhEn';
import { DocsUtil } from 'utils/docs-util';

function keyTypeToLabel(type) {
  if (!type) {
    return '-';
  } else if (type === 'I' || type === 'i') {
    return ze('数值', 'Numeric');
  } else if (type === 'K' || type === 'k') {
    return ze('字符串', 'String');
  } else if (type === 'S' || type === 's') {
    return ze('行为序列', 'Sequence');
  } else {
    return type;
  }
}

const keyTypeTooltipContent = <div>
  <span>{ ze('自定义字段目前支持数值、字符串、行为序列三种数据格式，具体说明请', 'Custom key-values currently support three data types: numeric, string, and behavioral sequence. For specific instructions, please ') }</span>
  <a
    href={isZh() ? DocsUtil.makeDocsUrl('/webDocuments/custom-key-value#3-数据格式说明') : DocsUtil.makeDocsUrl('/webDocuments/custom-key-value/#3-data-format-introduction')}
    target="_blank"
    rel="noopener noreferrer"
  >{ ze('点击查看', 'click to view') }</a>
</div>;

export const CustomKvUtil = Object.freeze({
  /**
   * 自定义KV，将通过#拼成一条kv的多组kv拆分出来（igame的数据格式）
   * @param key {string}
   * @param value {string}
   * @returns {{ type: string, key: string, keyWithType: string, value: string }[]}
   */
  splitCompositeKv(key, value) {
    key = key || '';
    value = value || '';

    key = key.replace(/^C03_/, '');

    if (!key.includes('#')) {
      return [{ type: '-', key, keyWithType: key, value }];
    }
    const res = [];
    const keyList = key.split('#');
    const valueForSplit = value.startsWith('#') ? value : `#${value}`; // value可能以#开头也可能没有，需要适配统一一下
    const valueList = valueForSplit.split('#');
    const keyLen = keyList.length;
    const valueLen = valueList.length;

    if (keyLen <= valueLen) {
      for (let i = 1; i < keyLen; i++) {
        res.push({
          type: keyList[0],
          key: keyList[i],
          keyWithType: keyList[0] + '#' + keyList[i],
          value: (i === keyLen - 1) ? valueList.slice(i).join('#') : valueList[i],
        });
      }
    } else {
      for (let i = 1; i < keyLen; i++) {
        res.push({
          type: keyList[0],
          key: keyList[i],
          keyWithType: keyList[0] + '#' + keyList[i],
          value: valueList[i] || '',
        });
      }
    }
    return res;
  },

  /**
   * 从带前缀（C03_I#之类）的自定义key里提取出不带前缀的key和type
   * @param {String} key
   */
  getKeyInfoFromPrefixedCustomKey(key) {
    const matchResult = key.match(/^(?:C03_)?(.)#(.+)$/);
    if (matchResult == null) {
      return {
        keyWithoutPrefix: key,
        keyType: '',
        keyTypeLabel: '-',
      };
    }
    return {
      keyWithoutPrefix: matchResult[2],
      keyType: matchResult[1],
      keyTypeLabel: keyTypeToLabel(matchResult[1]),
    };
  },

  getKeyTypeTooltipContent() {
    return keyTypeTooltipContent;
  },
});
