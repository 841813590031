import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as platformUtils from 'utils/platform';
import RestHelper from 'utils/RestHelper.js';
import _style from './style.scss';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  DatePicker,
  Row,
  Tooltip,
  TreeSelect,
  message, Popover, Form, Radio, Checkbox
} from 'antd';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import LastReport from 'components/exception/issue/LastReport/LastReport';
import { isNotNullish, isNullish } from 'utils/nullish';
import isString from 'lodash/lang/isString';
import moment from 'moment';
import { isAndroidOrHarmony, isGameConsole, isIos, isMobile, isPcOrLinux, PlatformUtil } from 'utils/platform';
import { convertIosModelToProductName } from 'utils/model-convert/ios-model-convert';
import { CrashUtil, makeCrashListSearchParamsFromSearchParams } from 'utils/api/crash';
import { fromJS } from 'immutable';
import { formatTime, generateSearch, getIssueDetailUrlPrefix } from 'utils/helper';
import IssueCrashSearchUtil from 'utils/IssueCrashSearchUtil';
import { dataComplianceConfirm } from 'utils/dataComplianceConfirm';
import { isZh, ze } from 'utils/zhEn';
import { connect } from 'react-redux';
import { sleep } from 'utils/sleep';
import IssueCrashFilterEx from 'components/commons/IssueCrashFilter/IssueCrashFilterEx';
import {
  FieldName,
  IssueCrashFilterExUtil,
  QueryType
} from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { StackUtil } from 'utils/stack';
import { CrashAttachmentUtil } from 'utils/crash-attachment-util';
import { countryI18n } from 'utils/country';
import DownloadIcon from 'svg/v2/newcs_dashboard_download_icon_normal.svg';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import classnames from 'classnames';
import axios from 'axios';
import uniq from 'lodash/array/uniq';
import { zip, chunk } from 'lodash';
import { ExceptionCategoryUtil } from 'utils/exception-category';

function createExcel(data,title){
  const { utils } = XLSX;
  var ws_name = "sheet1";
  const wb= utils.book_new();
  /* make worksheet */
  var ws = utils.aoa_to_sheet(data);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, title);
}

function tryDecodeUriComponent(s) {
  try {
    return decodeURIComponent(s);
  } catch (e) {
    return s;
  }
}

@connect((state) => {
  return {
    reduxState: state,
  };
}, (dispatch) => ({ dispatch }))
@withTranslation()
export default class ReportRecord extends Component {
  static propTypes = {
    /**
     * 本组件会在两个地方用到，原来是上报列表下面，现在高级搜索展示不聚合上报也用了这个组件
     * true: 表示是高级搜索页面  false: 表示是上报列表页面
     * */
    isFromAdvancedSearch: PropTypes.bool,
    isFromFeaturePreview: PropTypes.bool,
    user: PropTypes.object,
    appId: PropTypes.string,
    issueId: PropTypes.string,
    issue: PropTypes.object,
    crashResp: PropTypes.object,
    dispatch: PropTypes.func,
    handleSearch: PropTypes.func,
    exceptionType: PropTypes.string,
    isDemoApp: PropTypes.bool,
    changeLog: PropTypes.func,
    changeLevel: PropTypes.func,
    clickCheckbox: PropTypes.func,
    changeLogKey: PropTypes.func,
    getCrashAttachment: PropTypes.func,
    getCrashDoc: PropTypes.func,
    pid: PropTypes.number,
    actions: PropTypes.object,
    selectOptions: PropTypes.object,
    path: PropTypes.string,
    rows: PropTypes.number,
    start: PropTypes.number,
    location: PropTypes.object,
    reduxState: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      isInitDone: false,

      searchConditionGroup: {},

      moreOption: false,
      isDownloading: false,
      downloadingTipText: undefined,
      downloadExceptionRows: 100,
      downloadExceptionWithCustomKv: false,

      searchAfterComponentDidUpdate: false,

      downloadReportsModalVisible: false,
      downloadAttachmentModalVisible: false,
      downloadAttachmentFilename: '',
      downloadAttachmentRows: null,
      allowLargeNumberDownloadRows: false,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    // 默认先关闭
    dispatch({
      type: 'IS_SHOW_CRASHITEM',
      value: '-1',
    });
  }

  componentDidMount() {
    const { appId, pid } = this.props;
    if (appId && pid) {
      this.initRoutine();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { appId, pid } = this.props;
    if (appId && pid && (prevProps.appId !== appId || prevProps.pid !== pid)) {
      this.initRoutine();
    }
    if (!prevState.searchAfterComponentDidUpdate && this.state.searchAfterComponentDidUpdate) {
      this.setState({ searchAfterComponentDidUpdate: false });
      const { handleSearch, issueId, actions } = this.props;
      actions.showLoading();
      handleSearch(issueId).then(actions.hideLoading);
    }
  }

  initRoutine() {
    if (this.state.isInitDone) {
      return;
    }
    this.setState({ isInitDone: true });
    const searchConditionGroup = this.makeSearchConditionGroupFromQuery();
    this.setState({
      searchConditionGroup,
    });
  }

  /**
   * 触发设置search params。旧的条件被全部丢弃。
   * @param {{name: string, value: string}[]} nameValueList
   */
  async setMultipleSearchParams(nameValueList) {
    await this.setOrUpdateMultipleSearchParams(nameValueList, false);
  }

  /**
   * 触发更新search params。 旧的条件保留。
   * @param {{name: string, value: string}[]} nameValueList
   */
  async handleChangeMultiple(nameValueList) {
    await this.setOrUpdateMultipleSearchParams(nameValueList, true);
  }

  /**
   * @param {{name: string, value: string}[]} nameValueList
   * @param {Boolean} isUpdate
   */
  async setOrUpdateMultipleSearchParams(nameValueList, isUpdate) {
    const {
      actions, location, crashResp, pid,
    } = this.props;
    const { searchParams: searchParamsObj } = crashResp;

    let query = { pid };

    nameValueList.filter(x => x && x.name).forEach(({ name, value }) => {
      if (name === 'start' && value === searchParamsObj.start) {
        return false;
      }

      if (typeof name === 'object') {
        query = Object.assign({}, query, name);
      } else {
        query = Object.assign({}, query, {
          [name]: value,
        });
      }
    });
    query = Object.assign(query, { start: query.start || 0 });
    query = IssueCrashSearchUtil.searchParamsObjToUrlQueryObj(query);
    if(this.getPageSize() !== query.rows){
      query.start = 0;
    }
    const oldSearch = isUpdate ? location.search : '';
    await actions.pushState({
      pathname: location.pathname,
      search: generateSearch(oldSearch, query, true),
    });
  }

  async onClickSearchButton(searchConditionGroup) {
    await this.changeQueryFromSearchConditionGroup(searchConditionGroup);
    this.setState({ searchAfterComponentDidUpdate: true });
  }

  isEmpty(value) {
    return (Array.isArray(value) && value.length === 0)
      || (Object.prototype.isPrototypeOf.call({}, value) && Object.keys(value).length === 0);
  }

  async onRetraceConfirm(){
    const { t, appId, pid: platformId, issueId, user } = this.props;
    const instance = Modal.success({
      title: ze('本页内容重试还原堆栈','Re-Symbolicate reports on this page'),
      content: '',
      okButtonProps: { style: { display: 'none' } },
    });
    for (const i in this.getTableData()){
      await RestHelper.mustPost('/api/issue/reRetraceCrash',
        {
          appId,
          platformId,
          issueId,
          crashDataType: 'ReloadCrash',
          crashHash: this.getTableData()[i].crashId,
          requestLocalUserId: user.get('newUserId'), // 用于帮助定位重还原问题，增加重还原的请求人
          requestUserName: user.get('nickname'),
        },
      );
        instance.update({
          content:'已还原'+(Number(i)+1)+'条,'+'共需还原'+this.getTableData().length+'条'
        })
      await sleep(2000);
    }
    instance.destroy();
  }

  getNullableLastSearchUsedSearchParams() {
    const { isFromAdvancedSearch, crashResp, reduxState } = this.props;
    if (isFromAdvancedSearch) {
      return reduxState.seniorSearch.get('lastSearchParams');
    } else {
      const { searchParams } = crashResp;
      return fromJS(searchParams);
    }
  }

  async downloadException() {
    await dataComplianceConfirm();
    this.setState({ isDownloading: true });
    const { appId, pid, issue, exceptionType, t, isFromAdvancedSearch } = this.props;
    const issueId = isFromAdvancedSearch
      ? undefined
      : issue.toJS().issueHash.replace(/:/g, '');
    const lastSearchUsedSearchParams = this.getNullableLastSearchUsedSearchParams();
    if (!lastSearchUsedSearchParams) {
      message.error('You must searched before download.');
      return;
    }

    const params = makeCrashListSearchParamsFromSearchParams(lastSearchUsedSearchParams);
    const query = params.merge({
      appId,
      platformId: pid,
      issueId,
      start: 0,
      rows: this.state.downloadExceptionRows,
      needRetracedStack: true,
      needQueryCustomKv: this.state.downloadExceptionWithCustomKv,
    }).toJS();
    const rsp = await RestHelper.post('/api/crash/queryCrashList', query);
    const { crashDatas, crashIdList, crashIdToCustomKvMap: nullableCrashIdToCustomKvMap } = rsp.json.ret;
    const crashIdToCustomKvMap = nullableCrashIdToCustomKvMap || {};

    const issueDetailUrlPrefix = getIssueDetailUrlPrefix(exceptionType);

    let allCustomKeyList = [];
    if (nullableCrashIdToCustomKvMap) {
      const allDupKeys = Object.values(crashIdToCustomKvMap).map(x => Object.keys(x)).flat();
      allCustomKeyList = uniq(allDupKeys);
    }

    const refinedCrashDatas = Object.fromEntries(Object.entries(crashDatas).map(([k, v]) => {
      v = v || {};
      const appInBackString = String(v.appInBack);
      let feStatusText = t("REPORTDETAIL.unknown");
      if (appInBackString === 'false') {
        feStatusText = t("REPORTDETAIL.foreground");
      } else if (appInBackString === 'true') {
        feStatusText = t("REPORTDETAIL.background");
      }
      const newV = {
        ...v,
        feStatusText,
        countryText: countryI18n(v.country || v.srcCountry) || '-',
        crashReportUrlText: `${window.location.origin}/${issueDetailUrlPrefix}/${appId}/${issueId}/report-id/${v.crashId}?pid=${pid}`,
        isVmText: String(v.isVirtualMachine > 0),
        elapsedTimeText: isNotNullish(v.elapsedTime) ? formatTime(v.elapsedTime) : '-',
        retraceCrashDetailLineCount: StackUtil.splitStackIntoLines(v.retraceCrashDetail, pid).filter(x => x.trim()).length,
        customKvMap: crashIdToCustomKvMap[k] || {},
      };
      return [k, newV];
    }));

    const fileTitle = isFromAdvancedSearch
      ? ze('高级搜索上报列表.xlsx', 'AdvancedSearchReportList.xlsx')
      : `${issueId}.xlsx`;
    const columnKeyInfos = [
      { label: t('issueCrashFilterKey.issueId'), dataIndex: 'issueId', available: isFromAdvancedSearch },
      { label: t('issueCrashFilterKey.expUid'), dataIndex: 'expUid' },
      { label: t('issueCrashFilterKey.crashId'), dataIndex: 'crashId' },
      { label: 'URL', dataIndex: 'crashReportUrlText' },
      { label: t('issueCrashFilterKey.crashTime'), dataIndex: 'crashTime' },
      { label: t('issueCrashFilterKey.userId'), dataIndex: 'userId' },
      { label: t('issueCrashFilterKey.deviceId'), dataIndex: 'deviceId' },
      { label: t('issueCrashFilterKey.uploadTime'), dataIndex: 'uploadTime' },
      { label: t('issueCrashFilterKey.version'), dataIndex: 'productVersion' },
      { label: t('REPORTDETAIL.feStatus'), dataIndex: 'feStatusText' },
      ...(isPcOrLinux(pid) ? [] : [
        { label: t('REPORTDETAIL.设备商品名'), dataIndex: 'model' },
      ]),
      { label: t("REPORTDETAIL.cpu"), dataIndex: isPcOrLinux(pid) ? 'cpuName' : 'cpuType' },
      { label: t("issueCrashFilterKey.osVersion"), dataIndex: 'osVer' },
      { label: t('issueCrashFilterKey.rom'), dataIndex: 'rom', available: isAndroidOrHarmony(pid) },
      { label: t('issueCrashFilterKey.vmStatus'), dataIndex: 'isVmText', available: isAndroidOrHarmony(pid) },
      { label: t('issueCrashFilterKey.elapsedTime'), dataIndex: 'elapsedTimeText' },
      { label: t('issueCrashFilterKey.country'), dataIndex: 'countryText' },
      { label: t('issueCrashFilterKey.crashDetail'), dataIndex: 'retraceCrashDetail' },
      { label: ze('出错堆栈行数', 'Lines of Error Stack'), dataIndex: 'retraceCrashDetailLineCount' },
      { label: t('issueCrashFilterKey.crashExpMessage'), dataIndex: 'expMessage' },
      ...allCustomKeyList.map(x => ({ label: x, dataIndex: x, isCustomKv: true }))
    ].filter(x => isNullish(x.available) || x.available);
    const columnTitles = columnKeyInfos.map(x => isString(x) ? x : x.label);
    const columnDatas = crashIdList.map(id => columnKeyInfos.map(column => {
      const dataIndex = isString(column) ? column : column.dataIndex;
      const isCustomKv = !!column.isCustomKv;
      return isCustomKv
        ? refinedCrashDatas[id]?.customKvMap?.[dataIndex] || ''
        : refinedCrashDatas[id][dataIndex] || '';
    }));
    this.setState({ isDownloading: false });
    createExcel([columnTitles, ...columnDatas], fileTitle);
  }

  async batchDownloadAttachments() {
    const downloadAttachmentFilename = this.state.downloadAttachmentFilename;
    const rows = this.state.downloadAttachmentRows;
    const isDownloadPcMinidump = downloadAttachmentFilename === 'Minidump.dmp.gz';
    const isDownloadPcLog = downloadAttachmentFilename === 'logFile.log';
    const isDownloadPcOtherThreads = downloadAttachmentFilename === 'OtherThreads.txt';
    const fileInfo = CrashAttachmentUtil.getInfoByFilename(downloadAttachmentFilename);
    const { isBase64Encoded } = (fileInfo || {});
    const alias = (fileInfo || {}).alias || downloadAttachmentFilename;

    if (!downloadAttachmentFilename) {
      message.error(ze('附件名不能为空', 'Attachment not selected.'));
      return;
    }
    if (!rows) {
      message.error(ze('条数不能为空', 'Rows not selected.'));
      return;
    }

    await dataComplianceConfirm();
    this.setState({ isDownloading: true, downloadingTipText: ze('下载中，请稍候', 'Downloading.') });
    const {
      appId, pid, issue, isFromAdvancedSearch,
    } = this.props;

    const lastSearchUsedSearchParams = this.getNullableLastSearchUsedSearchParams();
    if (!lastSearchUsedSearchParams) {
      message.error('You must searched before download.');
      return;
    }

    const issueId = isFromAdvancedSearch
      ? undefined
      : issue.toJS().issueHash.replace(/:/g, '');
    const params = makeCrashListSearchParamsFromSearchParams(lastSearchUsedSearchParams);
    const query = params.merge({
      appId,
      platformId: pid,
      issueId,
      start: 0,
      rows,
    }).toJS();

    let zipFile = new JSZip();

    // PC minidump: cos直下。
    // PC log：cos直下 + attachment（为了兼容新旧SDK） 。
    // 其他附件：attachment
    if (!isDownloadPcMinidump && !isDownloadPcOtherThreads) {
      const rsp = await RestHelper.post('/redir/query/data/fetchMultipleCrashAttachments', {
        appId,
        platformId: pid,
        search: query,
        attachmentFilenameList: [downloadAttachmentFilename],
      });
      const {crashIdAndAttachmentsList} = rsp.json.data;
      crashIdAndAttachmentsList.forEach(({crashId, expUid, attachments}) => {
        const attach = attachments[0];
        if (isNullish(attach)) {
          return;
        }
        const {content} = attach;
        let downloadContent = isBase64Encoded
          ? Uint8Array.from(window.atob(content), c => c.charCodeAt(0))
          : content;
        const refinedContent = CrashAttachmentUtil.refineFileContent(pid, downloadAttachmentFilename, downloadContent);
        if (isNotNullish(attach)) {
          zipFile.file(`${expUid || crashId}_${alias}`, refinedContent);
        }
      });
    }
    if (isDownloadPcMinidump || isDownloadPcLog) {
      let completeCount = 0;
      const rsp = await RestHelper.post('/api/crash/queryCrashList', query);
      const rsp2 = await RestHelper.get('/api/app/getCosDownloadOrigin');
      const origin = rsp2.json.data;
      const { crashDatas, crashIdList } = rsp.json.ret;
      const crashMapList = Object.values(crashDatas);
      for (const crashMap of crashMapList) {
        const { expUid, crashId, dumpId, sdkVersion } = crashMap;
        let requestParams;
        let filename;
        if (isDownloadPcMinidump) {
          filename = alias;
          requestParams = [`${origin}/${dumpId}.dmp.gz`, { responseType: 'blob' }];
        } else if (isDownloadPcLog) {
          filename = CrashAttachmentUtil.getSdkLogFilename(pid, sdkVersion);
          requestParams = CrashAttachmentUtil.makeRequestParamsForSdkUploadedFile(filename, appId, pid, crashMap);
        } else {
          throw Error('Attachment file not supported');
        }

        if (!requestParams) {
          zipFile.file(`${expUid || crashId}_error.txt`, 'Make request params failed.');
        }

        try {
          const rsp = await axios.get(...requestParams);
          zipFile.file(`${expUid || crashId}_${filename}`, rsp.data);
        } catch (e) {
          const { status } = e.response || {};
          if (!status || status >= 500) {
            const errorContent = 'Error occurred:\n\n' + JSON.stringify(e.response);
            zipFile.file(`${expUid || crashId}_error.txt`, errorContent);
          }
        }
        completeCount += 1;
        this.setState({ downloadingTipText: `${completeCount} / ${crashMapList.length}` });
      }
    }
    if (isDownloadPcOtherThreads) {
      const crashListRsp = await RestHelper.post('/api/crash/queryCrashList', query);
      const { crashDatas } = crashListRsp.json.ret;
      const crashMapList = Object.values(crashDatas);
      const totalCount = crashMapList.length;
      const reqFileList = crashMapList
        .filter(x => !!x.otherBreakpadCosKey)
        .map(x => {
          let { otherBreakpadCosKey } = x;
          if (isPcOrLinux(pid) && !otherBreakpadCosKey?.endsWith('.dmp.gz')) {
            otherBreakpadCosKey = otherBreakpadCosKey + '.dmp.gz';
          }
          return {
            cosKey: otherBreakpadCosKey,
            compressFormat: 'GZIP',
          };
        });
      let completeCount = totalCount - reqFileList.length;
      const chunkSize = 100;
      const reqChunks = chunk(reqFileList, chunkSize);
      let cosKeyToText = {};
      for (const fileListChunk of reqChunks) {
        const rsp = await RestHelper.post('/redir/api/file/batchDecompressInflateFiles', {
          appId,
          fileList: fileListChunk,
        });
        const { fileList: rspFileList } = rsp.json.data;
        for (const [reqFile, rspFile] of zip(fileListChunk, rspFileList)) {
          const { cosKey } = reqFile;
          const { hasFile, contentBase64 } = rspFile;
          if (hasFile) {
            const uint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
            const otherThreadsText = new TextDecoder().decode(uint8Array);
            cosKeyToText[cosKey] = otherThreadsText;
          }
        }
        completeCount += fileListChunk.length;
        this.setState({ downloadingTipText: `${completeCount} / ${totalCount}` });
      }

      crashMapList.forEach(x => {
        const { expUid } = x;
        let { otherBreakpadCosKey } = x;
        if (isPcOrLinux(pid) && !otherBreakpadCosKey?.endsWith('.dmp.gz')) {
          otherBreakpadCosKey = otherBreakpadCosKey + '.dmp.gz';
        }
        const text = cosKeyToText[otherBreakpadCosKey] || '';
        zipFile.file(`expUid_${expUid}_other_threads.txt`, text);
      });
    }

    const blob = await zipFile.generateAsync({type: 'blob'});
    saveAs(blob, `${alias}.zip`);
    message.success(ze('附件已成功下载', 'Attachments downloaded successfully.'));
    this.setState({ isDownloading: false, downloadingTipText: undefined, downloadAttachmentModalVisible: false });
  }

  getPageSize() {
    const { rows } = this.props;
    return rows;
  }

  getPageNumber() {
    const { start } = this.props;
    return Math.floor(start / this.getPageSize()) + 1;
  }

  getIsClientSidePagination() {
    const { isFromFeaturePreview } = this.props;
    return !!isFromFeaturePreview;
  }

  getTableColumns() {
    const { pid, t, exceptionType } = this.props;
    const isClientSidePagination = this.getIsClientSidePagination();
    const isJankCategory = ExceptionCategoryUtil.isJank(exceptionType);

    return [{
      dataIndex: 'crashId',
      title: t('REPORTRECORD.postId'),
    }, {
      dataIndex: 'userId',
      title: t('REPORTDETAIL.userId'),
      width: '300px',
      render: (text) => <Tooltip placement="top" title={text}>
        <div style={{maxWidth:'300px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{text}</div>
      </Tooltip>
    },{
      dataIndex: 'productVersion',
      title: t('REPORTRECORD.version'),
      width: '200px',
    }, {
      dataIndex: 'crashTime', // 因为要排序，必须指定原始字段
      title: t('REPORTRECORD.发生时间'),
      sorter: isClientSidePagination ? undefined : true,
      render: (text, record) => record.crashTimeFormatted,
      width: '250px',
    }, {
      dataIndex: 'uploadTime', // 因为要排序，必须指定原始字段
      title: t('REPORTRECORD.postTime'),
      sorter: isClientSidePagination ? undefined : true,
      render: (text, record) => record.uploadTimeFormatted,
      width: '250px',
    }, {
      dataIndex: 'model',
      disabled: !isMobile(pid),
      title: t('REPORTRECORD.hardware'),
      onCell() {
        return {
          style: {
            paddingTop: '2px',
            paddingBottom: '2px',
          }
        };
      },
      render: (text, record) => {
        let { model, modelOriginalName } = record || {};
        return CrashUtil.formatDeviceName(modelOriginalName, model);
      },
      width: '350px',
    }, {
      dataIndex: 'osVer',
      title: t('REPORTRECORD.osVersion'),
      render: (text) => {
        if (!isIos(pid)) {
          return text;
        }
        return tryDecodeUriComponent(text);
      },
      width: '250px',
    }, {
      dataIndex: 'jankAvgTime',
      key: 'jankAvgTime',
      title: ze('卡顿Root时长(ms)', 'Jank Time (ms)'),
      width: '120px',
      align: 'right',
      render: (text, record) => {
        const { expMessage } = record;
        // expMessage的格式是Jank=123.45ms，用正则把后面的123.45ms这部分取出来
        const jankAvgTime = (expMessage || '').match(/=(\d*\.?\d*)ms/);
        return jankAvgTime ? jankAvgTime[1] : '-';
      },
      disabled: !isJankCategory,
    }].filter(x => !x.disabled);
  }

  getTableData() {
    const { crashResp } = this.props;
    const { crashIdList, crashDatas } = crashResp;
    return (crashIdList || []).map((x) => {
      const v = CrashUtil.makeRefinedCrashMap(crashDatas[x] || {});
      return {
        ...v,
        key: v.crashId,
      };
    });
  }

  async onTableChange(pagination, filters, sorter, { action }) {
    if (action === 'paginate') {
      const { current, pageSize } = pagination;
      if (this.getPageSize() !== pageSize || this.getPageNumber() !== current) {
        const rows = pageSize;
        const start = (current - 1) * pageSize;
        await this.handleChangeMultiple([
          { name: 'rows', value: rows },
          { name: 'start', value: start },
        ]);
        this.setState({ searchAfterComponentDidUpdate: true });
      }
    } else if (action === 'sort') {
      const { field, order } = sorter;
      const desc = order === 'descend';
      await this.handleChangeMultiple([
        { name: 'sortField', value: isNotNullish(order) ? field : null },
        { name: 'desc', value: desc },
      ]);
      this.setState({ searchAfterComponentDidUpdate: true });
    }
  }

  onTableExpandedRowsChange(expandedRows) {
    const oldExpandedKey = this.props.reduxState.issue.get('expandedCrashListTableRowKey');
    const newExpandedKey = expandedRows.find(x => x !== oldExpandedKey) || null;

    const { actions } = this.props;
    actions.resetCrashRelatedInfoInCurrentIssue();  // 清空旧的上报相关信息
    actions.updateReduxIssueState({ expandedCrashListTableRowKey: newExpandedKey });

    const { crashId } = this.getTableData().find(x => x.key === newExpandedKey) || {};
    if (!crashId) {
      return;
    }
    this.fetchCrashDetailByCrashId(crashId);
  }

  closeTableExpandedRows() {
    const { actions } = this.props;
    actions.resetCrashRelatedInfoInCurrentIssue();  // 清空旧的上报相关信息
    actions.updateReduxIssueState({ expandedCrashListTableRowKey: null });
  }

  fetchCrashDetailByCrashId(crashId) {
    const { getCrashDoc, getCrashAttachment, actions, issueId, pid, exceptionType, issue } = this.props;
    let id = crashId;
    if (platformUtils.isPcOrLinux(crashId)) {
      id = crashId.toLowerCase();
    }

    const tasks = !PlatformUtil.hasCrashAttachment(pid, exceptionType, issue)
      ? [getCrashDoc(issueId, id)]
      : [getCrashDoc(issueId, id), getCrashAttachment(issueId, id)];
    actions.showLoading();
    Promise.all(tasks).then(() => {
      actions.hideLoading();
    });
  }

  getCrashTimeRange() {
    const { crashResp } = this.props;
    const { searchParams } = crashResp;
    const { crashTimeBeginMillis, crashTimeEndMillis } = searchParams;
    return [crashTimeBeginMillis, crashTimeEndMillis].map(x => x && moment(Number(x)));
  }

  renderTag(userTag, serverTag) {
    if (`${userTag}` !== '0' && `${serverTag}` !== '0') {
      return userTag + serverTag;
    } else {
      return '-';
    }
  }

  getSelectedVersions() {
    const { crashResp } = this.props;
    const { searchParams } = crashResp;
    const { version } = searchParams;
    return (version && version !== 'all') ? version.split(';') : [];
  }

  makeSearchConditionGroupFromQuery() {
    // const { searchParams } = this.props;
    const urlQueryObj = Object.fromEntries(new URLSearchParams(window.location.search));
    const searchParamsDict = IssueCrashSearchUtil.urlQueryObjToSearchParamsObj(urlQueryObj);
    const searchConditionGroup = IssueCrashFilterExUtil.makeSearchConditionGroupFromSearchParamsDict(searchParamsDict);
    if (searchConditionGroup && (searchConditionGroup.conditions || []).length > 0) {
      return searchConditionGroup;
    }
    // default conditions
    return {
      conditions: [
        { field: FieldName.version },
        { field: FieldName.crashUploadTime, queryType: QueryType.RANGE_RELATIVE_DATETIME, gte: 7 * 86400 * 1000 },
        { field: FieldName.crashDetail },
      ],
    }
  }

  async changeQueryFromSearchConditionGroup(searchConditionGroup) {
    const searchParamsDict = IssueCrashFilterExUtil.makeSearchParamsDictFromSearchConditionGroup(searchConditionGroup);
    const queryNameValueList = Object.entries(searchParamsDict).map(x => ({ name: x[0], value: x[1] }));
    await this.setMultipleSearchParams(queryNameValueList);
  }

  makeFilterOptions() {
    const {
      selectOptions,
    } = this.props;
    const {
      version,
      bundleIdList,
      channelList,
    } = selectOptions.size ? selectOptions.toJS() : {};

    return {
      version: (version.options || []).filter(x => x.value && x.value !== 'all'),
      bundleId: ((bundleIdList || {}).options || []).filter(x => x.value && x.value !== 'all'),
      channelId: ((channelList || {}).options || []).filter(x => x.value && x.value !== 'all'),
      exceptionTypeList: [],
      tagList: [],
      status: [],
      processor: [],
    }
  }

  renderFilter() {
    const { pid } = this.props;
    return <IssueCrashFilterEx
      platformId={pid}
      hideIssueFields
      hiddenFieldList={[FieldName.issueId]}
      searchConditionGroup={this.state.searchConditionGroup}
      filterOptions={this.makeFilterOptions()}
      onChange={({ searchConditionGroup }) => {
        this.changeQueryFromSearchConditionGroup(searchConditionGroup)
      }}
      onSubmit={({ searchConditionGroup }) => {
        this.onClickSearchButton(searchConditionGroup);
      }}
    />;

  }

  renderDownloadReportsModal() {
    const { t, appId, pid, issue } = this.props;
    const rowOptions = [100, 1000, 10000].map(x => ({ label: x, value: x }));

    const supportDownloadWithCustomKv = !isGameConsole(pid);

    const modalContent = <div>
      <Row align='middle' gutter='10'>
        <Col flex='none'>{ ze('要导出的最近上报条数：','Recent records to export:') }</Col>
        <Col flex='none'><Select
          style={{ width: '100px' }}
          options={rowOptions}
          value={this.state.downloadExceptionRows}
          onChange={(v) => this.setState({ downloadExceptionRows: v })}
        /></Col>
      </Row>
      { supportDownloadWithCustomKv && <div style={{ marginTop: '12px' }}>
        <Checkbox
          value={this.state.downloadExceptionWithCustomKv}
          onChange={e => this.setState({ downloadExceptionWithCustomKv: e.target.checked })}
        >{ ze('包含自定义字段', 'Include Custom Key-Values') }</Checkbox>
      </div> }
    </div>;

    return <Modal
      visible={this.state.downloadReportsModalVisible}
      title={t("REPORTRECORD.导出异常")}
      width='480px'
      onOk={() => {
        this.downloadException();
      }}
      onCancel={() => { this.setState({ downloadReportsModalVisible: false }) }}
      okButtonProps={{ disabled: this.state.isDownloading }}
    >{ modalContent }</Modal>;
  }

  getDownloadAttachmentRowOptionValues(filename) {
    let res = this.state.allowLargeNumberDownloadRows
      ? [100, 500, 1000, 10000]
      : [100, 500, 1000];
    if (filename !== 'Minidump.dmp.gz') { // PC minidump附件下载方式不同，而且偏大，所以限制只能少下一些
      return res;
    } else {
      return res.map(x => x / 10);
    }
  }

  renderDownloadAttachmentModal() {
    const { t, appId, pid, issue } = this.props;
    const attachmentFilenameOptions = [
      'valueMapOthers.txt',
      ...(isAndroidOrHarmony(pid) ? ['extraMessage.txt'] : []),
      ...(isIos(pid) ? ['crash_attach.log'] :[]),
      ...(isPcOrLinux(pid) ? ['crashSightLog.log'] :[]),
      ...(isAndroidOrHarmony(pid) ? ['crashsightlog.zip'] :[]),
      ...(isIos(pid) ? ['crashsightlog.txt'] :[]),
      ...(isPcOrLinux(pid) ? ['logFile.log'] :[]),
      ...(isPcOrLinux(pid) ? ['Minidump.dmp.gz'] :[]),
      ...(isPcOrLinux(pid) ? ['OtherThreads.txt'] :[]),
    ].map(x => {
      const fileInfo = CrashAttachmentUtil.getInfoByFilename(x);
      const label = (fileInfo || {}).alias || x;
      return { label, value: x };
    });
    const rowOptions = this.getDownloadAttachmentRowOptionValues(this.state.downloadAttachmentFilename).map(x => ({ label: x, value: x }));

    const modalContent = <Spin
      spinning={this.state.isDownloading}
      tip={this.state.downloadingTipText}
    ><div>
      <Form style={{ width: '100%' }} labelCol={{ style: { width: isZh() ? '150px' : '200px' } }}>
        <Form.Item label={ze('要导出的附件', 'Attachment File')}>
          <Select
            options={attachmentFilenameOptions}
            value={this.state.downloadAttachmentFilename}
            onChange={(v) => this.setState({
              downloadAttachmentFilename: v,
              downloadAttachmentRows: this.getDownloadAttachmentRowOptionValues(v)[0],
            })}
          />
        </Form.Item>
        <Form.Item label={ze('要导出的上报条数','Recent records to download')}>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              this.setState({ allowLargeNumberDownloadRows: true });
            }}
          ><Select
            options={rowOptions}
            value={this.state.downloadAttachmentRows}
            onChange={(v) => this.setState({ downloadAttachmentRows: v })}
          /></div>
        </Form.Item>
      </Form>
    </div></Spin>;

    return <Modal
      visible={this.state.downloadAttachmentModalVisible}
      maskClosable={false}
      title={t("REPORTRECORD.导出附件")}
      width='480px'
      onOk={() => {
        this.batchDownloadAttachments();
      }}
      onCancel={() => { this.setState({ downloadAttachmentModalVisible: false }) }}
      okButtonProps={{ disabled: this.state.isDownloading }}
    >{ modalContent }</Modal>;
  }

  render() {
    const {
      issue, crashResp, appId, pid, issueId, exceptionType, dispatch, path, t, user,
      changeLog, changeLevel, clickCheckbox, changeLogKey, isDemoApp, actions,
      isFromAdvancedSearch,
      isFromFeaturePreview,
    } = this.props;
    const isClientSidePagination = this.getIsClientSidePagination();

    const expandedCrashListTableRowKey = this.props.reduxState.issue.get('expandedCrashListTableRowKey');
    const { crashId: crashHash } = this.getTableData().find(x => x.key === expandedCrashListTableRowKey) || {};

    const maxListedCrashCount = 1000;
    const paginationTotal = Math.min(crashResp.numFound, maxListedCrashCount); // 限制深度分页，只能通过分页器查询前1000条
    const { isSuper } = this.props.reduxState.user.get('current').toJS();

    return (
      <div className={isFromAdvancedSearch ? undefined : classnames(_style.issue_container, _style.reportDetailContainer)}>
        { !isFromAdvancedSearch && <div>
          { this.renderFilter() }
        </div> }
        { !isFromFeaturePreview && crashResp.crashIdList
          && crashResp.crashIdList.length > 0
          && <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '12px 0 12px 0' }}><Space>
            <Button
              disabled={this.state.isDownloading}
              onClick={() => this.setState({ downloadReportsModalVisible: true })}
            ><div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <DownloadIcon />
              <div>{ t('REPORTRECORD.导出异常') }</div>
            </div></Button>
            { !isGameConsole(pid) && <Button
              disabled={this.state.isDownloading}
              onClick={() => this.setState({ downloadAttachmentModalVisible: true })}
            ><div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <DownloadIcon />
              <div>{ t('REPORTRECORD.导出附件') }</div>
            </div></Button>}
            { !!isSuper && <Button onClick={() => this.onRetraceConfirm()}>{ze('（超管）重还原本页上报的堆栈','(Super Admin) Resymbolicate reports in this page')}</Button>}
          </Space></div>
        }
        <Table
          columns={this.getTableColumns()}
          sortDirections={['descend', 'ascend', 'descend']}
          dataSource={this.getTableData()}
          scroll={{ x: 'max-content'}}
          pagination={isClientSidePagination ? { showTotal: (total) => t('common.共n条', { count: total }) } : {
            defaultPageSize: this.getPageSize(),
            pageSize: this.getPageSize(),
            defaultCurrent: this.getPageNumber(),
            current: this.getPageNumber(),
            total: paginationTotal,
            showTotal: () => t('common.共n条', { count: crashResp.numFound }),
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          expandable={{
            expandRowByClick: true,
            expandedRowKeys: expandedCrashListTableRowKey ? [expandedCrashListTableRowKey] : [],
            expandedRowRender: (record) => (record.key === expandedCrashListTableRowKey && <div>
              <LastReport
                {...{
                  appId,
                  pid,
                  issueId: record.issueId,
                  issue,
                  dispatch,
                  exceptionType,
                  changeLog,
                  changeLevel,
                  clickCheckbox,
                  changeLogKey,
                  isReport: true,
                  style: _style,
                  isDemoApp,
                  crashHash,
                  path,
                  user,
                  closeCrashItem: this.closeTableExpandedRows.bind(this),
                }}
              />
            </div>),
            onExpandedRowsChange: this.onTableExpandedRowsChange.bind(this),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button size='small' style={{ width: 16, height: 16, borderRadius: 4 }} icon={<CaretUpFilled style={{ fontSize: 12 }} />} onClick={e => onExpand(record, e)}></Button>
              ) : (
                <Button size='small' style={{ width: 16, height: 16, borderRadius: 4 }} icon={<CaretDownFilled style={{ fontSize: 12 }} />} onClick={e => onExpand(record, e)}></Button>
              )
          }}
          onChange={isClientSidePagination ? undefined : this.onTableChange.bind(this)}
        />
        { this.renderDownloadReportsModal() }
        { this.renderDownloadAttachmentModal() }
      </div>
    );
  }
}
