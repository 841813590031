import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import {
  Button,
  Col,
  Input, message, Popover,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import scss from './IssueCrashFilter.scss';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';
import { isNotNullish, isNullish } from 'utils/nullish';
import {
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import OverviewSave from 'svg/v2/newcs_dashboard_selectbox_close_icon_normal.svg';
import { connect, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import {
  selectCurrentAppCustomUserSceneTagLabel,
  selectFrontendWebVolatileConfigValueByKey
} from 'utils/selectors/selectors';
import { FrontendWebVolatileConfigKeys } from 'utils/constants/frontend-web-volatile-config';
import { ze } from 'utils/zhEn';
import { GlobalResizeObserver } from 'utils/global-resize-observer';
import FieldValuePart from 'components/commons/IssueCrashFilter/FieldValuePart';
import {
  AllFilterFields,
  FieldName,
  FieldNameToFieldInfo
} from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ClipboardUtil } from 'utils/clipboard-util';
import { ServerAppSettings } from 'utils/server-app-settings';
import FilterFieldSelect from 'components/commons/IssueCrashFilter/FilterFieldSelect';
import { isAndroid, isPc, PlatformUtil } from 'utils/platform';

const IssueCrashFilterContext = React.createContext({
  isSuperAdmin: false,
  appId: '',
  platformId: 0,
  hideIssueFields: false,
  fieldWhitelist: [],
  fieldBlacklist: [],
  hiddenFieldList: [],
  implicitHiddenFieldList: [],
  fixedFieldList: [],
  isReadOnly: false,
  isOom: false,
  isFeatureConfig: false,
  isFeatureConfigRealtimeTag: false,
  enableIgameSearchFields: false,
  enableSearchKeyStackSoName: false,
});


// 从特征分析那边来的特殊过滤字段
const FEATURE_ANALYSIS_HIDDEN_INPUT_FIELDS = [
  { name: 'exceptionCategoryList' },
  { name: 'rawSearchGroupJsonStr' },
];

const FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES = FEATURE_ANALYSIS_HIDDEN_INPUT_FIELDS.map(x => x.name);

const ALL_FILTER_NAMES = AllFilterFields.map(x => x.name);

const OOM_WHITELIST_FILTER_NAMES = [
  FieldName.version,
  FieldName.crashTime,
  FieldName.crashUploadTime,
  FieldName.deviceId,
  FieldName.userId,
  FieldName.oomStatus,
  FieldName.ramGib,
  FieldName.iosModel,
  FieldName.androidModel,
  FieldName.model,
  FieldName.osVersion,
  FieldName.elapsedTime,
  FieldName.expUid,
  FieldName.userSceneTag,
];

// iGame定制需求，搜索system log, custom log，crashInfo（其他信息）和appSoNameWhitelist暂时只对iGame开放
const IGAME_FIELDS_SET = new Set(['customLog', 'systemLog', 'crashInfos', 'appSoNameWhitelist']);

const SingleFilter = ({
  filterOptions,
  disabledNameList,
  name,
  value,
  onChangeKv,
}) => {
  const { t } = useTranslation();
  const {
    isSuperAdmin,
    appId,
    platformId,
    hideIssueFields,
    fieldWhitelist,
    fieldBlacklist,
    hiddenFieldList,
    implicitHiddenFieldList,
    fixedFieldList,
    isReadOnly,
    isOom,
    isFeatureConfig,
    isFeatureConfigRealtimeTag,
    enableIgameSearchFields,
    enableSearchKeyStackSoName,
  } = useContext(IssueCrashFilterContext);

  const customUserSceneTagLabel = useSelector(selectCurrentAppCustomUserSceneTagLabel);

  const [innerName, setInnerName] = useState('');
  const [innerValue, setInnerValue] = useState(null);

  const isFixedField = useMemo(() => fixedFieldList.includes(innerName), [innerName, fixedFieldList]);

  let nameOptions = AllFilterFields
    .filter(x => {
      if ((fieldWhitelist || []).length > 0 && !fieldWhitelist.includes(x.name)) {
        return false;
      }
      if (!isSuperAdmin && x.name === FieldName.crashId) { // crashId对普通用户没用，仅限超管使用
        return false;
      }
      if (isOom && !OOM_WHITELIST_FILTER_NAMES.includes(x.name)) {
        return false;
      }
      if (!isOom && x.oomOnly) {
        return false;
      }
      if (hideIssueFields && x.issueOnly) {
        return false;
      }
      if (fieldBlacklist && fieldBlacklist.includes(x.name)) {
        return false;
      }
      if (implicitHiddenFieldList && implicitHiddenFieldList.includes(x.name) && x.name !== innerName) { // 对于隐藏的字段，如果当前就是选的这个字段就显示出来，否则的话不显示
        return false;
      }
      if (hiddenFieldList && hiddenFieldList.includes(x.name) && x.name !== innerName) { // 对于隐藏的字段，如果当前就是选的这个字段就显示出来，否则的话不显示
        return false;
      }
      if (typeof x.availableByPid == 'function' && !x.availableByPid(platformId)) {
        return false;
      }
      if (FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES.includes(x.name)) {
        return false;
      }
      if (isFeatureConfig && x.name === FieldName.featureTag) {
        return false;
      }
      if (!enableIgameSearchFields && IGAME_FIELDS_SET.has(x.name)) {
        return false;
      }
      if (!enableSearchKeyStackSoName && x.name === 'keyStackSoName') {
        return false;
      }
      return true;
    })
    .map(x => {
      let label = x.customLabel || t(`issueCrashFilterKey.${x.name}`, x.name);
      if (x.name === FieldName.userSceneTag && customUserSceneTagLabel) {
        label = customUserSceneTagLabel;
      }
      return {
        label,
        richLabel: <div>{ label }</div>,
        value: x.name,
        issueOnly: x.issueOnly,
        disabled: (disabledNameList || []).includes(x.name),
      };
    });

  const nullableMatchedOption = nameOptions.find(x => x.value === innerName);
  const isFieldNotInOptions = innerName && isNullish(nullableMatchedOption);
  let unsupportedFieldTooltipDom = undefined;

  // 如果innerName不在nameOptions里，增加有提示icon的选项
  // 主要原因是
  // 1. 以前支持的字段，后来添加到blacklist标为不支持了，但是要兼容旧数据，所以要显示出来但要有提示
  // 2. 特征规则那边传过来的隐藏字段
  if (isFieldNotInOptions) {
    if (FEATURE_ANALYSIS_HIDDEN_INPUT_FIELD_NAMES.includes(innerName)) {
      unsupportedFieldTooltipDom = <Tooltip title={t('issueCrashFilter.featureAnalysisHiddenField')}>
        <ExclamationCircleOutlined style={{color: '#AAA'}}/>
      </Tooltip>;
    } else {
      unsupportedFieldTooltipDom = <Tooltip title={t('issueCrashFilter.invalidFieldName')}>
        <ExclamationCircleOutlined style={{color: 'red'}}/>
      </Tooltip>;
    }

    nameOptions.push({
      label: t(`issueCrashFilterKey.${innerName}`, innerName),
      richLabel: <Space size={4}>
        <div>{unsupportedFieldTooltipDom}</div>
        <div>{t(`issueCrashFilterKey.${innerName}`, innerName)}</div>
      </Space>,
      value: innerName,
      disabled: true,
      fieldNotInOptions: true,
    });
  }

  const nameOptionGroups = [{
    label: t('issueCrashFilter.问题级字段'),
    options: nameOptions.filter(x => !x.fieldNotInOptions && x.issueOnly),
  }, {
    label: t('issueCrashFilter.上报级字段'),
    options: nameOptions.filter(x => !x.fieldNotInOptions && !x.issueOnly),
  }, {
    label: ze('其他', 'Others'),
    options: nameOptions.filter(x => x.fieldNotInOptions),
  }].filter(x => x.options.length > 0);

  useEffect(() => {
    setInnerName(name);
    setInnerValue(value);
  }, [name, value]);

  function updateName(k) {
    const defaultValue = (AllFilterFields.find(x => x.name === k) || {}).defaultValue;
    setInnerName(k);
    setInnerValue(defaultValue);
    onChangeKv(k, defaultValue);
  }

  function updateValue(v) {
    setInnerValue(v);
    onChangeKv(innerName, v);
  }

  const valueDom = <FieldValuePart
    isReadOnly={isReadOnly}
    isFeatureConfigRealtimeTag={isFeatureConfigRealtimeTag}
    name={innerName}
    value={innerValue}
    appId={appId}
    platformId={platformId}
    filterOptions={filterOptions}
    updateValue={(v) => updateValue(v)}
  />;

  return <div className={scss.singleFilterBox}>
    <div className={scss.singleFilterFieldSelect}>
      { !isReadOnly && !isFixedField && <FilterFieldSelect
        optionGroups={nameOptionGroups}
        value={innerName}
        onChange={(v) => updateName(v)}
      /> }
      { (isReadOnly || isFixedField) && <Tooltip trigger='click' title={isFixedField ? ze(`不可以删除这个字段`, 'This filter condition is mandatory. You cannot remove the condition.') : undefined}><Input
        style={{ width: '100%' }}
        readOnly={true}
        value={ nullableMatchedOption ? nullableMatchedOption.label : null }
        prefix={ isFieldNotInOptions ? unsupportedFieldTooltipDom : undefined }
      /></Tooltip> }
      { !isReadOnly && !isFixedField && <div className={scss.singleFilterFieldSelectDeleteBtn}>
        <div
          onClick={() => updateName(null)}
        ><OverviewSave/></div>
      </div> }
    </div>
    <div style={{ flexGrow: 1, marginLeft: '0' }}>{valueDom}</div>
  </div>;
};

function calcDefaultColSpan(containerWidthPx) {
  if (containerWidthPx > 1800) {
    return 8;
  } else if (containerWidthPx > 1000) {
    return 12;
  } else {
    return 24;
  }
}

const IssueCrashFilterEx = ({
  reduxState,
  selectFrontendWebVolatileConfigValueByKey,
  platformId,
  hideIssueFields,
  fieldWhitelist,
  fieldBlacklist,
  hiddenFieldList, // 隐藏的字段，不会出现在下拉里面。和fieldBlacklist的区别是不会在字段名前面用红色icon提示该字段是无效字段
  fixedFieldList, // 固定字段，不可以删除或者修改
  filterOptions,
  searchConditionGroup,
  onChange = () => {},
  hideSubmitButton,
  onSubmit = () => {},
  extraDomAfterSubmitButton,
  isFeatureConfig, // 是否是自定义特征这边用的
  isFeatureConfigRealtimeTag, // 是否是特征分析上报打标签模式的过滤器，这种模式下支持的过滤方式不同
  isOom, // 是否是OOM的页面
  isReadOnly, // feature config专用
  onDelete = () => {}, // feature config专用
  prefixSlotList, // 额外插入的类似字段一样的dom列表
  copyScgToClipboardTrigger,
}) => {
  const { t } = useTranslation();
  const appId = reduxState.app.get('current').get('appId');
  const isSuperAdmin = reduxState.user.get('current').get('isSuper');

  const bugTrackingSimplifiedInfo = useMemo(() => reduxState.app.get('appIdToBugTrackingSimplifiedInfo')?.[appId],
    [reduxState.app, appId]);
  const { tapdWorkspaceId, jiraProjectId } = bugTrackingSimplifiedInfo || {};
  const serverAppSettings = useMemo(() => reduxState.app.get('appIdToServerAppSettings')?.[appId] || {},
    [reduxState.app, appId]);
  const isEnableSubModuleId = !!serverAppSettings[ServerAppSettings.keys.enableSubModuleId];
  const isEnableAndroidSearchGpu = !!serverAppSettings[ServerAppSettings.keys.enableAndroidSearchGpu];
  const isEnableChannelId = !!serverAppSettings[ServerAppSettings.keys.enableReportFieldChannelId];

  const implicitHiddenFieldList = [
    FieldName.vmStatus,
    FieldName.errorType,
    (!PlatformUtil.isMac(platformId) && !isPc(platformId, undefined)) && FieldName.model, // 安卓/iOS的原始model已经废弃，改用机型Model字段
    !tapdWorkspaceId && FieldName.tapdBugStatus,
    !jiraProjectId && FieldName.jiraBugStatus,
    !isEnableSubModuleId && FieldName.serverEnv,
    !isEnableAndroidSearchGpu && isAndroid(platformId) && FieldName.gpu, // 安卓端，gpu字段需要通过开关开放
    !isEnableChannelId && FieldName.channelId,
  ].filter(x => x);

  // 是否支持搜索system log和custom log
  const enableIgameSearchFields = (selectFrontendWebVolatileConfigValueByKey(
    FrontendWebVolatileConfigKeys.ENABLE_IGAME_SEARCH_FIELDS_APP_ID_WHITELIST
  ) || '')
    .split(',')
    .map(x => x.trim())
    .includes(appId);

  const enableSearchKeyStackSoName = (selectFrontendWebVolatileConfigValueByKey(
    FrontendWebVolatileConfigKeys.ENABLE_SEARCH_KEY_STACK_SO_NAME
  ) || '')
    .split(',')
    .map(x => x.trim())
    .includes(appId);

  const [containerWidthPx, setContainerWidthPx] = useState(0);

  const [innerSearchConditionGroup, updateInnerSearchConditionGroup] = useImmer({
    conditions: [],
  });
  const [changeEventTrigger, setChangeEventTrigger] = useState(0);

  const containerRefCallback = useCallback((node) => {
    if (!node) {
      GlobalResizeObserver.unobserve(node);
      return;
    }

    setContainerWidthPx(node.offsetWidth);
    GlobalResizeObserver.observe(node, (entry) => {
      setContainerWidthPx(entry.target.offsetWidth);
    });
  }, []);

  useEffect(() => {
    if (copyScgToClipboardTrigger) {
      copyScgToClipboard();
    }
  }, [copyScgToClipboardTrigger]);

  useEffect(() => {
    if (searchConditionGroup === innerSearchConditionGroup) {
      return;
    }
    updateInnerSearchConditionGroup(draft => ({
      conditions: (searchConditionGroup || {}).conditions || [],
    }));
  }, [searchConditionGroup]);

  useEffect(() => {
    if (changeEventTrigger <= 0) {
      return;
    }
    onChange({
      searchConditionGroup: innerSearchConditionGroup,
    });
  }, [changeEventTrigger]);

  const innerConditions = (innerSearchConditionGroup || {}).conditions || [];

  const disabledNameList = innerConditions
    .filter(x => x.field !== FieldName.customKey && x.field !== FieldName.crashExpMessage && !(FieldNameToFieldInfo[x.field] || {}).isVersatileTextSearch ) // 特征分析，异常消息和text类型的字段支持选择多个
    .map(x => x.field);

  const singleFilters = innerConditions.map((x, i) => {
    if (!x) {
      return null;
    }
    if (x.field && !x.field.startsWith('LEGACY_') && !ALL_FILTER_NAMES.includes(x.field)) {  // 允许name为空，但必须在名单内，或者是旧的IssueCrashFilter兼容字段
      return null;
    }

    let colSpan;
    if (isFeatureConfig) {
      colSpan = containerWidthPx < 1100 ? 22 : 11; // 留2格span给特征分析的操作按钮
    } else {
      colSpan = calcDefaultColSpan(containerWidthPx);
    }
    if (x.field === FieldName.customKey) {
      colSpan = isFeatureConfig ? 22 : 24;
    }

    return <Col
      key={`${i}-${x.field}`}
      span={colSpan}
    >
      <ErrorBoundary fallback={<div style={{ color: 'red' }}>过滤器{x.field}渲染故障</div>}><SingleFilter
        filterOptions={filterOptions}
        disabledNameList={disabledNameList}
        name={x.field}
        value={x}
        onChangeKv={(k, v) => {
          updateInnerSearchConditionGroup(draft => {
            if (!k) { // 删除field
              draft.conditions.splice(i, 1);
            } else {
              draft.conditions[i] = {
                ...v,
                field: k,
              };
            }
          });
          setChangeEventTrigger(changeEventTrigger + 1);
        }}
      /></ErrorBoundary>
    </Col>;
  });

  let prefixSlotDomList;
  if (Array.isArray(prefixSlotList)) {
    prefixSlotDomList = prefixSlotList.filter(x => x).map((x, i) => {
      const colSpan = calcDefaultColSpan(containerWidthPx);
      return <Col
        key={i}
        span={colSpan}
      >
        <ErrorBoundary fallback={<div style={{ color: 'red' }}>自定义字段渲染故障</div>}>
          { x }
        </ErrorBoundary>
      </Col>;
    });
  }

  let filterButtonDomColSpan;
  if (isFeatureConfig) {
    filterButtonDomColSpan = 2;
  } else {
    if (containerWidthPx > 1800) {
      filterButtonDomColSpan = 8;
    } else if (containerWidthPx > 1000) {
      filterButtonDomColSpan = 12;
    } else {
      filterButtonDomColSpan = 24;
    }
  }

  async function copyScgToClipboard() {
    const content = JSON.stringify({ searchConditionGroup: innerSearchConditionGroup }, null, 4);
    await ClipboardUtil.copyToClipboard(content);
    message.success(ze('已复制至剪贴板', 'Copied to clipboard.'));
  }

  let searchButton = null;
  if (!isFeatureConfig && !hideSubmitButton) {
    const button = <Button
      style={{ marginLeft: '16px' }}
      type='primary'
      onClick={() => {
        onSubmit({
          searchConditionGroup: innerSearchConditionGroup,
        });
      }}>{t('common.search')}</Button>;
    const popoverContent = <div>
      <Button
        onClick={() => copyScgToClipboard()}
      >{ ze('复制过滤器的JSON数据结构（Open API用）', 'Copy Filter JSON Data (For Open API Usage)')}</Button>
    </div>
    searchButton = <Popover
      trigger='contextMenu'
      content={popoverContent}
      placement="bottom"
    >{ button }</Popover>;
  }

  const filterButtonDom = <Col span={filterButtonDomColSpan}>
    { !isReadOnly && <div className={scss.singleFilterBox}>
      <Button
        icon={<PlusOutlined/>}
        onClick={() => {
          updateInnerSearchConditionGroup(draft => {
            draft.conditions.push({ field: '' });
          });
        }}
      />
      { searchButton }
      { isFeatureConfig && <Button
        style={{ marginLeft: '8px' }}
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          if (onDelete) {
            onDelete();
          }
        }}/> }
      { extraDomAfterSubmitButton }
    </div>}
  </Col>;

  return <div
    ref={containerRefCallback}
    className={scss.filterContainer}
  >
    <IssueCrashFilterContext.Provider value={{
      isSuperAdmin,
      appId,
      platformId,
      hideIssueFields,
      fieldWhitelist: fieldWhitelist || [],
      fieldBlacklist: fieldBlacklist || [],
      hiddenFieldList: hiddenFieldList || [],
      implicitHiddenFieldList,
      fixedFieldList: fixedFieldList || [],
      isReadOnly: !!isReadOnly,
      isOom: !!isOom,
      isFeatureConfig: !!isFeatureConfig,
      isFeatureConfigRealtimeTag: !!isFeatureConfigRealtimeTag,
      enableIgameSearchFields,
      enableSearchKeyStackSoName,
    }}>
      <Row gutter={[12, 10]}>
        {prefixSlotDomList}
        {singleFilters}
        {filterButtonDom}
      </Row>
    </IssueCrashFilterContext.Provider>
  </div>;
};

const mapStateToProps = state => ({
  reduxState: state,
  selectFrontendWebVolatileConfigValueByKey: (key) => selectFrontendWebVolatileConfigValueByKey(state, key),
});

export default connect(mapStateToProps)(IssueCrashFilterEx);
