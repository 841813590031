import React, { useState, useEffect, useMemo } from 'react';
import RestHelper from 'utils/RestHelper';
import PropTypes from 'prop-types';
import { Spin, Table, TreeSelect, Button, Pagination, Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { fromJS } from 'immutable';
import { EXCEPTION_TYPE_LIST } from 'utils/constants';
import scss from './IssueCrashPreview.scss';
import { ReportDetail } from 'components/exception/issue';
import DataDetail from 'components/exception/issue/DataDetail';
import lastReportStyle from 'components/exception/issue/LastReport/style.scss';
import { isMobile, isPcOrLinux } from 'utils/platform';
import IssueCrashSearchUtil from 'utils/IssueCrashSearchUtil';
import { IssueReducerUtil } from 'reducers/issue/issueReducer';
import CsCollapseButton from 'components/commons/CsCollapseButton/CsCollapseButton';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { useDispatch } from 'react-redux';
import { fetchCustomKvConfigForCurrentApp } from 'reducers/app/appActions';

const EXPANDED_MAX_CRASH_COUNT = 10;

const IssueCrashPreview = (props) => {
  const {
    appId,
    platformId,
    issueId,
    exceptionType, // 'crashes', 'blocks', 'errors' 类型
    crashListQueryObj,
    matchReportCount,
    showMatchReportCount,
    onClose,
  } = props;

  const dispatch = useDispatch();

  const pid = platformId;

  const { t } = useTranslation();

  const [isIssueExpandedLoading, setIsIssueExpandedLoading] = useState(false);
  const [expandedCrashIdList, setExpandedCrashIdList] = useState([]);
  const [matchReportCountFromCrashList, setMatchReportCountFromCrashList] = useState(0);
  const [expandedCurrentCrashIndex, setExpandedCurrentCrashIndex] = useState(0);
  const [expandedCrashImmutableIssue, setExpandedCrashImmutableIssue] = useState(fromJS({}));

  async function expandIssue() {
    setIsIssueExpandedLoading(true);
    const [issueRsp, crashListRsp] = await Promise.all([
      RestHelper.get(`/issueInfo/appId/${appId}/platformId/${platformId}/issueId/${issueId}`),
      RestHelper.post('/api/crash/queryCrashList', {
        ...IssueCrashSearchUtil.urlQueryObjToSearchParamsObj(crashListQueryObj || {}),
        appId,
        platformId,
        start: 0,
        rows: 10,
        issueId,
        explicitCrashCrossVerStat: 2,
      }),
    ]);
    const immutableIssueInfo = fromJS(issueRsp.json.ret);
    setExpandedCrashImmutableIssue(immutableIssueInfo);
    const expandedCrashIdList = (crashListRsp.json.ret.crashIdList || []).map(x => x.replace(/:/g, ''));
    setExpandedCrashIdList(expandedCrashIdList);
    setMatchReportCountFromCrashList(crashListRsp.json.ret.numFound);
    setExpandedCurrentCrashIndex(0);
    setIsIssueExpandedLoading(false);
    console.log("=== 获取异常详情成功 ===", expandedCrashIdList, crashListRsp);
  }

  useEffect(() => {
    expandIssue();
    dispatch(fetchCustomKvConfigForCurrentApp({ skipIfFetched: true }));
  }, []);

  async function fetchExpandedCrash(crashId) {
    setIsIssueExpandedLoading(true);
    const rsp = await RestHelper.get(`/crashDoc/appId/${appId}/platformId/${pid}/crashHash/${crashId}`);
    let tempGlobalIssueState = fromJS({}).set('current', expandedCrashImmutableIssue);
    tempGlobalIssueState = IssueReducerUtil.handleGetCrashDoc(tempGlobalIssueState, { response: rsp.json.ret, params: { pid } });
    if (isMobile(platformId)) {
      const rsp = await RestHelper.get(`/appDetailCrash/appId/${appId}/platformId/${pid}/crashHash/${crashId}`);
      tempGlobalIssueState = IssueReducerUtil.handleGetCrashAttachment(tempGlobalIssueState, { response: rsp.json.ret, params: { pid } });
    }
    const newImmutableIssue = tempGlobalIssueState.get('current');
    setExpandedCrashImmutableIssue(newImmutableIssue);
    setIsIssueExpandedLoading(false);
  }

  const expandedCurrentCrashId = isPcOrLinux(platformId)
    ? (expandedCrashIdList[expandedCurrentCrashIndex] || '').toLowerCase()
    : expandedCrashIdList[expandedCurrentCrashIndex];

  useEffect(() => {
    if (!expandedCurrentCrashId) {
      return;
    }
    fetchExpandedCrash(expandedCurrentCrashId);
  }, [expandedCurrentCrashId]);

  return <div className={scss.crashDetail}>
    <Spin spinning={isIssueExpandedLoading}>
      <div className={scss.crashDetailHeader} style={{ fontSize: '14px', margin: '0 0 px 0px'}}>
        <div style={{ marginRight: 32 }}>{t('LASTREPORT.postId')} {expandedCurrentCrashId}</div>
        <NoJumpNextPagination
          size='small'
          total={Math.min(EXPANDED_MAX_CRASH_COUNT, matchReportCountFromCrashList)}
          pageSize={1}
          current={expandedCurrentCrashIndex + 1}
          onChange={(pageNum) => setExpandedCurrentCrashIndex(pageNum - 1)}
        />
        {/* <Pagination
          size='small'
          total={Math.min(EXPANDED_MAX_CRASH_COUNT, matchReportCountFromCrashList)}
          pageSize={1}
          current={expandedCurrentCrashIndex + 1}
          onChange={(pageNum) => setExpandedCurrentCrashIndex(pageNum - 1)}
        /> */}
        { showMatchReportCount
          && <div>{ t('SENIORSEARCH.crash结果统计', { totalCrashMatchCount: matchReportCountFromCrashList }) }</div> }
        { <Tooltip
            title={t('SENIORSEARCH.expandedIssueCrashViewHint', { count: EXPANDED_MAX_CRASH_COUNT }) }
          ><WrappedTipsIcon style={{ marginTop: 1 }}/></Tooltip> }
        <div style={{ flexGrow: '1' }}/>
        <CsCollapseButton
          isCollapsed={false}
          onClick={() => onClose()}
        />
      </div>
      {/* <Divider
        style={{ margin: '8px 0', color: '#AAA' }}
        orientation='left'
        plain={true}
      >{ t('LASTREPORT.上报信息') }</Divider> */}
      <Divider
        style={{ margin: '18px 0 8px', color: '#AAA' }}
        orientation='left'
        plain={true}
      ></Divider>
      { expandedCurrentCrashId && <div>
        <ReportDetail
          appId={appId}
          pid={platformId}
          exceptionType={exceptionType}
          issue={expandedCrashImmutableIssue}
          isSmallSize={true}
        />
      </div>}
      <Divider
        style={{ margin: '8px 0', color: '#AAA' }}
        orientation='left'
        plain={true}
      >{ t('LASTREPORT.errorStack') }</Divider>
      { expandedCurrentCrashId && <div>
        <DataDetail
          pid={platformId}
          dataType='stack'
          style={lastReportStyle}
          exceptionType={exceptionType}
          issue={expandedCrashImmutableIssue}
        />
      </div>}
      { expandedCurrentCrashId && <div className={scss.crashDetailHeader} style={{marginTop: '18px'}}>
        <div style={{ flexGrow: '1' }}/>
        <CsCollapseButton
          isCollapsed={false}
          onClick={() => onClose()}
        />
      </div> }
    </Spin>
  </div>;
}

IssueCrashPreview.propTypes = {
  appId: PropTypes.string.isRequired,
  platformId: PropTypes.string.isRequired,
  issueId: PropTypes.string.isRequired,
  exceptionType: PropTypes.string.isRequired,
  crashListQueryObj: PropTypes.object,
  matchReportCount: PropTypes.number,
  showMatchReportCount: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default IssueCrashPreview;
