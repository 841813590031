import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, message, Modal, Radio, Row, Space, Spin, Switch, Table } from 'antd';
import { ze } from 'utils/zhEn';
import { useImmer } from 'use-immer';
import { DeleteOutlined } from '@ant-design/icons';
import RestHelper from 'utils/RestHelper';
import { fetchCustomKvConfigForCurrentApp } from 'reducers/app/appActions';
import { actionFetchCustomKeyOptions } from 'reducers/seniorSearch';
import { CustomKvUtil } from 'utils/custom-kv';
import CsSwapSelect from 'components/commons/CsSwapSelect/CsSwapSelect';
import { CsvUtil } from 'utils/csv-util';
import uniqBy from 'lodash.uniqby';

function convertTextareaValueToTableData(v) {
  const lines = (v || '').split('\n')
    .map(x => x.trim())
    .filter(x => x);
  return lines.map((x, i) => {
    const [value, valueAlias, ...rest] = CsvUtil.singleLineParse(x);
    return {
      rowKey: i,
      value: (value || '').trim(),
      valueAlias: (valueAlias || '').trim(),
    };
  })
}

function convertTableDataToTextareaValue(tableData) {
  const lines = (tableData || [])
    .filter(x => x.value)
    .map(x => {
      const { value, valueAlias } = x;
      return [value || '', valueAlias || ''].join(',');
    });
  return lines.join('\n');
}

const CustomKvValueAliasConfigModal = ({
  reduxState,
  visible,
  appId,
  keyConfig,
  onCancel = () => {},
  onSave = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [tableData, updateTableData] = useImmer([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [isTextareaMode, setIsTextareaMode] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');

  //触发分页事件
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };
  //当每页条数发生变化的时候更改页数
  useEffect(() => {setPagination({current: 1, pageSize: pagination.pageSize})},[pagination.pageSize]);

  const { key, keyWithoutPrefix } = keyConfig || {};

  useEffect(() => {
    if (visible) {
      setPagination({ ...pagination, current: 1 });
      updateTableData([]);
      setTextareaValue('');
      initTableData();
    }
  }, [visible]);

  async function initTableData() {
    setLoading(true);
    if (!key) {
      return;
    }
    const rsp = await RestHelper.mustPost('/redir/api/appConfig/getCustomKvValueAliasConfig', {
      appId,
      key,
    });
    const valueAliasList = rsp.json.data;
    const newTableData = (valueAliasList || []).map((x) => {
      return {
        ...x,
        rowKey: Math.random(),
      };
    });
    updateTableData(newTableData);
    setTextareaValue(convertTableDataToTextareaValue(newTableData));
    setLoading(false);
  }

  function onChangeTextareaMode(value) {
    setIsTextareaMode(value);
    if (value) {
      setTextareaValue(convertTableDataToTextareaValue(tableData));
    } else {
      updateTableData(convertTextareaValueToTableData(textareaValue));
    }
  }

  function onClickAddNewValue() {
    updateTableData((draft) => {
      draft.push({
        rowKey: Math.random(),
        value: '',
        valueAlias: '',
      });
    });
    const newCurrent = (tableData.length + 1) / (pagination.pageSize || 10) + 1;
    setPagination({ ...pagination, current: newCurrent });
  }

  async function onClickSave() {
    setLoading(true);
    let finalTableData = isTextareaMode
      ? convertTextareaValueToTableData(textareaValue)
      : tableData;
    finalTableData = uniqBy(finalTableData, x => x?.value);
    const valueAliasList = finalTableData
      .filter(x => x.value)
      .map(x => ({
        ...x,
        appId,
        key,
      }));
    await RestHelper.mustPost('/redir/api/appConfig/setCustomKvValueAliasConfig', {
      appId,
      key,
      valueAliasList,
    });
    setLoading(false);
    onSave(key, valueAliasList);
  }

  const tableColumns = [{
    dataIndex: 'value',
    title: ze('值', 'Value'),
    render: (text, record) => {
      return <Input
        size='small'
        value={text}
        onChange={(e) => {
          const v = e.target.value;
          updateTableData((draft) => {
            const i = draft.findIndex(x => x.rowKey === record.rowKey);
            if (i >= 0) {
              draft[i].value = v;
            }
          });
        }}
      />;
    },
  }, {
    dataIndex: 'valueAlias',
    title: ze('值别名', 'Value Alias'),
    render: (text, record) => {
      return <Input
        size='small'
        value={text}
        onChange={(e) => {
          const v = e.target.value;
          updateTableData((draft) => {
            const i = draft.findIndex(x => x.rowKey === record.rowKey);
            if (i >= 0) {
              draft[i].valueAlias = v;
            }
          });
        }}
      />;
    },
  }, {
    dataIndex: 'op',
    title: ze('操作', 'Actions'),
    render: (text, record) => {
      return <Button
        size='small'
        icon={<DeleteOutlined />}
        onClick={() => {
          updateTableData((draft) => {
            const i = draft.findIndex(x => x.rowKey === record.rowKey);
            if (i >= 0) {
              draft.splice(i, 1);
            }
          });
        }}
      />;
    },
  }];

  const modal = <Modal
    title={ ze(`设置 ${keyWithoutPrefix} 的值别名`, `Config Value Alias For ${keyWithoutPrefix}`) }
    visible={visible}
    width='800px'
    maskClosable={false}
    onCancel={() => onCancel()}
    footer={<div style={{ display: 'flex', gap: '20px' }}>
      <Button onClick={() => onCancel()}>{ ze('取消', 'Cancel') }</Button>
      <Button
        disabled={loading}
        type='primary'
        onClick={() => onClickSave()}
      >{ ze('保存', 'Save') }</Button>
    </div>}
  >
    <Spin spinning={loading}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          { !isTextareaMode && <Button
            size='small'
            onClick={() => {
              onClickAddNewValue();
            }}
          >{ ze('添加新的值', 'Add New Value') }</Button> }
          <div style={{ flexGrow: 1 }}/>
          <CsSwapSelect
            options={[
              { value: false, label: ze('切换为文本框编辑模式','Switch to Text Box Editor') },
              { value: true, label: ze('切换为表格编辑模式','Switch to Table Editor') }
            ]}
            value={isTextareaMode}
            onChange={v => onChangeTextareaMode(v)}
          />
        </div>
        { !isTextareaMode && <div style={{ marginTop: '8px' }}>
          <Table
            size='small'
            rowKey='rowKey'
            dataSource={tableData}
            columns={tableColumns}
            pagination={{ ...pagination, showSizeChanger: true }}
            onChange={handleTableChange}
          />
        </div> }
        { isTextareaMode && <div style={{ marginTop: '8px' }}>
          { ze('内容格式为CSV格式，每一行代表一组值和别名的配置，用英文逗号分隔值和别名。', 'Content format is CSV, each line represents a set of value and alias configuration, separated by commas.') }
        </div> }
        { isTextareaMode && <div style={{ marginTop: '8px' }}>
          <Input.TextArea
            style={{ height: '400px',padding:'10px' }}
            value={textareaValue}
            placeholder={ze('值1,别名1\n值2,别名2\n值3,别名3', 'value1,alias1\nvalue2,alias2\nvalue3,alias3')}
            onChange={e => setTextareaValue(e.target.value)}
          />
        </div> }
      </div>
    </Spin>
  </Modal>;

  return modal;
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(CustomKvValueAliasConfigModal);
