export const ReduxActionType = Object.freeze({
  patchSummaryState: 'patchSummaryState',
  resetAppRelatedStateWhenAppChanged: 'resetAppRelatedStateWhenAppChanged',
  setReportDiscardStrategyByAppId: 'setReportDiscardStrategyByAppId',
  setCustomKvConfigByAppId: 'setCustomKvConfigByAppId',
  setDashboardFilterListByAppId: 'setDashboardFilterListByAppId',
  setAdminPermissionAppList: 'setAdminPermissionAppList',
  setAdvancedSearchState: 'setAdvancedSearchState',
  parseAggResultAndSetIssueIdToWangZheTopFormList: 'parseAggResultAndSetIssueIdToWangZheTopFormList',
  setQueryIssueListResult: 'setQueryIssueListResult',
  setQueryIssueListDownloadResult: 'setQueryIssueListDownloadResult',
});
