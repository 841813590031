import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Spin, message, Table, Divider, Switch, Space, Select, Radio, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ze } from 'utils/zhEn';
import { connect } from 'react-redux';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination';
import moment from 'moment';
import { getIssueDetailUrlPrefix, makeDetailObjectFromStackLine } from 'utils/helper';
import { WebAppSettings } from "utils/web-app-settings";
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import { Link } from 'react-router-dom';
import { isNotNullish, isNullish } from 'utils/nullish';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { useImmer } from 'use-immer';
import uniq from 'lodash/array/uniq';
import useDeepCompareEffect from 'use-deep-compare-effect';
import LabeledField from 'components/exception/BaseProblemPage/PageHeader/LabeledField';
import ClampDiv from 'components/commons/ClampDiv';
import { CS_STYLES } from 'utils/constants/style-constants';
import { geekblue } from '@ant-design/colors';
import { TimeZoneUtil } from 'utils/time-zone-util';
import { PlatformUtil } from 'utils/platform';
import  CrashDetailDisplay  from './CrashDetailDisplay';
import { ErrorBoundary } from 'react-error-boundary';
import { CsSelect } from 'components/antd-extension/CsSelect';
import CrashidIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_otherreportsofsamedevice-user_issue-crashid_icon.svg';
import IconAnr from 'svg/v2/newcs_dashboard_left_anr_icon_normal.svg';
import IconCrash from 'svg/v2/newcs_dashboard_left_crash_icon_normal.svg';
import IconError from 'svg/v2/newcs_dashboard_left_error_icon_normal.svg';
import IconLanuch from 'svg/v2/newcs_dashboard_advancedsearch_allrecords_list_lanuch_icon.svg';
import IconOom from 'svg/v2/newcs_dashboard_advancedsearch_allrecords_list_oom_icon.svg';
import { ExceptionTypeToIcon } from 'utils/exception-icon';
import { QueryAccessUtil } from 'utils/crash-search/query-access-util';

const { Option } = Select;

const MatchField = Object.freeze({
  userId: 'userId',
  deviceId: 'deviceId',
});

const SameDeviceRelatedReports = ({
  reduxState,
  currentReportIssueId,
  currentReportCrashId,
  currentReportUserId,
  currentReportDeviceId,
  currentReportExceptionType,
}) => {
  const { t } = useTranslation();

  const currentApp = reduxState.app.get('current');
  const { appId, platformId, utcOffset } = currentApp.toJS();
  const settings = reduxState.app.get('appIdToWebAppSettings')[appId];
  const configCsvText = (settings || {})[WebAppSettings.keys.userIdDescriptionConfigCsvText] || '';
  // 构造{userID: 昵称+qq}对象
  const configObj = {}
  configCsvText.split('\n').slice(1).map(item => item.split(',')).forEach(item => {
    configObj[item[0]] = `${ze('昵称', 'nickname')}:${item[1]} qq:${item[2]}`
  })

  const [loading, setLoading] = useState(true);
  const [matchField, setMatchField] = useState(MatchField.deviceId);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [records, setRecords] = useState([]);

  const defaultFilterExceptionCategoryList = [
    ExceptionCategory.CRASH,
    PlatformUtil.isAnrAvailable(platformId) && ExceptionCategory.ANR,
    ExceptionCategory.ERROR,
  ].filter(x => x);

  const [filterExceptionCategoryList, setFilterExceptionCategoryList] = useState(defaultFilterExceptionCategoryList);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [intermediateValue, setIntermediateValue] = useState(defaultFilterExceptionCategoryList);

  const [crashIdToCrashMapInfo, updateCrashIdToCrashMapInfo] = useImmer({
    "__crashId__": {
      crashMap: {},
      isFetching: false,
    },
  });
  const [searchTrigger, setSearchTrigger] = useState(0);

  const invalidDeviceId = !currentReportDeviceId || String(currentReportDeviceId).toLowerCase() === 'unknown';
  const invalidUserId = !currentReportUserId || String(currentReportUserId).toLowerCase() === 'unknown';

  const localFilteredRecords = useMemo(() => {
    const filteredRecords = records;
    // 添加session情况（是否和当前是同一上报，以及相同session添加相同的groupIndex）
    const currentRecord = records.find(x => x.crashId === currentReportCrashId);
    let sessionGroupIndex = 0;
    let prevRecordLaunchTimeSeconds = 0;
    return filteredRecords.map(x => {
      const isSameSessionWithCurrentReport = currentRecord && currentRecord.launchTimeSeconds === x.launchTimeSeconds;
      if (x.launchTimeSeconds !== prevRecordLaunchTimeSeconds) {
        prevRecordLaunchTimeSeconds = x.launchTimeSeconds;
        sessionGroupIndex += 1;
      }

      return {
        ...x,
        sessionGroupIndex,
        isSameSessionWithCurrentReport,
      };
    });
  }, [records, filterExceptionCategoryList]);

  const localFilteredTotal = localFilteredRecords.length;

  const currentPageRecords = localFilteredRecords.filter((x, i) => {
    const begin = (currentPage - 1) * pageSize;
    const end = currentPage * pageSize;
    return i >= begin && i < end;
  });

  const currentPageCrashIds = uniq(currentPageRecords
    .map(x => x.crashId)
    .filter(x => x));

  useDeepCompareEffect(() => {
    fetchCrashMapInfosByCrashIds(currentPageCrashIds);
  }, [currentPageCrashIds]);

  const exceptionCategoryOptions = [
    {
      value: ExceptionCategory.ACCESS,
      label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <IconLanuch style={{height: 14, width: 14, color: '#13c2c2'}} />
        {ExceptionCategoryUtil.toI18n(ExceptionCategory.ACCESS, platformId)}
      </div>,
    },
    {
      value: ExceptionCategory.CRASH,
      label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <IconCrash style={{height: 14, width: 14, color:'#FF5461'}} />
        {ExceptionCategoryUtil.toI18n(ExceptionCategory.CRASH, platformId)}
      </div>,
    },
    {
      value: PlatformUtil.isAnrAvailable(platformId) && ExceptionCategory.ANR,
      label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <IconAnr style={{height: 14, width: 14, color:'#FB835E'}} />
        {ExceptionCategoryUtil.toI18n(ExceptionCategory.ANR, platformId)}
      </div>,
    },
    {
      value: PlatformUtil.isErrorAvailable(platformId) && ExceptionCategory.ERROR,
      label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <IconError style={{height: 14, width: 14, color:'#A452DD'}}  />
        {ExceptionCategoryUtil.toI18n(ExceptionCategory.ERROR, platformId)}
      </div>,
    },
    {
      value: ExceptionCategory.OOM,
      label: <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
        <IconOom style={{height: 14, width: 14, color: '#6669fe'}}  />
        {ExceptionCategoryUtil.toI18n(ExceptionCategory.OOM, platformId)}
      </div>,
    }
  ].filter(x => !!x.value);


  // 初始化
  useEffect(() => {
    if (!appId || !currentReportIssueId || !currentReportCrashId) {
      return;
    }

    if (!invalidDeviceId) {
      setMatchField(MatchField.deviceId);
    } else {
      setMatchField(MatchField.userId);
    }

    if (!invalidDeviceId || !invalidUserId) {
      setSearchTrigger(searchTrigger + 1);
      setLoading(false);
    }
  }, [appId, currentReportIssueId, currentReportCrashId]);

  useEffect(() => {
    if (!searchTrigger) {
      return;
    }
    onClickSubmit();
  }, [searchTrigger]);

  const tableColumns = [{
    dataIndex: 'launchTimeFormatted',
    title: ze('启动时间', 'Launch Time'),
    width: '130px',
    render: (label, record) => {
      return <div style={{whiteSpace: 'nowrap' }}>{label}</div>;
    },
  }, {
    dataIndex: 'uploadTime',
    title: ze('上报时间', 'Upload Time'),
    width: '130px',
    render: (label, record) => {
      return <div style={{whiteSpace: 'nowrap' }}>{label}</div>;
    },
  }, {
    dataIndex: 'csType',
    title: ze('消息类型', 'Record Category'),
    onCell: () => {
      return {
        style: {
          minWidth: '90px',
        },
      };
    },
    render: (_, record) => {
      const { csType, isCoolStart } = record;
      if (!ExceptionCategoryUtil.isAccess(csType)) {
        return <div style={{whiteSpace: 'nowrap' }}>
          {ExceptionCategoryUtil.toI18n(String(csType || ''), platformId)}
        </div>
      }
      return <div style={{whiteSpace: 'nowrap' }}>{isCoolStart ? ze('冷启动', 'Cold Launch') : ze('热启动', 'Warm Launch')}</div>
    },
  }, {
    key: 'crashId',
    title: ze('问题/上报ID', 'Issue/Crash ID'),
    onCell: () => {
      return {
        style: {
          minWidth: '100px',
        },
      };
    },
    render: (text, record) => {
      const { crashId, issueId, expUid, csType } = record;
      if (ExceptionCategoryUtil.isAccess(csType)) {
        return '-';
      }
      if (ExceptionCategoryUtil.isOom(csType)) {
        if (!expUid) {
          return '-';
        } else {
          const path = `/crash-reporting/oom/${appId}?pid=${platformId}&expUid=${expUid}`;
          return <div style={{whiteSpace: 'nowrap' }}><Space>
            <div style={{ color: '#AAA', userSelect: 'none' }}>{ ze('上报', 'Report') }</div>
            <Link to={path} target='_blank' rel='noopener noreferrer'>{ expUid }</Link>
          </Space></div>;
        }
      }
      if (!crashId || !issueId) {
        return QueryAccessUtil.makeReportDiscardedDom(appId, platformId, csType, issueId);
      }
      const crashMapInfo = crashIdToCrashMapInfo[crashId];
      if (isNotNullish(crashMapInfo)) {
        if (crashMapInfo.isFetching) {
          return <Spin spinning={true}/>;
        } else if (isNullish(crashMapInfo.crashMap)) {
          return QueryAccessUtil.makeReportDiscardedDom(appId, platformId, csType, issueId);  // ES里面如果查不到，即使CK里面有也算丢弃
        }
      }

      if (crashId === currentReportCrashId) {
        return <div style={{ color: CS_STYLES.MAIN_TEXT_COLOR, userSelect: 'none',whiteSpace: 'nowrap', display:'flex', alignItems:'center' }}>
          <CrashidIcon style={{marginRight:'5px'}}/>
          { ze('本次上报', 'Current Report') }</div>;
      }
      const crashIdPath = `/${getIssueDetailUrlPrefix(csType)}/${appId}/${issueId}/report-id/${crashId}?pid=${platformId}`;
      const issueIdDom = currentReportIssueId !== issueId
        ? <div><Space>
          <div style={{ color: '#AAA', userSelect: 'none' }}>{ ze('问题ID', 'Issue ID') }</div>
          {/* <div>{ issueId }</div> */}
          <Link to={crashIdPath} target='_blank' rel='noopener noreferrer'>{ issueId }</Link>
        </Space></div>
        : <div style={{ color: '#AAA', userSelect: 'none' }}>{ ze('相同问题', 'Same Issue') }</div>;
      const crashIdDom = <div><Space>
        <div style={{ color: '#AAA', userSelect: 'none' }}>{ ze('上报ID', 'Crash ID') }</div>
        <Link to={crashIdPath} target='_blank' rel='noopener noreferrer'>{ crashId }</Link>
      </Space></div>;
      return <div style={{whiteSpace: 'nowrap' }}>
        { issueIdDom }
        { crashIdDom }
      </div>;
    },
  }, {
    key: 'crashExpMessage',
    title: t('issueCrashFilterKey.crashExpMessage'),
    onCell: () => {
      return {
        style: {
          minWidth: '300px',
        },
      };
    },
    render: (_, record) => {
      const { crashId, expMessage } = record;
      if (!crashId) {
        return <ClampDiv
          lineClamp={5}
          showButton={true}
        >{ expMessage || '-' }</ClampDiv>;
      }
      const crashMapInfo = crashIdToCrashMapInfo[crashId];
      if (isNullish(crashMapInfo)) {
        return '-';
      }
      if (crashMapInfo.isFetching) {
        return <Spin spinning={true}/>;
      }
      return <ClampDiv
        lineClamp={5}
        showButton={true}
      >{ (crashMapInfo.crashMap || {}).expMessage || '-' }</ClampDiv>;
    },
  }, {
    key: 'crashDetail',
    title: t('issueCrashFilterKey.crashDetail'),
    onCell: () => {
      return {
        style: {
          minWidth: '300px',
        },
      };
    },
    render: (_, record) => {
      const { crashId } = record;
      if (!crashId) {
        return '-';
      }
      const crashMapInfo = crashIdToCrashMapInfo[crashId];
      if (isNullish(crashMapInfo)) {
        return '-';
      }
      if (crashMapInfo.isFetching) {
        return <Spin spinning={true}/>;
      }
      return <ErrorBoundary
        fallback={<div style={{ color: 'red' }}>Render Stack Detail Error</div>}
      >
        <CrashDetailDisplay
          stackTrace={(crashMapInfo.crashMap || {}).retraceCrashDetail || ''}
          platformId={platformId}
          currentReportExceptionType={currentReportExceptionType}
        />
      </ErrorBoundary>;
    },
  }];

  async function onClickSubmit() {
    const uploadTimeBeginMillis = moment().subtract(3, 'day').valueOf(); // 只支持最近72小时内的上报
    let userIdList;
    let deviceIdList;
    if (matchField === MatchField.userId) {
      userIdList = [currentReportUserId];
    } else if (matchField === MatchField.deviceId) {
      deviceIdList = [currentReportDeviceId];
    } else {
      return message.error('Invalid match type! Must be userId or deviceId.');
    }

    const params = {
      uploadTimeBeginMillis,
      userIdList,
      deviceIdList,
      appId,
      platformId,
      skipDistinctQuery: true,
      pageNumber: 1,
      pageSize: 1000,
      exceptionCategoryList: filterExceptionCategoryList,
    };

    setLoading(true);
    const rsp = await RestHelper.mustPost('/redir/api/queryAccess/queryAccessList', params);
    let { records } = rsp.json.data.result;
    records = records.map(x => {
      const { launchTimeMillisStr } = x;
      const launchTimeMillis = !!launchTimeMillisStr ? Number(launchTimeMillisStr) : 0;
      return {
        ...x,
        uploadTime: TimeZoneUtil.convertDefaultTimeZoneLocalDateTimeToUtcOffsetMoment(x.uploadTime, utcOffset).format('YYYY-MM-DD HH:mm:ss'),
        launchTimeSeconds: Math.floor(launchTimeMillis / 1000),
        launchTimeFormatted: launchTimeMillis ? moment(launchTimeMillis).format('YYYY-MM-DD HH:mm:ss') : '-',
      };
    });
    records.sort((a, b) => {
      if (a.launchTimeMillis && b.launchTimeMillis) {
        return b.launchTimeMillis - a.launchTimeMillis;
      }
      return String(b.uploadTime).localeCompare(a.uploadTime);
    });

    setRecords(records);
    setLoading(false);
  }

  async function fetchCrashMapInfosByCrashIds(crashIdList) {
    crashIdList = (crashIdList || [])
      .filter(crashId => {
        const crashMapInfo = crashIdToCrashMapInfo[crashId];
        return isNullish(crashMapInfo);
      });

    if ((crashIdList || []).length === 0) {
      return;
    }

    let patchCrashIdToCrashMapInfo = {};
    crashIdList.forEach(crashId => {
      patchCrashIdToCrashMapInfo[crashId] = {
        isFetching: true,
        crashMap: null,
      };
    });
    updateCrashIdToCrashMapInfo(draft => ({ ...draft, ...patchCrashIdToCrashMapInfo }));
    const rsp = await RestHelper.mustPost('/api/crash/queryCrashList', {
      appId,
      platformId,
      rows: crashIdList.length,
      needRetracedStack: true,
      searchConditionGroup: {
        conditions: [{
          field: FieldName.crashId,
          queryType: QueryType.TERMS,
          terms: crashIdList,
        }],
      },
    });
    patchCrashIdToCrashMapInfo = {};
    crashIdList.forEach(crashId => {
      patchCrashIdToCrashMapInfo[crashId] = {
        isFetching: false,
        crashMap: (rsp.json.ret.crashDatas || {})[crashId],
      };
    });
    updateCrashIdToCrashMapInfo(draft => ({ ...draft, ...patchCrashIdToCrashMapInfo }));
  }

  if (invalidDeviceId && invalidUserId) {
    return <div>{ ze('未获取到设备/用户，无法匹配。', 'Unknown device and user.') }</div>;
  }

  const searchConditionGroup ={
    conditions: [
      {queryType: "TERM", field: "userId", term: currentReportUserId},
      {queryType: "TERM", field: "deviceId", term: currentReportDeviceId},
    ]
  };
  const queryString = new URLSearchParams({
    pid: platformId,
    searchConditionGroup: JSON.stringify(searchConditionGroup),
  }).toString();

  return <Spin spinning={loading}>
    <div style={{ marginBottom: '8px' }}>
      <span>{ ze('显示本次上报的设备/用户在', 'Displays related reports that have been reported within the ') }</span>
      <span style={{ color: CS_STYLES.LINK_HOVER_COLOR }}>{ ze('最近72小时内', 'last 72 hours ') }</span>
      <span>{ ze('上报过的其他异常上报。', 'for current device / user.') }</span>
      <span>{ ze('如果查询近90天数据，请前往 ','If you want to query data for the recent 90 days, please go to ') }</span>
      <span>
        <Link
          to={`/crash-reporting/advanced-search/${appId}?${ queryString }`}
          target="_blank"
          rel="noopener noreferrer"
        >{ ze('高级搜索','Search') }</Link>
        </span>
    </div>
    <div style={{ display: 'flex', gap: '8px' }}>
      <LabeledField label={ze('匹配方式', 'Match Type')}><Radio.Group
        optionType='button'
        options={[
          { label: `${ze('相同设备', 'Same Device')}${invalidDeviceId ? ze('（未知设备）', ' (Unknown Device)') : ''}`, value: MatchField.deviceId, disabled: invalidDeviceId },
          { label: `${ze('相同用户', 'Same User')}${invalidUserId ? ze('（未知用户）', ' (Unknown User)') : ''}`, value: MatchField.userId, disabled: invalidUserId },
        ]}
        value={matchField}
        onChange={e => {
          const v = e.target.value;
          setMatchField(v);
          setCurrentPage(1);
          setSearchTrigger(searchTrigger + 1);
        }}
      /></LabeledField>
      <LabeledField label={ze('消息类型', 'Report Category')}><CsSelect
        showArrow={true}
        mode='multiple'
        style={{ width: '400px' }}
        allowClear={true}
        placeholder={ze('过滤消息类型', 'Filter Categories')}
        value={intermediateValue}
        onChange={v => {
          setIntermediateValue(v);
          if (!dropdownOpen) {
            setFilterExceptionCategoryList(v);
            setCurrentPage(1);
            setSearchTrigger(searchTrigger + 1);
          }
        }}
        onDropdownVisibleChange={(open) => {
          setDropdownOpen(open);
          if (open) {
            setIntermediateValue(filterExceptionCategoryList);
          } else if (filterExceptionCategoryList !== intermediateValue) {
            setFilterExceptionCategoryList(intermediateValue);
            setCurrentPage(1);
            setSearchTrigger(searchTrigger + 1);
          }
        }}
      >
        {
          exceptionCategoryOptions.map(({label, value, icon}) => <Option key={value} value={value}>
            {label}
          </Option>)
        }
      </CsSelect>
      </LabeledField>
    </div>
    { records && <Table
      style={{ marginTop: '16px' }}
      size='small'
      columns={tableColumns}
      dataSource={currentPageRecords}
      pagination={false}
      scroll={{
        x: '100%',
      }}
      onRow={(record) => {
        const { sessionGroupIndex, isSameSessionWithCurrentReport } = record;
        let backgroundColor = undefined;
        if (isSameSessionWithCurrentReport) {
          backgroundColor = geekblue[0];
        } else if (sessionGroupIndex % 2) {
          backgroundColor = '#F3F3F3';
        }
        return {
          style: {
            backgroundColor,
          },
        };
      }}
    /> }
    { records && records.length > 0 && <div style={{ marginTop: '12px', display: 'flex', justifyContent:'flex-end' }}>
      <NoJumpNextPagination
        size='small'
        showSizeChanger
        current={currentPage}
        pageSize={pageSize}
        total={localFilteredTotal}
        showTotal={ () => t('common.共n条', { count: localFilteredTotal }) }
        onChange={(page, newPageSize) => {
          setCurrentPage(newPageSize === pageSize ? page : 1);
          setPageSize(newPageSize);
        }}
      />
    </div> }
  </Spin>;
};

SameDeviceRelatedReports.propTypes = {
  reduxState: PropTypes.object,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(SameDeviceRelatedReports);
