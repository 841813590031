import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Spin,
} from 'antd';
import { connect, useDispatch } from 'react-redux';
import { isNotNullish, isNullish } from 'utils/nullish';
import { ze } from 'utils/zhEn';
import { upsertWebAppSettings } from 'reducers/app/appActions';
import TapdTitleTemplateEditor, { TapdTitleTemplateUtil } from 'components/exception/issue/TapdTitleTemplateEditor';
import _style from './TapdModal.scss';

const TapdModelEditTitleTemplateModel = ({
  reduxState,
  visible,
  issueInfo,
  lastReportCrashMap,
  onCancel,
  onSave,
}) => {
  const dispatch = useDispatch();

  const { appId, platformId } = reduxState.app.get('current').toJS();
  const settings = reduxState.app.get('appIdToWebAppSettings')[appId];

  const [loading, setLoading] = useState(false);

  const [titleTemplate, setTitleTemplate] = useState('');

  useEffect(() => {
    if (!visible) {
      return;
    }
    setTitleTemplate(titleTemplateFromSettings);
  }, [visible]);

  if (isNullish(settings)) {
    return <Modal
      className={_style.tapdStyle}
      visible={visible}
      maskClosable={false}
      onCancel={() => onCancel()}
      onOk={() => onCancel()}
    >
      <div>项目设置加载失败</div>
    </Modal>;
  }

  const titleTemplateFromSettings = (settings || {})[TapdTitleTemplateUtil.getSettingKey()]
    || TapdTitleTemplateUtil.makeDefaultValue(platformId);

  async function onSubmitTemplate() {
    setLoading(true);
    dispatch(upsertWebAppSettings(appId, {
      [TapdTitleTemplateUtil.getSettingKey()]: titleTemplate,
    }));
    setLoading(false);
    onSave();
  }

  const modelChildren = <Spin spinning={loading}>
    <div style={{marginBottom: '20px'}}>{ ze('项目级设置。模板设置保存后，该项目的问题在创建缺陷时将默认使用此模板填充缺陷标题。', 'This is app setting. After saving the template, creating TAPD bugs for issues of the app will use this template.') }</div>
    <TapdTitleTemplateEditor
      appId={appId}
      platformId={platformId}
      fromFeatureAnalysis={false}
      enrichedIssueInfo={{
        ...issueInfo,
        platformId,
      }}
      lastReportCrashMap={lastReportCrashMap}
      value={titleTemplate}
      onChange={v => setTitleTemplate(v)}
    />
  </Spin>;

  return <Modal
    className={_style.tapdStyle}
    title={ze('设置TAPD缺陷标题模板', 'Set Tapd Bug Template')}
    width='620px'
    visible={visible}
    maskClosable={false}
    onCancel={() => onCancel()}
    okText={ze('保存', 'Save')}
    okButtonProps={{ disabled: loading }}
    onOk={() => onSubmitTemplate()}
  >
    { modelChildren }
  </Modal>
};

TapdModelEditTitleTemplateModel.propTypes = {
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(TapdModelEditTitleTemplateModel);
