import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { isAndroidOrHarmony, isIos, isPcOrLinux, isPlaystation, PlatformUtil } from 'utils/platform';
import { DataDetailUtil } from 'components/exception/issue/DataDetail';
import { Col, Row, Spin } from 'antd';
import { isNotNullish, isNullish } from 'utils/nullish';
import scss from './RegisterInfo.scss';
import { ErrorBoundary } from 'react-error-boundary';
import { ze } from 'utils/zhEn';

/**
 * @param {string[]} lines
 * @param {RegExp} headerRegex
 * @return {{ header: string, bodyLines: string[] }[]}
 */
function makeSectionsFromLinesByHeaderRegex(lines, headerRegex) {
  const result = [];
  let currentSection = null;
  (lines || []).forEach(line => {
    if (headerRegex.test(line)) {
      if (isNotNullish(currentSection)) {
        result.push(currentSection);
      }
      currentSection = { header: line, bodyLines: [] };
    } else {
      if (isNullish(currentSection)) {
        currentSection = { header: '', bodyLines: [] };
      }
      currentSection.bodyLines.push(line);
    }
  });

  if (isNotNullish(currentSection)) {
    result.push(currentSection);
  }
  return result;
}

/**
 * @param {string[]} lines
 * @return {{ header: string, registerKvPairs: [string, string][] }[]}
 */
function parseAndroidLines(lines) {
  const sections = makeSectionsFromLinesByHeaderRegex(lines, /info/);
  return sections.map(({ header, bodyLines }) => {
    const registerSlices = bodyLines.join(' ').split(/\s/).filter(x => x);
    const registerKvPairs = registerSlices.map(x => {
      const [k, ...other] = x.split('=');
      return [k, other.join('')];
    });
    return {
      header,
      registerKvPairs,
    }
  });
}

/**
 * @param {string[]} lines
 * @return {{ header: string, registerKvPairs: [string, string][] }[]}
 */
function parseIosLines(lines) {
  const sections = makeSectionsFromLinesByHeaderRegex(lines, /Thread/);
  return sections.map(({ header, bodyLines }) => {
    const registerSlices = bodyLines.join(' ').split(/\s/).filter(x => x);
    const registerKvPairs = registerSlices.map(x => {
      const [k, ...other] = x.split(':');
      return [k, other.join('')];
    });
    return {
      header,
      registerKvPairs,
    }
  });
}

/**
 * @param {string[]} lines
 * @return {{ header: string, registerKvPairs: [string, string][] }[]}
 */
function parsePcLines(lines) {
  const sections = makeSectionsFromLinesByHeaderRegex(lines, /^\d/);
  return sections.map(({ header, bodyLines }) => {
    const registerSlices = [...bodyLines.join('\n')
      .matchAll(/[a-zA-Z0-9]+[^\S\r\n]*=[^\S\r\n]*[a-zA-Z0-9]+/g)] // 匹配 rax=0x123之类，等号两边允许有空格
      .map(x => x[0]);
    const registerKvPairs = registerSlices.map(x => {
      const [k, ...other] = x.split('=');
      return [k.trim(), other.join('').trim()];
    });
    return {
      header,
      registerKvPairs,
    }
  });
}

const RegisterInfo = ({
  issue,
  pid,
}) => {
  const issueObj = useMemo(() => issue.toJS() || {}, [issue]);

  const {
    attachList,
    crashDoc,
    pcRegisterInfoFetching,
    pcRegisterInfoLines,
    pcModuleInfoList,
    pcAllModuleInfoLines,
    isShowingMinidumpStack,
    retracedAnrTrace,
  } = issueObj;
  const { esMap } = crashDoc || {};
  const { modelBit } = esMap || {};

  let lines = [];
  /** @type {{ header: string, registerKvPairs: [string, string][] }[]} */
  let parseResult = [];

  if (!isShowingMinidumpStack && isAndroidOrHarmony(pid)) {
    lines = DataDetailUtil.makeRegisterInfoTextLines(pid, attachList, modelBit);
    parseResult = parseAndroidLines(lines);
  } else if (!isShowingMinidumpStack && PlatformUtil.isIosOrMac(pid)) {
    lines = DataDetailUtil.makeRegisterInfoTextLines(pid, attachList, modelBit);
    parseResult = parseIosLines(lines);
  } else if (isShowingMinidumpStack || isPcOrLinux(pid) || isPlaystation(pid)) {
    lines = (pcRegisterInfoLines || []);
    parseResult = parsePcLines(lines);
  }

  const sectionDomList = parseResult.map(({ header, registerKvPairs }, i) => {
    const longestKeyLength = Math.max(...registerKvPairs.map(([k, v]) => (k || '').length));
    const regKeyMinWidth = 20 + Math.min(longestKeyLength, 6) * 8;

    const cols = registerKvPairs.map((kvPair, j) => {
      const [k, v] = kvPair;
      return <Col
        key={j}
        span={6}
      ><div className={scss.reg}>
        <div
          className={scss.regKey}
          style={{
            minWidth: `${regKeyMinWidth}px`
          }}
        >{ k || '-' }</div>
        <div className={scss.regValue}>{ v || '-' }</div>
      </div></Col>;
    });

    return <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} key={i}>
      { (!isAndroidOrHarmony(pid) || isShowingMinidumpStack) && <div>{ header }</div> }
      <Row gutter={[10, 10]}>{ cols }</Row>
    </div>;
  });

  const dom = (!pcRegisterInfoFetching && (lines || []).length === 0)
    ? <div>{ ze('无数据', 'No Data') }</div>
    : <div className={scss.regInfoBody} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>{ sectionDomList }</div>;

  return <div
    className={scss.registerInfoContainer}
  ><Spin spinning={!!pcRegisterInfoFetching}>{ dom }</Spin></div>;
};

RegisterInfo.propTypes = {
  reduxState: PropTypes.object,
  issue: PropTypes.object,
  pid: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(RegisterInfo);
