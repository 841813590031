import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatTime } from 'utils/helper';
import { PLATFORM_ID } from 'utils/constants.js';
import * as platformUtils from 'utils/platform';
import style from './style.scss';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import RestHelper from 'utils/RestHelper';
import { Button, Modal, Spin, notification, Tooltip, message, Space, Tag, Dropdown } from 'antd';
import { connect } from 'react-redux';
import { orange } from '@ant-design/colors';
import {
  selectCurrentAppCustomUserSceneTagLabel,
  selectHasSpecifiedPermissionInCurrentApp, selectNullableCurrentAppSettings
} from 'utils/selectors/selectors';
import {formatBytes} from "utils/format/file";
import {isNotNullish, isNullish} from "utils/nullish";
import {downloadAsFile} from "utils/file-utils";
import { countryFlagUrl, countryI18n } from 'utils/country';
import { CrashUtil } from 'utils/api/crash';
import { dataComplianceConfirm } from 'utils/dataComplianceConfirm';
import { isZh, ze } from 'utils/zhEn';
import { isGameConsole, isMobile, PlatformUtil, isPlaystation, isSwitch, isWechatMiniProgram, isLinux, isPc, isAndroid, isIos, isHarmony } from 'utils/platform';
import { WebAppSettings } from 'utils/web-app-settings';
import memoize from 'memoize-one';
import { UserIdDescriptionConfigUtil } from 'containers/UserIdDescriptionConfigPage';
import { CS_STYLES } from 'utils/constants/style-constants';
import { getBackendInjectEnvs } from 'utils/backend-inject-envs';
import { Link } from 'react-router-dom';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { EnvUtil } from 'utils/env-util';
import { BillingTeamType } from 'utils/constants/billing';
import UnfoldUseridIcon from "svg/v2/newcs_dashboard_crashanalysis_issuelist_unfold_userid_icon_normal.svg";
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon.jsx';
import { BrandIconUtil } from 'utils/brand-icon-util';
import PinnedIcon from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_operate_yes_icon.svg';
import { EXCEPTION_TYPE_INT_ANDROID_NATIVE } from 'utils/constants/exception-type-int';
import { ServerAppSettings } from 'utils/server-app-settings';
import { VmTypeInt, VmTypeUtil } from 'utils/constants/vm-type';

export const ReportDetailUtil = Object.freeze({
  renderExpAddr(expAddr) {
    if (!expAddr) {
      return '-';
    }
    const addrAsNumber = Number(expAddr);
    if (addrAsNumber >= 0x1000) {
      return expAddr;
    }
    // 空指针的提示不能保证一定准确，避免海外商业化项目疑问，只对国内项目开放
    return <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div>{ expAddr }</div>
      { EnvUtil.isChinaEnv() && <Tag color='processing'>{ ze('空指针', 'Null Pointer') }</Tag> }
    </div>;
  },
});

/**
 * 异常详情信息
 */
@connect((state) => {
  return {
    getHasSpecifiedPermission: (permission) => selectHasSpecifiedPermissionInCurrentApp(state, permission),
    appIdToWebAppSettings: state.app.get('appIdToWebAppSettings'),
    currentUser: state.user.get('current'),
    reduxState: state,
  };
}, (dispatch) => ({ dispatch }))
@withTranslation()
export default class ReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pcLogModalTitle: '',
      pcLogCosKey: '',
      pcLogLoading: false,
      pcLogText: '', // PC log转为文本的内容。如果log是二进制文件，转换后内容会失真，所以只能用于显示，不能用于下载
      pcLogUint8Array: null, // PC log的实际二进制内容，用于下载
      pcLogModalVisible: false,
    };
  }

  componentDidMount() {
  }

  getIsFromShareLink() {
    const { shareId } = this.props;
    return !!shareId;
  }

  getRom() {
    const { crashDoc } = this.props.issue.toJS();
    let roms = [];
    if (crashDoc?.crashMap?.rom) {
      roms = crashDoc?.crashMap?.rom.split('%2F');
    }
    let rom = this.props.t("REPORTDETAIL.romNull");
    if (roms.length > 0) {
      if (roms.length > 1) {
        rom = `${roms[0]}/${roms[1]}`;
      } else {
        rom = roms[0];
      }
    }
    return rom;
  }

  async openModalAndFetchPcCustomLog(logId) {
    const { appId, pid: platformId, t } = this.props;
    this.setState({ pcLogModalTitle: t("REPORTDETAIL.自定义日志来自文件") });
    const cosKey = `${logId}.log.inflate`;

    await dataComplianceConfirm();
    await this.openModalAndFetchPcCommonData(cosKey);

  }

  async openModalAndFetchPcCrashSightLog(logId) {
    const { appId, pid: platformId, t } = this.props;
    this.setState({ pcLogModalTitle: t("REPORTDETAIL.自定义日志来自接口") });
    const cosKey = `${logId}.crashSightLog.log.inflate`;

    await dataComplianceConfirm();
    await this.openModalAndFetchPcCommonData(cosKey);

  }

  async openModalAndFetchPcValueMap(logId) {
    const { appId, pid: platformId, t } = this.props;
    this.setState({ pcLogModalTitle: 'Value Map' });
    const cosKey = `${logId}.valueOtherMap.log.inflate`;

    await dataComplianceConfirm();
    await this.openModalAndFetchPcCommonData(cosKey);

  }

  async openModalAndFetchPcCommonData(cosKey) {
    const { appId, pid: platformId, t, shareId } = this.props;
    if (this.state.pcLogCosKey === cosKey) {
      this.setState({
        pcLogModalVisible: true,
      });
      return;
    }

    this.setState({
      pcLogLoading: true,
      pcLogText: '',
      pcLogModalVisible: true,
    });
    const rsp = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId,
      shareId,
      cosKey,
    });
    const { hasFile, contentBase64 } = rsp.json.data;
    let pcLogText = t('REPORTDETAIL.日志不存在');
    let pcLogUint8Array = null;
    if (hasFile) {
      pcLogUint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      pcLogText = new TextDecoder().decode(pcLogUint8Array);
    }
    this.setState({
      pcLogLoading: false,
      pcLogCosKey: cosKey,
      pcLogText,
      pcLogUint8Array,
    });
  }

  onClosePcLogModal() {
    this.setState({
      pcLogModalVisible: false,
    });
  }

  onDownloadPcLog() {
    const { pcLogModalTitle, pcLogUint8Array } = this.state;
    if (!pcLogUint8Array) {
      message.error('日志内容为空');
      return;
    }
    downloadAsFile(`${pcLogModalTitle}.txt`, pcLogUint8Array);
  }

  async onDownloadDump() {
    const {issue, appId, pid: platformId, t, shareId} = this.props;
    const { crashDoc } = issue.toJS() || {};
    const { esMap } = crashDoc || {};
    const cosKey = `${esMap.originMinidumpFileCoskey}.dmp.gz`
    const rsp = await RestHelper.post('/api/crash/decompressInflateFile',{
      appId,
      platformId,
      shareId,
      cosKey,
      compressFormat: 'GZIP',
    })
    const { hasFile, contentBase64 } = rsp.json.data;
    if (hasFile) {
      let pcLogUint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      downloadAsFile(`${esMap.originMinidumpFileCoskey}.minidump.dmp.gz`, pcLogUint8Array)
    } else {
      message.error(t('ERRORSTACKTAB.Minidump内容为空'))
    }
  }

  decodeBase64(s, defaultOnFail = 'Decode BASE64 Failed') {
    if (!s) {
      return s;
    }
    try {
      return window.atob(s);
    } catch (e) {
      return defaultOnFail;
    }
  }

  parseUserIdDescriptionConfigCsvText = memoize((text) => UserIdDescriptionConfigUtil.parseCsvContent(text));

  hasViewPersonalDataPermission() {
    const { getHasSpecifiedPermission, shareLinkEquivalentUserPermissions } = this.props;
    return getHasSpecifiedPermission('VIEW_PERSONAL_DATA')
      || (this.getIsFromShareLink() && (shareLinkEquivalentUserPermissions || []).includes('VIEW_PERSONAL_DATA'));
  }

  renderUserIdFieldValue(userId) {
    const { appId, pid, appIdToWebAppSettings } = this.props;
    const settings = appIdToWebAppSettings[appId] || {};
    const userIdDescriptionConfigCsvText = settings[WebAppSettings.keys.userIdDescriptionConfigCsvText];
    const userIdDescriptionConfigInfo = this.parseUserIdDescriptionConfigCsvText(userIdDescriptionConfigCsvText);

    const descKeyValueList = userId
      ? userIdDescriptionConfigInfo.userIdToDescKeyValueList[userId] || []
      : [];

    const isValidUserId = String(userId).toLowerCase() !== 'unknown' && userId !== 'NO_PERMISSION';
    let userIdDiv;
    if (this.hasViewPersonalDataPermission() && userId && isValidUserId) {
      userIdDiv = <div>
        <Tooltip title={ze('查询此用户最近3天的上报记录', 'Query reports of this user in last 3 days.')} placement="top">
          <div style={{display:'inline-block', whiteSpace:'normal'}}>
            <Link
              to={`/crash-reporting/advanced-search/${appId}?${ new URLSearchParams({ pid, queryAccessPage: 1, queryAccessUserId: userId }).toString() }`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div>
                <span className={style.UnfoldUseridIcon}><UnfoldUseridIcon /></span>
                <span style={{ marginLeft: '4px', wordBreak: 'break-all' }}>{ userId }</span>
              </div>
            </Link>
          </div>
        </Tooltip>
      </div>;
    } else {
      userIdDiv = this.requirePermissionHoc( userId || 'Unknown');
    }

    return <div>
      <div>{ userIdDiv }</div>
      { descKeyValueList.map(({ key, value }, i) => {
        return <div key={i} style={{ fontSize: '14px', color: CS_STYLES.PRIMARY_COLOR }}>{ key }: { value }</div>;
      }) }
    </div>;
  }

  renderDefault(isAndroid) {
    const { isSmallSize } = this.props;
    const className = isSmallSize ? [style.report_detail, style.reportDetailSmallSize].join(' ') : style.report_detail;

    return (
      <div className={className}>
        <ul>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.异常进程") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.异常线程") }</p>
            <p className={style.detail}>-</p>
          </li>
        </ul>
        <ul>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.userId") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.occurTime") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.postTime") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{isAndroid ? this.props.t("REPORTDETAIL.apkName") : this.props.t("REPORTDETAIL.bundleId")}</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.version") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.useTime") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
              <p className={style.label}>{ this.props.t("REPORTDETAIL.feStatus") }</p>
              <p className={style.detail}>-</p>
            </li>
          <li>
            <p className={style.label}>{ this.props.t(isAndroid ? "oomFieldKeys.model" :"REPORTDETAIL.设备商品名") }</p>
            <p className={style.detail}>-</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.osVersion") }</p>
            <p className={style.detail}>-</p>
          </li>
          {
            isAndroid && (
              <li>
                <p className={style.label}>{ this.props.t("REPORTDETAIL.rom") }</p>
                <p className={style.detail}>-</p>
              </li>
            )
          }
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.cpu") }</p>
            <p className={style.detail}>-</p>
          </li>
        </ul>
      </div>
    );
  }

  getUserSceneTagLabel() {
    const { reduxState, t } = this.props;
    return selectCurrentAppCustomUserSceneTagLabel(reduxState) || t("issueCrashFilterKey.userSceneTag");
  }

  getIsShowChannelId() {
    const { reduxState, t } = this.props;
    const settings = selectNullableCurrentAppSettings(reduxState) || {};
    return !!settings[ServerAppSettings.keys.enableReportFieldChannelId];
  }

  getKeyToCustomKvConfig() {
    const { appId, reduxState } = this.props;
    const customKvConfigList = reduxState.app.get('appIdToCustomKvConfig')?.[appId] || [];
    const keyToCustomKvConfig = customKvConfigList.reduce((map, item) => {
      map[(item.key || '').replace(/^C03_/, '')] = item;
      return map;
    }, {});
    return keyToCustomKvConfig;
  }

  renderPcOrGameConsole() {
    const { appId, issue, pid, exceptionType, t, isSmallSize, appIdToWebAppSettings, style: extraStyle } = this.props;
    const settings = appIdToWebAppSettings[appId] || {};
    const { pcLogModalTitle, pcLogModalVisible, pcLogText, pcLogLoading } = this.state;
    let { crashDoc, exceptionName } = issue.toJS();
    const crashMap = crashDoc.crashMap || {};
    const refinedCrashMap = CrashUtil.makeRefinedCrashMap(crashMap);
    const esMap = crashDoc.esMap || {};
    const isPcOrLinux = platformUtils.isPcOrLinux(pid);
    const isGameConsole = platformUtils.isGameConsole(pid);
    const isSwitch = platformUtils.isSwitch(pid);
    const isPlaystation = platformUtils.isPlaystation(pid);
    const isXbox = platformUtils.isXbox(pid);
    // 崩溃那边处理链暂不支持hasLogFile字段，所以按钮一直展示出来
    const isCrash = exceptionType === 'crashes' || exceptionType === 'crash';
    const showViewLogButton = isCrash || crashMap.hasLogFile;
    const showViewValueMapButton = esMap.hasValueMap;
    const showViewCrashSightLogButton = esMap.hasCrashSightLog;
    const pcLogTextLength = (pcLogText || '').length;
    const disableViewPcLogText = pcLogTextLength > 3 * 1024 * 1024; // 超过3M的pc log只能下载

    const quickViewCustomKeyList = WebAppSettings.getQuickViewCustomKeyList(settings);
    const attachCustomKvList = issue.get('attachCustomKvList') || [];
    const displayedCustomKvList = attachCustomKvList.filter(x => quickViewCustomKeyList.includes(x.keyWithType));

    const nintendoSwitchFields = {};
    if (isSwitch && crashMap.thread) {
      let threadContent = {};
      try {
        threadContent = JSON.parse(crashMap.thread);
      } catch (e) {
        notification.error({ message: 'crashMap.thread解JSON失败' });
      }
      nintendoSwitchFields.lrSymbol = threadContent.lrSymbol;
      nintendoSwitchFields.pcSymbol = threadContent.pcSymbol;
    }

    const {
      bucketPath,
      userId,
      crashTimeFormatted,
      uploadTimeFormatted,
      integratedOriginAppId,
      integratedOriginAppName,
      featureTagInfos,
    } = refinedCrashMap;

    const className = isSmallSize ? [style.report_detail, style.reportDetailSmallSize].join(' ') : style.report_detail;

    if (crashDoc && crashDoc.crashMap) {
      exceptionName = crashDoc.crashMap.expName || exceptionName;
    }

    // PS4/5特有字段
    const psFields = [
      'coreTargetClockGame',
      'coreTargetClockOs',
      'gfxEffectiveClock',
      'gfxTargetClock',
      'srcIp',
      'stopLocation',
      'stopReason',
      'titleId',
      'systemName',
      'vrrPegStatus',
      'vrrRange',
      // 'hardwareId',
      // 'stackTrace',
      // 'moduleCosUrl',
      // 'attachmentCosUrl',
    ];

    const psFieldsAlias = {
      'coreTargetClockGame': 'Core Target Clock Game',
      'coreTargetClockOs': 'Core Target Clock OS',
      'gfxEffectiveClock': 'GFX Effective Clock',
      'gfxTargetClock': 'GFX Target Clock',
      'srcIp': 'Src IP',
      'stopLocation': 'Stop Location',
      'stopReason': 'Stop Reason',
      'titleId': 'Title ID',
      'vrrPegStatus': 'VRR Peg Status',
      'vrrRange': 'VRR Range',
    }

    const cabDownloadUrl = esMap.cabDownloadable
      ? `${getBackendInjectEnvs().cosDownloadOrigin}/${esMap.applicationId}_${esMap.cabId}.zip`
      : null;


    const psFilesLink = ((esMap.links || {}).files || {}).href;
    const psSelfLink = ((esMap.links || {}).self || {}).href;

    const {
      srcCountry,
      displayVersion,
      hardware,
    } = esMap;

    return (
      <div className={className} style={extraStyle}>
        <ul>
          {/* <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.异常进程") }</p>
            <p className={style.detail}>
              {crashDoc.crashMap.processName || "-"}
            </p>
          </li> */}
          {!isPlaystation && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.异常线程") }</p>
            <p className={style.detail}>
              {crashMap.threadName || '-'}
            </p>
          </li>}
          { (featureTagInfos || []).length > 0 && <li>
            <p className={style.label}>{ ze('特征标签', 'Feature Tag') }</p>
            <p className={style.detail}>{
              featureTagInfos.map((x, i) => <Tag key={i} color='blue'>{ x.name }</Tag>)
            }</p>
          </li> }
        </ul>
        <ul>
          { this.renderUserId(userId)  }
          { this.renderDeviceId(crashMap.deviceId) }
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.occurTime") }</p>
            <p className={style.detail}>{ crashTimeFormatted }</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.postTime") }</p>
            <p className={style.detail}>{ uploadTimeFormatted }</p>
          </li>
          { (isPcOrLinux && !isXbox) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.mac") }</p>
            <p className={style.detail} title={crashDoc.crashMap.mac}>{crashDoc.crashMap.mac}</p>
          </li> }
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.version") }</p>
            <p className={style.detail} title={crashDoc.crashMap.productVersion}>{crashDoc.crashMap.productVersion}</p>
          </li>
          { <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.useTime") }</p>
            <p className={style.detail}>{ isNotNullish(crashMap.elapsedTime) ? formatTime(crashMap.elapsedTime) : '-' }</p>
          </li> }
          { isPcOrLinux && !isXbox && <li>
            <p className={style.label}>{ this.props.t('REPORTDETAIL.设备商品名') }</p>
            <p className={style.detail}>{ hardware || (platformUtils.isPc(pid, appId) ? 'Windows' : 'Linux') }</p>
          </li> }
          { !isXbox && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.osVersion") }</p>
            <p className={style.detail} title={crashDoc.crashMap.osVer}>{crashDoc.crashMap.osVer}</p>
          </li> }
          { isPc(pid,appId) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.cpu") }</p>
            <p className={style.detail} title={crashDoc.crashMap.cpuName}>
              { BrandIconUtil.makePcCpuGpuLabelWithIcon(crashDoc.crashMap.cpuName) }
            </p>
          </li>}
          { (!EnvUtil.isChinaEnv() && (isMobile(pid) || isPc(pid,appId) || isLinux(pid,appId) )) && this.renderCountryOrRegion(srcCountry) }
          { isPc(pid,appId) && <li>
            <p className={style.label}>{ t("REPORTDETAIL.freeMem")} / {t("REPORTDETAIL.memSize")} </p>
            <p className={style.detail}>
              {(crashMap.memSize >= 1 && crashMap.freeMem >= 1) ?
                formatBytes(crashMap.freeMem) + '/' + formatBytes(crashMap.memSize) : '-'}
            </p>
          </li>}
          { isPc(pid,appId) && <li>
            { PlatformUtil.is(pid, PLATFORM_ID.PC)
              ? <p className={style.label} style={{ display: 'flex', alignItems: 'center', gap: '2px'}}>
                  <div>{ this.props.t("REPORTDETAIL.gpu") }</div>
                  <Tooltip
                    title={PlatformUtil.is(pid, PLATFORM_ID.PC)
                      ? ze('仅Windows Management Instrumentation列表中的首个GPU会被显示。', 'Only the first GPU in Windows Management Instrumentation list is displayed.')
                      : undefined}
                  ><WrappedTipsIcon /></Tooltip>
                </p>
              : <p className={style.label}>{ this.props.t("REPORTDETAIL.gpu") }</p>  }
            <p className={style.detail} title={crashDoc.crashMap.brand}>{ BrandIconUtil.makePcCpuGpuLabelWithIcon(crashDoc.crashMap.brand) }</p>
          </li>}
          { isPc(pid,appId) && <li>
            <p className={style.label}>{ ze('显卡驱动版本', 'GPU Driver Version') }</p>
            <p className={style.detail}>{ displayVersion || '-' }</p>
          </li> }
          {isPcOrLinux && !bucketPath && <li>
            <p className={style.label}>{ t("REPORTDETAIL.自定义日志来自文件") }</p>
            <p className={style.detail}>
              { showViewLogButton
                ? <Button onClick={() => this.openModalAndFetchPcCustomLog(crashDoc.crashMap.logId)}>{ t("PERMISSION.check") }</Button>
                : <div>-</div>
              }
            </p>
          </li>}
          {isPcOrLinux && !bucketPath && <li>
            <p className={style.label}>{ t("REPORTDETAIL.自定义日志来自接口") }</p>
            <p className={style.detail}>
              { showViewCrashSightLogButton
                ? <Button onClick={() => this.openModalAndFetchPcCrashSightLog(crashDoc.crashMap.logId)}>{ t("PERMISSION.check") }</Button>
                : <div>-</div>
              }
            </p>
          </li>}
          {isPcOrLinux && !bucketPath && <li>
            <p className={style.label}>Value Map</p>
            <p className={style.detail}>
              { showViewValueMapButton
                ? <Button onClick={() => this.openModalAndFetchPcValueMap(crashDoc.crashMap.logId)}>{ t("PERMISSION.check") }</Button>
                : <div>-</div>
              }
            </p>
          </li>}
          {(isPc(pid,appId) || isPlaystation) && <li>
            <p className={style.label}>{ t("issueCrashFilterKey.expUid") }</p>
            <p className={style.detail}>{ esMap.expUid || '-' }</p>
          </li>}
          {isSwitch && <li>
            <p className={style.label}>Error Code</p>
            <p className={style.detail} title={crashMap.crashErrorCode}>{crashMap.crashErrorCode}</p>
          </li>}
          {isSwitch && <li>
            <p className={style.label}>Exception Code</p>
            <p className={style.detail} title={crashMap.crashExceptionCode}>{crashMap.crashExceptionCode}</p>
          </li>}
          {isSwitch && <li style={{ width: '50%' }}>
            <p className={style.label}>LR Symbol</p>
            <p className={style.detail} title={nintendoSwitchFields.lrSymbol}>{nintendoSwitchFields.lrSymbol}</p>
          </li>}
          {isSwitch && <li style={{ width: '50%' }}>
            <p className={style.label}>PC Symbol</p>
            <p className={style.detail} title={nintendoSwitchFields.pcSymbol}>{nintendoSwitchFields.pcSymbol}</p>
          </li>}
          {isSwitch && <li style={{ width: '100%' }}>
            <p className={style.label}>Dying Message</p>
            <p className={style.detail}>{this.decodeBase64(crashMap.dyingMessageBase64) || '-'}</p>
          </li>}
          {isPlaystation && psFields.map((x, i) => <li key={i}>
            <p className={style.label}>{ psFieldsAlias[x] ?? x }</p>
            <p className={style.detail}>{isNotNullish(esMap[x]) ? `${esMap[x]}` : '-'}</p>
          </li>)}
          {/* {isPlaystation && <li>
            <p className={style.label}>links.files</p>
            <p className={style.detail}><a href={psFilesLink} download>{ t("REPORTDETAIL.点击下载") }</a></p>
          </li>}
          {isPlaystation && <li>
            <p className={style.label}>links.self</p>
            <p className={style.detail}><a href={psSelfLink} download>{ t("REPORTDETAIL.点击下载") }</a></p>
          </li>} */}
          {isPlaystation && <li style={{ width: '100%' }}>
            <p className={style.label}>User Property</p>
            <p>{
              Object.entries(esMap.userProperty || {}).map(([k, v]) => {
                return <div style={{ fontSize: '14px' }}><Space style={{ marginBottom: '4px' }}>
                  <Tag>{k}</Tag>
                  <div>{v}</div>
                </Space></div>;
              })
            }</p>
          </li>}
          { !!esMap.userSceneTag && <li>
            <p className={style.label}>{ this.getUserSceneTagLabel() }</p>
            <p className={style.detail}>{ esMap.userSceneTag }</p>
          </li> }
          { integratedOriginAppId && <li>
            <p className={style.label}>{ t("REPORTDETAIL.原始转发项目") }</p>
            <p className={style.detail}>
              <div>{ integratedOriginAppName || '-' }</div>
              <div style={{ fontSize: '12px', color: CS_STYLES.PRIMARY_COLOR, lineHeight: 'normal' }}>App ID: { integratedOriginAppId }</div>
            </p>
          </li> }
          { this.getIsShowChannelId() && !!esMap.channelId && <li>
            <p className={style.label}>Channel ID</p>
            <p className={style.detail}>{ esMap.channelId }</p>
          </li>  }
          { this.renderCustomKvList(displayedCustomKvList) }
        </ul>
        <Modal
          title={pcLogModalTitle}
          visible={pcLogModalVisible}
          maskClosable={true}
          width='80%'
          onCancel={this.onClosePcLogModal.bind(this)}
          footer={[
            <Button type='primary' onClick={() => this.onDownloadPcLog()}>{t('REPORTDETAIL.点击下载')}</Button>,
            <Button onClick={this.onClosePcLogModal.bind(this)}>{t('common.close')}</Button>,
          ]}
        >
          <Spin spinning={pcLogLoading}>
            <pre>{disableViewPcLogText ? t('REPORTDETAIL.日志太大提示') : pcLogText}</pre>
          </Spin>
        </Modal>
      </div>
    )
  }

  requirePermissionHoc(node) {
    const { t } = this.props;
    if (this.hasViewPersonalDataPermission()) {
      return node;
    }
    return <div style={{ color: orange.primary }}>
      <Tooltip
        title={<div>
          <div>{t('common.当前用户角色缺少以下权限')}</div>
          <div>VIEW_PERSONAL_DATA</div>
        </div>}
      >{t('common.无权限')}<WrappedTipsIcon /></Tooltip></div>;
  }

  renderUserId(userId) {
    const { t, appId, pid } = this.props;
    const items = [{
      key: 'queryAccess',
      disabled: !userId || String(userId).toLowerCase() === 'unknown',
      label: <Link
        to={`/crash-reporting/advanced-search/${appId}?${ new URLSearchParams({ pid, queryAccessPage: 1, queryAccessUserId: userId }).toString() }`}
        target='_blank'
        rel='noopener noreferrer'
      >{ ze('查询此用户最近3天的上报记录', 'Query reports of this user in last 3 days') }</Link>,
    }];

    return <li>
      <p className={style.label}>{ t("REPORTDETAIL.userId") }</p>
      <p className={style.detail}>{ this.requirePermissionHoc(this.renderUserIdFieldValue(userId)) }</p>
    </li>;
  }

  renderDeviceId(deviceId) {
    const { t, appId, pid } = this.props;

    const isValidDeviceId = String(deviceId).toLowerCase() !== 'unknown' && deviceId !== 'NO_PERMISSION';
    let deviceIdDiv;
    if (this.hasViewPersonalDataPermission() && deviceId && isValidDeviceId) {
      deviceIdDiv = <div>
        <Tooltip title={ze('查询此设备最近3天的上报记录', 'Query reports of this device in last 3 days.')} placement="top">
          <div style={{display:'inline-block', whiteSpace:'normal'}}>
            <Link
              to={`/crash-reporting/advanced-search/${appId}?${ new URLSearchParams({ pid, queryAccessPage: 1, queryAccessDeviceId: deviceId }).toString() }`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div>
                <span className={style.UnfoldUseridIcon}><UnfoldUseridIcon /></span>
                <span style={{ marginLeft: '4px', wordBreak: 'break-all' }}>{ deviceId }</span>
              </div>
            </Link>
          </div>
        </Tooltip>
      </div>;
    } else {
      deviceIdDiv = this.requirePermissionHoc( deviceId || 'Unknown');
    }

    return <li>
      <p className={style.label}>{ t("REPORTDETAIL.deviceId") }</p>
      <p className={style.detail}>{ deviceIdDiv }</p>
    </li>
  }

  renderOrRedirect( value ) {
    if (!isNotNullish(value)) {
        return (<p>{ '-' }</p>);
    }
    const isHttpLink = typeof value ==='string' && (value.startsWith('http:') || value.startsWith('https:'));
    return isHttpLink? (
        <a href={value} target="_blank">
            {value}
        </a>
    ) : (
        <p>{ value }</p>
    );
  }

  renderCustomKvList(displayedCustomKvList) {
    const keyToCustomKvConfig = this.getKeyToCustomKvConfig();

    return displayedCustomKvList.map(x => {
      const keyWithType = x.keyWithType;
      const { alias } = keyToCustomKvConfig[keyWithType] || {};
      const keyDisplayText = alias ? `${alias} (${keyWithType})` : keyWithType;

      return <li key={keyWithType}>
        <p className={style.label}>
          <span>{ keyDisplayText }</span>
          <span
            style={{ marginLeft: '2px', position: 'absolute', transform: 'translateY(-2px)' }}
          >
            <Tooltip
              title={ze('在“跟踪数据”页签下，设置了固定展示的自定义字段。', 'Pinned custom field from "Trace Data" tab.')}
            ><PinnedIcon/></Tooltip>
          </span>
        </p>
        <p className={style.detail}>{ this.renderOrRedirect(x.value) }</p>
      </li>;
    });
  }

  renderCountryOrRegion(srcCountry) {
    const { t } = this.props;
    const flagUrl = countryFlagUrl(srcCountry);
    return <li>
      <p className={style.label}>{ t("issueCrashFilterKey.country") }</p>
      <p className={style.detail} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        { !!flagUrl && <img style={{ width: '20px' }} alt={srcCountry} src={flagUrl} /> }
        <div>{ countryI18n(srcCountry || '-') }</div>
      </p>
    </li>;
  }

  renderVmType(crashMap, esMap) {
    const { t, currentUser } = this.props;
    const isSuperAdmin = !!currentUser.get('isSuper');
    const {
      vmType,
      vmDeviceBrand,
      reservedMapStr,
    } = esMap || {};

    let cloudGamingInstance;
    let cloudGamingDeviceId;
    if (vmType === VmTypeInt.CLOUD_GAMING && reservedMapStr) {
      try {
        const reservedMap = JSON.parse(reservedMapStr);
        cloudGamingInstance = reservedMap['E9'];
        cloudGamingDeviceId = reservedMap['E10'];
      } catch (e) {
        message.error('Parse reservedMapStr error.');
        console.error('Parse reservedMapStr error.', e);
      }
    }

    return vmType && (<li>
      <p className={style.label}>{ t('issueCrashFilterKey.vmType') }</p>
      <p className={style.detail}>
        <span>{ VmTypeUtil.toI18n(vmType) }</span>
        { vmType === VmTypeInt.EMULATOR && vmDeviceBrand && <span style={{ marginLeft: '4px' }}>({ vmDeviceBrand })</span> }
        { isSuperAdmin && vmType === VmTypeInt.EMULATOR && !vmDeviceBrand && <span style={{ marginLeft: '4px' }}>(code={ crashMap.isVirtualMachine })</span> }
        { cloudGamingInstance && <div style={{ marginTop: '4px' }}><Tag color='geekblue'>Instance</Tag>{cloudGamingInstance}</div> }
        { cloudGamingDeviceId && <div style={{ marginTop: '4px' }}><Tag color='geekblue'>Device ID</Tag>{cloudGamingDeviceId}</div> }
      </p>
    </li>)
  }

  render() {
    const { appId, issue, pid, t, isSmallSize, exceptionType, appIdToWebAppSettings, currentUser, reduxState, style: extraStyle } = this.props;
    const teamType = reduxState.app.get('current').get('teamType');
    const isNotCommercialApp = teamType && teamType !== BillingTeamType.COMMERCIAL; // 是否非商业化项目

    const isSuperAdmin = !!currentUser.get('isSuper');
    const settings = appIdToWebAppSettings[appId] || {};
    const quickViewCustomKeyList = WebAppSettings.getQuickViewCustomKeyList(settings);
    const userIdDescriptionConfigCsvText = settings[WebAppSettings.keys.userIdDescriptionConfigCsvText];
    const userIdDescriptionConfigInfo = this.parseUserIdDescriptionConfigCsvText(userIdDescriptionConfigCsvText);

    let { crashDoc, exceptionName, issueExceptionType: issueExceptionTypeInt } = issue.toJS();
    const attachCustomKvList = issue.get('attachCustomKvList') || [];
    const isAndroid = parseInt(pid) === PLATFORM_ID.ANDROID;
    const isIos = parseInt(pid) === PLATFORM_ID.IOS;

    const displayedCustomKvList = attachCustomKvList.filter(x => quickViewCustomKeyList.includes(x.keyWithType));

    if (!crashDoc) {
      return this.renderDefault(isAndroid);
    }
    if (platformUtils.isPcOrLinux(pid)) {
      return this.renderPcOrGameConsole();
    }
    if (platformUtils.isGameConsole(pid) && !ExceptionCategoryUtil.isError(exceptionType)) {
      return this.renderPcOrGameConsole();
    }

    const crashMap = crashDoc.crashMap || {};
    const refinedCrashMap = CrashUtil.makeRefinedCrashMap(crashMap);
    const {
      userId,
      deviceId,
      crashTimeFormatted,
      uploadTimeFormatted,
      integratedOriginAppId,
      integratedOriginAppName,
      appBit,
      featureTagInfos,
    } = refinedCrashMap;

    const esMap = crashDoc.esMap || {};

    const { srcCountry, hasMinidump, vmType } = esMap;

    const rom = this.getRom();

    let appInBack = this.props.t("REPORTDETAIL.unknown");
    if (crashMap.appInBack === 'false') {
      appInBack = this.props.t("REPORTDETAIL.foreground");
    } else if (crashMap.appInBack === 'true') {
      appInBack = this.props.t("REPORTDETAIL.background");
    }

    const vmCode = crashMap.isVirtualMachine;

    let modelLabel = crashMap.model || '';
    const modelOriginalName = refinedCrashMap.modelOriginalName || '';

    if (CrashUtil.checkIsGameMatrix(appId, crashMap)) {
      modelLabel = `${modelLabel} (先锋云游戏)`;
    }

    const className = isSmallSize ? [style.report_detail, style.reportDetailSmallSize].join(' ') : style.report_detail;

    if (crashDoc && crashDoc.crashMap) {
      exceptionName = crashDoc.crashMap.expName || exceptionName;
    }

    const showExpAddr = PlatformUtil.isMobileOrMobileLike(pid)
      && (exceptionType || '').startsWith('crash')
      && (exceptionName || '').includes('SIGSEGV');

    const showSecondReport = isAndroid && issueExceptionTypeInt === EXCEPTION_TYPE_INT_ANDROID_NATIVE;

    return (
      <div className={className} style={extraStyle}>
        <ul>
          {PlatformUtil.isMobileOrMobileLike(pid) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.异常进程") }</p>
            <p className={style.detail}>
              {crashMap.processName || "-"}
            </p>
          </li>}
          {!isWechatMiniProgram(pid) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.异常线程") }</p>
            <p className={style.detail}>
              {crashMap.threadName || '-'}
            </p>
          </li>}
          { showExpAddr && <li>
            <p className={style.label}>{ ze('崩溃时访问地址', 'Accessed Address When Crashed') }</p>
            <p className={style.detail}>{ ReportDetailUtil.renderExpAddr(crashMap.expAddr) }</p>
          </li> }
          { (featureTagInfos || []).length > 0 && <li>
            <p className={style.label}>{ ze('特征标签', 'Feature Tag') }</p>
            <p className={style.detail}>{
              featureTagInfos.map((x, i) => <Tag key={i} color='blue'>{ x.name }</Tag>)
            }</p>
          </li> }
        </ul>
        <ul>
          { this.renderUserId(userId) }
          { this.renderDeviceId(deviceId) }
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.occurTime") }</p>
            <p className={style.detail}>{crashTimeFormatted}</p>
          </li>
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.postTime") }</p>
            <p className={style.detail}>{uploadTimeFormatted}</p>
          </li>
          { isMobile(pid) && <li>
            <p className={style.label}>{isAndroid ? this.props.t("REPORTDETAIL.apkName") : this.props.t("REPORTDETAIL.bundleId")}</p>
            <p className={style.detail} title={crashMap.bundleId}>{crashMap.bundleId}</p>
          </li> }
          <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.version") }</p>
            <p className={style.detail} title={crashMap.productVersion}>{crashMap.productVersion}</p>
          </li>
          {!isWechatMiniProgram(pid) && !isSwitch(pid) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.useTime") }</p>
            <p className={style.detail}>{crashDoc.launchTime && crashDoc.launchTime !== '-' ? (formatTime(crashDoc.launchTime * 1000)) : '-'/*'无法获取'*/}</p>
          </li>}
          { PlatformUtil.isMobileOrMobileLike(pid) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.feStatus") }</p>
            <p className={style.detail}>{appInBack || '-'}</p>
          </li>}
          { PlatformUtil.isMobileOrMobileLike(pid) && <li>
            <p className={style.label}>{ this.props.t(isAndroid ? "oomFieldKeys.model" :"REPORTDETAIL.设备商品名") }</p>
            <p className={style.detail}>
              <div>{ BrandIconUtil.makeDeviceNameLabelWithIcon(pid, modelOriginalName, modelLabel) }</div>
            </p>
          </li> }
          {!isSwitch(pid) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.osVersion") }</p>
            <p className={style.detail}>
              { decodeURIComponent(crashMap.osVer) }
            </p>
          </li>}
          { (PlatformUtil.isMobileOrMobileLike(pid) || isPc(pid)) && !isHarmony(pid) && <li>
            <p className={style.label}>{ this.props.t("REPORTDETAIL.cpu") }</p>
            <p className={style.detail}>{crashMap.cpuType}{ isAndroid && crashMap.cpuName && ` (${crashMap.cpuName})` }</p>
          </li> }
          { (!EnvUtil.isChinaEnv() && (PlatformUtil.isMobileOrMobileLike(pid) || isPc(pid,appId) || isLinux(pid,appId) )) && this.renderCountryOrRegion(srcCountry) }
          {(PlatformUtil.isMobileOrMobileLike(pid) || isPc(pid)) && <li>
            <p className={style.label}>{ t("REPORTDETAIL.freeMem")} / {t("REPORTDETAIL.memSize")} </p>
            <p className={style.detail}>
              {(crashMap.memSize >= 1 && crashMap.freeMem >= 1) ?
                formatBytes(crashMap.freeMem) + '/' + formatBytes(crashMap.memSize) : '-'}
            </p>
          </li>}
          {
            isAndroid && (
              <li>
                <p className={style.label}>{ this.props.t("REPORTDETAIL.rom") }</p>
                <p className={style.detail}>
                  { decodeURIComponent(rom) }
                </p>
              </li>
            )
          }
          {
            isAndroid && esMap.gpu && (<li>
              <p className={style.label}>GPU</p>
              <p className={style.detail}>{esMap.gpu}</p>
            </li>)
          }
          {
            isAndroid && this.renderVmType(crashMap, esMap)
          }
          {
            isIos && vmType === VmTypeInt.PLAYCOVER && (<li>
              <p className={style.label}>{ ze('基于PlayCover', 'Run on PlayCover') }</p>
              <p className={style.detail}>{ t('common.yes') }</p>
            </li>)
          }
          {!isSwitch(pid) && !isLinux(pid) && <li>
            <p className={style.label}>{ t("REPORTDETAIL.expUid") }</p>
            <p className={style.detail}>{ esMap.expUid || '-' }</p>
          </li>}
          {
            isAndroid && isNotNullish(appBit) && (<li>
              <p className={style.label}>{ t('issueCrashFilterKey.appBit') }</p>
              <p className={style.detail}>{appBit > 0 ? `${appBit}${ze('位', ' Bit')}` : ze('未识别', 'Unknown') }</p>
            </li>)
          }
          { hasMinidump && <li>
            <p className={style.label}>{ t("ERRORSTACKTAB.下载原始dump") }</p>
            <Button type='primary' onClick={() => this.onDownloadDump()}>{t('REPORTDETAIL.点击下载')}</Button>
          </li>}
          { PlatformUtil.isMobileOrMobileLike(pid) && <li>
            <p className={style.label} style={{ display: 'flex', alignItems: 'center', gap: '2px'}}>
              <div>{ this.props.t("REPORTDETAIL.是否二次上报")}</div>
              <Tooltip
                title={<div>
                  <p>{ ze('信号处理状态：', 'Signal Processing Status:') }</p>
                  <p>{ ze('正常 - 信号处理正常，崩溃详情中显示的信息是完整的。', 'Normal - The signal is processed successfully, and the information displayed in the crash details is complete.') }</p>
                  <p>{ ze('异常中断 - 信号处理的过程中，由于一些异常，处理过程被中断。下次App启动初始化时，CrashSight会整理已收集到的信息并上报，但崩溃详情中显示的信息可能不完整。', 'Interrupted - Signal processing was interrupted due to some exceptions. Once the app is launched again, CrashSight organizes the collected information and reports the crash, but the information displayed in the crash details may be incomplete.') }</p>
                </div>}
              ><WrappedTipsIcon /></Tooltip>
            </p>
            <p className={style.detail}>{ esMap.isSecondReport ? t('REPORTDETAIL.secondReportTrue') : t('REPORTDETAIL.secondReportFalse') }</p>
          </li> }
          { !!esMap.userSceneTag && <li>
            <p className={style.label}>{ this.getUserSceneTagLabel() }</p>
            <p className={style.detail}>{ esMap.userSceneTag }</p>
          </li> }
          { integratedOriginAppId && <li>
            <p className={style.label}>{ t("REPORTDETAIL.原始转发项目") }</p>
            <p className={style.detail}>
              <div>{ integratedOriginAppName || '-' }</div>
              <div style={{ fontSize: '12px', color: CS_STYLES.PRIMARY_COLOR, lineHeight: 'normal' }}>App ID: { integratedOriginAppId }</div>
            </p>
          </li> }
          { this.getIsShowChannelId() && !!esMap.channelId && <li>
            <p className={style.label}>Channel ID</p>
            <p className={style.detail}>{ esMap.channelId }</p>
          </li>  }
          { this.renderCustomKvList(displayedCustomKvList) }
        </ul>
      </div>
    );
  }
}

ReportDetail.propTypes = {
  appId: PropTypes.string,
  issue: PropTypes.object,
  pid: PropTypes.string,
  exceptionType: PropTypes.string,
  getHasSpecifiedPermission: PropTypes.func,
  appIdToWebAppSettings: PropTypes.object,
  isSmallSize: PropTypes.bool,  // 是否显示为小尺寸（在issue预览的时候显示用）
  shareId: PropTypes.string,  // 分享链接的id，如果不为空表示是通过分享链接查看的
  shareLinkEquivalentUserPermissions: PropTypes.array, // 分享链接的相关查看权限
  currentUser: PropTypes.object,
};
