import React, { useState, useEffect } from 'react';
import { Badge, Tooltip } from 'antd';
import scss from './CrashSightHeader.scss';
import RestHelper from 'utils/RestHelper';
import { isZh, ze } from 'utils/zhEn';
import NotificationDrawer from './NoticeDrawer.jsx'
import PropTypes from "prop-types";
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent.js';
import NoticeIconNormal from 'svg/v2/newcs_dashboard_topbar_notice_icon_normal.svg';

// 提取数据过滤函数，用于筛选出符合当前语言的有效数据
const filterPresentData = (rspData) => {
  return rspData.filter(item => isZh()? item.hasZh : item.hasEn);
};

// 提取数据格式化函数，用于给数据添加额外属性等格式化操作
const formatNoticeData = (presentData) => {
  return (presentData || []).map(item => ({
     ...item,
      showEdit: false
  }));
};

const NoticeButton = (prop) => {
  const { appId } = prop;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ listNotices, setListNotices ] = useState([]);
  const [ total, setTotal ] = useState(0);
  const [ badgeCount, setBadgeCount ] = useState(0);

  //获取公告数据
  const getListNotices = async({current,pageSize,onlyUnread,complexContentFilter} = {}) => {
    setListNotices([]);
    const rsp = await RestHelper.mustPost('/redir/api/notice/listNotices',{
      appId,
      lang: isZh() ? 'zh' : 'en',
      pageNumber: current,
      pageSize: pageSize,
      onlyUnread: onlyUnread,
      ...complexContentFilter,
    })
    const rspData = rsp.json.data.items;
    const total = rsp.json.data.total;
    const presentData = filterPresentData(rspData);
    const data = formatNoticeData(presentData);
    if(onlyUnread){
      setBadgeCount(total)
    }
    setListNotices(data);
    setTotal(total);
  }

  //用户一键已读所有通知
  const getMarkAllAsRead = async() => {
    await RestHelper.mustPost('/redir/api/notice/markAllAsRead',{
      appId, //把指定项目下的通知全部标为已读
    })
  }

  //用户修改通知的已读状态
  const getChangeReadStatus = async(recordId,hasRead) => {
    const rsp = await RestHelper.mustPost('/redir/api/notice/changeReadStatus',{
      appId,
      recordId,
      hasRead: !hasRead, //true表示标为已读false表示标为未读
    })
  }

  //打开抽屉弹框
  const drawerShow = () => {
    setDrawerOpen(true);
    // 消息中心曝光
    reportEvent({
      action: EVENT_ACTIONS.EXPOSURE,
      tp1: '消息中心曝光',
    });
  };

  const drawerDisable = () => {
    setDrawerOpen(false);
  }

  return (<div style={{cursor: 'pointer', marginTop:'6px'}}>
    <Badge size="small" count={badgeCount} offset={[5, 0]} onClick={drawerShow} color='#EE2F27'>
      <div className={scss.noticeIcon}>
        <Tooltip title={ze('消息提示','Message')} placement='bottom'><NoticeIconNormal /></Tooltip>
      </div>
    </Badge>
    <NotificationDrawer
      appId={appId}
      drawerOpen={drawerOpen}
      drawerDisable={drawerDisable}
      listNotices={listNotices}
      total={total}
      getMarkAllAsRead={getMarkAllAsRead}
      getChangeReadStatus={getChangeReadStatus}
      getListNotices={getListNotices}
    />
  </div>)
}

NoticeButton.propTypes = {
  appId: PropTypes.any,
};

export default NoticeButton;
