import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Input, InputNumber, Divider, Radio, Dropdown, Spin, Menu, Tooltip } from 'antd';
import CrashSightSubheader from './CrashSightSubheader';
import { useTranslation } from 'react-i18next';
import { isGameConsole, isMobile, isSwitch, PlatformUtil } from 'utils/platform';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProductSummaryUtil } from 'components/exception/ProductSummaryPage/ProductSummaryUtil';
import CardHead from 'components/commons/CardHead';
import { ServerAppSettings } from 'utils/server-app-settings';
import i18n from 'i18n.js';
import style from './PreferencesSubMenu.scss';
import { bindActionCreators } from 'redux';
import * as productActions from 'reducers/product/actions';
import { updateInfoOnAppList } from 'reducers/app/appActions';
import * as globalActions from 'reducers/global/globalActions';
import groupBy from 'lodash/collection/groupBy';
import { ze } from 'utils/zhEn';
import {
  selectFrontendWebVolatileConfigValueByKey,
} from 'utils/selectors/selectors';
import {
  FrontendWebVolatileConfigKeys,
} from 'utils/constants/frontend-web-volatile-config';
import ProductSettingIcon from 'svg/v2/newcs_dashboard_left_setting_icon_normal.svg';
import AlertSettingIcon from 'svg/v2/newcs_dashboard_setting_alertsetting_icon.svg';
import OrganizationSettingIcon from 'svg/v2/newcs_setting_organization.svg';
import { UserTypeEnum } from 'utils/constants/user-type-enum';
import { EnvUtil } from 'utils/env-util';

const MenuGroup = {
  PRODUCT: 'PRODUCT',
  ORGANIZATION: 'ORGANIZATION',
  STRATEGY: 'STRATEGY',
  INTEGRATED: 'INTEGRATED',
};

const MenuGroupKeyToInfo = {
  [MenuGroup.PRODUCT]: {
    label: ze('产品设置', 'PRODUCT SETTINGS'),
    icon: <ProductSettingIcon width="14" height="14" viewBox="0 0 20 20" />,
  },
  [MenuGroup.ORGANIZATION]: {
    label: ze('成员设置', 'MEMBERS'),
    icon: <OrganizationSettingIcon />,
  },
  [MenuGroup.INTEGRATED]: {
    label: ze('集成设置', 'INTEGRATIONS'),
    icon: <OrganizationSettingIcon />,
  },
  [MenuGroup.STRATEGY]: {
    label: ze('云控设置', 'STRATEGY'),
    icon: <AlertSettingIcon width="14" height="14" viewBox="0 0 14 14" />,
  },
};

export const PreferencesSubMenuUtil = Object.freeze({
  makeMenuItems({ currentAppJs, serverAppSettings, currentUserJs, isCustomFeatureAvailable }) {
    const { appId, pid, accountId } = currentAppJs;
    const isNotNetmarble = accountId && accountId !== '10050690181558001';

    const { isSuper: isSuperAdmin, type: userType } = currentUserJs;

    const isRegionalDashboardAvailable = ProductSummaryUtil.getIsRegionalDashboard(pid);
    const enableSdkStrategyConfig = serverAppSettings[ServerAppSettings.keys.enableSdkStrategyConfig];



    return [{
      available: true,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'ProductInfo',
      label: i18n.t('PRODUCTBASE.information'),
      pathPrefixes: ['/crash-reporting/preferences/product-info'],
      linkTo: { pathname: `/crash-reporting/preferences/product-info/${appId}`, search: `?pid=${pid}` },
    }, {
      available: PlatformUtil.hasAccessStats(pid),
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'alarmSetting',
      label: i18n.t('EXCP_LEFTMENU.alarmSetting'),
      pathPrefixes: ['/crash-reporting/preferences/notification'],
      linkTo: { pathname: `/crash-reporting/preferences/notification/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isSwitch(pid),
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'switch',
      label: i18n.t('EXCP_LEFTMENU.switch'),
      pathPrefixes: ['/crash-reporting/preferences/nintendo-switch'],
      linkTo: { pathname: `/crash-reporting/preferences/nintendo-switch/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isMobile(pid),
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'tagSetting',
      label: i18n.t('EXCP_LEFTMENU.tagSetting'),
      pathPrefixes: ['/crash-reporting/preferences/tags'],
      linkTo: { pathname: `/crash-reporting/preferences/tags/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'VersionManage',
      label: i18n.t('PRODUCTBASE.versionManage'),
      pathPrefixes: ['/crash-reporting/preferences/version-management'],
      linkTo: { pathname: `/crash-reporting/preferences/version-management/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'symbolSetting',
      label: i18n.t('EXCP_LEFTMENU.symbolSetting'),
      pathPrefixes: ['/crash-reporting/preferences/dsyms'],
      linkTo: { pathname: `/crash-reporting/preferences/dsyms/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isRegionalDashboardAvailable,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'countryGroupConfig',
      label: i18n.t('EXCP_LEFTMENU.国家地区组合配置'),
      pathPrefixes: ['/crash-reporting/preferences/country-group-config'],
      linkTo: { pathname: `/crash-reporting/preferences/country-group-config/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'WebConsoleConfig',
      label: i18n.t('EXCP_LEFTMENU.webConsoleConfig'),
      pathPrefixes: ['/crash-reporting/preferences/web-console-config'],
      linkTo: { pathname: `/crash-reporting/preferences/web-console-config/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isCustomFeatureAvailable,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'DashboardFilters',
      label: ze('大盘策略','Dashboard Filters'),
      pathPrefixes: ['/crash-reporting/preferences/dashboard-filters'],
      linkTo: { pathname: `/crash-reporting/preferences/dashboard-filters/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isNotNetmarble,  // netmarble屏蔽用户别名配置
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'userIdDescriptionConfig',
      label: i18n.t('EXCP_LEFTMENU.userIdDescriptionConfig'),
      pathPrefixes: ['/crash-reporting/preferences/user-id-description-config'],
      linkTo: { pathname: `/crash-reporting/preferences/user-id-description-config/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'issueClassificationSettingPage',
      label: i18n.t('EXCP_LEFTMENU.issueGroupingConfiguration'),
      pathPrefixes: ['/crash-reporting/preferences/issue-classification-setting'],
      linkTo: { pathname: `/crash-reporting/preferences/issue-classification-setting/${appId}`, search: `?pid=${pid}` },
    }, {
      available: UserTypeEnum.hasAdminPermission(userType),
      menuGroupKey: MenuGroup.PRODUCT,
      key: 'DeleteProject',
      label: i18n.t('PRODUCTBASE.deleteProduct'),
      pathPrefixes: ['/crash-reporting/preferences/delete-project'],
      linkTo: { pathname: `/crash-reporting/preferences/delete-project/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.ORGANIZATION,
      key: 'MemberManagement',
      label: i18n.t('PRODUCTBASE.members'),
      pathPrefixes: ['/crash-reporting/preferences/member-management'],
      linkTo: { pathname: `/crash-reporting/preferences/member-management/${appId}`, search: `?pid=${pid}` },
    }, {
      available: UserTypeEnum.hasAdminPermission(userType),
      menuGroupKey: MenuGroup.ORGANIZATION,
      key: 'OperationLogs',
      label: i18n.t('PRODUCTBASE.operationLogs'),
      pathPrefixes: ['/crash-reporting/preferences/audit-log'],
      linkTo: { pathname: `/crash-reporting/preferences/audit-log/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.INTEGRATED,
      key: 'platformSetting',
      label: i18n.t('EXCP_LEFTMENU.platformSetting'),
      pathPrefixes: ['/crash-reporting/preferences/tapd'],
      linkTo: { pathname: `/crash-reporting/preferences/tapd/${appId}`, search: `?pid=${pid}` },
    }, {
      available: true,
      menuGroupKey: MenuGroup.INTEGRATED,
      key: 'Efficiency',
      label: ze('告警推送软件','Alert Push Software'),
      pathPrefixes: ['/crash-reporting/preferences/efficiency'],
      linkTo: { pathname: `/crash-reporting/preferences/efficiency/${appId}`, search: `?pid=${pid}` },
    }, {
      available: enableSdkStrategyConfig || isSuperAdmin,
      menuGroupKey: MenuGroup.STRATEGY,
      key: 'SdkStrategyConfig',
      label: i18n.t('EXCP_LEFTMENU.SDK端云控功能配置'),
      pathPrefixes: ['/crash-reporting/preferences/sdk-strategy-config'],
      linkTo: { pathname: `/crash-reporting/preferences/sdk-strategy-config/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isSuperAdmin,
      menuGroupKey: MenuGroup.STRATEGY,
      key: 'SuperAdminServerSettings',
      label: i18n.t('PRODUCTBASE.functionConfiguration'),
      pathPrefixes: ['/crash-reporting/preferences/super-admin-server-settings'],
      linkTo: { pathname: `/crash-reporting/preferences/super-admin-server-settings/${appId}`, search: `?pid=${pid}` },
    }, {
      available: isSuperAdmin,
      menuGroupKey: MenuGroup.STRATEGY,
      key: 'SuperAdminNoticeSettings',
      label: i18n.t('PRODUCTBASE.notification'),
      pathPrefixes: ['/crash-reporting/preferences/super-admin-notice-config'],
      linkTo: { pathname: `/crash-reporting/preferences/super-admin-notice-config/${appId}`, search: `?pid=${pid}` },
    }].filter(x => x.available);
  },
});

const MenuHead = ({icon,title}) => {
  return <div className={style.menuHead}>
    <div className={style.menuHeadIcon}>{icon}</div>
    <div className={style.menuHeadTitle}>{title}</div>
  </div>
}

const PreferencesSubMenu = ({
  pathname,
  currentApp,
  reduxState,
  actions,
  selectFrontendWebVolatileConfigValueByKey,
}) => {
  const { t } = useTranslation();
  const currentAppJs = currentApp.toJS();
  const { appId, pid } = currentAppJs;
  const serverAppSettings = reduxState.app.get('appIdToServerAppSettings')[appId] || {};
  const currentUserJs = reduxState.user.get('current').toJS();

  const enableFeatureAnalysis = serverAppSettings[ServerAppSettings.keys.enableFeatureAnalysis];
  const customFeatureAppIdWhitelist = (selectFrontendWebVolatileConfigValueByKey(FrontendWebVolatileConfigKeys.CUSTOM_FEATURE_APP_ID_WHITELIST) || '')
    .split(',')
    .map(x => x.trim())
    .filter(x => x);
  const isCustomFeatureAvailable = enableFeatureAnalysis || customFeatureAppIdWhitelist.includes(appId);
  const menuItems = useMemo(() => PreferencesSubMenuUtil.makeMenuItems({ currentAppJs, serverAppSettings, currentUserJs, isCustomFeatureAvailable }),
    [appId, pid, serverAppSettings, reduxState.user]);

  const menuGroupList = Object.entries(groupBy(menuItems, x => x.menuGroupKey))
    .map(([k, v]) => ({ menuGroupKey: k, menuGroupItems: v }));

  const currentMenuItem = menuItems.find(menuItem => menuItem.pathPrefixes.some(x => pathname.includes(x)));
  const currentMenuKey = (currentMenuItem || {}).key;

  useEffect(() => {
    if(!pathname.startsWith('/product/apps')){
      actions.changeCurTab('')
    }
  }, [pathname])

  return <div className={style.preferencesSubMenu}>
    { menuGroupList.map(menuGroup => {
      const { menuGroupKey, menuGroupItems } = menuGroup;
      const menuGroupInfo = MenuGroupKeyToInfo[menuGroupKey];
      return <>
        <MenuHead
          icon={menuGroupInfo.icon}
          title={menuGroupInfo.label}
        />
        <Menu
          mode='inline'
          selectedKeys={[currentMenuKey]}
        >{
          menuGroupItems.map(x => <Menu.Item
            key={x.key}
          >
            <Link to={x.linkTo}>{ x.label }</Link>
          </Menu.Item>)
        }</Menu>
      </>;
    }) }
  </div>;
};

PreferencesSubMenu.props = {
  pathname: PropTypes.string,
  currentApp: PropTypes.object,
  reduxState: PropTypes.object,
};

const mapStateToProps = (state, router) => ({
  reduxState: state,
  selectFrontendWebVolatileConfigValueByKey: key => selectFrontendWebVolatileConfigValueByKey(state, key),
});

export default connect(mapStateToProps,(dispatch) => ({
  actions: bindActionCreators({
    ...productActions,
    ...globalActions,
    updateInfoOnAppList,
  }, dispatch),
  dispatch,
}))(PreferencesSubMenu);
