import React from 'react';
import { ExceptionCategory } from './exception-category';

import IconAnrList from 'svg/v2/newcs_dashboard_left_anr_icon_normal.svg';
import IconCrashList from 'svg/v2/newcs_dashboard_left_crash_icon_normal.svg';
import IconErrorList from 'svg/v2/newcs_dashboard_left_error_icon_normal.svg';
import IconAccessList from 'svg/v2/newcs_dashboard_advancedsearch_allrecords_list_lanuch_icon.svg';
import IconOomList from 'svg/v2/newcs_dashboard_advancedsearch_allrecords_list_oom_icon.svg';
import IconJankList from 'svg/v2/newcs_dashboard_left_jank_icon_normal.svg';

export const ExceptionTypeToIcon = {
  [ExceptionCategory.ACCESS]: <IconAccessList style={{height: 16, width: 16, color: '#13c2c2'}} />,
  [ExceptionCategory.CRASH]: <IconCrashList style={{height: 16, width: 16, color:'#FF5461'}}/>,
  [ExceptionCategory.ANR]: <IconAnrList  width="16" height="16" viewBox="0 0 20 20" style={{color:'#FB835E'}}/>,
  [ExceptionCategory.OOM]: <IconOomList style={{height: 16, width: 16, color: '#6669fe'}} />,
  [ExceptionCategory.ERROR]: <IconErrorList width="16" height="16" viewBox="0 0 20 20" style={{color:'#A452DD'}} />,
  [ExceptionCategory.JANK]: <IconJankList width="16" height="16" viewBox="0 0 20 20" style={{color:'#F5B800'}} />,
  [ExceptionCategory.UNKNOWN]: undefined,
};

export const getExceptionIconByType = ({exceptionType, size = 14}) => {
  const iconMap = {
    [ExceptionCategory.ACCESS]: <IconAccessList style={{color: '#13c2c2', height: size, width: size}} />,
    [ExceptionCategory.CRASH]: <IconCrashList style={{color:'#FF5461', height: size, width: size}}/>,
    [ExceptionCategory.ANR]: <IconAnrList style={{color:'#FB835E', height: size, width: size}}/>,
    [ExceptionCategory.OOM]: <IconOomList style={{color: '#6669fe', height: size, width: size}} />,
    [ExceptionCategory.ERROR]: <IconErrorList style={{color:'#A452DD', height: size, width: size}} />,
    [ExceptionCategory.JANK]: <IconJankList width="16" height="16" viewBox="0 0 20 20" style={{color: '#F5B800', height: size, width: size}} />,
  }

  if (!iconMap[exceptionType]) {
    return null;
  }

  return iconMap[exceptionType];
}
