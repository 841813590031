import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatNum } from 'utils/helper';
import { connect } from 'react-redux';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { orange } from '@ant-design/colors';
import { Col, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatBytes, formatBytesAdvanced } from 'utils/format/file';
import { isAndroid, isHarmony, isIos, isLinux, isMac, isMobile, isPc, PlatformUtil, isAndroidOrHarmony } from 'utils/platform';
import scss from './DataTable.scss';
import { ExceptionCategoryUtil } from 'utils/exception-category';
import { EXCEPTION_TYPE_INT_ANDROID_NATIVE } from 'utils/constants/exception-type-int';
import { ErrnoUtil } from 'utils/constants/errno';
import { isNotNullish } from 'utils/nullish';
import IconWifi from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_wifi_icon.svg';
import Icon2G from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_2g_icon.svg';
import Icon3G from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_3g_icon.svg';
import Icon4G from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_4g_icon.svg';
import Icon5G from 'svg/v2/newcs_dashboard_crashanalysis_issuedetail_tracedata_5g_icon.svg';
import PercentageBar from 'components/commons/PercentageBar/PercentageBar';
import { ze } from 'utils/zhEn';

function transformMemData(data) {
  const kbData = data / 1024;
  if (kbData > 1048576) {
    return `${formatNum((kbData / 1048576).toFixed(2))} GiB`;
  } else if (kbData > 1024) {
    return `${formatNum((kbData / 1024).toFixed(2))} MiB`;
  } else {
    return `${formatNum(kbData.toFixed(2))} KiB`;
  }
}

function getRate(free, total) {
  if (total > 0 && free >= 0) {
    return ((100 * free) / total).toFixed(2);
  }
  return 0;
}

function makeValueBoxWithPercentageBar(text, percentage) {
  if (Number(percentage) === 0) {
    return '-';
  }
  return <div style={{  display: 'flex', alignItems: 'center', gap: '12px' }}>
    <PercentageBar
      percentage={Number(percentage)}
      reversed
    />
    <div>{ text }</div>
  </div>;
}

const NetworkType = {
  WIFI: 'WIFI',
  CELLULAR: 'CELLULAR',
  CELLULAR_2G: 'CELLULAR_2G',
  CELLULAR_3G: 'CELLULAR_3G',
  CELLULAR_4G: 'CELLULAR_4G',
  CELLULAR_5G: 'CELLULAR_5G',
  UNKNOWN: 'UNKNOWN',
};

function makeNetworkTypeValueBox(apn, networkType) {
  let icon;
  if (networkType === NetworkType.WIFI) {
    icon = <IconWifi />;
  } else if (networkType === NetworkType.CELLULAR_2G) {
    icon = <Icon2G />;
  } else if (networkType === NetworkType.CELLULAR_3G) {
    icon = <Icon3G />;
  } else if (networkType === NetworkType.CELLULAR_4G) {
    icon = <Icon4G />;
  } else if (networkType === NetworkType.CELLULAR_5G) {
    icon = <Icon5G />;
  }
  return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
    {/* { icon && <img src={icon} alt={networkType} style={{ transform: 'translateY(-1px)' }}/> } */}
    { icon && icon }
    <div>{apn || '-'}</div>
  </div>;
}

/**
 * 功能：issue基础数据
 * 使用页面：issue详情-跟踪数据-基础数据
 */
const DataTable = ({
  appId,
  issue, style, pid, exceptionType, getHasSpecifiedPermission,
  shareId,
  shareLinkEquivalentUserPermissions,
}) => {
  const { t } = useTranslation();

  const isFromShareLink = !!shareId;

  function hasViewPersonalDataPermission() {
    return getHasSpecifiedPermission('VIEW_PERSONAL_DATA')
      || (isFromShareLink && (shareLinkEquivalentUserPermissions || []).includes('VIEW_PERSONAL_DATA'));
  }

  const attachAllKvKeyWithTypeToValue = useMemo(() => {
    const attachAllKvList = issue.get('attachAllKvList') || [];
    return Object.assign({}, ...attachAllKvList.map(x => ({ [x.key]: x.value })));
  }, [issue]);

  // 进程使用内存数据获取
  function getIosMemoryFootprint() {
    const { attachList } = issue.toJS() || {};
    if (!attachList) {
      return '-';
    }
    let storedData = attachList.find((item) => item.fileName === "meminfo.txt");
    if (!storedData) {
      return '-';
    }
    let data = storedData.content.match(/footprint memory bytes : (\d+)/g);
    if (!data) {
      return '-';
    }
    return `${formatBytes(Number(data[0].match(/\d+/g)),2)}`;
  }

  function getVpnStatus() {
    const v = attachAllKvKeyWithTypeToValue.vpn;
    if (!v) {
      return '-';
    }
    return v === 'true' ? ze('是', 'On') : ze('否', 'Off');
  }

  function getTotalStackBytes() {
    return Number(attachAllKvKeyWithTypeToValue.G04  || 0);
  }

  function getFreeStackBytes() {
    const total = getTotalStackBytes();
    const used = Number(attachAllKvKeyWithTypeToValue.G03 || 0);
    return total - used;
  }

  function getPssBytes() {
    // kv里面pss的单位是KB
    return Number(attachAllKvKeyWithTypeToValue.pss || 0) * 1024;
  }

  const { crashDoc, issueExceptionType } = issue.toJS();
  if (!crashDoc) {
    return <div></div>;
  }
  const esMap = crashDoc.esMap || {};
  const crashMap = crashDoc.crashMap || {};
  const iosMemoryFootprint = getIosMemoryFootprint();

  const {
    freeMem,
    memSize,
    freeStorage,
    diskSize,
    freeSdCard,
    totalSD,
  } = crashMap;
  const {
    freeGpuMem,
    totalGpuMem,
    freeProcessMem,
    totalProcessMem,
    errno,
  } = esMap;

  const memoryPercentage = getRate(freeMem, memSize);
  const memoryAvailableText = freeMem >= 0
    ? `${transformMemData(freeMem)} ${ze('可用', 'Free')} / ${transformMemData(memSize)}`
    : '-';

  const processMemoryPercentage = getRate(freeProcessMem, totalProcessMem);
  const processMemoryAvailableText = freeProcessMem >= 0
    ? `${transformMemData(freeProcessMem)} ${ze('可用', 'Free')} / ${transformMemData(totalProcessMem)}`
    : '-';

  const gpuMemoryPercentage = getRate(freeGpuMem, totalGpuMem);
  const gpuMemoryAvailableText = freeGpuMem >= 0
    ? `${transformMemData(freeGpuMem)} ${ze('可用', 'Free')} / ${transformMemData(totalGpuMem)}`
    : '-';

  const storagePercentage = getRate(freeStorage, diskSize);
  const storageAvailableText = freeStorage >= 0
    ? `${transformMemData(freeStorage)} ${ze('可用', 'Free')} / ${transformMemData(diskSize)}`
    : '-';

  const sdPercentage = getRate(freeSdCard, totalSD);
  const sdAvailableText = freeSdCard >= 0
    ? `${transformMemData(freeSdCard)} ${ze('可用', 'Free')} / ${transformMemData(totalSD)}`
    : '-';

  const freeStackBytes = getFreeStackBytes();
  const totalStackBytes = getTotalStackBytes();
  const hasStackBytes = totalStackBytes > 0;
  const stackPercentage = getRate(freeStackBytes, totalStackBytes);
  const stackAvailableText = hasStackBytes
    ? `${transformMemData(freeStackBytes)} / ${transformMemData(totalStackBytes)}`
    : '-';

  const romNameText = crashDoc.detailMap.romName
    ? decodeURIComponent(crashDoc.detailMap.romName)
    : t("TRACKDATATAB.noObtain");

  const pssBytes = getPssBytes();
  const pssText = pssBytes > 0
    ? formatBytes(pssBytes)
    : '';

  const isAnr = ExceptionCategoryUtil.isAnr(exceptionType);

  const isUnixLike = isAndroid(pid) || isLinux(pid, appId) || isHarmony(pid);

  const items = [PlatformUtil.isMobileOrMobileLike(pid) && {
    key: t("TRACKDATATAB.netAPN"),
    value: makeNetworkTypeValueBox(crashDoc.crashMap.apn, crashDoc.crashMap.networkType),
  }, PlatformUtil.isMobileOrMobileLike(pid) && {
    key: isAndroid(pid) ? t("TRACKDATATAB.isRoot") : t("TRACKDATATAB.isJailbreak"),
    value: String(crashDoc.crashMap.isRooted) === 'true' ? t("TRACKDATATAB.yes") : t("TRACKDATATAB.no"),
  }, (PlatformUtil.isMobileOrMobileLike(pid) || isPc(pid,appId)) && {
    key: t("TRACKDATATAB.memoryAvaible"),
    value: makeValueBoxWithPercentageBar(memoryAvailableText, memoryPercentage),
    isPercentage: true,
  }, totalProcessMem > 0 && {
    key: t("TRACKDATATAB.processMemoryAvailable"),
    value: makeValueBoxWithPercentageBar(processMemoryAvailableText, processMemoryPercentage),
    isPercentage: true,
  }, isPc(pid,appId) && totalGpuMem > 0 && {
    key: t("TRACKDATATAB.GPU剩余可用内存大小"),
    value: makeValueBoxWithPercentageBar(gpuMemoryAvailableText, gpuMemoryPercentage),
    isPercentage: true,
  }, PlatformUtil.isMobileOrMobileLike(pid) && {
    key: t("TRACKDATATAB.storageAvaible"),
    value: makeValueBoxWithPercentageBar(storageAvailableText, storagePercentage),
    isPercentage: true,
  }, PlatformUtil.isIosOrMac(pid) && {
    key: t("TRACKDATATAB.进程使用内存"),
    value: iosMemoryFootprint,
  }, isAndroid(pid) && {
    key: t("TRACKDATATAB.sdAvaible"),
    value: makeValueBoxWithPercentageBar(sdAvailableText, sdPercentage),
    isPercentage: true,
  }, hasStackBytes && {
    key: t('TRACKDATATAB.stackAvailable'),
    value: makeValueBoxWithPercentageBar(stackAvailableText, stackPercentage),
    isPercentage: true,
  },{
    key: t("TRACKDATATAB.sdkVersion"),
    value: crashDoc.crashMap.sdkVersion,
  }, isAndroidOrHarmony(pid) && {
    key: t("TRACKDATATAB.romDetail"),
    value: romNameText,
  }, isUnixLike && issueExceptionType === EXCEPTION_TYPE_INT_ANDROID_NATIVE && {
    key: 'Errno',
    value: isNotNullish(errno) ? `${errno} (${ErrnoUtil.getDesc(errno)})` : '-',
  }, isMobile(pid) && pssText && {
    key: 'PSS',
    value: pssText,
  }, PlatformUtil.isMobileOrMobileLike(pid) && {
    key: ze('是否使用VPN', 'VPN'),
    value: getVpnStatus(),
  }].filter(x => x);

  const nonPercentageItems = items.filter(x => !x.isPercentage);
  const percentageItems = items.filter(x => x.isPercentage);
  const minLength = Math.min(nonPercentageItems.length, percentageItems.length);

  let finalItems = [];
  for (let i = 0; i < minLength; i++) {
    finalItems.push(nonPercentageItems[i]);
    finalItems.push(percentageItems[i]);
  }
  if (nonPercentageItems.length > minLength) {
    finalItems.push(...nonPercentageItems.slice(minLength));
  } else if (percentageItems.length > minLength) {
    finalItems.push(...percentageItems.slice(minLength));
  }

  const itemCols = finalItems.map((item, i) => <React.Fragment key={i}>
    <Col
      span={4}
      className={scss.keyBox}
    >{ item.key }</Col>
    <Col
      span={8}
      className={scss.valueBox}
    >{ item.value }</Col>
  </React.Fragment>);

  return <div className={scss.dataTable}>
    <Row
      className={scss.kvContainer}
    >{ itemCols }</Row>
  </div>;
};

DataTable.propTypes = {
  issue: PropTypes.object,
  style: PropTypes.object,
  pid: PropTypes.string.isRequired,
  exceptionType: PropTypes.string,
  getHasSpecifiedPermission: PropTypes.func,
  shareId: PropTypes.string,  // 分享链接的id，如果不为空表示是通过分享链接查看的
  shareLinkEquivalentUserPermissions: PropTypes.array, // 分享链接的相关查看权限
};

const mapStateToProps = state => ({
  getHasSpecifiedPermission: (permission) => selectHasSpecifiedPermissionInCurrentApp(state, permission),
});

export default connect(mapStateToProps)(DataTable);
