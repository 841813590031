import { createAction } from 'redux-actions';
import IssueRest from 'utils/IssueRest';
import CrashRest from 'utils/CrashRest';
import { EXCEPTION_TYPE_LIST, IssueListTrendTypeEnum, IssueTrendGranularityUnit } from 'utils/constants';
import { RESET_ISSUE_SEARCH_PARAMS, CHANGE_EXCEPTION_TYPE_OPTIONS, CHANGE_ISSUE_LIST_STATUS_CODE } from 'store/actions';
import moment from 'moment';
import { IssueTrendOptions, IssueTrendUtil } from 'components/exception/issue/IssueTrend';
import RestHelper from 'utils/RestHelper';
import { makeDetailObjectFromStackLine } from 'utils/helper';
import uniq from 'lodash/array/uniq';
import { CountryUtil } from 'utils/country';
import { selectHasSpecifiedPermissionInCurrentApp } from 'utils/selectors/selectors';
import { RolePermission } from 'utils/constants/role-permission';
import { ExceptionCategory, ExceptionCategoryUtil } from 'utils/exception-category';
import { ReduxActionType } from 'reducers/redux-action-type';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { IssueAggregateType } from 'utils/constants/issue-aggregate-type';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';


/**
 * 根据参数生成格式一样的action
 *
 *
 */
function generateAction(rest) {
  return (issueId, crashDataType) => {
    return (dispatch, getState) => {
      const currentApp = getState().app.get('current');
      return dispatch({
        rest,
        data: {
          appId: currentApp.get('appId'),
          pid: currentApp.get('pid'),
          issueId,
          crashDataType,
        },
      });
    };
  };
}

export const changeIssueListStatusCode = createAction(CHANGE_ISSUE_LIST_STATUS_CODE);

/**
 * 改变问题类型搜索条件
 */
export const changeExceptionTypeOptions = createAction(CHANGE_EXCEPTION_TYPE_OPTIONS);

/**
 * 出错堆栈 =》 关键线程
 *
 */

/**
 * 历史备注记录 /noteList/appId/{appId}/platformId/{pid}/issueId/{issueId}
 * get
 */
export const getNoteList = generateAction(IssueRest.noteList.get);


export const toggleCrashDetail = createAction('toggle_crash_detail');


// type ： TEST_NODE_API
export function testNodeApi(id) {
  return (dispatch) => {
    return dispatch({
      rest: IssueRest.lastCrashDoc.testNodeApi,
      data: { id },
    });
  };
}

/**
 * 改变issue趋势tag
 * type: CHANGE_ISSUE_TREND_TAG
 */
export function changeIssueTrendTag(tagName) {
  return (dispatch) => {
    return dispatch({
      type: 'CHANGE_ISSUE_TREND_TAG',
      tagName,
    });
  };
}

export function getCrashDoc(issueId, crashId) {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const rsp = !!crashId
      ? await RestHelper.get(`/crashDoc/appId/${appId}/platformId/${platformId}/crashHash/${crashId}`)
      : await RestHelper.mustPost('/api/crash/fetchLastCrashDocInIssue', { appId, platformId, issueId });
    return dispatch({
      type: 'saveCrashDocToReduxState',
      response: !!crashId ? rsp.json.ret : rsp.json.data,
      params: { pid: platformId },
    });
  };
}

export function getCrashDocFromShareLink(appId, platformId, shareId) {
  return async (dispatch, getState) => {
    const rsp = await RestHelper.mustPost(`/api/crash/getCrashDocFromShareLink`, { shareId });
    return dispatch({
      type: 'saveCrashDocToReduxState',
      response: rsp.json.ret,
      params: { pid: platformId },
    });
  };
}

export const resetCrashRelatedInfoInCurrentIssue = createAction('resetCrashRelatedInfoInCurrentIssue');

export const updateReduxIssueSearchParams = createAction('updateReduxIssueSearchParams');

export function updateReduxIssueState(data) {
  return (dispatch) => {
    return dispatch({
      type: 'updateReduxIssueState',
      data,
    });
  };
}

/**
 * 获取系统日志和自定义日志，获取其它线程的一些crash信息
 * type:GET_CRASH_ATTACHMENT_SUCC
 * '/appDetailCrash/appId/{appId}/platformId/{pid}/crashHash/{crashHash}'
 */
export function getCrashAttachment(issueId, crashHash) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: IssueRest.crashAttachment.getCrashAttachment,
      data: { appId, pid, crashHash },
    });
  };
}

export function getCrashAttachmentFromShareLink(appId, pid, shareId) {
  return (dispatch, getState) => {
    return dispatch({
      rest: IssueRest.crashAttachment.getCrashAttachmentFromShareLink,
      data: { appId, pid, shareId },
    });
  };
}


/** by joanaxu
 * 重还原功能，获取新的还原数据信息，意义和getCrashAttachment是一样的
 * type:RELOAD_CRASH_ATTCHMENT_SUCC
 */
export function reloadCrashAttachment(issueId, crashHash) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: IssueRest.crashAttachment.reloadCrashAttachment,
      data: { appId, pid, crashHash },
    });
  };
}

/**
 * 上传备注 /addIssueNote
 * appId  string  appId
 * platformId  int  platformId
 * issueId  long  issueId
 * note  string  备注
 * userId  string  创建人
 * issueStatus string issue状态 0:未处理,1:已处理,2:处理中
 */
export function addIssueNote(issueId, note, issueStatus) {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    const userId = getState().user.get('current').get('userId');
    const newUserId = getState().user.get('current').get('newUserId');
    return dispatch({
      rest: IssueRest.noteList.add,
      data: {
        appId: currentApp.get('appId'),
        platformId: currentApp.get('pid'),
        issueStatus, //
        issueIds: issueId,
        note,
        userId,
        newUserId,
      },
    });
  };
}

/**
 * 获取短链接的url
 * @param issueId
 * @param category
 * @returns {Function}
 * 1.0 遗留接口，已经不再使用
 */
export function getShortUrl(issueId, category) {
  return (dispatch, getState) => {
    const currentApp = getState().app.get('current');
    return dispatch({
      rest: IssueRest.shortUrl.get,
      data: {
        appId: currentApp.get('appId'),
        pid: currentApp.get('pid'),
        issueId,
        category,
      },
    });
  };
}


/**
 * 添加tag
 * @param appId
 * @param platformId
 * @param issueId
 * @param tagName
 * @param path
 * @returns {Function}
 */
export function addTag(issueId, tagName, path) {
  return (dispatch, getState) => {
    const current = getState().app.get('current');
    return dispatch({
      rest: IssueRest.tag.add,
      data: {
        appId: current.get('appId'),
        platformId: current.get('pid'),
        issueId,
        tagName,
        path,
      },
    });
  };
}


/**
 * 删除tag
 * @param appId
 * @param platformId
 * @param issueId
 * @param tagName
 * @param path
 * @returns {Function}
 */
export function delTag(issueId, tagId, path) {
  return (dispatch, getState) => {
    const current = getState().app.get('current');
    return dispatch({
      rest: IssueRest.tag.del,
      data: {
        appId: current.get('appId'),
        pid: current.get('pid'),
        issueId,
        tagId,
        path,
      },
    });
  };
}
/**
 * 切换日志类型
 *
 */
export function changeLog(logType, issueId) {
  return (dispatch) => {
    return dispatch({
      type: 'CHANGE_LOG',
      logType,
      issueId,
    });
  };
}

/**
 * 切换日志level
 *
 */
export function changeLevel(level, issueId) {
  return (dispatch) => {
    return dispatch({
      type: 'CHANGE_LEVEL',
      level,
      issueId,
    });
  };
}

/**
 * 切换IDE格式checkbox
 *
 */
export function clickCheckbox(checked, issueId) {
  return (dispatch) => {
    return dispatch({
      type: 'CLICK_CHECKBOX',
      checked,
      issueId,
    });
  };
}

/**
 * 切换IDE格式checkbox
 *
 */
export function changeLogKey(value, issueId) {
  return (dispatch) => {
    return dispatch({
      type: 'CHANGE_KEY',
      value,
      issueId,
    });
  };
}

/**
 * 改变issue趋势的版本
 */
export function changeIssueTrendVersion(version) {
  return {
    type: 'CHANGE_ISSUE_TREND_VERSION',
    version,
  };
}

/**
 * 改变issue趋势的国家
 */
export function changeIssueTrendCountryList(countryList) {
  return {
    type: 'CHANGE_ISSUE_TREND_COUNTRY_LIST',
    countryList,
  };
}

/** 修改issue的状态
 * /updateIssueStatus/appId/{appId}/platformId/{platformId}
 * /issueIds/{issueIds}/status/{status}/processors/{processors}
 *
 * CHANGE_STATE_SUCC
 * * */

export function changeIssueState(issueIds, status, processors, note, checkboxGroup, assigneeWithoutLocalUserIdList) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    const page = getState().global.get('popAlertData').get('page');

    return dispatch({
      rest: IssueRest.issue.changeState,
      data: {
        appId,
        issueIds,
        status,
        processors,
        assigneeWithoutLocalUserIdList,
        note,
        platformId: pid,
        page,
        notifySubscriber:checkboxGroup.includes('notifySubscriber'),
        notifyProcessor:checkboxGroup.includes('notifyProcessor'),
      },
    });
  };
}

/**
 *
 * @param currentApp
 * @returns {Function}
 * GETSELECTOR_GET_SUCC
 */
export function getOptions(types) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: IssueRest.getSelector.get,
      data: { appId, platformId: pid, types },
    });
  };
}

/**
 * 获取issue趋势数据
 * type GETISSUETREND_GET_SUCC
 * @returns {Function}
 */
export function getIssueTrend(patchQueryParams= {}) {
  return async (dispatch, getState) => {
    const hasPermission = selectHasSpecifiedPermissionInCurrentApp(getState(), RolePermission.VIEW_DASHBOARD);
    if (!hasPermission) {
      console.warn('User has no permission to call queryIssueTrend!');
      return;
    }

    const { appId, platformId } = getState().app.get('current').toJS();
    let {
      issueTrendVersion,
      issueTrendTag,
      issueId,
      issueTrendCountryList,
      issueTrendSubModuleId: subModuleId,
      issueExceptionType,
    } = getState().issue.get('current').toJS();
    issueTrendTag = issueTrendTag || IssueTrendOptions.find(x => x.defaultOption).value;
    const exceptionCategory = ExceptionCategoryUtil.fromExceptionTypeInt(issueExceptionType);
    const queryJank = ExceptionCategoryUtil.isJank(exceptionCategory) && !!patchQueryParams?.jankKeyName;
    // 初始加载数据，issueTrendVersion还没设定，默认拉取父issue的数据，就是全版本数据
    const version = issueTrendVersion || '-1';

    const issueTrendOption = IssueTrendOptions.find(x => x.value === issueTrendTag);
    const { granularityUnit, granularityMagnitude, intervalUnit, intervalMagnitude } = issueTrendOption;
    const minMaxMomentPair = IssueTrendUtil.makeMinMaxMomentPair(issueTrendOption);
    if (!minMaxMomentPair) {
      console.error('action.getIssueTrend: failed to makeMinMaxMomentPair');
      return;
    }
    const [minMoment, maxMoment] = minMaxMomentPair;
    // 获取国家选择数据，根据国家组合进行筛选
    const countryGroupConfigList = getState().summary.get('countryGroupConfigList').toJS();
    const prefixedIdToCountries = Object.assign({}, ...countryGroupConfigList.map(x => ({ [CountryUtil.COUNTRY_GROUP_PREFIX + x.id]: x.countries })));
    const countryList = issueTrendCountryList ? issueTrendCountryList:[];
    const queryCountryList = uniq([
      ...countryList,
      ...countryList.map(x => prefixedIdToCountries[x] || [x]).flat(),
    ]);
    let countryListParam = {};
    if (queryCountryList.length > 0) {
      countryListParam = {
        countryList: queryCountryList,
      }
    }

    const rsp = await RestHelper.post('/redir/api/statResource/queryIssueTrend', {
      appId,
      platformId,
      queryJank,
      issueIds: [issueId],
      granularityUnit,
      granularityMagnitude,
      minDate: minMoment.format('YYYY-MM-DD HH:mm:ss'),
      maxDate: maxMoment.format('YYYY-MM-DD HH:mm:ss'),
      version,
      subModuleId,
      ...patchQueryParams,
      ...countryListParam,
    });

    const {
      trendList,
      jankTrendList,
    } = rsp.json.data[0] || {};
    return dispatch({ type: 'setIssueTrendData', payload: { trendList, jankTrendList } });
  };
}

export function fetchIssueTrendAccessDeviceTrend() {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    let { issueTrendVersion, issueTrendTag, issueId, issueTrendCountryList, issueTrendSubModuleId: subModuleId } = getState().issue.get('current').toJS();
    issueTrendTag = issueTrendTag || IssueTrendOptions.find(x => x.defaultOption).value;
    const version = issueTrendVersion || '-1';

    const issueTrendOption = IssueTrendOptions.find(x => x.value === issueTrendTag);
    const { granularityUnit, granularityMagnitude, intervalUnit, intervalMagnitude } = issueTrendOption;
    const minMaxMomentPair = IssueTrendUtil.makeMinMaxMomentPair(issueTrendOption);
    if (!minMaxMomentPair) {
      console.error('fetchIssueTrendAccessDeviceTrend: failed to makeMinMaxMomentPair');
      return;
    }
    const [minMoment, maxMoment] = minMaxMomentPair;

    // 获取国家选择数据，根据国家组合进行筛选
    const countryGroupConfigList = getState().summary.get('countryGroupConfigList').toJS();
    const prefixedIdToCountries = Object.assign({}, ...countryGroupConfigList.map(x => ({ [CountryUtil.COUNTRY_GROUP_PREFIX + x.id]: x.countries })));
    const countryList = issueTrendCountryList ? issueTrendCountryList:[];
    const queryCountryList = uniq([
      ...countryList,
      ...countryList.map(x => prefixedIdToCountries[x] || [x]).flat(),
    ]);
    let countryListParam = {};
    if (queryCountryList.length > 0) {
      countryListParam = {
        countryList: queryCountryList,
        needCountryDimension: true,
        mergeCountries: true,
      }
    }

    if (granularityUnit === IssueTrendGranularityUnit.DAY) {
      const rsp = await RestHelper.mustPost('/api/app/getTrend', {
        ...{
          appId,
          platformId,
          startDate: minMoment.format('YYYYMMDD'),
          endDate: maxMoment.format('YYYYMMDD'),
          version,
          subModuleId,
          type: 'crash', // 只拿联网数据，type不影响
        },
        ...countryListParam
      });
      return dispatch({ type: 'setIssueTrendAccessDeviceTrendData', payload: rsp.json.ret.data });
    } else if (granularityUnit === IssueTrendGranularityUnit.HOUR) {
      const rsp = await RestHelper.mustPost('/api/app/getRealTimeHourlyStat', {
        ...{
          appId,
          platformId,
          startDate: minMoment.format('YYYYMMDDHH'),
          endDate: maxMoment.format('YYYYMMDDHH'),
          version,
          subModuleId,
          type: 'crash', // 只拿联网数据，type不影响
        },
        ...countryListParam
      });
      return dispatch({ type: 'setIssueTrendAccessDeviceTrendData', payload: rsp.json.ret.data });
    } else { // 分钟级不支持
      return dispatch({ type: 'setIssueTrendAccessDeviceTrendData', payload: [] });
    }
  };
}


/**
 * 获取问题列表
 */
export function getIssueList({isDownload, numDownloads}={}) {
  return async (dispatch, getState) => {
    const { issue, app } = getState();
    const { appId, pid, teamType } = app.get('current').toJS();
    const params = issue.get('searchParams').filter((value) => !!value && value !== 'all').toJS();
    if (params.version) {
      params.version = decodeURIComponent(params.version);
    }
    const versionList = (params.version || '').split(';').filter(x => x && x !== 'all');
    if (params.processor) {
      params.processor = decodeURIComponent(params.processor);
    }
    delete params.searchType;
    if (params.search) {
      const searchType = /^[0-9A-F]{32}$/i.test(params.search)
        ? versionList.length > 0 ? 'crossIssueHash' : 'issueId'
        : 'errorType';
      params[searchType] = params.search;
      delete params.search;
    }
    if (params.exceptionTypeList) {
      delete params.exceptionCategoryList;
    }
    const postData = Object.assign(params, {
      appId,
      platformId: pid,
      teamType,
      skipQueryHbase: true,
      fetchLatestBugInfo: isDownload ? true : undefined,
      rows: isDownload ? numDownloads : getState().global.get('rows'),
    });
    const rsp = await RestHelper.mustPost('/api/issue/queryIssueList', postData);
    dispatch({
      type: isDownload ? ReduxActionType.setQueryIssueListDownloadResult : ReduxActionType.setQueryIssueListResult,
      response: rsp.json.ret,
      params: postData,
    });

    // 上报queryIssueList耗时打点
    const apiTimingInfo = rsp.json.ret?.apiTimingInfo;
    if (apiTimingInfo) {
      reportEvent({
        action: EVENT_ACTIONS.CLICK,
        tp1: 'queryIssueListTimeCost',
        tp6: JSON.stringify(apiTimingInfo),
      });
    }

    // 王者卡顿定制需求，额外拉取问题的自定义kv：TopForm分布情况
    const jankIssueIdList = (rsp.json.ret?.issueList || [])
      .filter(x => ExceptionCategoryUtil.fromExceptionTypeInt(x.issueExceptionType) === ExceptionCategory.JANK)
      .map(x => x.issueId);
    if (jankIssueIdList.length > 0) {
      const rsp = await RestHelper.mustPost('/api/issue/queryIssueAggregate', {
        appId,
        searchConditionGroup: {
          conditions: [
            {
              field: FieldName.issueId,
              queryType: QueryType.TERMS,
              terms: jankIssueIdList,
            },
            versionList.length > 0 && {
              field: FieldName.version,
              queryType: QueryType.TERMS_WILDCARD,
              terms: versionList,
            },
          ].filter(x => x),
        },
        customFields: [{
          aggregateType: IssueAggregateType.MULTI_TERMS,
          name: 'crashIssueId',
          subTerm: 'hashKeyValues.dgfGbgRrbDOPvvCPX00J2A==.originValue', // C03_K#TopForm
          termsSizeLimit: 100,
        }],
      });
      dispatch({ type: ReduxActionType.parseAggResultAndSetIssueIdToWangZheTopFormList, payload: rsp });
    }
  };
}

export function setIssueList(issueList) {
  return (dispatch) => {
    return dispatch({
      type: 'SET_ISSUE_LIST',
      issueList,
    });
  };
}

export function patchCurrentIssueInfo(partialCurrentIssueInfo) {
  return (dispatch) => {
    return dispatch({
      type: 'patchCurrentIssueInfo',
      payload: partialCurrentIssueInfo,
    });
  };
}

export function fetchMultipleIssueTrend(issueIds, trendType) {
  return async (dispatch, getState) => {
    const hasPermission = selectHasSpecifiedPermissionInCurrentApp(getState(), RolePermission.VIEW_DASHBOARD);
    if (!hasPermission) {
      console.warn('User has no permission to call queryIssueTrend!');
      return;
    }

    const { appId, platformId } = getState().app.get('current').toJS();
    let issueListTrendType = null;
    if(trendType){
      issueListTrendType = trendType;
    } else {
      const localStorageState = getState().global.get('localStorage').toJS();
      issueListTrendType = localStorageState.issueListTrendType
    }
    if (!issueListTrendType || issueListTrendType === IssueListTrendTypeEnum.NONE) {
      return;
    }
    if (!issueIds || issueIds.length === 0) {
      return;
    }

    // 3分钟缓存，最近3分钟查过trend的issue跳过
    const CACHE_LIFETIME_MILLIS = 3 * 60 * 1000;
    const {
      issueIdToHourlyTrendListLastFetchMillis,
      issueIdToDailyTrendListLastFetchMillis,
    } = getState().issue.toJS();

    let nowMillis = +new Date();
    issueIds = issueIds.filter(x => {
      let lastFetchMillis = 0;
      if (issueListTrendType === IssueListTrendTypeEnum.HOURLY) {
        lastFetchMillis = issueIdToHourlyTrendListLastFetchMillis[x] || 0;
      } else if (issueListTrendType === IssueListTrendTypeEnum.DAILY) {
        lastFetchMillis = issueIdToDailyTrendListLastFetchMillis[x] || 0;
      }
      return nowMillis - lastFetchMillis > CACHE_LIFETIME_MILLIS;
    });
    if (!issueIds || issueIds.length === 0) {
      return;
    }

    dispatch({ type: 'QUERY_ISSUE_TREND_START' });
    let minDate, maxDate, granularityUnit;
    if (issueListTrendType === IssueListTrendTypeEnum.HOURLY) {
      maxDate = moment().startOf('hour').subtract(0, 'hour').format('YYYY-MM-DD HH:mm:ss');
      minDate = moment().startOf('hour').subtract(23, 'hour').format('YYYY-MM-DD HH:mm:ss');
      granularityUnit = 'HOUR';
    } else if (issueListTrendType === IssueListTrendTypeEnum.DAILY) {
      maxDate = moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
      minDate = moment().startOf('day').subtract(14, 'day').format('YYYY-MM-DD HH:mm:ss');
      granularityUnit = 'DAY';
    }

    const rsp = await RestHelper.post('/redir/api/statResource/queryIssueTrend', {
      appId,
      platformId,
      issueIds,
      granularityUnit,
      minDate,
      maxDate,
      version: '-1'
    });
    const issueIdToTrendList = Object.assign(
      Object.create(null),
      ...rsp.json.data.map(x => {
        const { issueId, trendList } = x;
        return { [issueId]: trendList };
      }));
    nowMillis = +new Date();
    const issueIdsToLastFetchMillis = Object.assign({}, ...issueIds.map(x => ({ [x]: nowMillis })));
    if (issueListTrendType === IssueListTrendTypeEnum.HOURLY) {
      dispatch({type: 'patchIssueIdToHourlyTrendList', payload: issueIdToTrendList});
      dispatch({type: 'patchIssueIdToHourlyTrendListLastFetchMillis', payload: issueIdsToLastFetchMillis});
    } else if (issueListTrendType === IssueListTrendTypeEnum.DAILY) {
      dispatch({type: 'patchIssueIdToDailyTrendList', payload: issueIdToTrendList});
      dispatch({type: 'patchIssueIdToDailyTrendListLastFetchMillis', payload: issueIdsToLastFetchMillis});
    }
  }
}

/**
 * 获取issue信息
 */
export function getIssueInfo(issueId) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: IssueRest.issueInfo.getIssueInfo,
      data: {
        appId,
        pid,
        issueId,
      },
    });
  };
}

export function getIssueInfoFromShareLink(shareId) {
  return (dispatch, getState) => {
    return dispatch({
      rest: IssueRest.issueInfo.getIssueInfoFromShareLink,
      data: { shareId },
    });
  };
}

export function fetchCurrentCrashMinidumpStack(shareId) {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const { crashDoc, minidumpStackInfo: oldMinidumpStackInfo } = getState().issue.get('current').toJS();
    const { esMap, crashMap } = crashDoc;
    const { crashId } = crashMap;
    const { crashMinidumpStackCosKey } = esMap;
    const cosKey = `${crashMinidumpStackCosKey}.dmp.gz`;

    if ((oldMinidumpStackInfo || {}).crashId === crashId) { // 已经获取过了，跳过
      return;
    }

    let minidumpStackInfo = {
      crashId,
      isFetchingMinidumpStack: true,
      hasMinidumpStack: false,
      minidumpStackText: '',
      minidumpOtherThreadInfoList: [],
    };
    dispatch({
      type: 'patchCurrentIssueInfo',
      payload: { minidumpStackInfo },
    });

    const data = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId,
      shareId,
      cosKey,
      compressFormat: 'GZIP',
    });
    const { hasFile, contentBase64 } = data.json.data;
    let minidumpStackText = '';
    let minidumpOtherThreadInfoList = [];
    if (hasFile) {
      const uint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      const allThreadsStackText = new TextDecoder().decode(uint8Array) || '';
      const allLines = allThreadsStackText.split('\n');

      let threadNameToThreadLines = {};
      let threadNameList = [];
      let currentThreadName = '';
      let currentThreadLines = [];
      allLines.forEach(line => {
        const threadNameMatchResult = line.trim().match(/^Thread\(.*/);
        if (threadNameMatchResult) {
          if (currentThreadName) {
            threadNameToThreadLines[currentThreadName] = currentThreadLines;
          }
          currentThreadName = threadNameMatchResult[0];
          threadNameList.push(currentThreadName);
          currentThreadLines = [];
        } else {
          currentThreadLines.push(line);
        }
      });
      if (currentThreadName) { // 添加最后一个线程
        threadNameToThreadLines[currentThreadName] = currentThreadLines;
      }

      const mainThreadName = threadNameList.find(x => x.includes('crash')) || threadNameList[0] || '';
      if (mainThreadName) {
        minidumpStackText = (threadNameToThreadLines[mainThreadName] || []).join('\n');
      }
      const otherThreadNames = threadNameList.filter(x => x !== mainThreadName);
      minidumpOtherThreadInfoList = otherThreadNames.map(threadName => {
        const content = (threadNameToThreadLines[threadName] || []).join('\n');
        return { // 这个数据结构和redux的otherThread对齐
          fileName: threadName,
          content,
          fileType: 2,
        }
      });
    }

    minidumpStackInfo = {
      crashId,
      isFetchingMinidumpStack: false,
      hasMinidumpStack: hasFile,
      minidumpStackText,
      minidumpOtherThreadInfoList,
    };

    return dispatch({
      type: 'patchCurrentIssueInfo',
      payload: { minidumpStackInfo },
    });
  };
}

export function fetchCurrentCrashRetracedAnrTrace(shareId) {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const { crashDoc } = getState().issue.get('current').toJS();
    const { esMap } = crashDoc;
    const { anrTraceCosKey } = esMap;

    if (!anrTraceCosKey) {
      return;
    }
    const rsp = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId,
      shareId,
      cosKey: anrTraceCosKey,
      compressFormat: 'GZIP',
    });
    const { hasFile, contentBase64 } = rsp.json.data;
    if (hasFile) {
      const uint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      const text = new TextDecoder().decode(uint8Array);
      return dispatch({
        type: 'patchCurrentIssueInfo',
        payload: { retracedAnrTrace: text || '' },
      });
    } else {
      //
    }
  }
}

export function fetchCurrentCrashPcSoInfo(shareId) {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const { crashDoc, lastFetchedPcSoInfoCrashId } = getState().issue.get('current').toJS();
    const { esMap, crashMap } = crashDoc;
    const { crashId } = crashMap;
    const { moduleInfoCosKey } = esMap;

    if (lastFetchedPcSoInfoCrashId === crashId) { // 已经获取过了，跳过
      return;
    }
    if (!moduleInfoCosKey) {
      return;
    }
    const rsp = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId,
      shareId,
      cosKey: moduleInfoCosKey,
      compressFormat: 'GZIP',
    });
    const { hasFile, contentBase64 } = rsp.json.data;
    if (hasFile) {
      const uint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      const text = new TextDecoder().decode(uint8Array);
      return dispatch({
        type: 'PARSE_AND_SAVE_PC_MODULE_INFO',
        payload: {
          text,
          crashId,
        },
      });
    } else {
      //
    }
  }
}

export function fetchCurrentCrashPcRegisterInfo(shareId) {
  return async (dispatch, getState) => {
    const { appId, platformId } = getState().app.get('current').toJS();
    const { crashDoc } = getState().issue.get('current').toJS();
    const { esMap } = crashDoc;
    const { registerInfoCosKey } = esMap;

    if (!registerInfoCosKey) {
      return;
    }

    dispatch({
      type: 'patchCurrentIssueInfo',
      payload: { pcRegisterInfoFetching: true },
    });

    const rsp = await RestHelper.post('/api/crash/decompressInflateFile', {
      appId,
      platformId,
      shareId,
      cosKey: registerInfoCosKey,
      compressFormat: 'GZIP',
    });
    const { hasFile, contentBase64 } = rsp.json.data;
    if (hasFile) {
      const uint8Array = Uint8Array.from(window.atob(contentBase64), c => c.charCodeAt(0));
      const registerInfoText = new TextDecoder().decode(uint8Array);
      dispatch({
        type: 'PARSE_AND_SAVE_PC_REGISTER_INFO',
        payload: {
          registerInfoText,
        },
      });
    }
    dispatch({
      type: 'patchCurrentIssueInfo',
      payload: { pcRegisterInfoFetching: false },
    });
  }
}

/**
 * 获取问题列表搜索选项
 * @returns {Function}
 * ISSUEOPTIONS_GET_SUCC
 */
export function getIssueSearchOptions() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: IssueRest.issueOptions.get,
      data: { appId, platformId: pid },
    });
  };
}

/**
 * 选中/取消问题列表项
 * @param data
 * @returns {{action: string, data: {issueId: string, checked: boolean}}}
 */
export function checkIssue({
  issue, checked, multi, checkAll,
}) {
  return {
    type: 'CHECK_ISSUE', issue, checked, multi, checkAll,
  };
}

/**
 * 重置问题列表搜索条件
 */
export const resetIssueSearchParams = createAction(RESET_ISSUE_SEARCH_PARAMS);

/**
 * 获取解决方案
 */
export function getSolutionInfo(issueId, crashHash) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: CrashRest.getSolutionInfo.get,
      data: {
        appId,
        pid,
        issueId,
        crashHash,
      },
    });
  };
}

/**
 * 解决方案评分
 */
export function commitComment(tempId, caseId, star, comment) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: CrashRest.commitComment.post,
      data: {
        appId,
        pid,
        tempId,
        caseId,
        star,
        comment,
      },
    });
  };
}

/**
 * utest 真机列表
 * type : current, crash, list
 * 对应详情页 currentIssue中的数据 currentIssue.single，crash对应上报记录中，每一条crash记录中的数据，list 对应详情页中设备信息Tab中的设备列表数据，currentIssue.utest
 */
export function utestDevices(model, issueId, type) {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: CrashRest.utest.get,
      data: {
        appId,
        platformId: pid,
        model,
        issueId,
        type: type || '',
      },
    });
  };
}


export const showInitialTab = createAction('INITIAL_TAB');
export const initLogStatus = createAction('INIT_LOG_STATUS');
