import React from 'react';
import AppRest from 'utils/AppRest';
import { createAction } from 'redux-actions';
import {
  SELECT_APP,
  UPDATE_APP_INFO,
  UPDATE_CURRENT_APP,
  APPLY_ACTIVE_SUCCESS,
  APPLY_ACTIVE_INIT,
  UPDATE_APP_FAVORITE_BY_ID,
} from 'store/actions';
import { ReduxActionType } from 'reducers/redux-action-type';
import RestHelper from 'utils/RestHelper';
import { ApiErrorCode } from 'utils/api-error-code';
import { Modal } from 'antd';
import { ze } from 'utils/zhEn';
import { getAllInOneReduxState } from 'index';
import ClampDiv from 'components/commons/ClampDiv';
import { isNotNullish } from 'utils/nullish';

export function fetchReportDiscardStrategyForCurrentApp() {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId, platformId } = app.get('current').toJS();
    const rsp = await RestHelper.mustPost('/redir/api/appConfig/fetchReportDiscardStrategy', { appId, platformId });
    return dispatch({
      type: ReduxActionType.setReportDiscardStrategyByAppId,
      payload: { appId, reportDiscardStrategy: rsp.json.data },
    });
  }
}

export function fetchCustomKvConfigForCurrentApp({ skipIfFetched = false }) {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId, platformId } = app.get('current').toJS();
    if (skipIfFetched && isNotNullish(app.get('appIdToCustomKvConfig')[appId])) {
      return;
    }
    const rsp = await RestHelper.mustPost('/redir/api/appConfig/getCustomReportKeyConfig', { appId, platformId });
    return dispatch({
      type: ReduxActionType.setCustomKvConfigByAppId,
      payload: { appId, data: rsp.json.data },
    });
  }
}

export function actionFetchDashboardFiltersForCurrentApp() {
  return async (dispatch, getState) => {
    const { app } = getState();
    const { appId } = app.get('current').toJS();
    const rsp = await RestHelper.mustPost('/redir/api/dashboardFilter/listDashboardFilters', { appId });
    return dispatch({
      type: ReduxActionType.setDashboardFilterListByAppId,
      payload: { appId, data: rsp.json.data },
    });
  }
}

export function fetchWebAppSettings(appId) {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/api/app/getWebAppSettings',
      { appId },
      { silentErrorCodes: [ApiErrorCode.NoPermission] });
    const webAppSettings = rsp.json.data || {};
    return dispatch({
      type: 'updateWebAppSettingsByAppId',
      payload: { appId, webAppSettings },
    });
  };
}

export function upsertWebAppSettings(appId, settings) {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/api/app/upsertWebAppSettings', { appId, settings });
    const webAppSettings = rsp.json.data || {};
    return dispatch({
      type: 'updateWebAppSettingsByAppId',
      payload: { appId, webAppSettings },
    });
  }
}

export function fetchServerAppSettings(appId) {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/redir/api/appSettings/fetchAppSettings',
      { appId },
      { silentErrorCodes: [ApiErrorCode.NoPermission] });
    const serverAppSettings = rsp.json.data || {};
    return dispatch({
      type: 'updateServerAppSettingsByAppId',
      payload: { appId, serverAppSettings },
    });
  };
}

export function upsertServerAppSettings(appId, settings) {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/api/app/upsertAppSettings', { appId, settings });
    const serverAppSettings = rsp.json.data || {};
    return dispatch({
      type: 'updateServerAppSettingsByAppId',
      payload: { appId, serverAppSettings },
    });
  }
}

export function fetchTapdWorkspaceIdByApp(appId, platformId) {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/redir/query/data/queryTapdWorkspaceInfo',
      { appId, platformId },
      { silentErrorCodes: [ApiErrorCode.NoPermission] });
    const { workspaceId } = rsp.json.data || {};
    return dispatch({
      type: 'updateTapdWorkspaceIdByAppId',
      payload: { appId, workspaceId },
    });
  }
}

export function fetchBugTrackingInfoByApp(appId) {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/redir/query/data/getBugTrackingPlatform',
      { appId },
      { silentErrorCodes: [ApiErrorCode.NoPermission] });
    const data = rsp.json.data || {};
    return dispatch({
      type: 'updateBugTrackingInfoByAppId',
      payload: { appId, data },
    });
  }
}

export function updateInfoOnAppList() {
  return (dispatch, getState) => {
    const { product } = getState();
    const appInfo = product.get('app'); // 更新的app信息
    return dispatch({
      type: UPDATE_APP_INFO,
      payload: { appInfo },
    });
  };
}

export function sendMessage(product, appId, pid, applyer, reason) {
  return (dispatch) => {
    return dispatch({
      rest: AppRest.App.sendMessage,
      data: {
        product, appId, pid, applyer, reason,
      },
    });
  };
}

async function fetchAppInfo(appId) {
  const rsp = await RestHelper.post(`/api/app/getAppInfo`, { appId }, { silentErrorCodes: [ApiErrorCode.NoPermission] });
  return rsp.json.data;
}

/**
 * 对内部用户，如果提示没有项目权限，额外查询一下项目的名字和管理员rtx信息提示用户
 * */
async function checkIsTencentUserAndQueryAdminsForTencentApp(appId) {
  const reduxState = getAllInOneReduxState();
  const currentUser = reduxState.user.get('current').toJS();
  let { newUserId: localUserId, rtx } = currentUser;
  // 因为用户信息接口和项目列表是进入页面同时加载的，可能出现项目拉取完了用户信息还没拉到的情况，这种情况下额外查询一次用户信息
  if (!localUserId) {
    const rsp = await RestHelper.post('/api/user/getUserInfo');
    rtx = rsp.json.ret.rtx;
  }

  if (!rtx) {
    return {
      appName: '',
      rtxList: [],
    };
  }

  const rsp = await RestHelper.post('/redir/api/appConfig/queryAdminsForTencentApp', { appId });
  return rsp.json.data;
}

function fixPidInUrlAndRedirect(newPid) {
  const u = new URLSearchParams(window.location.search);
  const needAutoFix = u.get('pidAutoFixed') !== 'true'; // 保证只触发一次自动重定向
  if (needAutoFix) {
    u.set('pid', newPid);
    u.set('pidAutoFixed', 'true');
    window.location.search = `?${u.toString()}`; // 触发重定向
  } else {
    Modal.error({
      title: ze('无效的项目页面地址', 'Invalid Project URL'),
      okText: ze('返回我的项目', 'Go To My Projects'),
      onOk() {
        window.location.href = '/workbench/apps';
      },
    });
    throw Error('Invalid Project URL');
  }
}

/**
 * 选择App
 */
export function selectApp({ appId, pid }) {
  return async (dispatch, getState) => {
    const { app, router, auth } = getState();
    const appList = app.get('appList');
    const { location } = router;

    const selected = appList.find((appItem) => appItem && appItem.get('appId') === appId);
    const selectPid = selected && selected.size && selected.get('pid');

    // url上的pid参数和实际不一致，通过重定向自动修复url的pid参数。只触发一次自动修复，防止无限重定向
    if (selected && parseInt(selectPid) !== parseInt(pid)) {
      fixPidInUrlAndRedirect(selectPid);
    }

    if (selected) {
      return dispatch({
        type: SELECT_APP,
        payload: { appId, pid, location },
      });
    }

    try {
      const appInfo = await fetchAppInfo(appId);
      if (appInfo) {
        const pidFromApi = appInfo.platformId;
        if (pidFromApi !== parseInt(pid)) {
          fixPidInUrlAndRedirect(pidFromApi);
        }
        return dispatch({
          type: 'ADD_EXTRA_APP',
          payload: appInfo,
        });
      } else {
        Modal.error({
          width: '600px',
          title: ze('项目不存在', 'The project does not exist'),
          okText: ze('返回我的项目', 'Go To My Projects'),
          onOk() {
            window.location.href = '/workbench/apps';
          },
        });
      }
    } catch (e) {
      const { errorCode } = e;
      if (errorCode === ApiErrorCode.NoPermission) {
        const { appName, rtxList } = await checkIsTencentUserAndQueryAdminsForTencentApp(appId);
        const rtxListNotEmpty = (rtxList || []).length > 0;
        const warningContent = <div>
          { !appName && <div>{ ze('你不是此项目的成员。', 'You are not a member of this project.') }</div> }
          { appName && <div>{ ze(`你不是项目 ”${appName}“ 的成员。`, `You are not a member of this project: ${appName}.`) }</div> }
          { !rtxListNotEmpty && ze('请联系项目管理员邀请你进入项目。', 'Please contact the admins of the project to invite you into the project.') }
          { rtxListNotEmpty && <div>{ ze('请企业微信联系以下项目管理员邀请你进入项目：', 'Please contact the following admins of the project to invite you into the project.') }</div> }
          { rtxListNotEmpty && <ClampDiv lineClamp={3} showButton={true} style={{ marginTop: '12px' }}>{ rtxList.join(', ') }</ClampDiv> }
        </div>;

        Modal.warn({
          width: rtxListNotEmpty ? '600px' : undefined,
          title: ze('没有此项目的权限', 'No Permission To This Project'),
          content: warningContent,
          okText: ze('返回我的项目', 'Go To My Projects'),
          onOk() {
            window.location.href = '/workbench/apps';
          },
        });
      }
    }
  };
}

/**
 * 获取App列表
 * @returns {Function}
 */
export const actionGetAppList = ({ skipIfInitDone } = {}) => {
  return (dispatch, getState) => {
    const initDone = getState().app.get('init');
    if (initDone && skipIfInitDone) {
      return;
    }

    return dispatch({
      rest: AppRest.App.list,
    });
  };
};

export function actionFetchHasAdminPermissionAppList() {
  return async (dispatch) => {
    const rsp = await RestHelper.mustPost('/redir/api/appConfig/listHasAdminPermissionApps');
    const data = rsp.json.data;
    return dispatch({
      type: ReduxActionType.setAdminPermissionAppList,
      payload: { data },
    });
  }
}

/**
 * 获取某个app下的版本号
 */
export const getAppVersionList = () => {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: AppRest.App.versionList,
      data: { appId, platformId: pid },
    });
  };
};

// 判断某个app是否有灰度的权限
export const hasGray = ({ appId, pid }) => {
  return (dispatch) => {
    return dispatch({
      rest: AppRest.App.hasGray,
      data: { appId, platformId: pid },
    });
  };
};

// 更新精准渠道分析的权限
export function updatePermission() {
  return (dispatch, getState) => {
    const { appId, pid } = getState().app.get('current').toJS();
    return dispatch({
      rest: AppRest.App.postPermission,
      data: {
        appId,
        pid,
      },
    });
  };
}

// 当前产品的信息激活
export const updateCurrentApp = createAction(UPDATE_CURRENT_APP);

// 产品激活成功
export const applyActiveSuccess = createAction(APPLY_ACTIVE_SUCCESS);

// 产品激活状态重还原
export const applyActiveInit = createAction(APPLY_ACTIVE_INIT);

// 更新某个app的收藏状态
export const updateAppFavoriteById = createAction(UPDATE_APP_FAVORITE_BY_ID);
