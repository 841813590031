import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  BaseProblemPage,
  // CrashProblemDetailPage,
  // ReportRecordPage,
} from 'components/exception';
import { LoadingPage } from 'route/index'

const { JankProblemPage } = BaseProblemPage;

const ReportRecordPage = React.lazy(() => import( /* webpackChunkName: "report-record-page" */ 'components/exception/ReportRecordPage'));
const CrashProblemDetailPage = React.lazy(() => import( /* webpackChunkName: "crash-problem-detail-page" */ 'components/exception/CrashProblemDetailPage'));

/* eslint-disable react/prop-types */
export default ({ match }) => (
  <Suspense fallback={<LoadingPage/>}>
    <Switch>
      <Route path={`${match.url}/:appId/:issueId/report`} component={ReportRecordPage} />
      <Route path={`${match.url}/:appId/:issueId/report-id/:reportId`} component={CrashProblemDetailPage} />
      <Route path={`${match.url}/:appId/:issueId`} component={CrashProblemDetailPage} />
      <Route path={`${match.url}/:appId`} component={JankProblemPage} />
      <Route path={`${match.url}/*`} component={JankProblemPage} />
    </Switch>
  </Suspense>
);
