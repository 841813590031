import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useImmer } from 'use-immer';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import _style from './style.scss';
import RestHelper from 'utils/RestHelper';
import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Switch,
  Card,
  Input,
  Spin,
  DatePicker,
  Radio,
  Pagination,
  Space,
  Form,
  Divider
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useCrashsightLocalStorage } from 'utils/useCrashSightLocalStorage';
import CommonSequence from './CommonSequence';
import { isEqual } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';
import pickBy from 'lodash.pickby';
import {isNotNullish, isNullish} from 'utils/nullish';
import { connect } from 'react-redux';
import { DownloadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import XLSX from 'xlsx';
import i18n from 'i18n.js';
import { isZh, ze } from 'utils/zhEn';
import { isMobile } from 'utils/platform';
import { DeviceStatsUtil } from 'components/exception/issue/DeviceStats/DeviceStats';
import IssueCrashFilterEx from 'components/commons/IssueCrashFilter/IssueCrashFilterEx';
import { DocsUtil } from 'utils/docs-util';
import { CountryZhToEnDict } from 'utils/country';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { SearchConditionGroupUtil } from 'components/commons/IssueCrashFilter/SearchConditionGroupUtil';
import { CustomKvUtil } from 'utils/custom-kv';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import IssueChartDownloadFieldSelectModal from 'components/exception/issue/IssueChartDownloadFieldSelectModal';
import { XlsxUtil } from 'utils/xlsx-util';
import { COLOR_LIST } from 'utils/constants/chart-options';
import BehaviorBarRanking from 'components/exception/issue/Behavior/BehaviorBarRanking';
import { ServerAppSettings } from 'utils/server-app-settings';
import CustomKvConfigModal from 'components/exception/issue/CustomKvConfigModal/CustomKvConfigModal';

function createExcel(data,title){
  const { utils } = XLSX;
  var ws_name = "sheet1";
  const wb= utils.book_new();
  /* make worksheet */
  var ws = utils.aoa_to_sheet(data);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, title);
}

/* function handleDownload(option) {
  const { key, total, noDataCount, valueList } = option;
  const hasDataCount = total - noDataCount;
  const title = `${key}-${i18n.t('hardwareInfo.全量数据')}.xlsx`;
  const header = [`${key}`, i18n.t('hardwareInfo.异常次数'), i18n.t('hardwareInfo.次数占比')];

  const body = valueList.filter(x => x.visibleInDownload).map(x => {
    return [
      x.name,
      x.count,
      `${(x.count / hasDataCount * 100).toFixed(2)} %`,
    ];
  });

  const totalLabel = ze('总有效异常次数（过滤了无自定义数据的上报）', 'Total (Invalid Records Filtered)');
  const datas = [header, ...body, [totalLabel, hasDataCount]];
  createExcel(datas, title);
} */

const DEFAULT_UPLOAD_TIME_CONDITION = { field: FieldName.crashUploadTime, queryType: QueryType.RANGE_RELATIVE_DATETIME, gte: 7 * 86400 * 1000 };
const DEFAULT_SCG = {
  conditions: [
    DEFAULT_UPLOAD_TIME_CONDITION,
  ],
};

function refineCachedSearchConditionGroup(scg) {
  if (!scg || !scg.conditions) {
    return DEFAULT_SCG;
  }

  const hasUploadTimeField = scg.conditions.some(cond => cond.field === FieldName.crashUploadTime);
  if (!hasUploadTimeField) {
    return SearchConditionGroupUtil.addMustCondition(scg, DEFAULT_UPLOAD_TIME_CONDITION);
  } else {
    return scg;
  }
}

/**
 * 行为分析卡片子页面
 * @param {*} props
 */
const Behavior = (props) => {
  const { t, i18n } = useTranslation();
  const { appId, platformId, issueHash, typeAnalysis, reduxState } = props;

  const serverAppSettings = useMemo(() => reduxState.app.get('appIdToServerAppSettings')?.[appId] || {},
    [reduxState.app, appId]);
  const customKvConfigList = useMemo(() => reduxState.app.get('appIdToCustomKvConfig')?.[appId] || [],
    [reduxState.app, appId]);

  const issueObj = reduxState.issue.toJS();
  const versionOptions = issueObj.selectOptions.version.options.filter(x => x.value && x.value !== 'all');

  const userBehaviorFields = [{
    available: true,
    name: FieldName.userId,
    label: t('issueCrashFilterKey.userId'),
    rankingChartLabel: ze('用户', 'User'),
    aggregateType: 0,
    isShowAsBarRankingChart: true,
    isSelfDefinedKeyValue: false,
    termsSizeLimit: 1000,
    needDistinctCount: true,
  }, {
    available: true,
    name: FieldName.deviceId,
    label: t('issueCrashFilterKey.deviceId'),
    rankingChartLabel: ze('设备', 'Device'),
    aggregateType: 0,
    isShowAsBarRankingChart: true,
    isSelfDefinedKeyValue: false,
    termsSizeLimit: 1000,
    needDistinctCount: true,
  }, {
    available: isMobile(platformId),
    name: 'appInBack',
    label: t('REPORTDETAIL.feStatus'),
    aggregateType: 0,
    isSelfDefinedKeyValue: false,
    bucketKeyDict: {
      false: t('behavior.前台'),
      true: t('behavior.后台'),
    },
  }, {
    available: true,
    name: "srcCountry",
    label: t('REPORTDETAIL.国家'),
    aggregateType: 0,
    isSelfDefinedKeyValue: false,
    ...(!isZh() ? { bucketKeyDict: CountryZhToEnDict } : {}),
  }, {
    available: isMobile(platformId),
    name: 'bundleId',
    label: t('REPORTDETAIL.bundleId'),
    aggregateType: 0,
    isSelfDefinedKeyValue: false,
  }, {
    available: isMobile(platformId),
    name: 'threadNameInfo', // es里面后加的字段，和threadName的区别是这个不带数字
    label: t('REPORTDETAIL.异常线程'),
    aggregateType: 0,
    isSelfDefinedKeyValue: false,
  }, {
    available: true,
    name: 'elapsedTime',
    label: t('REPORTDETAIL.useTime'),
    aggregateType: 2,
    isSelfDefinedKeyValue: false,
    customIntervals: [
      { to: 60 * 1000, label: t('behavior.1分钟以内') },
      { from: 60 * 1000, to: 5 * 60 * 1000, label: t('behavior.1-5分钟') },
      { from: 5 * 60 * 1000, to: 10 * 60 * 1000, label: t('behavior.5-10分钟') },
      { from: 10 * 60 * 1000, to: 30 * 60 * 1000, label: t('behavior.10-30分钟') },
      { from: 30 * 60 * 1000, to: 120 * 60 * 1000, label: t('behavior.30分钟-2小时') },
      { from: 120 * 60 * 1000, label: t('behavior.2小时以上') },
    ].map(x => ({ ...x, hasFrom: x.from != null, hasTo: x.to != null })),
  }].filter(x => x.available)
    .map(({ available, ...rest }) => ({
      termsSizeLimit: 300,
      ...rest,
    }));

  const downloadableUserBehaviorFieldOptions = useMemo(() => {
    return userBehaviorFields
      .filter(x => x.aggregateType !== 1)
      .map(x => ({ label: x.label, value: x.name }));
  }, [userBehaviorFields]);

  const [state, updateState] = useImmer({
    // 用户行为数据的结果集
    userBehaviorKeyInfoList: [],
    // 编辑自定义关键字界面，显示的key列表信息
    keysTableData: [],

    customKeyInfoList: [],
    customKeyConfigList: [],

    userBehaviorLoading: true,
    customReportLoading: true,

    initFinished: false,

    triggerFetchAndSetKeysTableData: 0,

    searchConditionGroup: DEFAULT_SCG,
  });
  const { searchConditionGroup } = state;

  const [downloadUserBehaviorModalVisible, setDownloadUserBehaviorModalVisible] = useState(false);
  const [downloadCustomKvModalVisible, setDownloadCustomKvModalVisible] = useState(false);

  const [searchTriggerCount, setSearchTriggerCount] = useState(0);
  //自定义关键字统计分页
  const [customKeywordPage, setCustomKeywordPage] = useState(1);
  const pageSize = 9;

  const [customKvConfigModalVisible, setCustomKvConfigModalVisible] = useState(false);

  const [storage, updateStorage] = useCrashsightLocalStorage();

  // 初始化状态
  useEffect(() => {
    if (!appId) {
      return;
    }
    updateState((draft) => {
      try {
        draft.searchConditionGroup = refineCachedSearchConditionGroup(storage.behaviorSearchConditionGroupByAppId[appId]);
      } catch (e) {
        console.error(e);
        draft.searchConditionGroup = DEFAULT_SCG;
      }
      draft.initFinished = true;
    });
  }, [appId]);

  async function fetchAndSetKeysTableData() {
    let keysTableData = customKvConfigList;
    keysTableData = keysTableData.map((x) => {
      const keyInfo = CustomKvUtil.getKeyInfoFromPrefixedCustomKey(x.key);
      if(x.showAsChart && isNullish(x?.otherConfig?.chartType)){
        return {
          ...x,
          otherConfig: {chartType: "PIE"},
          keyWithoutPrefix: keyInfo.keyWithoutPrefix,
          keyType: keyInfo.keyType,
          keyTypeLabel: keyInfo.keyTypeLabel,
        };
      }
      return {
        ...x,
        keyWithoutPrefix: keyInfo.keyWithoutPrefix,
        keyType: keyInfo.keyType,
        keyTypeLabel: keyInfo.keyTypeLabel,
      };
    });

    if (isEqual(keysTableData, state.keysTableData)) { // 避免依赖keysTableData的memo发生重复计算
      keysTableData = state.keysTableData;
    }

    updateState((draft) => {
      draft.keysTableData = keysTableData;
    });
  }

  // 状态初始化后的操作。拉取key以及配置
  useEffect(() => {
    if (!state.initFinished) {
      return;
    }

    (async () => {
      await fetchAndSetKeysTableData();
    })();
  }, [state.initFinished, state.triggerFetchAndSetKeysTableData]);

  // 拉取用户行为的饼图数据
  useEffect(() => {
    if (!state.initFinished || typeAnalysis !== 'UserBehavior') {
      return;
    }

    (async () => {
      if (!state.userBehaviorLoading) {
        updateState((draft) => {
          draft.userBehaviorLoading = true;
        });
      }

      const rsp = await RestHelper.post('/api/issue/queryIssueAggregate', {
        appId: props.appId,
        platformId: props.platformId,
        issueHash: props.issueHash,
        customFields: userBehaviorFields,
        searchConditionGroup,
      });
      const userBehaviorKeyInfoList = rsp.json.data
        .map((x, i) => {
          if (userBehaviorFields[i].aggregateType === 2) {
            const bucketKeyList = userBehaviorFields[i].customIntervals.map(y => y.label);
            return DeviceStatsUtil.refineIssueAggregateSingleResult(x, userBehaviorFields[i].aggregateType,{ bucketKeyList });
          }
          if (userBehaviorFields[i].bucketKeyDict) {
            const { bucketKeyDict } = userBehaviorFields[i];
            return DeviceStatsUtil.refineIssueAggregateSingleResult(x, userBehaviorFields[i].aggregateType, { bucketKeyDict });
          }
          return DeviceStatsUtil.refineIssueAggregateSingleResult(x, userBehaviorFields[i].aggregateType);
        })
        .map((x, i) => ({
          key: userBehaviorFields[i].name,
          label: userBehaviorFields[i].label,
          total: x.total,
          isShowAsBarRankingChart: userBehaviorFields[i].isShowAsBarRankingChart,
          rankingChartLabel: userBehaviorFields[i].rankingChartLabel,
          aggregateType: userBehaviorFields[i].aggregateType,
          termsSizeLimit: userBehaviorFields[i].termsSizeLimit,
          valueList: x.bucketList.map(y => ({
            ...y,
            name: y.key,
            count: y.docCount,
          })),
          noDataCount: x.noDataCount,
          distinctCount: x.distinctCount,
        }));
      updateState((draft) => {
        draft.userBehaviorKeyInfoList = userBehaviorKeyInfoList;
        draft.userBehaviorLoading = false;
      });
    })();
  }, [state.initFinished, searchTriggerCount,typeAnalysis]);

  let customKeysShowAsChart = useMemo(
    () => state.keysTableData.filter(x => x.showAsChart).map(x => x.key),
    [state.keysTableData],
  );

  let customKeysShowChartSetting = useMemo(()=>{
    return state.keysTableData.map(x => {
        return {
          chartType: x.otherConfig?.chartType,
          interval: x.otherConfig?.interval,
        }
      })
  },[state.keysTableData])

  const keyToAlias = useMemo(
    () => state.keysTableData.reduce((acc, x) => {
      const { keyWithoutPrefix } = CustomKvUtil.getKeyInfoFromPrefixedCustomKey(x.key);
      acc[x.key] = x.alias ? x.alias : keyWithoutPrefix;
      return acc;
    }, Object.create(null)),
    [state.keysTableData],
  );

  // 拉取自定义关键字的饼图数据
  useDeepCompareEffect(() => {
    if (customKeysShowAsChart.length === 0 || typeAnalysis !== 'CustomizedData') {
      updateState((draft) => {
        draft.customKeyInfoList = [];
        draft.customReportLoading = false;
      });
      return;
    }

    if (!state.customReportLoading) {
      updateState((draft) => {
        draft.customReportLoading = true;
      });
    }

    (async () => {
      const fields = customKeysShowAsChart.map(x =>{
        const tabelData = state.keysTableData.find(data => data.key === x);
        const aggregateType = tabelData.otherConfig?.chartType === 'PIE' ? 0 : 1 ;
        return {
          name: x,
          aggregateType: aggregateType,
          isSelfDefinedKeyValue: true,
          termsSizeLimit: 300,
          histogramInterval: tabelData.otherConfig?.interval,
        }
      });
      const fieldsFilter = fields.slice((customKeywordPage-1) * pageSize,customKeywordPage * pageSize);
      const rsp = await RestHelper.post('/api/issue/queryIssueAggregate', {
        appId: props.appId,
        platformId: props.platformId,
        issueHash: props.issueHash,
        customFields: fieldsFilter,
        searchConditionGroup,
      });
      const customKeyInfoList = rsp.json.data
        .map((x, i) => {
          //直方图
          if(fieldsFilter[i].aggregateType === 1){
            return DeviceStatsUtil.refineIssueAggregateSingleResult(x, 1);
          }
          return DeviceStatsUtil.refineIssueAggregateSingleResult(x, 0);
        })
        .map((x, i) => {
          const key = fieldsFilter[i].name;
          const customKvConfig = customKvConfigList.find(config => config.key === key);
          const { hasValueAliasConfig, valueAliasList } = customKvConfig || {};
          let valueToAlias = {};
          if (hasValueAliasConfig) {
            valueToAlias = valueAliasList.reduce((acc, item) => {
              acc[item.value] = item.valueAlias;
              return acc;
            }, Object.create(null));
          }
          return {
            key,
            total: x.total,
            aggregateType: fieldsFilter[i].aggregateType,
            histogramInterval: fieldsFilter[i].histogramInterval,
            valueList: x.bucketList.map(y => {
              const value = y.key;
              const alias = valueToAlias[y.key];
              const name = alias ? `${alias} (${value})` : value;
              return {
                ...y,
                name,
                count: y.docCount,
              };
            }),
            noDataCount: x.noDataCount,
          };
        });
      updateState((draft) => {
        draft.customKeyInfoList = customKeyInfoList;
        draft.customReportLoading = false;
      });
    })();
  }, [customKeysShowAsChart,customKeysShowChartSetting, searchTriggerCount, typeAnalysis]);

  const downloadableCustomKeyOptions = useMemo(() => {
    return state.customKeyInfoList.map(x => ({
      label: keyToAlias[x.key] || x.key,
      value: x.key,
    }));
  }, [state.customKeyInfoList, keyToAlias]);

  function onClickOpenCustomKeyModal() {
    setCustomKvConfigModalVisible(true);
  }

  function onClickSearch(newSearchConditionGroup = undefined) {
    // 记忆搜索条件到localStorage
    const cacheSearchConditionGroup = newSearchConditionGroup || state.searchConditionGroup;
    updateStorage({
      behaviorSearchConditionGroupByAppId: {
        ...storage.behaviorSearchConditionGroupByAppId,
        [appId]: cacheSearchConditionGroup,
      },
    });

    setSearchTriggerCount(searchTriggerCount + 1);
  }

  const customKeywordAlter = (page,pageSize) => {
    setCustomKeywordPage(page);
    onClickSearch();
  }

  function onClickDownloadFields(fieldKeyList, dataList) {
    const downloadDataList = dataList.filter(x => fieldKeyList.includes(x.key));

    const title = `${t('hardwareInfo.全量数据')}.xlsx`;
    const dataObjectList = downloadDataList.map((option) => {
      const { label, total, noDataCount, valueList } = option;
      const hasDataCount = total - noDataCount;
      const header = [`${label}`, t('hardwareInfo.异常次数'), t('hardwareInfo.次数占比')];

      const body = valueList.filter(x => x.visibleInDownload).map(x => {
        return [
          x.name,
          x.count,
          `${(x.count / hasDataCount * 100).toFixed(2)} %`,
        ];
      });

      const totalLabel = ze('总有效异常次数（过滤了无自定义数据的上报）', 'Total (Invalid Records Filtered)');
      const data = [header, ...body, [totalLabel, hasDataCount]];
      const sheetName = (label || '').replaceAll(/[\/]/g, '|'); // sheet名不能有斜杠
      return { sheetName, data };
    });
    XlsxUtil.createMultipleSheetsExcelAndDownload(title, dataObjectList);
  }

  return (
    <div>
      <div style={{ marginBottom: '12px' }}>
          <IssueCrashFilterEx
            hideIssueFields={true}
            fieldBlacklist={['crashId', 'issueId', 'bundleId', 'osVersion', 'deviceIdList', 'expUid', 'crashId', 'issueId', 'contectInfo', 'exceptionTypeList', 'uploadTime', 'crashTime']}
            fixedFieldList={[FieldName.crashUploadTime]}
            platformId={platformId}
            filterOptions={{
              version: versionOptions,
            }}
            searchConditionGroup={searchConditionGroup}
            onChange={({ searchConditionGroup }) => {
              updateState(draft => {
                draft.searchConditionGroup = searchConditionGroup;
              });
            }}
            onSubmit={({ searchConditionGroup }) => {
              updateState(draft => {
                draft.searchConditionGroup = searchConditionGroup;
              });
              onClickSearch(searchConditionGroup);
            }}
          />
      </div>
      {typeAnalysis == 'UserBehavior' && <div>
        <Spin spinning={state.userBehaviorLoading}>
          <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '12px' }}>
            <CsDownloadButton
              disabled={downloadableUserBehaviorFieldOptions.length === 0}
              onClick={() => setDownloadUserBehaviorModalVisible(true)}
            />
          </div>
          <Row gutter={16} justify='center'>
            {state.userBehaviorKeyInfoList.filter(x => x.isShowAsBarRankingChart).map(x => (
              <Col span={8} key={x.key}><ChartContainer option={x} keyToAlias={keyToAlias}/></Col>
            ))}
          </Row>
          <Divider/>
          <Row gutter={16} style={{ marginTop: '24px' }}>
            {state.userBehaviorKeyInfoList.filter(x => !x.isShowAsBarRankingChart).map(x => (
              <Col span={8} key={x.key}><ChartContainer option={x} keyToAlias={keyToAlias}/></Col>
            ))}
          </Row>
        </Spin>
      </div>}
      {typeAnalysis == 'CustomizedData' && <Card title={<div style={{ display: 'flex', alignItems: 'center' }}>
        <div>{ t('behavior.自定义关键字统计') }</div>
        <div><a href={DocsUtil.makeDocsUrl('/webDocuments/custom-key-value')} target='_blank' rel="noreferrer noopener"><Button type='link' style={{ fontSize: '12px' }}>{ t('behavior.如何使用此功能') }</Button></a></div>
        <div><Button size='small' onClick={onClickOpenCustomKeyModal}>{ t('behavior.管理自定义关键字') }</Button></div>
      </div>}>
        <Spin spinning={state.customReportLoading}>
          <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '12px' }}>
            <CsDownloadButton
              disabled={state.customKeyInfoList.length === 0}
              onClick={() => setDownloadCustomKvModalVisible(true)}
            />
          </div>
          <Row gutter={15}>
            {state.customKeyInfoList.map(x => (
              <Col span={8} key={x.key}><ChartContainer option={x} keyToAlias={keyToAlias} type="CustomizedData" info={{...props, searchConditionGroup}} /></Col>
            ))}
          </Row>
        </Spin>
        <div style={{textAlign:"right"}}>
          <Pagination
            total={customKeysShowAsChart.length}
            current={customKeywordPage}
            pageSize={pageSize}
            onChange={customKeywordAlter}
          />
        </div>
      </Card>}
      { typeAnalysis == 'CustomizedData' && <CommonSequence
        appId={appId}
        platformId={platformId}
        issueHash={issueHash}
      />}
      <IssueChartDownloadFieldSelectModal
        fieldOptions={downloadableUserBehaviorFieldOptions}
        visible={downloadUserBehaviorModalVisible}
        onVisibleChange={(v) => setDownloadUserBehaviorModalVisible(v)}
        onConfirm={(v) => onClickDownloadFields(v, state.userBehaviorKeyInfoList)}
      />
      <IssueChartDownloadFieldSelectModal
        fieldOptions={downloadableCustomKeyOptions}
        visible={downloadCustomKvModalVisible}
        onVisibleChange={(v) => setDownloadCustomKvModalVisible(v)}
        onConfirm={(v) => onClickDownloadFields(v, state.customKeyInfoList)}
      />
      <CustomKvConfigModal
        visible={customKvConfigModalVisible}
        onCancel={() => setCustomKvConfigModalVisible(false)}
        onSave={() => {
          setCustomKvConfigModalVisible(false);
          updateState((draft) => {
            draft.triggerFetchAndSetKeysTableData = state.triggerFetchAndSetKeysTableData + 1;
          });
        }}
      />
    </div>
  );
};

/**
 *
 * @param {{option: {
 *   key: String,
 *   label: String,
 *   total: Number,
 *   distinctCount; Number,
 *   noDataCount: Number,
 *   aggregateType: Number,
 *   isShowAsBarRankingChart: boolean,
 *   rankingChartLabel: string,
 *   termsSizeLimit: number,
 *   valueList: {name, count, isNoData}[],
 * }}} props
 */
const ChartContainer = (props) => {
  const { t } = useTranslation();
  const { option, keyToAlias, type, info} = props;
  let chartOptions = {};
  let subtext = '';
  const hasDataCount = option.total - option.noDataCount;
  if (option.noDataCount === 0) {
    subtext = `${t('behavior.有效异常次数')}: ${hasDataCount}`;
  } else {
    subtext = `${t('behavior.有效异常次数')}: ${hasDataCount} \n(${t('behavior.已过滤n条无数据上报', { n: option.noDataCount })})`;
  }
  const getPieChartOptions = () => {
    return {
      color: COLOR_LIST,
      title: {
        text: keyToAlias[option.key] ? keyToAlias[option.key] : (option.label || option.key),
        subtext,
        x: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: `{b}<br>${t('behavior.次数')}: {c}<br>${t('behavior.占比')}: {d} %`,
      },
      series: [{
        type: 'pie',
        radius: '50%',
        data: option.valueList.filter(x => x.visibleInChart).map(x => ({
          isNoData: x.isNoData,
          isShowAsOthers: x.isShowAsOthers,
          value: x.count,
          name: x.isNoData ? t('behavior.无数据') : x.name,
          ...(x.isNoData && { itemStyle: { color: '#EEE' }, emphasis: { itemStyle: { color: '#eeeeee' } } }),
          ...(x.isShowAsOthers && { itemStyle: { color: '#8e5933' }, emphasis: { itemStyle: { color: '#8e5933' } } }),
        })),
        label: {
          position: 'outer',
          alignTo: 'edge',
          margin: 10,
        },
      }],
    }
  } ;
  const getHistogramChartOptions = () =>{
    const xAxisData = option.valueList.map(x => parseInt(x.key));
    return {
      color: COLOR_LIST,
      title: {
        text: keyToAlias[option.key] ? keyToAlias[option.key] : (option.label || option.key),
        subtext,
        x: 'center',
      },
      grid: {
        left: 60,
      },
      tooltip: {
        trigger: 'item',
        formatter: `{b}<br>${t('behavior.次数')}: {c}`,
      },
      xAxis: [{
        type: 'category',
        data: xAxisData,
      }],
      yAxis: [{
        type: 'value',
      }],
      series: [{
        type: 'bar',
        barWidth: '80%',
        data: option.valueList.map(x => x.count),
        label: {
          position: 'outer',
          alignTo: 'edge',
          margin: 10,
        },
      }],
    }
  }

  if(option.aggregateType === 1){
    chartOptions = getHistogramChartOptions();
  }else{
    chartOptions = getPieChartOptions();
  };


  const handleClick = (params) => {
    const key = option.key;
    const clickPart = params.name;
    if(type === 'CustomizedData' && info){
      const {appId, platformId:pid, reduxState} = info;
      const {pathname} = reduxState.router.location;
      let searchConditionGroup = {...info.searchConditionGroup};
      if(params.seriesType === 'bar'){
        searchConditionGroup = {
          conditions: [
            ...searchConditionGroup.conditions,
            { subField: key, queryType: "RANGE", field: "customKey", gte: clickPart, lte: parseInt(clickPart,10) + parseInt(option.histogramInterval, 10) - 1},
          ],
        }
      }else{
        const isIgnoreClick = params.data.isShowAsOthers || params.data.isNoData;
        if(isIgnoreClick)return;
        searchConditionGroup = {
          conditions: [
            ...searchConditionGroup.conditions,
            { subField: key, queryType: "TERMS", field: "customKey", terms: [clickPart] },
          ],
        }
      };
      const queryString = new URLSearchParams({
        pid,
        searchConditionGroup: JSON.stringify(searchConditionGroup),
      }).toString();
      const link = `${pathname}/report?${queryString}`;
      window.open(link,'_blank');
    }
  };

  if (option.isShowAsBarRankingChart) {
    const dataList = option.valueList
      .filter(x => !x.isNoData && !x.isShowAsOthers)
      .map(x => {
        return {
          name: x.name,
          value: x.count,
        };
      });

    const {
      distinctCount,
      termsSizeLimit,
      rankingChartLabel,
    } = option;

    const averageReportsText = distinctCount > 0
      ? (hasDataCount / distinctCount).toFixed(2)
      : '-';

    const pthCandidates = [90, 95, 99];
    const pth = pthCandidates.find(x => (distinctCount * (100 - x) / 100) <= termsSizeLimit);
    let pthValue = 0;
    if (distinctCount > 0 && isNotNullish(pth)) {
      const index = Math.ceil(distinctCount * (100 - pth) / 100) - 1;
      pthValue = dataList[index]?.value || 0;
    }

    const pthText = distinctCount > 0 && isNotNullish(pth)
      ? ze(`p${pth}${rankingChartLabel}上报次数： ${pthValue}`, `p${pth} ${rankingChartLabel} Reports: ${pthValue}`)
      : '';

    return <div style={{ position: 'relative' }}>
      <div
        style={{ color: 'rgba(0,0,0,0.75)', fontSize: '18px', fontWeight: 700, textAlign: 'center' }}
      >{  ze(`${rankingChartLabel}上报次数排行`, `Most Reported ${rankingChartLabel}s`) }</div>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#444', fontSize: '12px', gap: '32px'}}
      >
        <div>{ ze(`${rankingChartLabel}平均上报次数： ${averageReportsText}`, `Average Reports Per ${rankingChartLabel}: ${averageReportsText}`) }</div>
        { pthText && <div>{ pthText }</div> }
      </div>
      <BehaviorBarRanking
        data={dataList}
        maxItems={5}
      />
    </div>
  }

  return <div>
    <ReactECharts
      option={chartOptions}
      style={{ width: '100%' }}
      onEvents={{ click: handleClick }}
    />
    { /*<Button
        style={{ position: 'absolute', top: '0px', right: '20px' }}
        icon={<DownloadOutlined />}
        size="small"
        onClick={() => handleDownload(option)}
      >{ t('hardwareInfo.下载') }</Button> */}
  </div>;
};

Behavior.propTypes = {
  appId: PropTypes.string,
  platformId: PropTypes.string,
  issueHash: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(Behavior);
