import React, { useEffect, useMemo } from 'react';
import { InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ze } from 'utils/zhEn';
import { isNotNullish, isNullish } from 'utils/nullish';

const CmpType = {
  gt: 'gt',
  lt: 'lt',
  gte: 'gte',
  lte: 'lte',
};

const CmpOptions = [
  { value: CmpType.gt , label: ze('大于', '>') },
  { value: CmpType.lt , label: ze('小于', '<') },
  { value: CmpType.gte, label: ze('大于等于', '≥') },
  { value: CmpType.lte, label: ze('小于等于', '≤') },
]

const NumberRangeFilter = ({
  isReadOnly,
  fieldValue,
  onChangeFieldValue,
}) => {
  const {
    queryType,
    gte,
    gt,
    lte,
    lt,
  } = fieldValue || {};

  let isInvalid = false;
  let cmpTypeValue = CmpType.gt;
  let numberValue = 0;
  if (queryType && queryType !== QueryType.RANGE) {
    isInvalid = true;
  } else if ([gte, gt, lte, lt].filter(x => isNotNullish(x)).length > 1) {
    isInvalid = true;
  } else if (isNotNullish(gt)) {
    cmpTypeValue = CmpType.gt;
    numberValue = gt;
  } else if (isNotNullish(gte)) {
    cmpTypeValue = CmpType.gte;
    numberValue = gte;
  } else if (isNotNullish(lt)) {
    cmpTypeValue = CmpType.lt;
    numberValue = lt;
  } else if (isNotNullish(lte)) {
    cmpTypeValue = CmpType.lte;
    numberValue = lte;
  }

  if (isInvalid) {
    return <div>{ JSON.stringify(fieldValue) }</div>;
  }

  return <div style={{ display: 'flex' }}>
    <Select
      style={{ width: '120px', flex: '0 0 auto' }}
      options={CmpOptions}
      value={cmpTypeValue}
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        onChangeFieldValue({
          queryType: QueryType.RANGE,
          [v]: numberValue,
        });
      }}
    />
    <InputNumber
      style={{ flexGrow: 1 }}
      min={0}
      value={numberValue}
      onChange={(v) => {
        if (isReadOnly) {
          return;
        }
        onChangeFieldValue({
          queryType: QueryType.RANGE,
          [cmpTypeValue]: v,
        });
      }}
    />
  </div>
}

export default NumberRangeFilter;
