import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Menu, Modal, Spin, Table } from 'antd';
import { connect } from 'react-redux';
import JankFlameGraph from 'components/exception/issue/DataHead/JankFlameGraph';
import scss from './SubJankIssueFlameGraphModal.scss';
import { Select } from 'antd/es';

const SubJankIssueDomItem = ({
  reduxState,
  mergedIssueList,
  onFetchSubJankTopIssues,
  initialMergedIssueId,
}) => {
  // 王者卡顿，虚拟issue ID -> 子issue list
  const mergedIssueIdToSubJankTopIssueData = useMemo(() => {
    const v = reduxState.summary.get('mergedIssueIdToSubJankTopIssueData');
    return v || {};
  }, [reduxState.summary]);

  const mergedIssueIdListOptions = useMemo(() => {
    const mergedIssueIdList = (mergedIssueList || []).map(x => x.issueId);
    return mergedIssueIdList.map(x => ({ label: x, value: x }));
  }, [mergedIssueList]);

  const [loading, setLoading] = useState(false);

  const [selectedMergedIssueId, setSelectedMergedIssueId] = useState('');
  const [issueIdList, setIssueIdList] = useState([]);
  const [selectedIssueId, setSelectedIssueId] = useState('');
  const [issueIdToFlameGraphData, setIssueIdToFlameGraphData] = useState({});

  const [fetchSubIssuesTrigger, setFetchSubIssuesTrigger] = useState(0);

  const selectedFlameGraphData = issueIdToFlameGraphData[selectedIssueId];

  const menuItems = useMemo(() => {
    return issueIdList.map((issueId, i) => ({ label: `(${i+1}) ${issueId}`, key: issueId }));
  }, [issueIdList]);

  const currentApp = reduxState.app.get('current').toJS();
  const { appId, platformId } = currentApp;

  useEffect(() => {
    if (initialMergedIssueId) {
      setSelectedMergedIssueId(initialMergedIssueId);
      setFetchSubIssuesTrigger(fetchSubIssuesTrigger + 1);
    }
  }, [initialMergedIssueId]);

  useEffect(() => {
    fetchSubJankIssuesFlameGraphData(selectedMergedIssueId);
  }, [fetchSubIssuesTrigger]);

  async function fetchSubJankIssuesFlameGraphData(mergedIssueId) {
    setLoading(true);
    const subJankTopData = mergedIssueIdToSubJankTopIssueData[mergedIssueId];
    const topIssueList = subJankTopData?.topIssueList || [];
    const issueIdList = topIssueList.map((issue) => issue.issueId);
    setIssueIdList(issueIdList);
    if (issueIdList.length === 0) {
      setLoading(false);
      return;
    }

    const rsp = await RestHelper.mustPost('/api/issue/queryIssueList', {
      appId,
      platformId,
      rows: 1000,
      issueIds: issueIdList,
    });
    const issueList = rsp.json.ret?.issueList || [];
    const issueIdAndFlameGraphDataEntries = issueList.map((issue) => {
      const { issueId, lastMatchedReport } = issue;
      const jankFlameGraphJson = lastMatchedReport?.crashMap?.jankFlameGraphJson;
      let jankFlameGraphData = null;
      if (jankFlameGraphJson) {
        try {
          jankFlameGraphData = JSON.parse(jankFlameGraphJson);
        } catch (e) {
          message.error(`解析火焰图失败, issueId = ${issueId}`);
        }
      }
      return [issueId, jankFlameGraphData];
    });
    setIssueIdToFlameGraphData(Object.fromEntries(issueIdAndFlameGraphDataEntries));
    setLoading(false);
  }

  const dom = <Spin spinning={loading}><div>
    <div>
      <Select
        style={{ width: '320px' }}
        options={mergedIssueIdListOptions}
        value={selectedMergedIssueId}
        onChange={async (v) => {
          setLoading(true);
          setSelectedMergedIssueId(v);
          await onFetchSubJankTopIssues(v);
          setFetchSubIssuesTrigger(fetchSubIssuesTrigger + 1);
        }}
      />
    </div>
    <div style={{ display: 'flex', gap: '12px' }}>
      <div style={{ width: '320px', height: '40vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <Menu
          inlineIndent={0}
          mode="inline"
          onClick={({ key }) => setSelectedIssueId(key)}
          items={menuItems}
        >
        </Menu>
      </div>
      <div style={{ flexGrow: 1 }}>
        { !!selectedFlameGraphData && <JankFlameGraph
          data={selectedFlameGraphData}
        /> }
      </div>
    </div>
  </div></Spin>;

  return dom;
}

const SubJankIssueFlameGraphModal = ({
  reduxState,
  mergedIssueId,
  mergedIssueList,
  visible,
  onClose,
  onFetchSubJankTopIssues,
}) => {
  const [loading, setLoading] = useState(false);

  const modalContent = visible && <Spin spinning={loading}><div className={scss.subJankIssueFlameGraph}>
    <SubJankIssueDomItem
      reduxState={reduxState}
      mergedIssueList={mergedIssueList}
      onFetchSubJankTopIssues={onFetchSubJankTopIssues}
      initialMergedIssueId={mergedIssueId}
    />
    <Divider/>
    <SubJankIssueDomItem
      reduxState={reduxState}
      mergedIssueList={mergedIssueList}
      onFetchSubJankTopIssues={onFetchSubJankTopIssues}
      initialMergedIssueId={mergedIssueId}
    />
  </div></Spin>;

  return (
    <Modal
      title="查看火焰图（对比模式）"
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      width='calc(100vw - 120px)'
      style={{ top: '24px' }}
    >
      { modalContent }
    </Modal>
  );
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(SubJankIssueFlameGraphModal);
