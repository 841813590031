import React, { useState, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import RestHelper from 'utils/RestHelper';
import { DownloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, Modal, Table, Switch, Card, Input, Spin, DatePicker, Tooltip } from 'antd';
import XLSX from 'xlsx';
import * as PlatformUtils from 'utils/platform';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';
import { PLATFORM_ID } from 'utils/constants';
import { convertIosModelToProductName } from 'utils/model-convert/ios-model-convert';
import { useZhEn } from 'utils/react-hooks/useZhEn';
import { ze } from 'utils/zhEn';
import IssueCrashFilterEx from 'components/commons/IssueCrashFilter/IssueCrashFilterEx';
import { FieldName, QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { isAndroidOrHarmony, isMobile, isPcOrLinux, isPc, isAndroid, isIos } from 'utils/platform';
import { BillingTeamType } from 'utils/constants/billing';
import CsDownloadButton from 'components/commons/CsDownloadButton/CsDownloadButton';
import IssueChartDownloadFieldSelectModal from 'components/exception/issue/IssueChartDownloadFieldSelectModal';
import { XlsxUtil } from 'utils/xlsx-util';
import { COLOR_LIST } from 'utils/constants/chart-options';
import { decodeCsSdkEncodedValue } from 'utils/helper';
import { IssueAggregateType } from 'utils/constants/issue-aggregate-type';
import { formatBytes } from 'utils/format/file';
import { getCrashStackSearchTooltipDom } from 'utils/crash-search/convert-search-content';
import WrappedTipsIcon from 'components/antd-extension/WrappedTipsIcon';
import { CS_STYLES } from 'utils/constants/style-constants';
import { EnvUtil } from 'utils/env-util';
import { VmTypeInt } from 'utils/constants/vm-type';

/**
 * @param {{ total: Number, bucketList: { key: String, docCount: Number, notInLimitedBuckets: boolean }[] }} issueAggregateSingleResult
 * @param {Number} aggregateType
 * @param {{ bucketKeyList: String[], bucketKeyDict: Object }} bucketKeyExtraInfo
 */
function refineIssueAggregateSingleResult(issueAggregateSingleResult, aggregateType, bucketKeyExtraInfo = {}) {
  const { total } = issueAggregateSingleResult;
  let { bucketList } = issueAggregateSingleResult;
  const { bucketKeyList, bucketKeyDict } = bucketKeyExtraInfo;
  // 如果提供了bucketKeyList, 那么用对应指定的key覆盖原来bucket的key
  // 如果提供了bucketKeyDictObj，根据字典来替换key
  if (bucketKeyList) {
    bucketList = bucketList.map((x, i) => ({ ...x, key: bucketKeyList[i] }));
  } else if (bucketKeyDict) {
    bucketList = bucketList.map(x => ({ ...x, key: bucketKeyDict[x.key] || x.key }));
  }
  // 有数据的样本条数
  const totalWithData = bucketList.reduce((acc, x) => acc + x.docCount, 0);
  // 过滤掉 <= 0 的项
  bucketList = bucketList.filter(x => x.docCount > 0);

  if (aggregateType === 0) {
    // 按照数量大小排序，其他的（notInLimitedBuckets = true）排在最后面
    bucketList.sort((a, b) => a.notInLimitedBuckets - b.notInLimitedBuckets || b.docCount - a.docCount);
    // 只保留TOP N，TOP N以下的都聚合成“其他”
    // N=5或10，判断方法是如果N=5的时候“其他”占25%以下（和total对比），则取N=5。否则取N=10
    const TOP_N_VALUE_CANDIDATES = [5, 10];
    const TOP_N_PERCENTAGE_THRESHOLD = 0.75;
    const countTopN = (n) => {
      return bucketList
        .filter((x, i) => i < n && !x.notInLimitedBuckets)
        .reduce((acc, x) => acc + x.docCount, 0);
    };
    const topNValue = TOP_N_VALUE_CANDIDATES.find((n) => {
      return totalWithData > 0 && countTopN(n) / totalWithData > TOP_N_PERCENTAGE_THRESHOLD;
    }) || TOP_N_VALUE_CANDIDATES[TOP_N_VALUE_CANDIDATES.length - 1];

    const countBelowTopN = totalWithData - countTopN(topNValue);
    bucketList = bucketList.map((x, i) => ({
      ...x,
      visibleInChart: i < topNValue && !x.notInLimitedBuckets,
      visibleInDownload: true,
    }));
    if (countBelowTopN > 0) {
      bucketList.push({
        key: ze('其他', 'Others'),
        docCount: countBelowTopN,
        visibleInChart: true,
        visibleInDownload: false,
        isShowAsOthers: true,
      });
    }
  } else {
    bucketList = bucketList.map(x => ({ ...x, visibleInChart: true, visibleInDownload: true }));
  }
  // 添加无数据的项
  const noDataCount = total - totalWithData;
  if (aggregateType === 0 && totalWithData === 0) {
    bucketList.push({
      key: ze('无数据', 'No Data'),
      docCount: noDataCount,
      isNoData: true,
      visibleInChart: true,
      visibleInDownload: false,
    });
  }
  return { ...issueAggregateSingleResult, total, noDataCount, bucketList };
}

/**
 *  @param {{key: String, label: String, total: Number, aggregateType: Number, valueList: {name, count, isNoData}[]}} option
 */
function refineHistogramData(ze, option) {
  const { valueList } = option;
  // 把百分比合并到0, 10, 20, ... 90的十分位
  const reducedValueMap = valueList.reduce((acc, x) => {
    const percentage = Math.round(x.name * 100);
    let xValue = Math.ceil(percentage / 10) * 10;
    if (xValue > 90) {
      xValue = 90;
    }
    acc.set(xValue, (acc.get(xValue) | 0) + x.count);
    return acc;
  }, new Map());

  const totalWithData = valueList.reduce((acc, x) => acc + x.count, 0);

  return [...Array(10).keys()]
    .map(x => x * 10)
    .map(x => ({
      value: [x + 5, (reducedValueMap.get(x) / totalWithData) || 0],
      name: `${x} ~ ${x + 10}%${ze('可用', ' Available')}`,
      reportCount: (reducedValueMap.get(x) || 0),
    }));
}

export const DeviceStatsUtil = Object.freeze({
  refineIssueAggregateSingleResult,
});

/**
 * 设备信息卡片子页面
 * @param {*} props
 */
const DeviceStats = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const { appId, platformId, issueHash, reduxState } = props;
  const pid = platformId;
  const teamType = reduxState.app.get('current').get('teamType');
  const isNotCommercialApp = teamType && teamType !== BillingTeamType.COMMERCIAL;
  const issueObj = reduxState.issue.toJS();
  const versionOptions = issueObj.selectOptions.version.options.filter(x => x.value && x.value !== 'all');

  const { crashDoc } = issueObj.current || {};
  const { esMap } = crashDoc || {};
  const {
    totalGpuMemGib,
  } = esMap;
  const gpuMemoryReported = totalGpuMemGib > 0; // 采集gpu是新SDK的功能，旧SDK上报的数据不显示对应饼图

  const isFreeStorageAvailable = isAndroid(pid) || isIos(pid);
  const isFreeSdAvailable = !EnvUtil.isChinaEnv() && isAndroid(pid);
  const isFreeMemAvailable = isMobile(pid);
  const isFreeGpuMemAvailable = isPc(pid, appId) && gpuMemoryReported;

  const percentilesAggPercents = [1, 5, 10, 25, 50, 75];
  function makePercentTableKey(percent) {
    return 'P' + String(Number(percent));
  }

  function makeFieldFilterGt0(field) {
    return {
      conditions: [{
        field,
        queryType: QueryType.RANGE,
        gt: 0,
      }],
    };
  }

  const fields = [{
    available: isMobile(pid),
    name: 'hardware',
    label: t('hardwareInfo.机型设备'),
    aggregateType: 0,
    bucketListHandler: (o) => {
      return o.bucketList.map(x => ({
        ...x,
        name: convertIosModelToProductName(decodeCsSdkEncodedValue(x.key)),
        count: x.docCount,
      }))
    },
  }, {
    available: true,
    name: isPcOrLinux(pid) ? 'osName' : 'os',
    label: t('hardwareInfo.系统版本'),
    aggregateType: 0,
    bucketListHandler: (o) => {
      return o.bucketList.map(x => ({
        ...x,
        name: decodeCsSdkEncodedValue(x.key),
        count: x.docCount,
      }))
    },
  }, {
    available: isAndroid(pid),
    name: 'brand',
    label: t('hardwareInfo.厂商'),
    aggregateType: 0,
  }, {
    available: isAndroid(pid),
    name: 'cpuType',
    label: t('REPORTDETAIL.cpu'),
    aggregateType: 0,
  }, {
    available: isPc(pid, appId),
    name: 'cpu',
    label: t('REPORTDETAIL.cpu'),
    aggregateType: 0,
  }, {
    available: isAndroidOrHarmony(pid),
    name: 'appBit',
    label: t('issueCrashFilterKey.appBit'),
    aggregateType: 0,
    bucketKeyDict: {
      0: ze('未知', 'Unknown'),
      32: ze('32位', '32 bit'),
      64: ze('64位', '64 bit'),
    },
  }, {
    available: (isNotCommercialApp && isAndroid(pid))
      || isPc(pid, appId),
    name: 'gpu',
    label: 'GPU',
    aggregateType: 0,
  }, {
    available: isPc(pid, appId),
    name: 'displayVersion',
    label: ze('显卡驱动版本', 'GPU Driver Version'),
    aggregateType: 0,
  }, {
    available: isPc(pid, appId) && gpuMemoryReported,
    name: 'totalGpuMemGib',
    label: ze('GPU内存', 'GPU Memory'),
    aggregateType: 0,
    bucketListHandler: (o) => {
      return o.bucketList.map(x => ({
        ...x,
        name: (!x.isShowAsOthers && !x.isNoData) ? `${Number(x.key)} GiB` : x.key,
        count: x.docCount,
      }));
    },
  }, {
    available: isMobile(pid),
    name: 'ram',
    label: t('REPORTDETAIL.memSize'),
    aggregateType: 0,
    bucketListHandler: (o) => {
      return o.bucketList.map(x => ({
        ...x,
        name: (!x.isShowAsOthers && !x.isNoData) ? `${Number(x.key)} GiB` : x.key,
        count: x.docCount,
      }));
    },
  }, {
    available: isAndroidOrHarmony(platformId),
    name: 'isRooted',
    label: t('hardwareInfo.是否ROOT'),
    aggregateType: 0,
    bucketKeyDict: {
      false: ze('否', 'No'),
      true: ze('是', 'Yes'),
    },
  }, {
    available: isMobile(platformId),
    name: 'crashDataType',
    label: t('hardwareInfo.是否是系统退出'),
    aggregateType: 0,
    bucketKeyDict: {
      false: ze('否', 'No'),
      true: ze('是', 'Yes'),
    },
  }, {
    available: isAndroidOrHarmony(platformId),
    name: FieldName.vmType,
    label: t('issueCrashFilterKey.vmType'),
    aggregateType: 0,
    bucketKeyDict: {
      [VmTypeInt.ALL]: ze('未知', 'Unknown'),
      [VmTypeInt.REAL_DEVICE]: t(`vmTypeOptions.${VmTypeInt.REAL_DEVICE}`),
      [VmTypeInt.EMULATOR]: t(`vmTypeOptions.${VmTypeInt.EMULATOR}`),
      [VmTypeInt.CLOUD_GAMING]: t(`vmTypeOptions.${VmTypeInt.CLOUD_GAMING}`),
    },
  }, {
    available: isAndroid(platformId),
    name: FieldName.vmDeviceBrand,
    label: t('issueCrashFilterKey.vmDeviceBrand'),
    aggregateType: 0,
  }, {
    available: isFreeStorageAvailable,
    name: 'freeStorageR',
    label: t('hardwareInfo.存储可用占比'),
    aggregateType: IssueAggregateType.HISTOGRAM,
    histogramInterval: 0.01,
    fieldFilter: makeFieldFilterGt0('freeStorage'),
  }, {
    available: isFreeStorageAvailable,
    name: 'freeStorage',
    aggregateType: IssueAggregateType.PERCENTILES,
    percents: percentilesAggPercents,
    fieldFilter: makeFieldFilterGt0('freeStorage'),
  }, {
    available: isFreeSdAvailable,
    name: 'freeSdR',
    label: t('hardwareInfo.SD卡可用占比'),
    aggregateType: IssueAggregateType.HISTOGRAM,
    histogramInterval: 0.01,
    fieldFilter: makeFieldFilterGt0('freeSdCard'),
  }, {
    available: isFreeSdAvailable,
    name: 'freeSdCard',
    aggregateType: IssueAggregateType.PERCENTILES,
    percents: percentilesAggPercents,
    fieldFilter: makeFieldFilterGt0('freeSdCard'),
  }, {
    available: isFreeMemAvailable,
    name: 'freeMemR',
    label: t('hardwareInfo.内存可用占比'),
    aggregateType: IssueAggregateType.HISTOGRAM,
    histogramInterval: 0.01,
    fieldFilter: makeFieldFilterGt0('freeMem'),
  }, {
    available: isFreeMemAvailable || isPc(pid, appId),
    name: 'freeMem',
    aggregateType: IssueAggregateType.PERCENTILES,
    percents: percentilesAggPercents,
    fieldFilter: makeFieldFilterGt0('freeMem'),
  }, {
    available: isFreeGpuMemAvailable,
    name: 'freeGpuMemR',
    label: t('hardwareInfo.GPU内存可用占比'),
    aggregateType: IssueAggregateType.HISTOGRAM,
    histogramInterval: 0.01,
    fieldFilter: makeFieldFilterGt0('freeGpuMem'),
  }, {
    available: isFreeGpuMemAvailable,
    name: 'freeGpuMem',
    aggregateType: IssueAggregateType.PERCENTILES,
    percents: percentilesAggPercents,
    fieldFilter: makeFieldFilterGt0('freeGpuMem'),
  }].filter(x => x.available).map(x => ({
    ...x,
    termsSizeLimit: 300,
    isSelfDefinedKeyValue: false,
  }));

  const percentilesDimensionKeyToLabel = {
    freeStorage: t('TRACKDATATAB.storageAvaible'),
    freeSdCard: t('TRACKDATATAB.sdAvaible'),
    freeMem: t('TRACKDATATAB.memoryAvaible'),
    freeGpuMem: t('TRACKDATATAB.GPU剩余可用内存大小'),
  };

  const percentilesTableColumns = [
    {
      title: ze('指标', 'Dimension'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => percentilesDimensionKeyToLabel[text] || text,
    },
    ...percentilesAggPercents.map(percent => {
      const percentKey = makePercentTableKey(percent);
      return {
        title: percentKey,
        dataIndex: percentKey,
        key: percentKey,
        align: 'right',
        render: (text, record) => {
          const v = Number(record[percentKey]);
          return Number.isFinite(v) ? formatBytes(v) : '-';
        },
      };
    }),
    {
      title: <div style={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'flex-end' }}>
        { ze('有效异常次数', 'Reports With Valid Data') }
        <Tooltip
          title={ze('部分上报由于异常中断等原因未采集到数据。', 'Data of some reports are not collected due to interruption or other reasons.')}
        ><WrappedTipsIcon style={{ color: CS_STYLES.SUB_TEXT_COLOR }}/></Tooltip>
      </div>,
      dataIndex: 'fieldFilterTotal',
      key: 'fieldFilterTotal',
      align: 'right',
    },
    {
      title: ze('有效次数占比', '% of Reports With Valid Data'),
      dataIndex: 'fieldFilterTotalRatio',
      key: 'fieldFilterTotalRatio',
      align: 'right',
      render: (text, record) => {
        const { total, fieldFilterTotal } = record;
        return total ? `${(fieldFilterTotal / total * 100).toFixed(2)}%` : '-';
      },
    },
  ];

  const downloadableFieldOptions = useMemo(() => {
    return fields
      .filter(x => x.aggregateType !== 1 && x.aggregateType !== IssueAggregateType.PERCENTILES)
      .map(x => ({ label: x.label, value: x.name }));
  }, [fields]);

  const [state, updateState] = useImmer({
    loading: true,
    searchConditionGroup: {
      conditions: [
        { field: FieldName.crashUploadTime, queryType: QueryType.RANGE_RELATIVE_DATETIME, gte: 7 * 86400 * 1000 },
      ],
    },
    chartDataList: [],
    percentilesTableData: [],
  });
  const { searchConditionGroup } = state;

  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const [searchTriggerCount, setSearchTriggerCount] = useState(0);

  // 拉取设备信息统计数据
  useEffect(() => {
    (async () => {
      if (!state.loading) {
        updateState((draft) => {
          draft.loading = true;
        });
      }

      const rsp = await RestHelper.post('/api/issue/queryIssueAggregate', {
        appId,
        platformId,
        issueHash,
        customFields: fields,
        searchConditionGroup,
      });
      const chartDataList = rsp.json.data
        .map((x, i) => refineIssueAggregateSingleResult(x, fields[i].aggregateType, { bucketKeyDict: fields[i].bucketKeyDict }))
        .map((x, i) => {
          const valueList = fields[i].bucketListHandler ? fields[i].bucketListHandler(x) : x.bucketList.map((y, j) => {
            return {
              ...y,
              name: y.key,
              count: y.docCount,
            };
          });
          return {
            key: fields[i].name,
            label: fields[i].label,
            aggregateType: fields[i].aggregateType,
            total: x.total,
            noDataCount: x.noDataCount,
            valueList,
          };
        })
        .filter((x, i) => fields[i].aggregateType !== IssueAggregateType.PERCENTILES);
      const percentilesTableData = rsp.json.data
        .filter((x, i) => fields[i].aggregateType === IssueAggregateType.PERCENTILES)
        .map(x => {
          const { bucketList, total, name, fieldFilterTotal } = x;
          const percentKeyToValue = Object.fromEntries(bucketList.map(y => [makePercentTableKey(y.key), y.value]));
          return {
            name,
            total,
            fieldFilterTotal,
            ...percentKeyToValue,
          };
        });

      updateState((draft) => {
        draft.chartDataList = chartDataList;
        draft.percentilesTableData = percentilesTableData;
        draft.loading = false;
      });
    })();
  }, [searchTriggerCount]);

  function onClickDownloadFields(fieldKeyList) {
    const downloadDataList = state.chartDataList.filter(x => fieldKeyList.includes(x.key));

    const title = `${t('hardwareInfo.全量数据')}.xlsx`;
    const dataObjectList = downloadDataList.map((x) => {
      const { label, total, valueList } = x;
      const header = [`${label}`, t('hardwareInfo.异常次数'), t('hardwareInfo.次数占比')];

      const body = valueList.filter(x => x.visibleInDownload).map(x => {
        return [
          x.name,
          x.count,
          `${(x.count / total * 100).toFixed(2)} %`,
        ];
      });

      const totalLabel = ze('总异常数', 'Total Reports');
      const data = [header, ...body, [totalLabel, total]];
      return { sheetName: label, data };
    });
    XlsxUtil.createMultipleSheetsExcelAndDownload(title, dataObjectList);
  }

  return (
    <div>
      <Spin spinning={state.loading}>
        <div style={{ marginBottom: '12px' }}>
          <IssueCrashFilterEx
            hideIssueFields={true}
            fieldBlacklist={['crashId', 'issueId', 'bundleId', 'osVersion', 'deviceIdList', 'expUid', 'crashId', 'issueId', 'userId', 'exceptionTypeList', 'uploadTime', 'crashTime']}
            fixedFieldList={[FieldName.crashUploadTime]}
            platformId={platformId}
            filterOptions={{
              version: versionOptions,
            }}
            searchConditionGroup={searchConditionGroup}
            onChange={({ searchConditionGroup }) => {
              updateState(draft => {
                draft.searchConditionGroup = searchConditionGroup;
              });
            }}
            onSubmit={({ searchConditionGroup }) => {
              updateState(draft => {
                draft.searchConditionGroup = searchConditionGroup;
              });
              setSearchTriggerCount(searchTriggerCount + 1);
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '12px' }}>
          <CsDownloadButton
            disabled={downloadableFieldOptions.length === 0}
            onClick={() => setDownloadModalVisible(true)}
          />
        </div>
        <div style={{ minHeight: '100px' }}>
          <Row gutter={5}>
            {state.chartDataList.filter(x => x.aggregateType === 0).map(x => (
              <Col span={8} key={x.key}><ChartContainer option={x}/></Col>
            ))}
          </Row>
          <Row gutter={5}>
            {state.chartDataList.filter(x => x.aggregateType !== 0).map(x => (
              <Col span={8} key={x.key}><ChartContainer option={x}/></Col>
            ))}
          </Row>
        </div>
        { fields.some(x => x.aggregateType === IssueAggregateType.PERCENTILES) && <div>
          <Table
            size='small'
            columns={percentilesTableColumns}
            dataSource={state.percentilesTableData}
            pagination={false}
          />
        </div> }
      </Spin>
      <IssueChartDownloadFieldSelectModal
        fieldOptions={downloadableFieldOptions}
        visible={downloadModalVisible}
        onVisibleChange={(v) => setDownloadModalVisible(v)}
        onConfirm={(v) => onClickDownloadFields(v)}
      />
    </div>
  );
};

function createExcel(data,title){
  const { utils } = XLSX;
  var ws_name = "sheet1";
  const wb= utils.book_new();
  /* make worksheet */
  var ws = utils.aoa_to_sheet(data);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, title);
}

/**
 * @param {{key: String,
 *   label: String,
 *   total: Number,
 *   valueList: {name, count, isNoData}[],
 * }} option
*/
function handleDownload(option) {
  const { label, total, valueList } = option;
  const title = `${label}-${i18n.t('hardwareInfo.全量数据')}.xlsx`;
  const header = [`${label}`, i18n.t('hardwareInfo.异常次数'), i18n.t('hardwareInfo.次数占比')];

  const body = valueList.filter(x => x.visibleInDownload).map(x => {
    return [
      x.name,
      x.count,
      `${(x.count / total * 100).toFixed(2)} %`,
    ];
  });

  const totalLabel = ze('总异常数', 'Total Reports');
  const datas = [header, ...body, [totalLabel, total]];
  createExcel(datas, title);
}

/**
 *
 * @param {{option: {
 *   key: String,
 *   label: String,
 *   total: Number,
 *   noDataCount: Number,
 *   aggregateType: Number,
 *   valueList: {name, count, isNoData}[],
 * }}} props
 */
const ChartContainer = (props) => {
  const { t } = useTranslation();
  const { ze } = useZhEn();
  const { option } = props;
  let chartOptions = {};

  let subtext = '';
  const hasDataCount = option.total - option.noDataCount;
  if (option.noDataCount === 0) {
    subtext = `${t('hardwareInfo.有效异常次数')}: ${hasDataCount}`;
  } else {
    subtext = `${t('hardwareInfo.有效异常次数')}: ${hasDataCount} \n${t('hardwareInfo.已过滤n条无数据上报', { n: option.noDataCount })}`;
  }

  if (option.aggregateType === 0 || option.aggregateType === 2) {
    chartOptions = {
      color: COLOR_LIST,
      title: {
        text: option.label,
        subtext,
        x: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: `{b}<br>${t('hardwareInfo.异常次数')}: {c}<br>${t('hardwareInfo.次数占比')}: {d} %`,
        /* formatter: (params) => {
          const { data, percent } = params;
          return `${data.name}<br>次数: ${data.value}<br>占比: ${percent} %`;
        }, */
      },
      series: [{
        type: 'pie',
        radius: '50%',
        data: option.valueList.filter(x => x.visibleInChart).map(x => ({
          value: x.count,
          name: x.isNoData ? t('hardwareInfo.无数据') : x.name,
          ...( x.isNoData && {itemStyle: { color: '#EEE' }, emphasis: {itemStyle: { color: '#EEE'}}}),
          ...( x.isShowAsOthers && { itemStyle: { color: '#8e5933' }, emphasis: { itemStyle: { color: '#8e5933' } } }),
        })),
        label: {
          position: 'outer',
          alignTo: 'edge',
          margin: 10,
        },
      }],
    };
  } else if (option.aggregateType === 1) {
    chartOptions = {
      color: COLOR_LIST,
      title: {
        text: option.label,
        subtext,
        x: 'center',
      },
      grid: {
        left: 60,
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          // console.warn(params);
          const { data } = params;
          const { value: [xValue, yValue], name, reportCount } = data;
          return `${name}<br>${t('hardwareInfo.异常次数')}: ${reportCount}<br>${t('hardwareInfo.次数占比')}: ${Math.round(yValue * 100)} %`;
        },
      },
      xAxis: [{
        name: ze(`${option.label}`, `% of ${option.label}`),
        nameLocation: 'center',
        nameGap: 25,
        type: 'value',
        scale: true,
        axisLabel: {
          formatter: (x) => `${x}%`,
        },
        max: 100,
      }],
      yAxis: [{
        name: ze('上报占比', '% of Reports'),
        type: 'value',
        offset: 10,
        axisLabel: {
          formatter: (y) => `${Math.round(y * 100)}%`,
        },
        max: 1,
      }],
      series: [{
        type: 'bar',
        barWidth: '80%',
        data: refineHistogramData(ze, option),
        label: {
          position: 'outer',
          alignTo: 'edge',
          margin: 10,
        },
      }],
    };
  }

  return (
    <div style={{ position: 'relative' }}>
      <ReactECharts
        option={chartOptions}
        style={{ width: '100%' }}
      />
      {/* option.aggregateType !== 1 && <Button
        style={{ position: 'absolute', top: '0px', right: '20px' }}
        icon={<DownloadOutlined />}
        size="small"
        onClick={() => handleDownload(option)}
      >{ t('hardwareInfo.下载') }</Button>*/}
    </div>
  );
};

DeviceStats.propTypes = {
  appId: PropTypes.string,
  platformId: PropTypes.string,
  issueHash: PropTypes.string,
};

const mapStateToProps = state => ({
  reduxState: state,
});

export default connect(mapStateToProps)(DeviceStats);
