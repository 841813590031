import React, { useState, useEffect } from 'react';
import { Button, Drawer, Menu, Checkbox, Tag, Badge, Empty, Spin, Image } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import gfm from "remark-gfm";
import { isZh, ze } from 'utils/zhEn';
import { NoticeRecordCategory } from 'utils/constants/notice-record-category';
import reportEvent, { EVENT_ACTIONS } from 'utils/reportEvent';
import scss from './NoticeDrawer.scss';
import { ErrorBoundary } from 'react-error-boundary';
import NoJumpNextPagination from 'components/antd-extension/NoJumpNextPagination.jsx';
import NoticeDrawerEcharts from './NoticeDrawerEcharts';
import IconCrashList from 'svg/v2/newcs_dashboard_left_crash_icon_normal.svg';
import IconErrorList from 'svg/v2/newcs_dashboard_left_error_icon_normal.svg';
import MarkasreadNormal from 'svg/v2/newcs_dashboard_message_markasread_normal_icon.svg';
import MarkasunreadNormal from 'svg/v2/newcs_dashboard_message_markasunread_normal_icon.svg';
import { CS_STYLES } from 'utils/constants/style-constants';
import { sleep } from "utils/sleep";

// 提取根据不同条件生成复杂内容过滤参数的逻辑到独立函数
const generateComplexContentFilter = (key) => {
  let categoryList = null;
  if (key === 'ADD') {
      categoryList = null;
  } else if (key === 'System') {
      categoryList = [
          NoticeRecordCategory.APP_PRECONFIGURED_CRASH_ALARM,
          NoticeRecordCategory.APP_PRECONFIGURED_ERROR_ALARM
      ];
  } else if (key === 'Custom') {
      categoryList = [
          NoticeRecordCategory.APP_CUSTOM_CRASH_ALARM,
          NoticeRecordCategory.APP_CUSTOM_ERROR_ALARM
      ];
  } else {
      categoryList = [
          NoticeRecordCategory.NEW_FEATURE,
          NoticeRecordCategory.OPTIMIZATION,
          NoticeRecordCategory.NEW_ITERATION,
      ];
  }
  return categoryList ? categoryList : null;
};

const App = (prop) => {
  const {
    appId,
    drawerOpen,
    drawerDisable,
    listNotices,
    getMarkAllAsRead,
    getChangeReadStatus,
    getListNotices,
    total,
  } = prop;

  const items = [
    {
      label:`${ze('全部','All')}`,
      key:'ADD',
      value: '',
    },{
      label:`${ze('系统告警','System Alert')}`,
      key: 'System',
      value: 'APP',
    },{
      label:`${ze('自定义告警','Custom Alert')}`,
      key: 'Custom',
      value: 'APP',
    },{
      label:`${ze('公告','Notice')}`,
      key:'GLOBAL',
      value: 'GLOBAL',
    }
  ];

  const announcementTypeList = [
    {
      label:`${ze('功能上线','Feature Launch')}`,
      value:NoticeRecordCategory.NEW_FEATURE,
      color: "default",
      icon: null,
    },{
      label:`${ze('功能优化','Feature Optimization')}`,
      value:NoticeRecordCategory.OPTIMIZATION,
      color: "default",
      icon: null,
    },{
      label:`${ze('版本迭代','Version Update')}`,
      value:NoticeRecordCategory.NEW_ITERATION,
      color: "default",
      icon: null,
    },{
      label:`${ze('自定义告警 - 崩溃','Custom Crash')}`,
      value:NoticeRecordCategory.APP_CUSTOM_CRASH_ALARM,
      color:'red',
      icon: <IconCrashList width="14" height="14" viewBox="0 0 20 20" style={{color:'#FF5461'}} />,
    },{
      label:`${ze('自定义告警 - 错误','Custom Error')}`,
      value:NoticeRecordCategory.APP_CUSTOM_ERROR_ALARM,
      color:'purple',
      icon: <IconErrorList  width="14" height="14" viewBox="0 0 20 20" style={{color:'#a452DD'}}/>,
    },{
      label:`${ze('系统告警 - 崩溃','System Alert and Crash')}`,
      value:NoticeRecordCategory.APP_PRECONFIGURED_CRASH_ALARM,
      color:'#FF5461',
      icon: <IconCrashList width="14" height="14" viewBox="0 0 20 20" style={{color:'#FFFFFF'}} />,
    },{
      label:`${ze('系统告警 - 错误','System Alert and Error')}`,
      value:NoticeRecordCategory.APP_PRECONFIGURED_ERROR_ALARM,
      color:'#A452DD',
      icon: <IconErrorList  width="14" height="14" viewBox="0 0 20 20" style={{color:'#FFFFFF'}}/>,
    },
  ];

  const [ presentState, setPresentState ] = useState(true);
  const [ contextState, setContextState ] = useState('ADD');
  const [ loading, setLoading ] = useState(false);
  const [ current, setCurrent ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ readerData, setReaderData ] = useState([]);
  const [ trigger, setTrigger ] = useState(0);
  const [ complexContentFilter, setComplexContentFilter ] = useState();

  useEffect(() => {
    const intervalId  = setInterval(() => {
      setTrigger(prevCount => prevCount + 1);
      setPresentState(true);
    }, 300000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    setCurrent(1);
  },[pageSize])
  useEffect(() => {
    setReaderData(listNotices)
  },[listNotices,total])

  useEffect(() => {
    if(appId){
      getListNotices({current,pageSize,onlyUnread:presentState,complexContentFilter});
    }
    setLoading(false);
  },[appId,trigger,current,pageSize,presentState,complexContentFilter])

  const getUnreadState = (e) => {
    setLoading(true);
    setPresentState(!presentState);
    setCurrent(1);
  }


  const updateContext = async(data) => {
    setLoading(true);
    const { key, value} = data;
    const hierarchyLevel = value;
    const categoryList = generateComplexContentFilter(key);
    const complexContentFilter = hierarchyLevel && categoryList && {hierarchyLevel, categoryList};
    await sleep(1000);
    setComplexContentFilter(complexContentFilter);
    setContextState(key);
    setLoading(false);

  }

  //分页器切换
  const paginationChange = (page,pageSize) => {
    setLoading(true);
    setCurrent(page);
  }

  const paginationSizeChange = (current,size) => {
    setLoading(true);
    setCurrent(1);
    setPageSize(size);
  }

  const updateMouseEnter = (recordId) => {
    setReaderData((prevDataList) =>
      prevDataList.map((item) =>
        item.recordId === recordId ? { ...item, showEdit: true } : item
      )
    );
  }

  const updateMouseLeave = (recordId) => {
    setReaderData((prevDataList) =>
      prevDataList.map((item) =>
        item.recordId === recordId ? { ...item, showEdit: false } : item
      )
    );
  }

  const updateReadState = async(recordId,hasRead) => {
    setLoading(true);
    getChangeReadStatus(recordId,hasRead);
    await sleep(1000);
    if(current === 1){
      getListNotices({current,pageSize,onlyUnread:presentState,complexContentFilter});
    } else {
      setCurrent(1);
    }
    setLoading(false);
  }

  const updateAllAsRead = async() => {
    setLoading(true);
    getMarkAllAsRead();
    await sleep(1000);
    setCurrent(1);
    setPresentState(false);
    setLoading(false);

    reportEvent({
      action: EVENT_ACTIONS.CLICK,
      tp1: '一键已读',
    });
    setCurrent(1);
    if(!presentState && current === 1){
      getListNotices({current,pageSize,onlyUnread:presentState,complexContentFilter});
    }
  }

  const getLabelByValue = (value) => {
    const selectedType = announcementTypeList.find(type => type.value === value);
    return (
      <div>
        {selectedType && (
          <Tag color={selectedType.color}>
            <div style={{display:'flex',gap:'5px',alignItems:'center'}}>
              <div style={{height:'16px'}}>{selectedType.icon}</div>
              <div>{selectedType.label}</div>
            </div>
          </Tag>
        )}
      </div>
    )
  }

  const drawerDisableClose = () => {
    drawerDisable();
    if(!presentState){
      setComplexContentFilter();
      setPresentState(true);
    } else {
      setComplexContentFilter();
      getListNotices({current,pageSize,onlyUnread:presentState});
    }
  }

  const noticeHeadMenu = items.map(item => (
    <li
      key={item.key}
      style={{
        borderBottom: contextState === item.key ? `4px solid ${CS_STYLES.PRIMARY_COLOR}` : '',
      }}
      onClick={() => updateContext(item)}
    >
      <div
        className={contextState === item.key ? scss.navigatorChoose : scss.navigatorUnselected}
      >
        {item.label}
      </div>
    </li>
  ))

  return (
    <div>
      <Drawer
        placement='right'
        closable={false}
        onClose={drawerDisableClose}
        open={drawerOpen}
        width={480}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Spin spinning={loading}>
          <div className={scss.notice}>
            <div>
              <ul class={scss.navigator}>{noticeHeadMenu}</ul>
              <div
                style={{
                  display:'flex',
                  borderTop: '1px solid #E9E9E9',
                  borderBottom: '1px solid #E9E9E9',
                  fontSize: '14px',
                  padding:'16px 24px'}}>
                <Checkbox checked ={presentState} onChange={(e) => getUnreadState(e)}>{ze('只看未读','View only Unread')}</Checkbox>
                <div
                  onClick={updateAllAsRead}
                  style={{cursor: 'pointer',marginLeft:'20px',display:'flex',alignItems:'center',gap:'4px'}}
                  className={scss.MarkasreadNormal}
                >
                  {/* <img src={markasread_normal_icon} alt="" /> */}
                  <MarkasreadNormal />
                  <div>{ze('一键已读','Mark all as Read')}</div>
                </div>
              </div>
            </div>
            <div style={{height:'calc(100vh - 85px - 44px)',overflowX:"auto"}}>
              {readerData.length > 0 ? readerData.map((item,index) => (
                <div key={item.recordId}>
                  <hr/>
                  <div style={{ backgroundColor: item.showEdit ? '#f0f0f0' : 'transparent'}}
                    onMouseEnter={() => updateMouseEnter(item.recordId)}
                    onMouseLeave={() => updateMouseLeave(item.recordId)}
                  >
                    <div style={{display:'flex',alignItems:'center',height:'22px',padding:'16px 24px 0 0'}}>
                      <div style={{width:'24px'}}>{ !item.hasRead && <Badge status="error" style={{margin: '8px'}} size='small'/>}</div>
                      <div>{getLabelByValue(item.category)}</div>
                      <div style={{flex:'1',fontWeight:'bolder',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{isZh() ? item.titleZh : item.titleEn}</div>
                      {item.showEdit && (
                        <Button type="link" onClick={() => updateReadState(item.recordId, item.hasRead)}>
                          {item.hasRead ? <div class={scss.Read}>{<MarkasreadNormal />}</div> : <div className={scss.unread}><MarkasunreadNormal /></div>}
                        </Button>
                      )}
                    </div>
                    <div style={{margin:'16px 24px'}}>
                      <ReactMarkdown
                        remarkPlugins={[gfm]}
                        linkTarget='_blank'
                        children={isZh() ? item.contentZh : item.contentEn}
                          components={{
                            img({ node, ...props}) {
                              return <div style={{ maxWidth:'100%' }}>
                                <Image src={props.src} />
                              </div>
                            },
                            a({ node, ...props }) {
                              const handleClick = (event) => {
                                updateReadState(item.recordId, item.hasRead)
                              };
                              return <a onClick={handleClick} {...props} />;
                            },
                          }}
                        />
                    </div>
                    <ErrorBoundary fallback={<div style={{ color: 'red',fontSize:'14px' }}>
                      {ze('渲染出错，请联系管理员','Render error, please contact administrator.')}
                    </div>}>
                      <NoticeDrawerEcharts key={item.recordId} data={item.otherInfo}/>
                    </ErrorBoundary>
                    <div style={{padding:'0 0 20px 24px',color:'#AAA',fontSize:'13px'}}>{item.updateTime}</div>
                  </div>
                </div>

              )) :  <div style={{position: 'relative',width:'100%',height:'100%'}}>
                <Empty
                  description={ze('暂无数据','No messages available')}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{position:'absolute',top:'50%',left:'50%',transform: 'translate(-50%, -50%)'}}/>
              </div>}
              <div style={{display:'flex',justifyContent:'flex-end',margin:'10px 0'}}>
                <NoJumpNextPagination
                  size='small'
                  total={total}
                  current={current}
                  pageSize={pageSize}
                  onChange={(page,pageSize) => paginationChange(page,pageSize)}
                  onShowSizeChange={ (current,size) => paginationSizeChange(current,size)}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Drawer>
    </div>
  );
};
export default App;
