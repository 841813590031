import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tag } from 'antd';
import { formatCrashPercentageString } from 'utils/format/format-number';
import { ze } from 'utils/zhEn';
import { isNotNullish } from 'utils/nullish';

const TOP_N = 5;

const WangZheTopFormList = ({
  issueId,
  issueIdToWangZheTopFormList,
}) => {
  if (!issueIdToWangZheTopFormList || !issueIdToWangZheTopFormList[issueId]) {
    return null;
  }
  const topFormList = (issueIdToWangZheTopFormList[issueId] || []).map(x => {
    const { matchedCount, totalCount } = x;
    const percentage = totalCount > 0 ? Math.floor(100 * matchedCount / totalCount) : 0;
    return {
      ...x,
      percentage,
    }
  });
  const topFormListTopN = topFormList.slice(0, TOP_N);
  let otherItem = null;
  if (topFormList.length > TOP_N) {
    const totalCount = topFormList[0].totalCount;
    otherItem = {
      name: ze('其他', 'Others'),
      matchedCount: totalCount - topFormListTopN.reduce((acc, cur) => acc + cur.matchedCount, 0),
      percentage: 100 - topFormListTopN.reduce((acc, cur) => acc + cur.percentage, 0),
      totalCount: topFormList[0].totalCount,
    };
  }

  const resultList = isNotNullish(otherItem) ? [...topFormListTopN, otherItem] : topFormListTopN;

  return <div>{
    resultList.map(x => {
      const { name, percentage } = x;
      return <Tag color='geekblue'>{ name } ({ percentage }%)</Tag>
    })
  }</div>;
};

export default WangZheTopFormList;
