import React, { useEffect, useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { QueryType } from 'components/commons/IssueCrashFilter/IssueCrashFilterExUtil';
import { ze } from 'utils/zhEn';

const V_STEAM_DECK = 'STEAM_DECK';
const V_NOT_STEAM_DECK = 'NOT_STEAM_DECK';

const STEAM_DECK_TERM_VALUE = 'Steam Deck';

const PcDeviceSelect = ({
  isReadOnly,
  fieldValue,
  onChangeFieldValue,
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => ([
    { label: 'Steam Deck', value: V_STEAM_DECK },
    { label: 'Windows', value: V_NOT_STEAM_DECK },
  ]), []);

  const {
    queryType,
    term,
    not,
  } = fieldValue || {};

  if (queryType && queryType !== QueryType.TERM) {
    return <div>{ JSON.stringify(fieldValue) }</div>;
  }
  if (term && term !== STEAM_DECK_TERM_VALUE) {
    return <div>{ JSON.stringify(fieldValue) }</div>;
  }

  let selectValue;
  if (queryType && term) {
    if (not) {
      selectValue = V_NOT_STEAM_DECK;
    } else {
      selectValue = V_STEAM_DECK;
    }
  }

  return <Select
    style={{ width: '100%', fontSize: '14px' }}
    options={options}
    placeholder={ze('不限', 'All')}
    value={selectValue}
    allowClear={true}
    onChange={(v) => {
      if (isReadOnly) {
        return;
      }
      if (!v) {
        onChangeFieldValue({});
      } else if (v === V_STEAM_DECK) {
        onChangeFieldValue({ queryType: QueryType.TERM, term: STEAM_DECK_TERM_VALUE, not: false });
      } else if (v === V_NOT_STEAM_DECK) {
        onChangeFieldValue({ queryType: QueryType.TERM, term: STEAM_DECK_TERM_VALUE, not: true });
      }
    }}
  />
}

export default PcDeviceSelect;
