export const ServerAppSettings = Object.freeze({
  keys: Object.freeze({
    enableOom: 'enableOom',
    enableFeatureAnalysis: 'enableFeatureAnalysis',
    /** @deprecated 现在特征全部支持标签方式 */
    // enableFeatureAnalysisRealtimeTag: 'enableFeatureAnalysisRealtimeTag',
    enableIssueRegroup: 'enableIssueRegroup',
    enableErrorRetrace: 'enableErrorRetrace',
    /** @deprecated 页面上是否支持启动次数和次数崩溃率（PC商业化项目默认是隐藏的），目前这个开关已经废弃，现在是全量开放 */
    // enableDashboardAccessCount: 'enableDashboardAccessCount',
    enableAiAnalyse: 'enableAiAnalyse',
    enableSdkStrategyConfig: 'enableSdkStrategyConfig',
    enableIssueLifetimeAllDataStats: 'enableIssueLifetimeAllDataStats',
    enableCustomErrorTypes: 'enableCustomErrorTypes',
    ignoreCustomKeyType: 'ignoreCustomKeyType', // DFM项目用的，--story=118169915 【CS】【DFM】兼容目前DFM线上版本，字符型的自定义kv，也要支持当作数值型kv存储数值并支持直方图
    commonSequenceCustomKeyList: 'commonSequenceCustomKeyList',
    enableOnlineRetrace:'enableOnlineRetrace', // 已废弃，现在在线还原是全量开放了
    enableSubModuleId: 'enableSubModuleId',
    /** 页面上大盘是否支持选择场景维度 */
    enableUserSceneTag: 'enableUserSceneTag',
    /** 元梦需求，页面上的场景字段（UserSceneTag）的自定义显示名字（中文） */
    customUserSceneTagLabelZh: 'customUserSceneTagLabelZh',
    /** 元梦需求，页面上的场景字段（UserSceneTag）的自定义显示名字（英文） */
    customUserSceneTagLabelEn: 'customUserSceneTagLabelEn',
    /** 项目是否支持“重新打开”的问题状态。 */
    enableIssueStatusReopened: 'enableIssueStatusReopened',
    /** 页面上，安卓端，过滤器是否支持GPU字段（安卓不是所有项目都支持，用开关控制） */
    enableAndroidSearchGpu: 'enableAndroidSearchGpu',
    disableStatsByCountry: 'disableStatsByCountry', // 异常概览中是否关闭按国家维度统计详情
    enableDevicesPercentage: 'enableDevicesPercentage', // 异常概览中是否展示32位CPU占比
    pcClassify:'pcClassify',
    dailyReportSetting: 'dailyReportSetting',
    enablePcLogTrace: 'enablePcLogTrace', // 崩溃详情-跟踪日志TAB下，堆栈还原按钮的开关。
    enableIssueMerge: 'enableIssueMerge', // 支持多issue合并
    enableAdvancedTrend: 'enableAdvancedTrend', // 支持趋势对比
    /** 页面上是否支持洞察分析 */
    enableInsightPage: 'enableInsightPage',
    tapdFieldList: 'tapdFieldList', // 页面上TAPD提单的时候，表单中配置展示的字段列表
    /** 叠纸需求，2.0页面上报详情把channelId字段去掉了，但是部分旧的项目还在用，此开关控制是否支持显示旧的channelId字段。
     * https://tapd.woa.com/tapd_fe/20421727/story/detail/1020421727120685673 */
    enableReportFieldChannelId: 'enableReportFieldChannelId',
    /** 页面上成员管理是否可以批量添加/删除人员。 https://tapd.woa.com/tapd_fe/20421727/story/detail/1020421727120718765 */
    enableBatchAddOrDeleteMembers: 'enableBatchAddOrDeleteMembers',
    /** OOM页面上是否能看到“崩溃系统强杀类型”过滤器 */
    enableOomStatusFilter: 'enableOomStatusFilter',
    /** 新增告警规则里APP告警下拉框是否支持自定义webhook */
    enableAlarmCustomWebhook: 'enableAlarmCustomWebhook',
    /** HoK需求，大盘统计，默认展示的vm type */
    dashboardDefaultVmType: 'dashboardDefaultVmType',
    /** 王者需求，是否支持自定义卡顿类型。 */
    enableJank: 'enableJank',
  }),
});
